import { createSlice } from "@reduxjs/toolkit";

import { API } from "../../../utils/axios/index";
import {
    requestComplete,
    requestFail,
    requestStart,
    toastError,
    toastMessage,
} from "../global/global";

const initialState = {
    alerts: [],
};

function sortAlerts(alerts) {
    alerts.sort((alert1, alert2) => {
        if (alert1.region.label !== alert2.region.label) {
            return alert1.region.label.toLowerCase() <
                alert2.region.label.toLowerCase()
                ? -1
                : 1;
        }

        if (alert1.channel.label !== alert2.channel.label) {
            return alert1.channel.label.toLowerCase() <
                alert2.channel.label.toLowerCase()
                ? -1
                : 1;
        }

        return alert1.name.toLowerCase() < alert2.name.toLowerCase() ? -1 : 1;
    });
}

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setAlerts(state, action) {
            state.alerts = action.payload;
        },
    },
});

export const { setAlerts } = alertSlice.actions;

export default alertSlice.reducer;

export const fetchAlerts = () => (dispatch) => {
    dispatch(requestStart());

    return API.get("/alert-exceptions/1")
        .then((response) => {
            const { data: alerts } = response.data;

            sortAlerts(alerts);

            dispatch(requestComplete());
            dispatch(setAlerts(alerts));

            return alerts;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Alerts fetch failed";

            dispatch(requestFail(message));
        });
};

export const editAlerts = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/edit_alert_exceptions_config", data)
        .then(() => {
            dispatch(
                requestComplete({
                    success: true,
                    successMessage: "Alerts edited successfully.",
                })
            );

            // return dispatch(fetchAlerts());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Alerts edit failed";

            dispatch(requestFail(message));
        });
};
