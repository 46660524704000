/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { map, isEmpty, cloneDeep, find } from "lodash";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
    resetFilterData as resetFilterDataHelper,
    getUpdatedFilters,
} from "../../../utils/helpers/filterHelper";
import {
    requestStart,
    requestComplete,
    requestFail,
    requestStartNoLoader,
    requestCompleteNoLoader,
    requestFailNoLoader,
} from "../global/global";

const initialState = {
    retainFilters: false,
    filtersList: {}, // Moved to global as screenConfig
    filtersLabels: {}, // Moved to global as elementLabels
    filtersData: {},
    activeScreen: null,
    selectedFilters: {},
    lastSavedFilters: {},
    filteredProducts: [],
    savedFilters: {
        EU_VIEW_BY_FILE_UPLOAD: {},
        EU_VIEW_BY_FILTERING_ATTRIBUTES: {},
        EU_VIEW_BY_PLAN_GROUPS: {},
        EU_SELECT_PLAN_GROUPS: {},
        VIEW_PLAN_GROUP_CONFIG: {},
        PRICE_RULES_LIST: {},
        EU_PRICE_FILE_HISTORY: {},
    },
};

const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setLastSavedFilterValues(state, action) {
            const { payload, from } = cloneDeep(action.payload);
            let newLastSavedFilters = { ...state.lastSavedFilters };
            newLastSavedFilters[from] = payload;
            state.lastSavedFilters = newLastSavedFilters;
        },
        resetFiltersData(state, action) {
            let { payload, activeScreen } = cloneDeep(action.payload);
            state.filtersData = payload;
            state.activeScreen = activeScreen;
        },
        resetFilterOptions(state, action) {
            let newFiltersData = resetFilterDataHelper(state.filtersData);
            state.filtersData = newFiltersData;
            state.activeScreen = action.activeScreen;
        },
        setRetainFilterOptions(state, action) {
            state.retainFilters = action.payload;
        },
        setSelectedFilterOptions(state, action) {
            state.selectedFilters = action.payload;
        },
        setFilteredData(state, action) {
            const {
                filterName,
                dataType,
                payload,
                from,
                activeScreen,
                updateLastSaved,
            } = cloneDeep(action.payload);
            let filtersData = payload;
            if (filterName) {
                let newFiltersData = { ...state.filtersData };

                if (!isEmpty(newFiltersData[filterName])) {
                    //case filter name already exists in reducer

                    if (dataType === "options") {
                        newFiltersData[filterName][dataType] = payload;
                    }
                    if (
                        dataType === "options" &&
                        newFiltersData[filterName].callApi
                    ) {
                        newFiltersData[filterName].callApi = false;
                    }

                    if (dataType === "selectedItems") {
                        let resetValues = false;

                        map(Object.keys(newFiltersData), (o) => {
                            if (resetValues && !o.includes("dateRange")) {
                                newFiltersData[o] = {
                                    options: [],
                                    selectedItems: [],
                                    selectedItemsArray: [],
                                };
                            }
                            if (o === filterName && o.includes("dateRange")) {
                                newFiltersData[o] = payload;
                            }
                            if (o === filterName && !o.includes("dateRange")) {
                                newFiltersData[o][dataType] = payload;
                                newFiltersData[o]["selectedItemsArray"] = map(
                                    payload,
                                    (e) =>
                                        //e.value.replace(/'/g, "''")
                                        o === "custom_style_group"
                                            ? e.id
                                            : e.value
                                );
                                resetValues =
                                    from === "reportingToCreateMd"
                                        ? false
                                        : true; //filters onwards this one will get reset
                            }
                        });
                    }
                } else {
                    //case filter name doesn't exists in reducer, then add it to the reducer
                    //this case will only exist for options type
                    newFiltersData[filterName] = filterName.includes(
                        "dateRange"
                    )
                        ? payload
                        : {
                              options: payload,
                              selectedItems: [],
                              selectedItemsArray: [],
                          };
                }
                filtersData = newFiltersData;
            }
            if (!filterName && !isEmpty(state.filtersData)) {
                let newFiltersData = cloneDeep(state.filtersData);
                map(Object.keys(newFiltersData), (o) => {
                    filtersData[o] = newFiltersData[o];
                });
            }
            if (updateLastSaved && filterName && dataType === "selectedItems") {
                let newLastSaved = cloneDeep(state.lastSavedFilters);
                if (
                    newLastSaved[from] &&
                    newLastSaved[from].filtersData &&
                    newLastSaved[from].filtersData[filterName]
                ) {
                    newLastSaved[from].filtersData[filterName].options =
                        payload;
                    newLastSaved[from].filtersData[filterName].selectedItems =
                        payload;

                    state.lastSavedFilters = newLastSaved;
                }
            }
            state.activeScreen = activeScreen;
            state.filtersData = filtersData;
        },
        setFilteredProducts(state, action) {
            state.filteredProducts = action.payload;
        },
        setSavedFilters(state, action) {
            const { screen, data } = action.payload;

            state.savedFilters[screen] = data;
        },
        resetSavedFilters(state, action) {
            const { screen } = action.payload;

            state.savedFilters[screen] = {};
        },
    },
});

export const {
    setFilteredData,
    setLastSavedFilterValues,
    resetFiltersData,
    resetFilterOptions,
    setRetainFilterOptions,
    setSelectedFilterOptions,
    setFilteredProducts,
    setSavedFilters,
    resetSavedFilters,
} = filterSlice.actions;

export default filterSlice.reducer;

export const getFilterOptions =
    ({
        requestObject,
        filterName,
        from,
        selectedItems,
        filterEndpoint,
        filterEndpointMethod,
        updateLastSaved,
        filterfromExisting,
    }) =>
    (dispatch, getState) => {
        dispatch(requestStartNoLoader());
        API({
            method: filterEndpointMethod,
            url: `/${filterEndpoint}`,
            data: requestObject,
        })
            .then((response) => {
                if (response.data && response.data.status === true) {
                    let data = _.cloneDeep(response.data.data);

                    // Edit Promo Flow - Limit hierarchy options to event selection
                    if (!_.isEmpty(filterfromExisting)) {
                        const currentEvent = filterfromExisting?.[0];
                        const { product_hierarchy } = currentEvent;
                        const existingFilterOptions =
                            product_hierarchy[filterName];
                        if (existingFilterOptions) {
                            data = data.filter((d) =>
                                existingFilterOptions
                                    .map((o) => o.value)
                                    .includes(d.value)
                            );
                        }
                    }
                    const filtersDataPayload = {
                        payload: data || [],
                        filterName,
                        dataType: "options",
                        activeScreen: from,
                    };
                    dispatch(setFilteredData(filtersDataPayload));

                    if (!isEmpty(selectedItems)) {
                        //set selected items if data is present
                        //case: autofill filters
                        const findOption = find(data, {
                            value: selectedItems[0] && selectedItems[0].value,
                        });
                        const setDefaultSeletedItem = {
                            payload: [findOption],
                            filterName,
                            dataType: "selectedItems",
                            from: from,
                            activeScreen: from,
                            updateLastSaved,
                        };
                        dispatch(setFilteredData(setDefaultSeletedItem));
                    }
                    dispatch(requestCompleteNoLoader());
                }
            })
            .catch((error) => {
                const errorMessage = setErrorMessage(error);
                dispatch(requestFailNoLoader(errorMessage));
            });
    };

export const getFilterOptionsForSimulator =
    (payload) => (dispatch, getState) => {
        dispatch(requestStart());
        return API.post(`/filters`, payload)
            .then((response) => {
                if (response.data && response.data.status === true) {
                    let data = _.cloneDeep(response.data.data);
                    dispatch(requestComplete());
                    return data;
                }
            })
            .catch((error) => {
                const errorMessage = setErrorMessage(error);
                dispatch(requestFail(errorMessage));
            });
    };

export const setSelectedFilterData =
    ({ data, filterName, from, activeScreen }) =>
    (dispatch, getState) => {
        const filtersDataPayload = {
            payload: data,
            filterName,
            dataType: "selectedItems",
            from,
            activeScreen: from,
        };
        dispatch(setFilteredData(filtersDataPayload));
    };

export const setLastSavedFilters =
    ({ data, from }) =>
    (dispatch, getState) => {
        // dispatch(requestStart());
        const lastSavedFiltersPayload = {
            payload: cloneDeep(data),
            from,
        };
        dispatch(setLastSavedFilterValues(lastSavedFiltersPayload));
        // dispatch(requestComplete());
    };

export const setFiltersData =
    ({ data, from }) =>
    (dispatch, getState) => {
        const resetPayload = {
            payload: data,
            activeScreen: from,
        };
        dispatch(resetFiltersData(resetPayload));
    };

export const resetFilters = (from) => (dispatch, getState) => {
    const resetPayload = {
        activeScreen: from,
    };
    dispatch(resetFilterOptions(resetPayload));
};

export const setSelectedFilters = (selectedFilters) => (dispatch, getState) => {
    dispatch(setSelectedFilterOptions(selectedFilters));
};

export const setRetainFilters = (data) => (dispatch, getState) => {
    dispatch(setRetainFilterOptions(data));
};

/* Decision Dashboard - Last Selected Filters */
export const setLastSelectedFiltersForUser =
    (requestObject) => (dispatch, getState) => {
        dispatch(requestStart());
        return API.post("/cache/filters/", requestObject)
            .then((response) => {
                if (response.data && response.data.status === true) {
                    dispatch(requestComplete());
                    return true;
                } else {
                    dispatch(requestFail());
                    return true;
                }
            })
            .catch((error) => {
                const errorMessage = setErrorMessage(error);
                dispatch(requestFail(errorMessage));
                return true;
            });
    };

export const getLastSelectedFiltersForUser =
    ({ userId, screenName, filtersOptionsArr }) =>
    (dispatch, getState) => {
        dispatch(requestStart());
        return API.get("/cache/filters")
            .then((response) => {
                if (response.data && response.data.status === true) {
                    const { data } = response.data;
                    const filtersRequestObj = data.filters;
                    if (!_.isEmpty(filtersRequestObj)) {
                        const filtersDataObj = {
                            product_filters: filtersRequestObj.product,
                            store_filters: filtersRequestObj.store,
                            item_group: filtersRequestObj.item_group,
                            reg_clr: filtersRequestObj,
                        };
                        const filtersData = getUpdatedFilters({
                            data: filtersOptionsArr,
                            filtersList: filtersDataObj,
                            callApi: true,
                        });
                        const filterOptions = {
                            filters: {
                                filters: filtersDataObj,
                            },
                            filtersData: filtersData,
                        };

                        dispatch(
                            setFiltersData({
                                data:
                                    (filterOptions &&
                                        filterOptions.filtersData) ||
                                    {},
                                from: screenName,
                            })
                        );
                        dispatch(
                            setLastSavedFilters({
                                data: filterOptions,
                                from: screenName,
                            })
                        );
                        dispatch(requestComplete());
                        return filtersDataObj;
                    }
                    dispatch(requestComplete());
                    return true;
                } else if (response.data.status === false) {
                    const { message } = response.data;
                    const errorMessage = setErrorMessage(message);
                    dispatch(requestFail(errorMessage));
                    return false;
                }
            })
            .catch((error) => {
                const errorMessage = setErrorMessage(error);
                dispatch(requestFail(errorMessage));
                return true;
            });
    };

export const getProductsForSelectedFilters =
    (requestObject) => (dispatch, getState) => {
        dispatch(requestStart());
        return API.post("/products", requestObject)
            .then((response) => {
                if (response.data && response.status === 200) {
                    const { data } = response.data;
                    dispatch(setFilteredProducts(data));
                    dispatch(requestComplete());
                    return data;
                } else {
                    dispatch(requestFail());
                }
            })
            .catch((error) => {
                const errorMessage = setErrorMessage(error);
                dispatch(requestFail(errorMessage));
                return true;
            });
    };
