import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
	requestStart,
	requestComplete,
	requestFail,
	toastError,
	toggleLengthyOpLoader,
	requestFailNoLoader,
} from "../global/global";

const initialState = {
	promoList: {},
	activePromoId: null,
	promoDetails: {},
	validOffers: {},
	maxPromoStep: null,
	aggregateMetrics: {},
	simulationMetrics: {},
	baselineLyMetrics: {},
	simulatedPromoId: null,
};

const promoSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		setPromoList(state, action) {
			state.promoList = action.payload;
		},
		setActivePromoId(state, action) {
			state.activePromoId = action.payload;
		},
		setMaxPromoStep(state, action) {
			state.maxPromoStep = action.payload;
		},
		setPromoDetail(state, action) {
			state.promoDetails = action.payload;
		},
		setValidOffers(state, action) {
			state.validOffers = action.payload;
		},
		setAggregateMetrics(state, action) {
			state.aggregateMetrics = action.payload;
		},
		setSimulationMetrics(state, action) {
			state.simulationMetrics = action.payload;
		},
		setBaselineLyMetrics(state, action) {
			state.baselineLyMetrics = action.payload;
		},
		setSimulatedPromoId(state, action) {
			state.simulatedPromoId = action.payload;
		},
	},
});

export const {
	setPromoList,
	setActivePromoId,
	setMaxPromoStep,
	setPromoDetail,
	setValidOffers,
	setAggregateMetrics,
	setSimulationMetrics,
	setBaselineLyMetrics,
	setSimulatedPromoId,
} = promoSlice.actions;
export default promoSlice.reducer;

export const promoActions =
	({ payload, dateRange = "ty", screenName }) =>
	(dispatch, getState) => {
		if (payload.promo_target && !_.isEmpty(payload.promo_target)) {
			dispatch(toggleLengthyOpLoader(true));
		} else {
			dispatch(requestStart());
		}

		return API.post("/promos", payload)
			.then((response) => {
				if (
					payload.action === "get" &&
					(response.status === 200 || response.status === true)
				) {
					if (response.data) {
						if (_.isEmpty(response.data?.data)) {
							dispatch(
								toastError(
									"There is no data for the selected filters"
								)
							);
						}
						const { data } = response.data;
						let formattedData = [...data];
						if (screenName == "decision-dashboard") {
							formattedData = data.filter(
								(promo) => promo.status == 4
							);
						}
						dispatch(
							setPromoList({
								...getState().promo.promoList,
								[dateRange]: formattedData,
							})
						);
						dispatch(requestComplete());
					}
					return true;
				} else if (
					(payload.action === "create" ||
						payload.action === "edit") &&
					(response.status === 200 || response.status === true)
				) {
					// Recognize optimization and set lengthyOpLoader
					if (
						payload.promo_target &&
						!_.isEmpty(payload.promo_target)
					) {
						// Wait for SSE
					} else {
						const { data } = response.data;
						dispatch(setActivePromoId(data.promo_id));
						dispatch(requestComplete());
						return data.promo_id;
					}
				} else if (
					(payload.action === "delete" ||
						payload.action === "copy") &&
					(response.status === 200 || response.status === true)
				) {
					const { message } = response.data;
					dispatch(requestComplete(message));
					return true;
				} else if (
					payload.action === "finalize" &&
					(response.status === 200 || response.status === true)
				) {
					const { data } = response.data;
					if (!_.isEmpty(data.promo_ids?.existing)) {
						let offer_name = "";
						data.promo_ids?.existing?.forEach((promoId, index) => {
							offer_name += getState().promo.promoList?.ty?.find(
								(offer) =>
									offer.promo_id ===
									parseInt(Object.keys(promoId)?.[0])
							)?.promo_name;
							if (
								index !==
								data.promo_ids?.existing?.length - 1
							) {
								offer_name += ", ";
							}
						});
						dispatch(
							requestFail(
								`Some products in the Offer(s): ${offer_name} already exist in other finalized offers`
							)
						);
						return false;
					} else if (!_.isEmpty(data.promo_ids?.["in-request"])) {
						let offer_name = "";
						data.promo_ids?.["in-request"]?.forEach(
							(promoId, index) => {
								offer_name +=
									getState().promo.promoList?.ty?.find(
										(offer) =>
											offer.promo_id ===
											parseInt(Object.keys(promoId)?.[0])
									)?.promo_name;
								if (
									index !==
									data.promo_ids?.["in-request"]?.length - 1
								) {
									offer_name += ", ";
								}
							}
						);
						dispatch(
							requestFail(
								`There are some overlapping products in the offers you're trying to finalize. Offers: ${offer_name}`
							)
						);
						return false;
					}
					dispatch(requestComplete());
					return true;
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				if (payload.promo_target && !_.isEmpty(payload.promo_target)) {
					dispatch(toggleLengthyOpLoader(false));
					dispatch(requestFailNoLoader(errorMessage));
					return false;
				} else {
					dispatch(requestFail(errorMessage));
					return false;
				}
			});
	};

export const getPromoDetail =
	({ promo_id, section }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.get(`/promos?promo=${promo_id}&section=${section}`)
			.then((response) => {
				if (response.status === 200 || response.status === true) {
					let { data } = response.data;
					const promoDetailCurr = _.cloneDeep(
						getState().promo.promoDetails
					);
					if (promoDetailCurr.promo_id === data.promo_id) {
						data = {
							...promoDetailCurr,
							...data,
						};
					}
					dispatch(setPromoDetail(data));
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const getBaselineLyMetrics = (promo_id) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.get(`/ly-targets?promo=${promo_id}`)
		.then((response) => {
			if (response.status === 200 || response.status === true) {
				let { data } = response.data;
				dispatch(setBaselineLyMetrics(data));
				dispatch(requestComplete());
				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};

export const getValidOffers =
	({ payload, promo_id, hierarchy_id = null }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post("/valid-offers", payload)
			.then((response) => {
				if (response.status === 200 || response.status === true) {
					const { data } = response.data;
					if (hierarchy_id) {
						dispatch(
							setValidOffers({
								...getState().promo.validOffers,
								[promo_id]: {
									...getState().promo.validOffers?.[promo_id],
									[hierarchy_id]: data,
								},
							})
						);
					} else {
						dispatch(
							setValidOffers({
								...getState().promo.validOffers,
								[promo_id]: data,
							})
						);
					}

					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const lockPromo = (payload) => (dispatch) => {
	dispatch(requestStart());
	//change api end point
	return API.put("/promos/lock", payload)
		.then((response) => {
			if (response.status === 200 || response.status === true) {
				const { data } = response.data;
				// dispatch(setEventDetails(data));
				dispatch(requestComplete());

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const simulate =
	({ payload }) =>
	(dispatch, getState) => {
		dispatch(toggleLengthyOpLoader(true));
		API.post(`/simulate`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					// Wait for SSE
					// dispatch(requestComplete());
				}
			})
			.catch((error) => {
				dispatch(toggleLengthyOpLoader(false));
				const errorMessage = setErrorMessage(error);
				dispatch(requestFailNoLoader(errorMessage));
			});
	};

export const getSimulationMetrics =
	({ payload, aggregation }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/scenario-metrics`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					const { data } = response.data;
					const simulationMetrics = _.cloneDeep(
						getState().promo.simulationMetrics
					);
					dispatch(
						setSimulationMetrics({
							...simulationMetrics,
							[aggregation]: data,
						})
					);
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const approveScenario =
	({ payload }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/approve-scenario`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					// Wait for SSE
					dispatch(requestComplete());
					return true;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const getModelData =
	({ payload, aggregation, screen }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/model-data`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					const { data } = response.data;
					const currentAggregateMetrics = _.cloneDeep(
						getState().promo.aggregateMetrics
					);
					dispatch(
						setAggregateMetrics({
							...currentAggregateMetrics,
							[screen]: {
								...currentAggregateMetrics?.[screen],
								[aggregation]: data,
							},
						})
					);
					dispatch(requestComplete());
					return data || {};
				} else {
					dispatch(requestFail());
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};
