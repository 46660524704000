import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import marketingInfo from "./marketingInfo";

import styles from "../Login.module.scss";

const MarketingBanner = () => {
    const [intervalId, setIntervalId] = useState(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const slideLength = marketingInfo.length;

    /**
     * Initial setup
     */
    useEffect(() => {
        if (slideLength) {
            setCurrentSlideIndex(0);
        }
    }, []);

    /**
     * @func
     * @desc Call on every slideIndex value change
     */
    useEffect(() => {
        clearInterval(intervalId);
        const id = setInterval(setNextSlide, 3000);
        setIntervalId(id);
    }, [currentSlideIndex]);

    /**
     * @func
     * @desc Set the next slideIndex value
     */
    const setNextSlide = () => {
        let nextSlideIndex = currentSlideIndex + 1;
        if (slideLength - 1 >= nextSlideIndex) {
            setCurrentSlideIndex(nextSlideIndex);
        } else {
            setCurrentSlideIndex(0);
        }
    };

    return (
        <div
            className={`${styles["marketing-container"]} ${styles["h-md-100"]}`}
        >
            <h2 className={`${styles["marketing__header"]}`}>
                Powering the AI in RetAlL
            </h2>
            <div className={`${styles["marketing__image-wrapper"]}`}>
                {currentSlideIndex != null ? (
                    <img
                        className={`${styles["marketing__image"]}`}
                        src={marketingInfo[currentSlideIndex].img}
                        alt="decorative-image"
                    />
                ) : null}
            </div>
            <div
                className={`${styles["marketing__description-wrapper"]}`}
                textAlign="center"
            >
                {currentSlideIndex != null ? (
                    <>
                        <Typography
                            className={`${styles["marketing__title"]}`}
                            component="p"
                            variant="h4"
                        >
                            {marketingInfo[currentSlideIndex].title}
                        </Typography>
                        <Typography
                            className={`${styles["marketing__description"]}`}
                            component="p"
                            variant="body"
                        >
                            {marketingInfo[currentSlideIndex].description}
                        </Typography>
                    </>
                ) : null}
            </div>
            <div className={`${styles["marketing__indicators"]}`}>
                {marketingInfo.map((_item, index) => (
                    <CircleIcon
                        onClick={() => setCurrentSlideIndex(index)}
                        className={`${styles["icons"]} ${
                            currentSlideIndex == index ? styles["active"] : ""
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default MarketingBanner;
