import React, { useCallback } from "react";
import styled from "styled-components";
import { map } from "lodash";
import Select from "../../../../components/common/filters/Select/Select";
import "./Index.scss";

function ColumnSelection(props) {
	let labels = Object.keys(props.columnHeaders || []);
	labels.splice(labels.indexOf("fixed"), 1);
	labels.splice(labels.indexOf("default"), 1);
	labels = props.labels ? props.labels : labels;

	const dropDownRendrer = useCallback(
		(item) => {
			return (
				<div className="metric-selection">
					{item.label && (
						<label className="inline-label">{item.label}</label>
					)}
					<div>
						<Select
							is_multiple_selection={true}
							initialData={item.options}
							selectedOptions={props.selectedItems}
							updateSelected={props.onHandleSelect}
							updateSelectedOnEachSelection={true}
							showOnlyOptions={true}
							hideClearSelection={true}
							hideSearch={true}
						/>
					</div>
				</div>
			);
		},
		[props.selectedItems]
	);
	return (
		<Styles>
			{map(Object.values(props.metricsConfig), (item) => {
				if (item.type === "drop_down") {
					return dropDownRendrer(item);
				}
			})}
			{labels &&
				!!labels.length &&
				!props.hideMetricLabels &&
				labels.map((label, i) => {
					let isLabelSelected = props.selLabels.indexOf(label) + 1;
					return (
						<div className="custom-control custom-checkbox">
							<input
								checked={isLabelSelected ? true : false}
								type="checkbox"
								className="custom-control-input"
								id={
									label === "status"
										? i
										: props.from + label + i
								}
								onClick={(e) =>
									props.onHandleCheckBox(e, label, i)
								}
							/>
							<label
								className="custom-control-label"
								htmlFor={
									label === "status"
										? i
										: props.from + label + i
								}
							>
								{label}
							</label>
						</div>
					);
				})}

			{props.showLYAndLLYToggle ? (
				<>
					<span>Show: </span>
					{map(Object.keys(props.extraMetrics), (key, i) => {
						let item = props.extraMetrics[key];
						return (
							<div className="custom-control custom-checkbox">
								<input
									checked={item && item.isSelected}
									type="checkbox"
									className="custom-control-input"
									id={key + i}
									onClick={(e) =>
										props.onExtraMetricSelection(e, key)
									}
								/>
								<label
									className="custom-control-label"
									htmlFor={key + i}
								>
									{key}
								</label>
							</div>
						);
					})}
				</>
			) : null}
		</Styles>
	);
}

export default ColumnSelection;

const Styles = styled.div`
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	.custom-control {
		line-height: 1.5rem;
		margin: 0 1rem;
	}
	.custom-control:nth-child(1) {
		margin-left: 0.5rem;
	}

	.custom-control-label::before {
		top: 0.5rem;
	}
	.custom-control-label::after {
		top: 0.5rem;
		cursor: pointer;
	}
	.custom-checkbox label {
		vertical-align: -webkit-baseline-middle;
	}
`;
