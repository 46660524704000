/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import { configureStore } from "@reduxjs/toolkit";
// import { ThunkAction } from "redux-thunk";

import { combineReducers } from "redux";
import authReducer from "./features/auth/auth";
import filterReducer from "./features/filters/filters";
import globalReducer from "./features/global/global";
import eventReducer from "./features/event/event";
import promoReducer from "./features/promo/promo";
import dashboardReduer from "./features/dashboard/dashboard";
import { configurationAndAlertAPI } from "../components/views/configurationAndAlert/state";
import { auditLogDownloadAPI } from "../components/views/auditLogDownload/state";
import plangroupReducer from "./features/plangroup/plangroup";
import alertReducer from "./features/alert/alert";
import exceptionReducer from "./features/exception/exception";
import priceRulesReducer from "./features/priceRules";
import marketReducer from "./features/market/market";
import workbenchReducer from "./features/workbench/workbench";
import approvalLogReducer from "./features/approvalLog/approvalLog";
import { buyersScreenAPI } from "./features/workbench/buyersScreen";
import { planningScreenAPI } from "./features/workbench/planningScreen";

const rootReducer = {
    auth: authReducer,
    global: globalReducer,
    filters: filterReducer,
    event: eventReducer,
    promo: promoReducer,
    dashboard: dashboardReduer,
    plangroup: plangroupReducer,
    alert: alertReducer,
    exception: exceptionReducer,
    priceRules: priceRulesReducer,
    market: marketReducer,
    workbench: workbenchReducer,
    approvalLog: approvalLogReducer,
};

const store = configureStore({
    reducer: {
        ...rootReducer,
        [configurationAndAlertAPI.reducerPath]:
            configurationAndAlertAPI.reducer,
        [auditLogDownloadAPI.reducerPath]: auditLogDownloadAPI.reducer,
        [buyersScreenAPI.reducerPath]: buyersScreenAPI.reducer,
        [planningScreenAPI.reducerPath]: planningScreenAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        })
            .concat(configurationAndAlertAPI.middleware)
            .concat(auditLogDownloadAPI.middleware)
            .concat(buyersScreenAPI.middleware)
            .concat(planningScreenAPI.middleware),
    plangroup: plangroupReducer,
});

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof rootReducer>;
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
