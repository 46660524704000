import React, { Suspense, lazy, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Skeleton } from "@mui/material";
import moment from "moment";
import {
    getNextUpdateTime,
    resetNextUpdateTime,
    setNextUpdateTime,
    setUserPermissions,
    toastError,
} from "../../store/features/global/global";

/* 404 Page */
import NotFound from "./NotFound";

/* loader and alerts */
import { LoadingOverlayBrew } from "../../components/ui/loader/LoaderBrew";
import SnackBar from "../../components/ui/snackbar/Snackbar";

/* Layout */
import Layout from "../../components/common/layout/Layout";

/* Components */
import Login from "../../components/auth/Login";
import AuditLogDownload from "../../components/views/auditLogDownload";
import EUPlanningRollUp from "../../components/views/euPricePlanning/rollupsComponents/EUPlanningRollUp";
import GlobalSearch from "../../components/views/euPricePlanning/globalSearch";
import { IdleTimer } from "../../utils/helpers/utility_helpers";
import {
    MIN_INACTIVE_DURATION,
    INTERVAL_API_DURATION,
    MIN_INACTIVE_DURATION_FOR_SIGNOUT,
    INETRVAL_TO_CHECK_ACTIVITY_FOR_SIGNOUT,
} from "../../constants/Constants";
import { signOut } from "../../store/features/auth/auth";

const DiscountMain = lazy(() =>
    import(
        "../../components/views/configurationAndAlert/discountConfig/discountMain"
    )
);
const DiscountCreate = lazy(() =>
    import(
        "../../components/views/configurationAndAlert/discountConfig/Create/discountCreate"
    )
);

const PriceRules = lazy(() => import("../../components/views/priceRules/list"));
const CreatePriceRule = lazy(() =>
    import("../../components/views/priceRules/create")
);

const EUPricePlanning = lazy(() =>
    import("../../components/views/euPricePlanning/EUPricePlanning")
);

const EUPriceFileHistory = lazy(() =>
    import("../../components/views/euPricePlanning/EUPriceFileHistory")
);

const PlanGroupConfig = lazy(() =>
    import("../../components/views/planGroupConfig/PlanGroupConfig")
);

const CreatePlanGroupConfig = lazy(() =>
    import("../../components/views/createPlanGroupConfig/CreatePlanGroupConfig")
);

const PriceListConfig = lazy(() =>
    import("../../components/views/configurationAndAlert/priceListConfig")
);

const MarketConfig = lazy(() =>
    import("../../components/views/marketConfig/MarketConfig")
);

const AlertConfig = lazy(() =>
    import("../../components/views/alertConfig/AlertConfig")
);

const ExceptionConfig = lazy(() =>
    import("../../components/views/exceptionConfig/ExceptionConfig")
);

const ApprovalLog = lazy(() =>
    import("../../components/views/approvalLog/ApprovalLog")
);

export const protectedRoutes = [
    {
        path: "/workbench",
        component: EUPricePlanning,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Workbench",
        key: "workbench",
        breadcrumbPath: "workbench",
    },
    {
        path: "/workbench/price-file-history",
        component: EUPriceFileHistory,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Price File History",
        key: "price-file-history",
        breadcrumbPath: "price-file-history",
    },
    {
        path: "/plan-group-config",
        component: PlanGroupConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Plan Group",
        key: "plan-group-config",
        breadcrumbPath: "plan-group-config",
    },
    {
        path: "/plan-group-config/create-plan-group",
        component: CreatePlanGroupConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Create Plan Group",
        key: "create-plan-group",
        breadcrumbPath: "create-plan-group",
    },
    {
        path: "/plan-group-config/edit-plan-group",
        component: CreatePlanGroupConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Edit Plan Group",
        key: "edit-plan-group",
        breadcrumbPath: "edit-plan-group",
    },
    {
        path: "/price-list-configuration",
        component: PriceListConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Price List",
        key: "priceListConfiguration",
        breadcrumbPath: "price-list-configuration",
    },
    {
        path: "/discount-config",
        component: DiscountMain,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Discount Configuration",
        key: "discountConfiguration",
        breadcrumbPath: "discount-config",
    },
    {
        path: "/discount-config/create-discount-table",
        component: DiscountCreate,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Create Discount Table",
        key: "createDiscountTable",
        breadcrumbPath: "create-discount-table",
    },
    {
        path: "/discount-config/edit-discount-table",
        component: DiscountCreate,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Edit Discount Table",
        key: "editDiscountTable",
        breadcrumbPath: "edit-discount-table",
    },
    {
        path: "/discount-config/view-discount-table",
        component: DiscountCreate,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "View Discount Table",
        key: "viewDiscountTable",
        breadcrumbPath: "view-discount-table",
    },
    {
        path: "/price-rules",
        component: PriceRules,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Price Rules",
        key: "priceRules",
        breadcrumbPath: "price-rules",
    },
    {
        path: "/price-rules/create",
        component: PriceListConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Create Price Rule",
        key: "createPriceRule",
        breadcrumbPath: "create",
    },
    {
        path: "/price-rules/edit",
        component: PriceListConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Edit Price Rule",
        key: "editPriceRule",
        breadcrumbPath: "edit",
    },
    {
        path: "/price-rules/view",
        component: PriceListConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "View Price Rule",
        key: "viewPriceRule",
        breadcrumbPath: "view",
    },
    {
        path: "/market-config",
        component: MarketConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Market",
        key: "market-config",
        breadcrumbPath: "market-config",
    },
    {
        path: "/alert-config",
        component: AlertConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Alert",
        key: "alert-config",
        breadcrumbPath: "alert-config",
    },
    {
        path: "/exception-config",
        component: ExceptionConfig,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Exception",
        key: "exception-config",
        breadcrumbPath: "exception-config",
    },
    {
        path: "/audit-log-download",
        component: AuditLogDownload,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Audit Log Download",
        key: "audit-log-download",
        breadcrumbPath: "audit-log-download",
    },
    {
        path: "/approval-log",
        component: ApprovalLog,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Approval Log",
        key: "approval-log",
        breadcrumbPath: "approval-log",
    },
    {
        path: "/planning-roll-up",
        component: EUPlanningRollUp,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Planning Roll Up",
        key: "planning-roll-up",
        breadcrumbPath: "planning-roll-up",
    },
    {
        path: "/global-search",
        component: GlobalSearch,
        layout: Layout,
        exact: true,
        isPublic: true,
        title: "Global Search",
        key: "globalSearch",
        breadcrumbPath: "global-search",
    },
];

const timedUpdater = (dispatch) => {
    let nextUpdateTimeFound = false;

    const updateTimeFetcher = () => {
        if (!nextUpdateTimeFound) {
            // isActive calculates 1 hour inactivity
            const isActive =
                Date.now() - localStorage.getItem("_lastActivityAt") <
                    MIN_INACTIVE_DURATION ||
                !localStorage.getItem("_lastActivityAt");

            (isActive ? dispatch(getNextUpdateTime()) : Promise.resolve()).then(
                (res) => {
                    if (res) nextUpdateTimeFound = true;
                    else
                        setTimeout(() => {
                            updateTimeFetcher();
                        }, INTERVAL_API_DURATION);
                }
            );
        }
    };
    updateTimeFetcher();
};

const timedUpdaterForActivityBaseSignOut = (dispatch) => {
    let nextUpdateTimeFound = false;

    const updateTimeFetcher = () => {
        if (!nextUpdateTimeFound) {
            // isActive calculates 6 hour inactivity
            const isActive =
                Date.now() - localStorage.getItem("_lastActivityAt") <
                    MIN_INACTIVE_DURATION_FOR_SIGNOUT ||
                !localStorage.getItem("_lastActivityAt");
                
            if (isActive) {
                setTimeout(() => {
                    updateTimeFetcher();
                }, INETRVAL_TO_CHECK_ACTIVITY_FOR_SIGNOUT);
            } else {
                nextUpdateTimeFound = true;
                dispatch(signOut());
            }
        }
    };
    updateTimeFetcher();
};

const AppRoutes = (props) => {
    const dispatch = useDispatch();
    const { userPermissions } = useSelector((state) => state.global);

    useEffect(() => {
        const allowed_feature_actions = JSON.parse(
            localStorage.getItem("allowed_feature_actions")
        );
        const allowed_hierarchies = JSON.parse(
            localStorage.getItem("allowed_hierarchies")
        );
        const allowed_regions = JSON.parse(
            localStorage.getItem("allowed_regions")
        );
        const role_details = JSON.parse(localStorage.getItem("role_details"));
        const allowed_channel_hierarchies = JSON.parse(
            localStorage.getItem("allowed_channel_hierarchies")
        );
        const userId = localStorage.getItem("userId");

        dispatch(
            setUserPermissions({
                userId,
                allowed_feature_actions,
                allowed_hierarchies,
                allowed_regions,
                role_details,
                allowed_channel_hierarchies,
            })
        );
    }, []);

    useEffect(() => {
        const timer = new IdleTimer();
        const localNextUpdateTime =
            localStorage.getItem("nextUpdateTime") !== "undefined"
                ? JSON.parse(localStorage.getItem("nextUpdateTime"))
                : undefined;

        if (!userPermissions?.userId)
            return () => {
                timer.cleanUp();
                dispatch(resetNextUpdateTime());
            };
        if (localNextUpdateTime) {
            if (
                moment(localNextUpdateTime.end_time).isAfter(moment(Date.now()))
            )
                dispatch(setNextUpdateTime(localNextUpdateTime));
            else localStorage.removeItem("nextUpdateTime");
        } else timedUpdater(dispatch);

        timedUpdaterForActivityBaseSignOut(dispatch);

        return () => {
            timer.cleanUp();
            dispatch(resetNextUpdateTime());
        };
    }, [userPermissions]);

    return (
        <Router>
            <main className="App">
                <Suspense
                    fallback={
                        <div>
                            <h1>...Loading</h1>
                            <Skeleton />
                        </div>
                    }
                >
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <Suspense>
                                    <Login />
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/workbench"
                            element={
                                <Suspense>
                                    <Layout>
                                        <EUPricePlanning />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/workbench/price-file-history"
                            element={
                                <Suspense>
                                    <Layout>
                                        <EUPriceFileHistory />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/price-rules"
                            element={
                                <Suspense>
                                    <Layout>
                                        <PriceRules />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/price-rules/create"
                            element={
                                <Suspense>
                                    <Layout>
                                        <CreatePriceRule />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/price-rules/edit"
                            element={
                                <Suspense>
                                    <Layout>
                                        <CreatePriceRule />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/price-rules/view"
                            element={
                                <Suspense>
                                    <Layout>
                                        <CreatePriceRule />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/plan-group-config"
                            element={
                                <Suspense>
                                    <Layout>
                                        <PlanGroupConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/plan-group-config/create-plan-group"
                            element={
                                <Suspense>
                                    <Layout>
                                        <CreatePlanGroupConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/plan-group-config/edit-plan-group/:id"
                            element={
                                <Suspense>
                                    <Layout>
                                        <CreatePlanGroupConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/price-list-configuration"
                            element={
                                <Suspense>
                                    <Layout>
                                        <PriceListConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/market-config"
                            element={
                                <Suspense>
                                    <Layout>
                                        <MarketConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/alert-config"
                            element={
                                <Suspense>
                                    <Layout>
                                        <AlertConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/exception-config"
                            element={
                                <Suspense>
                                    <Layout>
                                        <ExceptionConfig />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/discount-config"
                            element={
                                <Suspense>
                                    <Layout>
                                        <DiscountMain />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/discount-config/create-discount-table"
                            element={
                                <Suspense>
                                    <Layout>
                                        <DiscountCreate />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/discount-config/view-discount-table"
                            element={
                                <Suspense>
                                    <Layout>
                                        <DiscountCreate />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/discount-config/edit-discount-table"
                            element={
                                <Suspense>
                                    <Layout>
                                        <DiscountCreate />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/audit-log-download"
                            element={
                                <Suspense>
                                    <Layout>
                                        <AuditLogDownload />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/approval-log"
                            element={
                                <Suspense>
                                    <Layout>
                                        <ApprovalLog />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/planning-roll-up"
                            element={
                                <Suspense>
                                    <Layout>
                                        <EUPlanningRollUp />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/global-search"
                            element={
                                <Suspense>
                                    <Layout>
                                        <GlobalSearch />
                                    </Layout>
                                </Suspense>
                            }
                        />
                        <Route path="/*" element={<NotFound />} />
                        <Route
                            path="/under-maintenance"
                            element={<NotFound type="under_maintenance" />}
                        />
                    </Routes>
                </Suspense>
                <LoadingOverlayBrew showLoader={props.lengthyOpLoader} />
                {props.message && (
                    <SnackBar
                        message={props.message}
                        alertType={props.messageType}
                    />
                )}
            </main>
        </Router>
    );
};

const mapStateToProps = (state) => ({
    loading: state.global.loading,
    lengthyOpLoader: state.global.lengthyOpLoader,
    error: state.global.error,
    message: state.global.message,
    messageType: state.global.messageType,
    isAuthenticated: state.auth.token,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toastError: (data) => dispatch(toastError(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
