import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _, { map, isEmpty } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
// import { getPromo } from "../../../store/features/summary/summary";

const EpocEditCell = (props) => {
	const [selected, setSelected] = useState("");
	const [offerValueOptions, setOfferValueOptions] = useState({});

	useEffect(() => {
		if (props.value || props.value === 0) {
			let offerValOptions = {};
			const newOfferValue = props.data && props.data.offer_name;

			offerValOptions[newOfferValue] = {
				offer_name: props.data.offer_name,
				benefit_value: props.value,
			};
			setSelected(newOfferValue);
			setOfferValueOptions(offerValOptions);
		}
	}, []);

	const offerValueOptionsFormatter = (options) => {
		let newOfferValues = {};
		map(options, (offerVal) => {
			newOfferValues[offerVal.offer_name] = offerVal;
		});
		return newOfferValues;
	};
	const handleChange = (e) => {
		let val = e.target.value;
		const newOfferValue =
			offerValueOptions[val] && offerValueOptions[val].benefit_value;

		setSelected(val);
		props.setValue(offerValueOptions[val]);
		if (!props.node.selected) {
			props.node.setSelected(true);
		}
		if (props.node.selected && newOfferValue === props.data.offer_value) {
			props.node.setSelected(false);
		}
	};
	const onOpenHandler = async () => {
		const { data, colDef, promoValues, lastSavedFilters } = props;

		if (data.store_id && isEmpty(promoValues[data.store_id])) {
			const filters =
				lastSavedFilters &&
				lastSavedFilters[colDef.screenName] &&
				lastSavedFilters[colDef.screenName].filters &&
				lastSavedFilters[colDef.screenName].filters.filters;

			const reqObj = {
				filters: {
					product: filters.product_filters,
					store: {
						...filters.store_filters,
						store_id: [data.store_id],
					},
					item_group: filters.item_group,
				},
				clearance_flag: "1",
				offer_type: data.offer_type,
				epoc_flag: true,
			};
			const res = []//await props.getPromo(reqObj);
			if (res && !_.isEmpty(res)) {
				const promoVal = res.find(
					(offer) => offer.offer_type === data.offer_type
				);
				const newOfferValues = offerValueOptionsFormatter(
					(promoVal && promoVal.offer_values) || []
				);
				setOfferValueOptions(newOfferValues);
			}
		} else if (
			Object.keys(offerValueOptions).length < 2 &&
			data.store_id &&
			!isEmpty(promoValues[data.store_id])
		) {
			const newOfferValues = offerValueOptionsFormatter(
				(promoValues && promoValues[data.store_id]) || []
			);
			setOfferValueOptions(newOfferValues);
		}
	};

	return (
		<div className="finalize-cell-edit-container">
			<div className="finalize-offer-value-container">
				<FormControl className="finalize-offer-value-drop-down">
					<Select
						displayEmpty
						autoWidth
						value={selected}
						onOpen={onOpenHandler}
						onChange={handleChange}
						input={<OutlinedInput />}
						id="finalize-offer-value-select"
						inputProps={{ "aria-label": "Without label" }}
						disabled={
							!props.userPermissions ||
							(props.userPermissions &&
								!props.userPermissions.includes("edit_epoc"))
						}
					>
						{/* <MenuItem disabled value="">
				<em>-</em>
			</MenuItem> */}
						{map(Object.keys(offerValueOptions), (option) => (
							<MenuItem key={option} value={option}>
								{offerValueOptions[option].offer_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => {
	return {
		promoValues: store.summary.promoValues,
		lastSavedFilters: store.filters.lastSavedFilters,
		userPermissions: store.global.userPermissions,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// getPromo: (data) => dispatch(getPromo(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EpocEditCell);
