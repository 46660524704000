import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { VALID_OFFER_TYPES } from "../../../constants/Constants";

export const PerformanceFormatter = function ({ value, node }) {
	if (value) {
		let color = "#FF832B";
		let label = "Average";
		if (value === "toxic") {
			color = "#DA1E28";
			label = "Toxic";
		}
		if (value === "good") {
			color = "#24A148";
			label = "Good";
		}
		return (
			<span style={{ height: "30px" }}>
				<FiberManualRecordIcon
					sx={{
						color,
						fontSize: "12px",
						verticalAlign: "text-top",
						marginRight: "5px",
						position: "relative",
						top: "1px",
					}}
				/>
				{label}
			</span>
		);
	}
	if (node && node.group && value === undefined) return "";

	return value ? value : "-";
};

export const ExceptionFormatter = function ({ value, node }) {
	if (value === 1) {
		value = "High";
	} else if (value === 2) {
		value = "Med";
	} else if (value === 3) {
		value = "Low";
	}
	if (value) {
		let color = "#34c795";
		if (value === "High") {
			color = "#ef5e5e";
		}
		if (value === "Med") {
			color = "#d6c22b";
		}
		return (
			<span
				className={`decision-dashboard-exception exception-${value}`}
				style={{ height: "30px" }}
			>
				<FiberManualRecordIcon
					sx={{
						color,
						fontSize: "12px",
						verticalAlign: "text-top",
						marginRight: "5px",
						position: "relative",
						top: "1px",
					}}
				/>
				{value}
			</span>
		);
	}
	if (node && node.group && value === undefined) return "";
	return value ? value : "-";
};

export const ShowTypeAndValue = function (params) {
	const { value, node, colDef, valueFormatted } = params;
	if (value && value.offer_type) {
		// let formattedVal = colDef.valueFormatter({
		// 	value: value.benefit_value,
		// });
		return (
			<div className="flex-cell-container">
				<div className="">{VALID_OFFER_TYPES[value.offer_type]}</div>
				<div>{value.offer_value}</div>
			</div>
		);
	} else if (value && !value.offer_type && value.offer_value) {
		return (
			<div className="flex-cell-container">
				<div>{value.offer_value}</div>
			</div>
		);
	}
	if (value && value.offer_value === null) return "-";
	if (node && node.group && value === undefined) return "";
	if (valueFormatted) return valueFormatted;
	return value ? value : "-";
};

export const ScenarioNameRendrer = function (params) {
	const { value } = params;
	if (value === undefined) return "";
	if (value === "current_discount") {
		return "Last Finalized";
	}
	if (value === "ia_recommended") {
		return "IA Recommended";
	}
	if (value === "finalized") {
		return "Finalized";
	}
	return value ? value : "-";
};

export const viewByLabelRendrer = function (params) {
	const { value, valueFormatted, data } = params;
	const cellValue = valueFormatted ? valueFormatted : value;
	return (
		<div>
			<span className="edit-cell-text" title={cellValue}>
				{cellValue}
			</span>
			&nbsp;
			{data && data.under_process && (
				<HourglassTopIcon
					className="processing-button-icon"
					sx={{
						fontSize: "12px",
						minWidth: "20px",
						height: "15px",
						verticalAlign: "text-bottom",
						color: "#4f677b",
					}}
				/>
			)}
		</div>
	);
};
