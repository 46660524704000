import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";

import { fallbackFormatter } from "../../../../utils/helpers/formatter";
import useTableRowCount from "../../../common/tableRowCount/useTableRowCount";
import TableRowCount from "../../../common/tableRowCount/TableRowCount";
import LoadingOverlay from "../../../ui/loader/Loader";

import styles from "../EUPricePlanning.module.scss";
import { toastError } from "../../../../store/features/global/global";
import { getPriceHistory } from "../../../../store/features/workbench/globalSearch";
import { euroFormatter } from "../EUPriceListLevelPrices";
import { Tooltip } from "@mui/material";

const currencyFormatter = ({ value, currencySymbol = "" }) =>
    value === null || value === undefined || isNaN(parseFloat(value))
        ? "-"
        : currencySymbol + " " + parseFloat(value)?.toFixed(2);

const PriceHistory = (props) => {
    const { src, onClose, isFromPopUp } = props;

    const gridRef = useRef();
    const dispatch = useDispatch();

    const [priceHistory, setPriceHistory] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    const priceHistoryColDefs = useMemo(() => {
        let list =
            src.channel !== "OUTLET"
                ? [
                      { field: "country_type", headerName: "Price List" },
                      {
                          field: "msrp_generated_value",
                          headerName: "MSRP",
                          valueFormatter: ({ value, data }) =>
                              currencyFormatter({
                                  value,
                                  currencySymbol: data.msrp_currency_symbol,
                              }),
                      },
                      {
                          field: "wholesale_generated_value",
                          headerName: "Wholesale",
                          valueFormatter: ({ value, data }) =>
                              currencyFormatter({
                                  value,
                                  currencySymbol:
                                      data.wholesale_currency_symbol,
                              }),
                      },
                  ]
                : [
                      { field: "country_type", headerName: "Price List" },
                      {
                          field: "msrp_generated_value",
                          headerName: "PFS Was",
                          valueFormatter: ({ value, data }) =>
                              currencyFormatter({
                                  value,
                                  currencySymbol: data.msrp_currency_symbol,
                              }),
                      },
                      {
                          valueFormatter: (params) => {
                              const msrp = params.data.msrp_generated_value;
                              const wholesale =
                                  params.data.wholesale_generated_value;
                              return `${(
                                  ((msrp - wholesale) / msrp) *
                                  100
                              ).toFixed(2)}%`;
                          },
                          headerName: "Discount",
                      },
                      {
                          field: "wholesale_generated_value",
                          headerName: "PFS Store",
                          valueFormatter: ({ value, data }) =>
                              currencyFormatter({
                                  value,
                                  currencySymbol:
                                      data.wholesale_currency_symbol,
                              }),
                      },
                  ];

            list = list.map((item) => ({
                ...item,
                cellRenderer: ({ value, valueFormatted }) => {
                    const finalValue = valueFormatted || value;
                    return (
                        <Tooltip title={finalValue}>
                            <span style={{ cursor: "pointer" }}>
                                {finalValue}
                            </span>
                        </Tooltip>
                    );
                },
            }));  

        return list;      
    }, [src]);

    useEffect(() => {
        setShowLoader(true);
        const payload = {
            method_type: "GET",
            market_id: src.market_id,
            region: src.region,
            channel: src.channel,
            material_season: `${src.material_number}${src.season}`,
            price_file_material_id: src.pfm_id,
            price_file_id: null,
            products: {},
        };

        getPriceHistory(payload)
            .then((res) => {
                setPriceHistory(res.data?.data?.[0]?.country_data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setShowLoader(false);
                setPriceHistory([]);
                dispatch(toastError(err?.message || err));
            });
    }, [src]);

    const onGridReady = () => {
        gridRef.current.api.sizeColumnsToFit();
    };

    const drawerStyles = { width: "calc(55% - 36px)" };
    if (isFromPopUp) {
        drawerStyles.height = "100%";
        drawerStyles.top = 0;
    }

    return (
        <>
            <div className="drawer-inner-full-height" style={drawerStyles}>
                <div className={styles["audit-log-modal"]}>
                    <div className={styles["audit-log-modal-header"]}>
                        Regional Pricing
                    </div>
                    <button
                        className={styles["audit-log-modal-close-button"]}
                        style={{ background: "transparent", border: "none" }}
                        onClick={onClose}
                    >
                        &times;
                    </button>

                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span className={styles["audit-log-info-key"]}>
                                Material No:
                                <span
                                    className={styles["audit-log-info-value"]}
                                >
                                    {src.material_number}
                                </span>
                            </span>
                            <span className={styles["audit-log-info-key"]}>
                                Season Code:
                                <span
                                    className={styles["audit-log-info-value"]}
                                >
                                    {src.season}
                                </span>
                            </span>
                        </div>
                    </div>

                    <div
                        style={{
                            minHeight: "265px",
                            marginTop: "2rem",
                            maxHeight: "calc(100vh - 335px)",
                            height: `calc(40px + calc(45 * ${priceHistory?.length}px))`,
                            position: "relative",
                        }}
                        className="ag-theme-alpine"
                    >
                        <LoadingOverlay showLoader={showLoader} isLocalLoader />
                        <AgGridReact
                            ref={gridRef}
                            rowData={priceHistory}
                            columnDefs={priceHistoryColDefs}
                            suppressClickEdit
                            onGridReady={onGridReady}
                            defaultColDef={{
                                valueFormatter: fallbackFormatter,
                                resizable: true,
                            }}
                            onModelUpdated={onTableModelUpdate}
                            onSelectionChanged={onTableSelectChange}
                        />
                    </div>
                    <TableRowCount
                        count={rowCount}
                        selected={selectedRowCount}
                    />
                </div>
            </div>
            <div className="drawer-inner-full-height-cover" />
        </>
    );
};

PriceHistory.propTypes = {
    src: PropTypes.shape.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PriceHistory;
