import { API } from "../../../utils/axios/index";
import {
    requestCompComplete,
    requestComplete,
    requestFail,
    requestStart,
    toastError,
} from "../global/global";

export const getPriceHistory = (data) =>
    API.post(`/material_price_list_view`, data);

export const getPriceFileMaterials =
    ({ data, queryParams }) =>
    (dispatch) => {
        dispatch(requestStart());

        return API({
            url: "/global_search",
            method: "POST",
            data,
            params: queryParams,
        })
            .then((response) => {
                const { data } = response.data;
                data.table_data = data.table_data.map((e) => {
                    const costOrigin =
                        (e.tfg_manual && "TFG") ||
                        (e.std_cost && "STANDARD COST") ||
                        "-";
                    const costUsed = e.tfg_manual || e.std_cost || "-";
                    return { ...e, costOrigin, costUsed };
                });

                return data;
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ?? "Base prices fetch failed";

                dispatch(toastError(message));
            })
            .finally(() => {
                dispatch(requestComplete());
            });
    };

export const downloadExcel =
    ({ data }) =>
    (dispatch) =>
        API({
            url: "/download_global_search_data",
            method: "POST",
            data,
        })
            .then((response) => {
                const { data } = response;
                const prefix = "data:text/csv;charset=utf-8,";

                const downloadData = prefix + encodeURIComponent(data);
                // : res.data;
                // console.log(uploadFile);
                const anchorEle = document.createElement("a");
                anchorEle.href = downloadData;
                anchorEle.download = `Global Search Results - ${new Date().toLocaleString()}`;
                document.body.appendChild(anchorEle);
                anchorEle.click();
                document.body.removeChild(anchorEle);
                // window.open(data, "_blank");

                return data;
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ?? "Base prices fetch failed";

                dispatch(toastError(message));
            });

export const validatePastedAttributes = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/attribute-filters/validate-v2", data)
        .then((res) => {
            dispatch(requestComplete());

            return res.data.data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Attribute values validation failed";

            dispatch(requestFail(message));

            throw message;
        });
};
