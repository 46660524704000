import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { useNavigate } from "react-router-dom";
import {
	setRetainFilters,
	setLastSelectedFiltersForUser,
} from "../../../store/features/filters/filters";

const EditButtonCell = (props) => {
	const navigate = useNavigate();

	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	const buttonClicked = async () => {
		// Save last selected filters for autopopulate after approval
		let filters =
			(props.lastSavedFilters &&
				props.lastSavedFilters.DECISION_DASHBOARD &&
				props.lastSavedFilters.DECISION_DASHBOARD.filters &&
				_.cloneDeep(
					props.lastSavedFilters.DECISION_DASHBOARD.filters.filters
				)) ||
			{};
		// Get item group / style value from selected row
		const activeTab =
			props.colDef &&
			props.colDef.decisionDashboardViewBy &&
			props.colDef.decisionDashboardViewBy.value === "item_group"
				? "item_group"
				: props.colDef.decisionDashboardViewBy.value === "hierarchy10"
				? "product"
				: "item_group";
		const requestObj = {
			product: filters.product_filters || {},
			store: filters.store_filters,
			item_group: filters.item_group,
			active_tab: activeTab,
		};
		let saveLastSelectedFilters = await props.setLastSelectedFiltersForUser(
			{
				filters: requestObj,
			}
		);
		// Save last selected filters for autopopulate after approval

		const screenName = props.colDef && props.colDef.screenName;
		let res = await props.setRetainFilters(screenName);
		let path = "/inseason-promo-planning?edit=true";
		if (
			props.colDef &&
			props.colDef.decisionDashboardViewBy &&
			props.colDef.decisionDashboardViewBy.value === "hierarchy10"
		) {
			path = "/inseason-clearance-planning?edit=true";
		}

		navigate(path, {
			state: {
				id: props.data.view_by_id,
				name: props.data.view_by_name + " - " + props.data.view_by_code,
				filterDetails:
					props.colDef && props.colDef.decisionDashboardViewBy,
			},
		});
	};
	const renderIcon = () => {
		if (props.data && props.data.under_process) {
			return (
				<HourglassTopIcon
					className="processing-button-icon"
					sx={{
						fontSize: "12px",
						minWidth: "20px",
						height: "15px",
						verticalAlign: "text-bottom",
						color: "#4f677b",
					}}
				/>
			);
		}
		if (
			props.data &&
			props.data.is_locked === "Unapproved" &&
			!props.data.is_decisions_absent
		) {
			return (
				<Button
					variant="outlined"
					sx={{ fontSize: "12px" }}
					onClick={() => buttonClicked()}
					className="edit-button"
				>
					<BorderColorOutlinedIcon
						className="edit-button-icon"
						sx={{
							fontSize: "12px",
							minWidth: "20px",
							height: "15px",
						}}
					/>
				</Button>
			);
		}
	};

	return (
		<div>
			<span className="edit-cell-text" title={cellValue}>
				{cellValue}
			</span>
			&nbsp;
			{renderIcon()}
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		lastSavedFilters: store.filters.lastSavedFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRetainFilters: (data) => dispatch(setRetainFilters(data)),
		setLastSelectedFiltersForUser: (data) =>
			dispatch(setLastSelectedFiltersForUser(data)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EditButtonCell);
