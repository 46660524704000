import React, { useEffect, useState } from "react";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import "../tableRenderer/AgGridTable.scss";

const TextEditableCell = (props) => {

    const [cellValue, setCellValue] = useState(props.valueFormatted ? props.valueFormatted : props.value);

    const handleChange = (e) => {
        console.log("*******E", e);
        console.log("*******E", props);
        setCellValue(e.target.value);
    }
    const handleOnblur = () => {
        // props.handleEditCell();
        let updatedValue = {
            rowData: props.data,
            value: cellValue
        }
        console.log("********", props);
        props.colDef.cellRendererParams.handleInlineEdit(updatedValue);
    }
    return (
        <div className="text-editable-table-cell">
            <span className="offer-indicator">
            O
			</span>
            {props.data.is_edit ?
                <TextField
                    className="text-input"
                    variant="outlined"
                    value={cellValue}
                    onChange={handleChange}
                    onBlur={handleOnblur}
                /> :
                <span className="offer-name">{cellValue}</span>
            }
        </div>
    );
};

export default TextEditableCell;
