import styles from "./TableRowCount.module.scss";

function TableRowCount(props) {
    const { count, selected } = props;

    return (
        <div className={`${styles["container"]}`}>
            Showing {count ?? 0} rows
            {selected ? (
                <>
                    <span style={{ margin: "8px" }}>|</span>Selected {selected}{" "}
                    row{selected > 1 ? "s" : ""}
                </>
            ) : (
                ""
            )}
        </div>
    );
}

export default TableRowCount;
