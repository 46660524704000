import { useCallback, useState } from "react";

function useTableRowCount() {
    const [rowCount, setRowCount] = useState(0);
    const [selectedRowCount, setSelectedRowCount] = useState(0);

    const onTableModelUpdate = useCallback((event) => {
        setRowCount(event.api.getDisplayedRowCount());
    }, []);

    const onTableSelectChange = useCallback((event) => {
        setSelectedRowCount(event.api.getSelectedNodes().length);
    }, []);

    return [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ];
}

export default useTableRowCount;
