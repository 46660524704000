import React, { useState, useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch } from "react-redux";

import { useAuditLogDownloadListQuery } from "./state";
import { fallbackFormatter } from "../../../utils/helpers/formatter";
import TableRowCount from "../../common/tableRowCount/TableRowCount";
import useTableRowCount from "../../common/tableRowCount/useTableRowCount";
import {
    disableLoader,
    requestStart,
    toastError,
} from "../../../store/features/global/global";
import ClearAllFilterButton from "../../common/clearAllFilterButton/ClearAllFilterButton";
import styles from "./index.module.scss";

export default function AuditLogDownload() {
    const gridRef = useRef();
    const dispatch = useDispatch();
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);

    const {
        data: auditLogDownloadList,
        isFetching: isAuditLogDownloadListFetching,
        refetch,
    } = useAuditLogDownloadListQuery();

    const tableColDefs = useMemo(() => {
        return [
            {
                field: "file_name",
                headerName: "File Name",
            },
            { field: "market_name", headerName: "Market" },
            { field: "region", headerName: "Region" },
            { field: "channel", headerName: "Channel" },
            { field: "utc_time", headerName: "Time (UTC)" },
            { field: "requested_by", headerName: "Requested By" },
            {
                field: "status",
                headerName: "Status",
                cellRenderer: StatusRenderer,
                cellStyle: {
                    display: "flex",
                    alignItems: "center",
                },
                filterValueGetter: (data) =>
                    data.getValue("status") === 1 ? "Done" : "In Progress",
            },
            {
                field: "url",
                headerName: "Download",
                cellRenderer: DownloadRenderer,
                filter: false,
            },
        ];
    }, []);

    const tableData = useMemo(() => {
        return isAuditLogDownloadListFetching || auditLogDownloadList
            ? auditLogDownloadList
            : [];
    }, [isAuditLogDownloadListFetching, auditLogDownloadList]);

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    function refreshList() {
        dispatch(requestStart());
        refetch()
            .then((res) => {
                if (res.isError)
                    dispatch(toastError("Failed to refresh. Try again"));
                dispatch(disableLoader());
            })
            .catch(() => {
                dispatch(disableLoader());
                dispatch(toastError("Failed to refresh. Try again"));
            });
    }

    const processCellForClipboard = useCallback((params) => {
        const col = params.column.getColId();
        if (col === "url") return "-";
        if (col === "status") {
            return params.value === 1 ? "Done" : "In Progress";
        }
        return params.value;
    }, []);

    const onGridReady = () => {
        gridRef.current.api.addEventListener(
            'filterChanged',
            (event) => {
                setIsAnyFilterApplied(event.api.isAnyFilterPresent());
            });
    }

    return (
      <div className={styles.container}>
        <div className={styles["header-flex"]}>
          <h3 className={styles["header-label"]}>Audit Log Download</h3>
          <div className="button-group-flex">
            <Button variant="contained" onClick={refreshList}>
              <RefreshOutlinedIcon fontSize="small" />
            </Button>
            <ClearAllFilterButton
              isVisible={isAnyFilterApplied}
              agGridApi={gridRef?.current?.api}
            />
          </div>
        </div>

        <div
          style={{ height: "calc(100vh - 230px)" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            ref={gridRef}
            rowData={tableData}
            onGridReady={onGridReady}
            columnDefs={tableColDefs}
            defaultColDef={{
              sortable: true,
              flex: 1,
              filter: "agMultiColumnFilter",
              floatingFilter: true,
              resizable: true,
              lockPinned: true,
              suppressMovable: true,
              menuTabs: ["filterMenuTab", "generalMenuTab"],
              valueFormatter: fallbackFormatter,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
            }}
            autoSizeAllColumns
            onModelUpdated={onTableModelUpdate}
            onSelectionChanged={onTableSelectChange}
            enableRangeSelection
            processCellForClipboard={processCellForClipboard}
          />
        </div>
        <TableRowCount count={rowCount} selected={selectedRowCount} />
      </div>
    );
}

function DownloadRenderer(props) {
    const { value } = props;

    function downloadFile() {
        const anchorEle = document.createElement("a");
        anchorEle.href = value;
        anchorEle.target = "_blank";
        anchorEle.download = props.data.file_name;
        anchorEle.click();
    }

    return (
        <div className={styles["download-link"]}>
            <FileDownloadOutlinedIcon
                onClick={value ? downloadFile : null}
                className={value ? styles["active-download-link"] : null}
            />
        </div>
    );
}

function StatusRenderer(props) {
    const { value } = props;

    return (
        <>
            <span
                className={styles["status-dot"]}
                style={{
                    backgroundColor: value === 1 ? "#24A148" : "#FABD4D",
                }}
            ></span>
            {value === 1 ? "Done" : "In Progress"}
        </>
    );
}
