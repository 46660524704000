import { sortChannels, sortMarkets, sortRegions } from "../helpers/sorters";

export const interceptResponse = (response) => {
    const {
        data: { data },
        config: { url },
    } = response;

    if (url === "/regions") {
        sortRegions(data);
    } else if (
        url === "/channels" ||
        /\/regions\/(\w|\d)*\/channels/.test(url)
    ) {
        sortChannels(data);
    } else if (url === "/markets/market-names") {
        sortMarkets(data);
    }
};
