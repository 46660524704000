import React, { useCallback, useEffect, useState } from "react";
import _, { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppBar, styled, Avatar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

import { signOut } from "../../../store/features/auth/auth";
import {
    removeLoader,
    requestCompleteNoLoader,
    toastError,
    getUserHierarchy,
    getGlobalConfig,
    toggleLengthyOpLoader,
    resetNewNotificationsCount,
} from "../../../store/features/global/global";
import { setSimulatedPromoId } from "../../../store/features/promo/promo";
import { setRetainFilters } from "../../../store/features/filters/filters";
import { screenNames } from "../../../constants/Constants";
import { protectedRoutes } from "../../../containers/common/Routes";
import Breadcrumbs from "../header/Breadcrumbs";
import {
    fetchCountryLevelPrices,
    getPriceFileMaterials,
    setCurrentPage,
    setCurrentTab,
    setInvalidValues,
    setPasteAreaValue,
    setSelectedAttributeOptions,
    setSelectedPlanGroup,
    setSelectedSeparatorOptions,
    setSelectedTab,
    setValidValues,
} from "../../../store/features/workbench/workbench";
import { getMaterialFetchData } from "../../views/euPricePlanning/euHelper";

import "./Header.scss";

const StyledAppBar = styled(AppBar)((theme) => ({
    padding: "0px 15px",
    background: "#fff",
    boxShadow: "0px 1px 4px #00000029",
    height: "72px",
    position: "sticky",
    // zIndex: 1500,
}));

const Header = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const {
        countryPriceRequestOperations,
        auditLogDownloadLinks,
        missingMaterialsGPMNotification,
    } = useSelector((state) => state.workbench.general);
    const { notificationsFetchCount } = useSelector((state) => state.global);
    const { selectedMarkets, selectedRegions, selectedChannels, priceFileId } =
        useSelector((state) => state.workbench.general);

    const [workbenchNotifications, setWorkbenchNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);

    const onCountryPriceGenerationNotificationClick = useCallback(
        (operation) => {
            const {
                tab: { id: tabId, data: tabData },
            } = operation;
            const page =
                tabId === 2 ? "filterByAttributes" : "filterByPlangroup";

            navigate("/workbench");

            dispatch(setSelectedTab(tabId));

            if (tabId === 2) {
                let selectedFilters = tabData.data;

                if (tabData.page === 2) {
                    const {
                        pasteAreaValue,
                        selectedAttributeOptions,
                        selectedSeparatorOptions,
                        validValues,
                        invalidValues,
                    } = tabData.data;

                    dispatch(
                        setSelectedAttributeOptions(selectedAttributeOptions)
                    );
                    dispatch(
                        setSelectedSeparatorOptions(selectedSeparatorOptions)
                    );
                    dispatch(setPasteAreaValue(pasteAreaValue));
                    dispatch(setValidValues(validValues));
                    dispatch(setInvalidValues(invalidValues));

                    selectedFilters = {
                        SELECT_FILTERS_ATTRIBUTE: {
                            [selectedAttributeOptions[0].value]: validValues,
                        },
                    };
                }

                const data = getMaterialFetchData(
                    selectedFilters,
                    selectedMarkets,
                    selectedRegions,
                    selectedChannels,
                    priceFileId
                );

                dispatch(setCurrentPage({ page, data: tabData.page }));

                dispatch(getPriceFileMaterials({ page, data })).then((res) => {
                    if (!res?.table_data.length) {
                        dispatch(
                            toastError(
                                "No material tagged for the selection you have made"
                            )
                        );
                    }

                    dispatch(
                        fetchCountryLevelPrices({
                            page,
                            data: {
                                ...data,
                                price_file_material_ids: res?.table_data.map(
                                    (e) => e.id
                                ),
                            },
                        })
                    );
                });
            } else {
                dispatch(setSelectedPlanGroup(tabData));
            }

            dispatch(setCurrentTab({ page, data: "price-list" }));

            setShowNotifications(!showNotifications);
        },
        [
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId,
            showNotifications,
            dispatch,
            navigate,
        ]
    );

    const priorityNumber = (op, auditLog, missingMt) => {
        if (
            op.price_generation_status &&
            missingMt.missing_gpm_price_generation_status
        ) {
            return "link";
        }
        if (op.price_generation_status) {
            if (moment(missingMt.created_at) >= moment(auditLog.timestamp)) {
                return "missing";
            }
            return "link";
        }
        if (missingMt.missing_gpm_price_generation_status) {
            if (moment(op.created_at) >= moment(auditLog.timestamp)) {
                return "op";
            }
            return "link";
        }

        if (moment(op.created_at) >= moment(auditLog.timestamp)) {
            if (moment(op.created_at) >= moment(missingMt.created_at))
                return "op";
            return "missing";
        } else {
            if (moment(auditLog.timestamp) <= moment(missingMt.created_at))
                return "missing";
            return "link";
        }
    };

    useEffect(() => {
        const countryPriceRequestOperationsArr = Object.values(
            countryPriceRequestOperations
        ).sort((op1, op2) =>
            op1.price_generation_status && !op2.price_generation_status
                ? 1
                : moment(op1.created_at) < moment(op2.created_at)
                ? 1
                : -1
        );
        const auditLogDownloadLinksCopy = cloneDeep(auditLogDownloadLinks).sort(
            (link1, link2) => moment(link2.timestamp) - moment(link1.timestamp)
        );

        const missingMaterialsGPMNotificationCopy = cloneDeep(
            missingMaterialsGPMNotification
        ).sort((op1, op2) =>
            op1.missing_gpm_price_generation_status &&
            !op2.missing_gpm_price_generation_status
                ? 1
                : moment(op1.created_at) < moment(op2.created_at)
                ? 1
                : -1
        );

        const notifications = [];
        let operationIndex = 0;
        let linkIndex = 0;
        let missingMaterialIndex = 0;

        while (
            operationIndex < countryPriceRequestOperationsArr.length &&
            linkIndex < auditLogDownloadLinksCopy.length &&
            missingMaterialIndex < missingMaterialsGPMNotificationCopy.length
        ) {
            const currentOperation =
                countryPriceRequestOperationsArr[operationIndex];
            const currentLink = auditLogDownloadLinksCopy[linkIndex];
            const currentMissingMaterial =
                missingMaterialsGPMNotificationCopy[missingMaterialIndex];

            const priority = priorityNumber(
                currentOperation,
                currentLink,
                currentMissingMaterial
            );
            switch (priority) {
                case "op":
                    notifications.push({
                        type: "operation",
                        data: currentOperation,
                    });
                    operationIndex++;
                    break;
                case "link":
                    notifications.push({ type: "link", data: currentLink });
                    linkIndex++;
                    break;
                case "missing":
                    notifications.push({
                        type: "missingMaterial",
                        data: currentMissingMaterial.message,
                    });
                    missingMaterialIndex++;
                    break;
                default:
                    break;
            }
        }

        while (operationIndex < countryPriceRequestOperationsArr.length) {
            const currentOperation =
                countryPriceRequestOperationsArr[operationIndex];

            notifications.push({
                type: "operation",
                data: currentOperation,
            });
            operationIndex++;
        }

        while (linkIndex < auditLogDownloadLinksCopy.length) {
            const currentLink = auditLogDownloadLinksCopy[linkIndex];

            notifications.push({ type: "link", data: currentLink });
            linkIndex++;
        }

        while (
            missingMaterialIndex < missingMaterialsGPMNotificationCopy.length
        ) {
            const currentMissingMaterial =
                missingMaterialsGPMNotificationCopy[missingMaterialIndex];

            notifications.push({
                type: "missingMaterial",
                data: currentMissingMaterial.message,
            });
            missingMaterialIndex++;
        }

        const newNotifications = notifications.map(
            (notificationItem, index) => {
                const { type, data } = notificationItem;

                if (type === "operation") {
                    const operation = data;
                    let notificationContent = operation.message;

                    if (operation.price_generation_status) {
                        const messageArr = operation.message.split(
                            `${operation.completed}`
                        );

                        const messageLink = (
                            <span
                                className="link"
                                onClick={() =>
                                    onCountryPriceGenerationNotificationClick(
                                        operation
                                    )
                                }
                                onKeyDown={() => {}}
                            >
                                {operation.completed} {messageArr[1]}
                            </span>
                        );

                        messageArr.splice(1, 1, messageLink);

                        notificationContent = messageArr;
                    }

                    return (
                        <li className="notification-li" key={index}>
                            {notificationContent}
                        </li>
                    );
                } else if (type === "missingMaterial") {
                    return (
                        <li className="notification-li" key={index}>
                            {data}
                        </li>
                    );
                } else if (type === "link") {
                    return (
                        <li
                            className="notification-li center-space-between"
                            key={index}
                        >
                            <span>
                                Audit-log download{" "}
                                <a
                                    href={data.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    link
                                </a>
                            </span>
                            <span
                                style={{ fontStyle: "italic", color: "#666" }}
                            >
                                {moment(data.timestamp).format("DD/MM/Y HH:mm")}
                            </span>
                        </li>
                    );
                }

                return null;
            }
        );

        setWorkbenchNotifications(newNotifications);
    }, [
        countryPriceRequestOperations,
        auditLogDownloadLinks,
        missingMaterialsGPMNotification,
        onCountryPriceGenerationNotificationClick,
    ]);

    const userName = localStorage.getItem("user")
        ? localStorage.getItem("user").split(" ")[0]
        : "User";

    const handleNotificationIconClick = async () => {
        setShowNotifications(!showNotifications);

        dispatch(resetNewNotificationsCount());
    };

    useEffect(() => {
        // Get global screen config
        // Get current screen name
        const pathName = location?.pathname;
        const currentRoute = protectedRoutes?.find((r) => r.path === pathName);
        const currentScreen = screenNames[currentRoute?.key];

        props.getGlobalConfig({ screen: currentScreen });

        document.addEventListener("click", (e) => {
            if (
                !e.target.closest(".notification-icon") &&
                !e.target.closest(".notification-drawer")
            ) {
                setShowNotifications(false);
            }
        });
    }, []);

    const stringAvatar = (name) => {
        const nameArray = name.split(" ");
        return {
            sx: {
                bgcolor: "#033162",
                width: "48px",
                height: "48px",
                boxShadow: "0px 0px 6px #00000029",
                textAlign: "left",
                font: "normal normal 600 16px/25px Poppins, sans-serif",
                letterSpacing: "0px",
                color: "#FFFFFF",
            },
            children:
                nameArray.lenght > 1
                    ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
                    : `${name.split(" ")[0][0]}`,
        };
    };

    return (
        <>
            <StyledAppBar>
                <div className="navbar-brand">
                    <span className="home-link">{props.title}</span>
                    <ul className="navbar-nav">
                        <li
                            title="Notifications"
                            className="nav-item"
                            id="notification-nav-item"
                        >
                            <NotificationsIcon
                                className="notification-icon"
                                sx={{
                                    width: "23px",
                                    height: "27px",
                                    cursor: "pointer",
                                    color: "#C8CED0",
                                    marginTop: "8px",
                                }}
                                onClick={() => handleNotificationIconClick()}
                            />
                            {props.newNotificationsCount > 0 && (
                                <span className="notification-count"></span>
                            )}
                        </li>

                        <li className="nav-item">
                            <Avatar {...stringAvatar(userName)} />
                        </li>
                        <li className="nav-item">Hi, {userName} </li>
                    </ul>
                </div>
                {showNotifications && (
                    <div className="notification-drawer">
                        <p className="notifications-title">Notifications</p>
                        {notificationsFetchCount > 0 ? (
                            <div
                                style={{ marginBottom: "24px" }}
                                className="align-center justify-center"
                            >
                                <CircularProgress size={30} />
                            </div>
                        ) : null}
                        {workbenchNotifications &&
                        !_.isEmpty(workbenchNotifications) ? (
                            <ul className="notification-ul">
                                {workbenchNotifications}
                            </ul>
                        ) : (
                            <p className="no-notifications">
                                No new notifications
                            </p>
                        )}
                    </div>
                )}
            </StyledAppBar>
            <Breadcrumbs />
        </>
    );
};

const mapStateToProps = (store) => {
    return {
        notifications: store.global.notifications,
        newNotificationsCount: store.global.newNotificationsCount,
        elementLabels: store.global.elementLabels,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => {
            dispatch(signOut());
        },
        requestCompleteNoLoader: (message) =>
            dispatch(requestCompleteNoLoader(message)),
        removeLoader: () => dispatch(removeLoader()),
        setRetainFilters: (screenName) =>
            dispatch(setRetainFilters(screenName)),
        toastError: (data) => dispatch(toastError(data)),
        getUserHierarchy: (data) => dispatch(getUserHierarchy(data)),
        getGlobalConfig: (data) => dispatch(getGlobalConfig(data)),
        toggleLengthyOpLoader: (data) => dispatch(toggleLengthyOpLoader(data)),
        setSimulatedPromoId: (data) => dispatch(setSimulatedPromoId(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
