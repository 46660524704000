import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { connect } from "react-redux";
import _, {
    isEmpty,
    differenceBy,
    cloneDeep,
    findIndex,
    map,
    filter,
} from "lodash";
import { AgGridReact } from "ag-grid-react";
import Box from "@mui/material/Box";

import NoData from "../../../containers/NoData";
import { AgGridColumnFormatter } from "../components/columnFormatter/AgGridColumnFormatter";
import ColumnSelection from "../components/columnSelection/Index";
import TableRowCount from "../../../components/common/tableRowCount/TableRowCount";
import useTableRowCount from "../../../components/common/tableRowCount/useTableRowCount";

import "./AgGridTable.scss";

function GridExample(props) {
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([]);
    const [from, setFrom] = useState("");
    const [rowData, setRowData] = useState([]);
    // const [labels, setLabels] = useState([]);
    // const [visibleColOptions, setVisibleColOptions] = useState([]);
    const [selectedColOptions, setSelectedColOptions] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [extraAgGridProps, setExtraAgGridProps] = useState({});
    const [metricsConfig, setMetricsConfig] = useState({});
    const [decisionDashboardViewBy, setDecisionDashboardViewBy] = useState("");
    const [unhideColumns, setUnhideColumns] = useState([]);
    // const [autoGroupColumnDef, setAutoGroupColumnDef] = useState[{}]

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    useEffect(() => {
        if (
            (from !== props.from && !isEmpty(props.screenConfig)) ||
            unhideColumns !== props.unhideColumns
        ) {
            let data = AgGridColumnFormatter({
                ...props,
            });
            setFrom(props.from);
            setUnhideColumns(props.unhideColumns);
            setExtraAgGridProps(data.extraAgGridProps);
            setMetricsConfig(data.metricsConfig);
            setSelectedLabels(data.selectedLabels);
            setSelectedColOptions(data.selectedMetrics);
            setDecisionDashboardViewBy(props.decisionDashboardViewBy);

            if (!isEmpty(columnDefs)) {
                gridRef.current.api.setColumnDefs(data.mainColdef);
                onDataRendered(); //to resize the columns after updating the coldef
            } else {
                setColumnDefs(data.mainColdef); // causes col resizing issue incase coldef is already present, use setColumnDefs to update coldefs
            }
        }
    }, [props.screenConfig, props.from, props.unhideColumns]);

    useEffect(() => {
        if (
            from === props.from &&
            !isEmpty(columnDefs) &&
            props.decisionDashboardViewBy &&
            props.decisionDashboardViewBy.value !== "store" &&
            props.decisionDashboardViewBy.value !== "epoc"
        ) {
            let newColDef = gridRef.current.api.getColumnDefs();

            map(newColDef, (column) => {
                if (column.children) {
                    map(column.children, (childColumn) => {
                        return handleColumnVisibilityOnViewByUpdate({
                            oldViewBy: decisionDashboardViewBy,
                            newViewBy: props.decisionDashboardViewBy,
                            column: childColumn,
                        });
                    });
                    //For Reg->Style-> show both effective discount and offer_value, offer_type data in 2 different cols
                    //In this case update header name to "Effective Discounts (Decisions for upcoming PCD)" from "Decisions for upcoming PCD"
                    /////-------header_name update start--------------/////
                    if (
                        decisionDashboardViewBy &&
                        decisionDashboardViewBy.columns_to_show &&
                        decisionDashboardViewBy.columns_to_show.includes(
                            "decisions_reg_actual_current_discount"
                        ) &&
                        findIndex(column.children, {
                            field: "decisions_reg_current_discount",
                        }) !== -1
                    ) {
                        column.headerName = "Decisions for upcoming PCD";
                    }
                    if (
                        props.decisionDashboardViewBy &&
                        props.decisionDashboardViewBy.columns_to_show &&
                        props.decisionDashboardViewBy.columns_to_show.includes(
                            "decisions_reg_actual_current_discount"
                        ) &&
                        findIndex(column.children, {
                            field: "decisions_reg_current_discount",
                        }) !== -1
                    ) {
                        column.headerName =
                            "Effective Discounts (Decisions for upcoming PCD)";
                    }
                    /////-------- header_name update end--------------/////

                    return column;
                } else {
                    return handleColumnVisibilityOnViewByUpdate({
                        oldViewBy: decisionDashboardViewBy,
                        newViewBy: props.decisionDashboardViewBy,
                        column,
                    });
                }
            });
            gridRef.current.api.setColumnDefs(newColDef);
            setDecisionDashboardViewBy(props.decisionDashboardViewBy);
            onDataRendered(); //to resize the columns after updating the coldef
        }
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setPinnedBottomRowData([]);
        }
    }, [props.decisionDashboardViewBy]);

    useEffect(() => {
        let newData = cloneDeep(props.data);
        setRowData(newData);

        if (rowData && gridRef.current && gridRef.current.api) {
            const filterModal = gridRef.current.api.getFilterModel();
            if (!isEmpty(filterModal)) {
                gridRef.current.api.setFilterModel(null);
            }
        }
        if (
            !isEmpty(newData) &&
            props.decisionDashboardViewBy &&
            props.decisionDashboardViewBy.value === "epoc"
        ) {
            let totalStoresCount = 0,
                totalStoresPerc = 0;
            map(newData, (row) => {
                totalStoresCount += row.stores_count || 0;
                totalStoresPerc += row.stores_percentage || 0;
            });
            let bottomPinnedRow = [
                {
                    epoc: "Total",
                    epoc_value: "",
                    stores_count: totalStoresCount,
                    stores_percentage: totalStoresPerc,
                },
            ];
            gridRef.current.api.setPinnedBottomRowData(bottomPinnedRow);
        }
    }, [props.data]);

    useEffect(() => {
        if (!isEmpty(props.forceSelectItems)) {
            gridRef.current.api.forEachNode(function (node) {
                const uniqVal = node.data[props.uniqKeyName];
                if (props.forceSelectItems[uniqVal]) {
                    node.setSelected(true);
                }
            });
        }
    }, [props.forceSelectItems]);

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            gridRef.current?.api.setQuickFilter(props.filterValue);
        }
    }, [props.filterValue]);

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            if (props.selectAll) {
                gridRef.current?.api.selectAll();
            } else {
                gridRef.current?.api.deselectAll();
            }
        }
    }, [props.selectAll]);

    const handleColumnVisibilityOnViewByUpdate = ({
        oldViewBy,
        newViewBy,
        column,
    }) => {
        //hide columns
        if (column.headerName === oldViewBy.label) {
            column.hide = true;
        }
        if (
            oldViewBy.columns_to_show &&
            (oldViewBy.columns_to_show.includes(column.field) ||
                oldViewBy.columns_to_show.includes(column.showRowGroup))
        ) {
            column.hide = true;
        }
        //show columns
        if (column.headerName === newViewBy.label) {
            column.hide = false;
            column.decisionDashboardViewBy = newViewBy;
        }
        if (
            !column.keepAlwaysHidden &&
            newViewBy.columns_to_show &&
            (newViewBy.columns_to_show.includes(column.field) ||
                newViewBy.columns_to_show.includes(column.showRowGroup))
        ) {
            column.hide = false;
            column.decisionDashboardViewBy = newViewBy;
        }
        return column;
    };

    const onDataRendered = () => {
        if (props.sizeColumnsToFit) {
            gridRef.current.api.sizeColumnsToFit();
        } else if (props.skipColumnAutoSize) {
        } else if (props.autoSizeAllColumns) {
            const allColumnIds = [];
            const skipHeader = props.autoSizeColumnsSkipHeader || false;
            gridRef.current.columnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
        }

        // Get column for which filter is active. Set the filter model on it and refresh data.
        let shouldRefreshData = false;
        gridRef.current.columnApi.getAllDisplayedColumns().forEach((column) => {
            if (
                !_.isEmpty(column.colDef) &&
                !_.isEmpty(column.colDef.filterParams) &&
                column.colDef.filterParams.setFilterByDefault
            ) {
                shouldRefreshData = true;
                gridRef.current.api.setFilterModel({
                    [column.colId]:
                        !_.isEmpty(column.colDef) &&
                        !_.isEmpty(column.colDef.filterParams) &&
                        !_.isEmpty(column.colDef.filterParams.defaultFilterText)
                            ? {
                                  type:
                                      !_.isEmpty(column.colDef) &&
                                      !_.isEmpty(column.colDef.filterParams) &&
                                      column.colDef.filterParams.defaultOption,
                                  filter:
                                      !_.isEmpty(column.colDef) &&
                                      !_.isEmpty(column.colDef.filterParams) &&
                                      !_.isEmpty(
                                          column.colDef.filterParams
                                              .defaultFilterText
                                      ) &&
                                      column.colDef.filterParams
                                          .defaultFilterText,
                              }
                            : {
                                  type:
                                      !_.isEmpty(column.colDef) &&
                                      !_.isEmpty(column.colDef.filterParams) &&
                                      column.colDef.filterParams.defaultOption,
                              },
                });
            }
            if (!_.isEmpty(column.colDef) && column.colDef.default_sort) {
                gridRef.current.columnApi.applyColumnState({
                    state: [
                        {
                            colId: column.colId,
                            sort: column.colDef.default_sort,
                        },
                    ],
                    // defaultState: { sort: column.colDef.default_sort },
                });
            }
        });

        if (shouldRefreshData) {
            gridRef.current.api.onFilterChanged();
        }

        // If all products are to be selected by default
        if (props.selectAll) {
            gridRef.current.api.selectAll();
        }

        // var datasource = getServerSideDatasource();
        // // register the datasource with the grid
        // gridRef.current.api.setServerSideDatasource(datasource);
    };

    const handleCheckBox = (e, metricName, i) => {};

    const handleSelect = ({ selectedItems, filterName }) => {
        if (selectedItems === selectedColOptions) return;
        setSelectedColOptions(selectedItems);
        if (props.addMetricsRowWise) {
            handleRowVisibility(selectedItems);
        } else {
            handleColumnVisibility(selectedItems);
        }
    };
    const handleColumnVisibility = (selOptions) => {
        let mainArr = selOptions,
            childArr = selectedColOptions,
            visible = true; //add cols to table

        if (selOptions.length < selectedColOptions.length) {
            mainArr = selectedColOptions;
            childArr = selOptions;
            visible = false; //remove cols from table
        }
        let difference = differenceBy(mainArr, childArr, "value");
        let keys = [];
        map(difference, (col) => {
            keys = [...keys, ...col.columns_included];
        });
        gridRef.current.columnApi.setColumnsVisible(keys, visible);
    };
    const handleRowVisibility = (selOptions) => {
        let mainArr = selOptions,
            childArr = selectedColOptions,
            visible = true; //add rows to table

        if (selOptions.length < selectedColOptions.length) {
            mainArr = selectedColOptions;
            childArr = selOptions;
            visible = false; //remove rows from table
        }
        let difference = differenceBy(mainArr, childArr, "value");
        let keys = [];
        map(difference, (col) => {
            keys = [...keys, ...col.columns_included];
        });
        let newRowData = [];
        if (visible) {
            newRowData = filter(props.data, (item) =>
                keys.includes(item.metrics)
            );
            newRowData = [...rowData, ...newRowData];
        } else {
            newRowData = filter(
                rowData,
                (item) => !keys.includes(item.metrics)
            );
        }
        setRowData(newRowData);
    };

    const onExtraMetricSelection = (e, metric) => {};

    const onCellValueChanged = useCallback((event) => {
        if (props.setTableData) {
            const data = [];
            gridRef.current.api.forEachNode((column) => {
                data.push(column.data);
            });
            props.setTableData(data, event.column.colId);
        }
    }, []);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        props.rowSelectionHandler(selectedRows);
    }, []);

    const getRowHeight = () => {
        return props.rowHeight;
    };

    const rowClassRules = useMemo(() => {
        return {
            // row style function
            //   'sick-days-warning': function (params) {
            // 	var numSickDays = params.data.sickDays;
            // 	return numSickDays > 5 && numSickDays <= 7;
            //   },
            //locked rows: grey background
            "locked-rows": "data.is_locked === 'Approved'",
            //processing rows
            "under-process": "data.under_process === true",
            //duplicate row
            "duplicate-row": "data.isDuplicate === true",
        };
    }, []);
    const autoGroupColumnDef = useMemo(() => {
        return {
            // headerName: 'Athlete',
            // field: 'athlete',
            minWidth: 250,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const rowDataChanged = () => {
        if (!_.isEmpty(props.selectedRows)) {
            props.selectedRows.forEach((row) => {
                // Add breakpoint here and
                gridRef.current?.api.forEachNode((node) => {
                    if (node.data.product_h5_id === row.product_h5_id) {
                        node.setSelected(true);
                    }
                });
            });
        }
        if (props.selectAll === true && _.isEmpty(props.selectedRows)) {
            gridRef.current?.api.selectAll();
        } else if (props.selectAll === false && _.isEmpty(props.selectedRows)) {
            gridRef.current?.api.deselectAll();
        }
    };

    // const setAutoGroupColumnDef = () => {
    // 	if(props.setAutoGroupColumnDef){
    // 		return {}
    // 	}
    // }

    return (
        <div className={props.containerClassName}>
            {!isEmpty(columnDefs) ? (
                <>
                    {(!isEmpty(metricsConfig) ||
                        props.left ||
                        props.right ||
                        props.children) && (
                        <>
                            <Box className="grid-custom-content">
                                <div className="grid-custom-content-left">
                                    {props.left}
                                </div>

                                {props.children}
                                <div className="grid-custom-content-right">
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            flexShrink: 0,
                                            textAlign: "left",
                                        }}
                                    >
                                        {!isEmpty(metricsConfig) && (
                                            <ColumnSelection
                                                metricsConfig={metricsConfig}
                                                onHandleCheckBox={
                                                    handleCheckBox
                                                }
                                                columnHeaders={[]}
                                                onHandleSelect={handleSelect}
                                                // columnOptions={visibleColOptions}
                                                selectedItems={
                                                    selectedColOptions
                                                }
                                                selLabels={selectedLabels}
                                                // labels={labels}
                                                from={props.from}
                                                // extraMetrics={extraMetrics}
                                                onExtraMetricSelection={
                                                    onExtraMetricSelection
                                                }
                                                showLYAndLLYToggle={
                                                    props.showLYAndLLYToggle
                                                }
                                                // hideMetricLabels={true}
                                            />
                                        )}
                                    </Box>
                                    {props.right}
                                </div>
                            </Box>
                        </>
                    )}

                    {props.extraContent}

                    <div
                        id="myGrid"
                        style={{
                            height: !props.domLayout && "400px",
                            width: "100%",
                            marginTop: "25px",
                            ...props.gridStyle,
                        }}
                        className="ag-theme-alpine"
                    >
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={{
                                sortable: true,
                                flex: 1,
                                filter: true,
                                resizable: true,
                                floatingFilter: true,
                                menuTabs: ["filterMenuTab", "generalMenuTab"],
                                ...props.defaultColDefProps,
                            }}
                            getRowHeight={getRowHeight}
                            rowClassRules={rowClassRules}
                            headerHeight={props.headerHeight || 50}
                            groupHeaderHeight={props.groupHeaderHeight || 50}
                            floatingFiltersHeight={40}
                            domLayout={props.domLayout ? "autoHeight" : ""}
                            groupDefaultExpanded={props.groupDefaultExpanded}
                            groupSelectsChildren={true}
                            // frameworkComponents={frameworkComponents}
                            // autoGroupColumnDef={autoGroupColumnDef}
                            // animateRows={true}
                            rowSelection={props.rowSelection}
                            onSelectionChanged={(...params) => {
                                onSelectionChanged(...params);
                                onTableSelectChange(...params);
                            }}
                            onFirstDataRendered={onDataRendered}
                            // singleClickEdit={true}
                            suppressRowClickSelection={true}
                            suppressRowHoverHighlight={
                                props.suppressRowHoverHighlight || false
                            }
                            isRowSelectable={props.isRowSelectable}
                            // rowData={props.data || []}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            pagination={false}
                            // pagination={props.pagination}
                            // paginationPageSize={5}
                            // rowModelType={"serverSide"}
                            // serverSideStoreType={"partial"}
                            paginationPageSize={props.paginationPageSize || 10}
                            // cacheBlockSize={10}
                            suppressContextMenu={true}
                            suppressHorizontalScroll={
                                props.suppressHorizontalScroll
                            }
                            suppressAggFuncInHeader={true}
                            onCellValueChanged={onCellValueChanged}
                            onRowDataChanged={rowDataChanged}
                            {...extraAgGridProps}
                            onFilterChanged={props.onFilterChanged}
                            suppressMovableColumns={
                                (props.suppressMovableColumns ??
                                    props.suppressMovableColumns) ||
                                true
                            }
                            suppressClickEdit
                            onModelUpdated={onTableModelUpdate}
                        />
                    </div>
                    <TableRowCount
                        count={rowCount}
                        selected={selectedRowCount}
                    />
                </>
            ) : (
                <NoData description="Please select filters" />
            )}
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        screenConfig: store.global.screenConfig,
        dataLoadStatus: store.global.loading,
    };
};

export default connect(mapStateToProps, null)(GridExample);
