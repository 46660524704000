function AlertExceptionEnabledCell(props) {
    return (
        <div className="align-center">
            <span
                style={{
                    width: 10,
                    height: 10,
                    margin: 8,
                    borderRadius: "50%",
                    backgroundColor: props.value === 0 ? "#DA1E28" : "#24A148",
                }}
            ></span>
            {props.value === 0 ? "No" : "Yes"}
        </div>
    );
}

export default AlertExceptionEnabledCell;
