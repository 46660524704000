import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { TextField, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SelectCellEditor from "../../../../../utils/reactTable/cellRenderers/SelectCellEditor";
import { toastError } from "../../../../../store/features/global/global";

import styles from "../index.module.scss";

export const binaryOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];

const retailRows = [
    {
        "PRICE LIST": "Description",
        field: "rule_description",
        defaultVal: "",
        type: "static",
    },
    {
        "PRICE LIST": "Base",
        field: "base_price_list_id",
        defaultVal: null,
        type: "dropdown",
    },
    {
        "PRICE LIST": "Base Currency",
        field: "base_currency",
        defaultVal: null,
        type: "static",
    },
    {
        "PRICE LIST": "Local Currency",
        field: "local_currency",
        defaultVal: null,
        type: "static",
    },
    {
        "PRICE LIST": "FX Rate",
        field: "fx_rate",
        defaultVal: 1.0,
        type: "input_number",
    },
    {
        "PRICE LIST": "Base VAT %",
        field: "base_vat",
        defaultVal: 0,
        type: "input_number",
    },
    {
        "PRICE LIST": "VAT included",
        field: "vat_incl",
        defaultVal: { value: true, label: "Yes" },
        type: "input_number",
    },
    {
        "PRICE LIST": "Coefficient",
        field: "uplift_coefficient",
        defaultVal: 0,
        type: "input_number",
    },
    {
        "PRICE LIST": "Local VAT",
        field: "local_vat",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
    {
        "PRICE LIST": "Base Duty",
        field: "base_duty",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
    {
        "PRICE LIST": "Local Duty",
        field: "local_duty",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
    {
        "PRICE LIST": "Spread",
        field: "spread",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
    {
        "PRICE LIST": "Ceiling",
        field: "ceiling",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
    {
        "PRICE LIST": "Marketing Mark-up (All)",
        field: "marketing_mark_up_all",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
    {
        "PRICE LIST": "STARME Co-efficient",
        field: "starme_coeff",
        defaultVal: 0,
        type: "input_number",
        height: 0,
    },
];
const wholesaleRows = [
    {
        "PRICE LIST": "Description",
        field: "rule_description",
        defaultVal: "",
        type: "static",
    },
    {
        "PRICE LIST": "Base",
        field: "base_price_list_id",
        defaultVal: null,
        type: "dropdown",
    },
    {
        "PRICE LIST": "Local Currency",
        field: "base_currency",
        defaultVal: null,
        type: "dropdown",
    },
    {
        "PRICE LIST": "Wholesale MU%",
        field: "wholesale_mu_percentage",
        defaultVal: 50,
        type: "input_number",
        min: 0,
        max: 100,
    },
    {
        "PRICE LIST": "FX Rate",
        field: "fx_rate",
        defaultVal: 0,
        type: "input_number",
    },
    {
        "PRICE LIST": "Rounding",
        field: "rounding",
        defaultVal: 0,
        type: "input_number",
    },
];

export function useCols(
    priceLists,
    invisiblePriceLists,
    priceTagList,
    mappings,
    isViewOnly,
    onShouldShowConfirmChangeStep,
    channel
) {
    const [retailCols, setRetailCols] = useState([]);
    const [wholesaleCols, setWholesaleCols] = useState([]);
    const dispatch = useDispatch();

    const priceRuleTagId = priceTagList?.find(
        (pt) => pt.label === "Price Rules"
    )?.value;
    const cloneRuleTagId = priceTagList?.find(
        (pt) => pt.label === "Clone"
    )?.value;
    const basePriceRuleTagId = priceTagList?.find(
        (pt) => pt.label === "Base Price List"
    )?.value;

    useEffect(() => {
        if (isEmpty(priceLists) || isEmpty(priceTagList) || isEmpty(mappings))
            return;

        const priceListIds = priceLists.map((e) => +e.id);
        const mappingsPLIds = mappings.map((e) => +e.price_list_id);

        if (mappingsPLIds.find((mplid) => !priceListIds.includes(mplid))) {
            dispatch(
                toastError(
                    "The data can't be displayed properly due to inconsistency."
                )
            );
            return;
        }

        const basePriceOptions = mappings
            ?.filter((pl) => +pl.retail_price_tag_id === basePriceRuleTagId)
            ?.map((e) => {
                const temp = priceLists?.find(
                    (pl) => +pl.id === +e.price_list_id
                );
                return {
                    value: e.price_list_id,
                    label: temp?.name + " MSRP",
                    currency: temp ? temp.msrp_currency : temp.currency,
                    base_price_list_type: temp.base_price_list_type,
                };
            });
        const euroBasePriceOptions = basePriceOptions.filter(
            (bpo) => bpo.base_price_list_type !== "US MSRP"
        );
        const usBasePriceOptions = basePriceOptions.filter(
            (bpo) => bpo.base_price_list_type === "US MSRP"
        );

        const defCol = mappings.find(
            (e) => +e.price_list_id === +priceLists[0].id
        );
        setRetailCols([
            {
                field: "PRICE LIST",
                cellStyle: {
                    background: "#f7f7f7 0% 0% no-repeat padding-box",
                },
                width: "250px",
                flex: 0,
                pinned: "left",
                headerComponent: (props) => (
                    <CustomHeader {...props} clone={false} />
                ),
            },
            {
                headerName: priceLists[0].name,
                field: priceLists[0].name,
                price_rule_price_list_mapping_id:
                    defCol.price_rule_price_list_mapping_id,
                price_list_id: priceLists[0].price_list_id,
                price_list_name: priceLists[0].name,
                rule_description: priceLists[0].retail_rule_description,
                formula: priceLists[0].retail_rule_description_py,
                currency: priceLists[0].msrp_currency || priceLists[0].currency,
                cellRenderer: CustomCellRenderer,
                cellRendererParams: {
                    options: basePriceOptions,
                    disabled:
                        +priceRuleTagId !== +defCol.retail_price_tag_id ||
                        isViewOnly,
                    clone:
                        +cloneRuleTagId === +defCol.retail_price_tag_id
                            ? +defCol.retail_clone_of_price_list_id
                            : null,
                    from: "retail",
                    onShouldShowConfirmChangeStep,
                },
                minWidth: "250",
                headerComponent: (props) => (
                    <CustomHeader
                        {...props}
                        clone={
                            +cloneRuleTagId === +defCol.retail_price_tag_id
                                ? +defCol.retail_clone_of_price_list_id
                                : null
                        }
                        clonedFrom={
                            +cloneRuleTagId === +defCol.retail_price_tag_id
                                ? priceLists.find(
                                      (pl) =>
                                          pl.price_list_id ===
                                          +defCol.retail_clone_of_price_list_id
                                  ).name
                                : null
                        }
                    />
                ),
                cellStyle: {
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                },
            },
            ...mappings
                .filter((e) => +e.price_list_id !== +defCol.price_list_id)
                .map((m) => {
                    const temp = priceLists.find(
                        (pl) => +pl.id === +m.price_list_id
                    );

                    return {
                        headerName: temp.name,
                        field: temp.name,
                        base_price_list_type: temp.base_price_list_type,
                        price_rule_price_list_mapping_id:
                            m.price_rule_price_list_mapping_id,
                        price_list_id: temp.price_list_id,
                        price_list_name: temp.name,
                        rule_description: temp.retail_rule_description,
                        formula: temp.retail_rule_description_py,
                        currency: temp.msrp_currency || temp.currency,
                        cellRenderer: CustomCellRenderer,
                        cellRendererParams: {
                            options:
                                temp.base_price_list_type === "US MSRP"
                                    ? usBasePriceOptions
                                    : euroBasePriceOptions,
                            disabled:
                                +priceRuleTagId !== +m.retail_price_tag_id ||
                                isViewOnly,
                            clone:
                                +cloneRuleTagId === +m.retail_price_tag_id
                                    ? +m.retail_clone_of_price_list_id
                                    : null,
                            from: "retail",
                            base_price_list_type: temp.base_price_list_type,
                            onShouldShowConfirmChangeStep,
                        },
                        minWidth: "250",
                        headerComponent: (props) => (
                            <CustomHeader
                                {...props}
                                selectedChannel={channel}
                                channel={temp.channel}
                                clone={
                                    +cloneRuleTagId === +m.retail_price_tag_id
                                        ? +m.retail_clone_of_price_list_id
                                        : null
                                }
                                clonedFrom={
                                    +cloneRuleTagId === +m.retail_price_tag_id
                                        ? priceLists.find(
                                              (pl) =>
                                                  pl.price_list_id ===
                                                  +m.retail_clone_of_price_list_id
                                          ).name
                                        : null
                                }
                            />
                        ),
                        headerClass:
                            channel.split(",").length > 1 &&
                            temp.channel?.includes("OUTLET")
                                ? "ag-grid-column-step6"
                                : "",
                        cellStyle: {
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                        },
                    };
                }),
        ]);
    }, [priceLists, priceTagList, mappings]);

    useEffect(() => {
        if (isEmpty(priceLists) || isEmpty(priceTagList) || isEmpty(mappings))
            return;

        const priceListIds = priceLists.map((e) => +e.id);
        const mappingsPLIds = mappings.map((e) => +e.price_list_id);

        if (mappingsPLIds.find((mplid) => !priceListIds.includes(mplid))) {
            dispatch(
                toastError(
                    "The data can't be displayed properly due to inconsistency."
                )
            );
            return;
        }

        const basePriceOptions = mappings?.map((e) => {
            const temp = priceLists?.find((pl) => +pl.id === +e.price_list_id);
            return {
                value: e.price_list_id,
                label: temp?.name + " MSRP",
                currency: temp ? temp.wholesale_currency : temp.currency,
                base_price_list_type: temp.base_price_list_type,
            };
        });
        const euroBasePriceOptions = basePriceOptions.filter(
            (bpo) => bpo.base_price_list_type !== "US MSRP"
        );
        const usBasePriceOptions = [
            ...invisiblePriceLists.map((e) => ({ ...e, value: +e.id })),
            ...basePriceOptions.filter(
                (bpo) => bpo.base_price_list_type === "US MSRP"
            ),
        ];

        const cols = mappings.map((m) => {
            const temp = priceLists.find((pl) => +pl.id === +m.price_list_id);
            return {
                headerName: temp.name,
                field: temp.name,
                base_price_list_type: temp.base_price_list_type,
                price_rule_price_list_mapping_id:
                    m.price_rule_price_list_mapping_id,
                price_list_id: temp.price_list_id,
                price_list_name: temp.name,
                rule_description: temp.wholesale_rule_description,
                formula: temp.wholesale_rule_description_py,
                currency: temp.wholesale_currency || temp.currency,
                cellRenderer: CustomCellRenderer,
                cellRendererParams: {
                    options:
                        temp.base_price_list_type === "US MSRP"
                            ? usBasePriceOptions
                            : euroBasePriceOptions,
                    disabled:
                        +priceRuleTagId !== +m.wholesale_price_tag_id ||
                        isViewOnly,
                    clone:
                        +cloneRuleTagId === +m.wholesale_price_tag_id
                            ? +m.wholesale_clone_of_price_list_id
                            : null,
                    from: "wholesale",
                    base_price_list_type: temp.base_price_list_type,
                    onShouldShowConfirmChangeStep,
                },
                minWidth: "250",
                headerComponent: (props) => (
                    <CustomHeader
                        {...props}
                        clone={
                            +cloneRuleTagId === +m.wholesale_price_tag_id
                                ? +m.wholesale_clone_of_price_list_id
                                : null
                        }
                        clonedFrom={
                            +cloneRuleTagId === +m.wholesale_price_tag_id
                                ? priceLists.find(
                                      (pl) =>
                                          pl.price_list_id ===
                                          +m.wholesale_clone_of_price_list_id
                                  ).name
                                : null
                        }
                    />
                ),
                cellStyle: {
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                },
            };
        });
        setWholesaleCols([
            {
                field: "PRICE LIST",
                cellStyle: {
                    background: "#f7f7f7 0% 0% no-repeat padding-box",
                },
                width: "250px",
                flex: 0,
                pinned: "left",
                headerComponent: (props) => (
                    <CustomHeader {...props} clone={false} />
                ),
            },
            ...cols,
        ]);
    }, [priceLists, priceTagList, mappings]);

    return { retailCols, wholesaleCols };
}

export function makeData(
    priceLists,
    invisiblePriceLists,
    priceTagList,
    mappings,
    retailPriceRules,
    wholesalePriceRules,
    dispatch
) {
    const priceListIds = priceLists.map((e) => +e.id);
    const mappingsPLIds = mappings.map((e) => +e.price_list_id);

    if (mappingsPLIds.find((mplid) => !priceListIds.includes(mplid))) {
        dispatch(
            toastError(
                "The data can't be displayed properly due to inconsistency."
            )
        );
        return [[], []];
    }
    const cloneRuleTagId = priceTagList?.find(
        (pt) => pt.label === "Clone"
    )?.value;
    const basePriceRuleTagId = priceTagList?.find(
        (pt) => pt.label === "Base Price List"
    )?.value;

    const tempRetailCols = mappings.map((m) => {
        const temp = priceLists.find((pl) => +pl.id === +m.price_list_id);
        return {
            field: temp?.name,
            price_list_id: temp?.price_list_id,
            base_price_list_type: temp.base_price_list_type,
            rule_description: temp?.retail_rule_description,
            currency: temp ? temp.msrp_currency : temp.currency,
            retail_price_tag_id: m.retail_price_tag_id,
            retail_clone_of_price_list_id: m.retail_clone_of_price_list_id,
        };
    });
    const tempWholesaleCols = mappings.map((m) => {
        const temp = priceLists.find((pl) => +pl.id === +m.price_list_id);
        return {
            field: temp?.name,
            price_list_id: temp?.price_list_id,
            base_price_list_type: temp.base_price_list_type,
            rule_description: temp?.wholesale_rule_description,
            currency: temp ? temp.wholesale_currency : temp.currency,
            wholesale_price_tag_id: m.wholesale_price_tag_id,
            wholesale_clone_of_price_list_id:
                m.wholesale_clone_of_price_list_id,
        };
    });

    if (isEmpty(retailPriceRules) && isEmpty(wholesalePriceRules)) {
        const retail = [...retailRows];
        for (let col of tempRetailCols) {
            retailRowMaker(
                col,
                retail,
                tempRetailCols,
                basePriceRuleTagId,
                cloneRuleTagId,
                invisiblePriceLists
            );

            retail.find((r) => r.field === "rule_description")[col.field] =
                col.rule_description;
        }

        const wholesale = [...wholesaleRows];
        for (let col of tempWholesaleCols) {
            wholesaleRowMaker(
                wholesale,
                col,
                tempWholesaleCols,
                cloneRuleTagId,
                invisiblePriceLists
            );

            wholesale.find((r) => r.field === "rule_description")[col.field] =
                col.rule_description;
        }

        return [retail, wholesale];
    } else {
        const retail = makeRetailDataForPriceRulesFromAPI(
            retailPriceRules,
            priceLists,
            invisiblePriceLists,
            tempRetailCols
        );

        const wholesale = makeWholesaleDataForPriceRulesFromAPI(
            wholesalePriceRules,
            priceLists,
            invisiblePriceLists,
            tempWholesaleCols
        );

        return [retail, wholesale];
    }
}

function MandatorySpan({ mandatory }) {
    return mandatory ? (
        <span
            style={{
                color: "red",
                position: "absolute",
                top: 0,
                right: "1rem",
            }}
        >
            *
        </span>
    ) : null;
}

function CustomCellRenderer(props) {
    const {
        data,
        column,
        setValue,
        api,
        rowIndex,
        options,
        disabled,
        clone,
        from,
        base_price_list_type,
        onShouldShowConfirmChangeStep,
    } = props;
    let { value } = props;

    const mandatory = column.colDef.formula?.includes(data.field);

    const isLATAM = base_price_list_type === "US MSRP";

    if (clone && !(from === "wholesale" && data.field.includes("currency"))) {
        value =
            data[
                api.getColumnDefs().find((e) => e.price_list_id === clone).field
            ];
    }

    if (data.field === "base_price_list_id" || data.field === "vat_incl") {
        const opts = data.field === "vat_incl" ? binaryOptions : options;
        return (
            <div
                style={{
                    maxWidth: "200px",
                    flexGrow: "1",
                }}
            >
                <SelectCellEditor
                    options={opts}
                    selectedOptions={value}
                    setSelectedOptions={(val) => {
                        if (val.value !== value.value)
                            onShouldShowConfirmChangeStep();
                        setValue(val);
                        if (data.field === "base_price_list_id") {
                            const temp = api
                                .getRenderedNodes()
                                .find((e) => e.data.field === "base_currency");
                            if (temp && from === "retail")
                                temp.setDataValue(column.colId, {
                                    value: val.currency,
                                    label: val.currency,
                                });
                        }
                        api.refreshCells({
                            force: true,
                            rowNodes: [
                                api.getDisplayedRowAtIndex(rowIndex),
                                api.getDisplayedRowAtIndex(rowIndex + 1),
                            ],
                        });
                    }}
                    isDisabled={
                        disabled ||
                        (isLATAM &&
                            from === "retail" &&
                            data.field !== "vat_incl")
                    }
                />
                <MandatorySpan mandatory={mandatory} />
            </div>
        );
    } else if (data.field.includes("currency")) {
        if (data.field === "local_currency")
            return (
                <>
                    {props.value?.value || ""}
                    <MandatorySpan mandatory={mandatory} />
                </>
            );
        return (
            <>
                {value?.value || ""}
                <MandatorySpan mandatory={mandatory} />
            </>
        );
    } else if (data.type === "static") {
        return (
            <div className={styles.tooltip}>
                <Tooltip title={value}>
                    <span>
                        {value}
                        <MandatorySpan mandatory={mandatory} />
                    </span>
                </Tooltip>
            </div>
        );
    } else {
        const temp = (from === "retail" ? retailRows : wholesaleRows).find(
            (e) => e.field === data.field
        );
        return (
            <div style={{ maxWidth: "200px", flexGrow: 1, height: "40px" }}>
                <TextField
                    variant="outlined"
                    value={value}
                    type="number"
                    min={temp.min || null}
                    max={temp.max || null}
                    onChange={(e) => {
                        if (e.target.value !== value)
                            onShouldShowConfirmChangeStep();
                        setValue(e.target.value < 0 ? 0 : e.target.value);
                        api.refreshCells({
                            force: true,
                            // only current row is being refreshed for performance (unlike with dropdowns)
                            rowNodes: [api.getDisplayedRowAtIndex(rowIndex)],
                        });
                    }}
                    disabled={disabled}
                />
                <MandatorySpan mandatory={mandatory} />
            </div>
        );
    }
}

export const payloadBuilder = (
    retailData,
    wholesaleData,
    retailCols,
    wholesaleCols
) => {
    const retailRowData = {};
    for (let row of retailData) {
        retailRowData[row.field] = row;
    }
    const retailRetVal = [];
    for (let col of retailCols) {
        // IF THE COLUMN IS DISABLED, IT MEANS THAT IT'S EITHER BASE PRICE LIST OR A CLONE
        // EITHER WAY, IT SHOULD BE IGNORED AND NOT SAVED AS PART OF THE PRICE RULES
        if (col.cellRendererParams.disabled) continue;
        const entry = {};
        entry.price_list_id = col.price_list_id;
        entry.price_list_name = col.price_list_name;
        entry.price_rule_price_list_mapping_id =
            col.price_rule_price_list_mapping_id;
        entry.rule_description = col.rule_description;
        entry.base_price_list_id =
            retailRowData.base_price_list_id[col.field]?.value || null;
        entry.base_currency =
            retailRowData.base_currency[col.field]?.value || null;
        entry.base_price_list_type = col.base_price_list_type;
        entry.local_currency = retailRowData.local_currency[col.field].value;
        if (
            !retailRowData.fx_rate[col.field] &&
            retailRowData.fx_rate[col.field] !== 0 &&
            col.formula.includes("fx_rate")
        ) {
            return [
                {
                    error: `${retailRowData.fx_rate["PRICE LIST"]} is required for ${col.field} in Retail Price Rules`,
                },
                "",
            ];
        }
        entry.fx_rate = retailRowData.fx_rate[col.field];
        if (
            !retailRowData.base_vat[col.field] &&
            retailRowData.base_vat[col.field] !== 0 &&
            col.formula.includes("base_vat")
        ) {
            return [
                {
                    error: `${retailRowData.base_vat["PRICE LIST"]} is required for ${col.field} in Retail Price Rules`,
                },
                "",
            ];
        }
        entry.base_vat = (retailRowData.base_vat[col.field] / 100).toFixed(4);
        if (
            !retailRowData.uplift_coefficient[col.field] &&
            retailRowData.uplift_coefficient[col.field] !== 0 &&
            col.formula.includes("uplift_coefficient")
        ) {
            return [
                {
                    error: `${retailRowData.uplift_coefficient["PRICE LIST"]} is required for ${col.field} in Retail Price Rules`,
                },
                "",
            ];
        }
        entry.uplift_coefficient = retailRowData.uplift_coefficient[col.field];
        entry.vat_incl = retailRowData.vat_incl[col.field].value;
        entry.local_vat = (retailRowData.local_vat[col.field] / 100).toFixed(4);
        entry.base_duty = (retailRowData.base_duty[col.field] / 100).toFixed(4);
        entry.local_duty = (retailRowData.local_duty[col.field] / 100).toFixed(
            4
        );
        entry.spread = retailRowData.spread[col.field];
        entry.ceiling = retailRowData.ceiling[col.field];
        entry.marketing_mark_up_all =
            retailRowData.marketing_mark_up_all[col.field];
        entry.starme_coeff = retailRowData.starme_coeff[col.field];
        retailRetVal.push(entry);
    }

    const wholesaleRowData = {};
    for (let row of wholesaleData) {
        wholesaleRowData[row.field] = row;
    }
    const wholesaleRetVal = [];
    for (let col of wholesaleCols) {
        if (col.cellRendererParams.disabled) continue;
        const entry = {};
        entry.price_list_id = col.price_list_id;
        entry.price_list_name = col.price_list_name;
        entry.price_rule_price_list_mapping_id =
            col.price_rule_price_list_mapping_id;
        entry.rule_description = col.rule_description;
        entry.base_price_list_id =
            wholesaleRowData.base_price_list_id[col.field]?.value || null;
        entry.base_price_list_type = col.base_price_list_type;
        entry.base_currency = wholesaleRowData.base_currency[col.field].value;
        if (
            !wholesaleRowData.wholesale_mu_percentage[col.field] &&
            wholesaleRowData.wholesale_mu_percentage[col.field] !== 0 &&
            col.formula.includes("wholesale_mu_percentage")
        ) {
            return [
                "",
                {
                    error: `${wholesaleRowData.wholesale_mu_percentage["PRICE LIST"]} is required for ${col.field} in Wholesale Price Rules`,
                },
            ];
        }
        entry.wholesale_mu_percentage = (
            wholesaleRowData.wholesale_mu_percentage[col.field] / 100
        ).toFixed(4);
        if (
            !wholesaleRowData.fx_rate[col.field] &&
            wholesaleRowData.fx_rate[col.field] !== 0 &&
            col.formula.includes("fx_rate")
        ) {
            return [
                "",
                {
                    error: `${wholesaleRowData.fx_rate["PRICE LIST"]} is required for ${col.field} in Wholesale Price Rules`,
                },
            ];
        }
        entry.fx_rate = wholesaleRowData.fx_rate[col.field];
        if (
            !wholesaleRowData.rounding[col.field] &&
            wholesaleRowData.rounding[col.field] !== 0 &&
            col.formula.includes("rounding")
        ) {
            return [
                "",
                {
                    error: `${wholesaleRowData.rounding["PRICE LIST"]} is required for ${col.field} in Wholesale Price Rules`,
                },
            ];
        }
        entry.rounding = wholesaleRowData.rounding[col.field];
        wholesaleRetVal.push(entry);
    }
    return [retailRetVal, wholesaleRetVal];
};

function retailRowMaker(
    col,
    retail,
    tempRetailCols,
    basePriceRuleTagId,
    cloneRuleTagId,
    invisiblePriceLists
) {
    let colToUse;
    switch (col.retail_price_tag_id) {
        // case cloneRuleTagId:
        //     colToUse = tempRetailCols.find(
        //         (e) => col.retail_clone_of_price_list_id === e.price_list_id
        //     );
        //     break;
        // case basePriceRuleTagId:
        //     colToUse = col;
        //     break;
        default:
            colToUse = tempRetailCols[0];
    }

    const isLATAM = col.base_price_list_type === "US MSRP";

    for (let row of retail) {
        row[col.field] =
            row.defaultVal ??
            (row.field === "base_price_list_id"
                ? isLATAM
                    ? {
                          value: invisiblePriceLists?.[0].id,
                          label: invisiblePriceLists?.[0].name,
                      }
                    : {
                          value: colToUse.price_list_id,
                          label: colToUse.field + " MSRP",
                      }
                : row.field === "base_currency"
                ? isLATAM
                    ? {
                          value: invisiblePriceLists[0].msrp_currency,
                          label: invisiblePriceLists[0].msrp_currency,
                      }
                    : {
                          value: colToUse.currency,
                          label: colToUse.currency,
                      }
                : { value: col.currency, label: col.currency });
    }
}

function wholesaleRowMaker(
    wholesale,
    col,
    tempWholesaleCols,
    cloneRuleTagId,
    invisiblePriceLists
) {
    const colToUse = tempWholesaleCols[0];

    const isLATAM = col.base_price_list_type === "US MSRP";

    for (let row of wholesale) {
        row[col.field] =
            row.defaultVal ??
            (row.field === "base_price_list_id"
                ? isLATAM
                    ? {
                          value: invisiblePriceLists?.[0].id,
                          label: invisiblePriceLists?.[0].name,
                      }
                    : {
                          value: colToUse.price_list_id,
                          label: colToUse.field + " MSRP",
                      }
                : {
                      value: col.currency,
                      label: col.currency,
                  });
    }
}

export function CustomHeader(props) {
    const { displayName, clone, clonedFrom } = props;
    return (
        <div className="align-center">
            {displayName.toUpperCase()}
            {clone ? (
                <Tooltip title={`Cloned from ${clonedFrom}`}>
                    <ContentCopyIcon sx={{ mx: 0.5, height: "16px" }} />
                </Tooltip>
            ) : (
                ""
            )}
        </div>
    );
}

function makeRetailDataForPriceRulesFromAPI(
    data,
    priceLists,
    invisiblePriceLists,
    tempRetailCols
) {
    const tableData = [...retailRows];
    for (let col of data) {
        const base =
            priceLists.find((pl) => +pl.id === col.base_price_list_id) ||
            priceLists[0];
        for (let row of tableData) {
            if (row.field === "base_price_list_id")
                row[col.price_list_name] =
                    col.base_price_list_type === "US MSRP"
                        ? {
                              value: invisiblePriceLists?.[0].id,
                              label: invisiblePriceLists?.[0].name,
                          }
                        : {
                              value: base.id,
                              label: base.name + " MSRP",
                          };
            else if (row.field === "base_currency")
                row[col.price_list_name] =
                    col.base_price_list_type === "US MSRP"
                        ? {
                              value: invisiblePriceLists[0].msrp_currency,
                              label: invisiblePriceLists[0].msrp_currency,
                          }
                        : {
                              value: base.msrp_currency || base.currency,
                              label: base.msrp_currency || base.currency,
                          };
            else if (row.field === "local_currency")
                row[col.price_list_name] = {
                    value: col.local_currency,
                    label: col.local_currency,
                };
            else if (row.field === "vat_incl")
                // IF THE STATE IS FROM THE API, IT WILL BE A STRING
                // IF THE STATE HAS BEEN SAVED FROM FRONTEND, IT WILL BE A NUMBER
                row[col.price_list_name] = binaryOptions.find((bo) =>
                    typeof col.vat_incl === "string"
                        ? bo.label === col.vat_incl
                        : bo.value == col.vat_incl
                );
            else
                row[col.price_list_name] =
                    ([
                        "base_vat",
                        "local_vat",
                        "base_duty",
                        "local_duty",
                    ].includes(row.field)
                        ? (col[row.field] * 100).toFixed(2)
                        : col[row.field]) ||
                    retailRows.find((e) => e.field === row.field).defaultVal;
        }
    }

    const priceRuleCols = data.map((e) => +e.price_list_id);
    const baseOrCloneCols = tempRetailCols.filter(
        (e) => !priceRuleCols.includes(+e.price_list_id)
    );
    for (let col of baseOrCloneCols) {
        const base = priceLists[0];
        for (let row of tableData) {
            if (row.field === "base_price_list_id")
                row[col.field] = {
                    value: base.id,
                    label: base.name + " MSRP",
                };
            else if (row.field === "base_currency")
                row[col.field] = {
                    value: base.msrp_currency || base.currency,
                    label: base.msrp_currency || base.currency,
                };
            else if (row.field === "local_currency")
                row[col.field] = {
                    value: col.currency,
                    label: col.currency,
                };
            else if (row.field === "rule_description")
                row[col.field] = col.rule_description;
            else {
                row[col.field] = retailRows.find(
                    (r) => r.field === row.field
                ).defaultVal;
            }
        }
    }
    return tableData;
}

function makeWholesaleDataForPriceRulesFromAPI(
    data,
    priceLists,
    invisiblePriceLists,
    tempWholesaleCols
) {
    const tableData = [...wholesaleRows];
    for (let col of data) {
        const base = priceLists.find((pl) => +pl.id === col.base_price_list_id);
        for (let row of tableData) {
            if (row.field === "base_price_list_id")
                row[col.price_list_name] = base
                    ? {
                          value: base.id,
                          label: base.name + " MSRP",
                      }
                    : {
                          value: invisiblePriceLists?.[0].id,
                          label: invisiblePriceLists?.[0].name,
                      };
            else if (row.field === "base_currency")
                row[col.price_list_name] = {
                    value: col.wholesale_currency || col.base_currency,
                    label: col.wholesale_currency || col.base_currency,
                };
            else
                row[col.price_list_name] =
                    (row.field === "wholesale_mu_percentage"
                        ? (col[row.field] * 100).toFixed(2)
                        : col[row.field]) ||
                    wholesaleRows.find((r) => r.field === row.field).defaultVal;
        }
    }

    const priceRuleCols = data.map((e) => +e.price_list_id);
    const cloneCols = tempWholesaleCols.filter(
        (e) => !priceRuleCols.includes(+e.price_list_id)
    );
    for (let col of cloneCols) {
        const base = priceLists[0];
        for (let row of tableData) {
            if (row.field === "base_price_list_id")
                row[col.field] = {
                    value: base.id,
                    label: base.name + " MSRP",
                };
            else if (row.field === "base_currency")
                row[col.field] = {
                    value: col.wholesale_currency || col.currency,
                    label: col.wholesale_currency || col.currency,
                };
            else if (row.field === "rule_description")
                row[col.field] = col.rule_description;
            else {
                row[col.field] = wholesaleRows.find(
                    (r) => r.field === row.field
                ).defaultVal;
            }
        }
    }
    return tableData;
}
