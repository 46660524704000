import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import store from "./store/index";
import AppRoutes from "./containers/common/Routes";
import "./App.css";

const { REACT_APP_ENV, REACT_APP_POSTHOG_API_KEY, REACT_APP_POSTHOG_URL } =
    process.env;

class App extends PureComponent {
    componentDidMount() {
        if (REACT_APP_ENV === "production" || REACT_APP_ENV === "uat") {
            const userName = localStorage.getItem("user")
                ? localStorage.getItem("user")
                : "User";

            posthog.init(REACT_APP_POSTHOG_API_KEY, {
                api_host: REACT_APP_POSTHOG_URL,
            });
            posthog.identify(userName);
        }
    }
    render() {
        return (
            <PostHogProvider client={posthog}>
                <Provider store={store}>
                    <AppRoutes />
                </Provider>
            </PostHogProvider>
        );
    }
}

export default App;
