function AlertExceptionMandatoryCell(props) {
    return (
        <div className="align-center">
            <span
                style={{
                    width: 10,
                    height: 10,
                    margin: 8,
                    borderRadius: "50%",
                    backgroundColor: props.value === 0 ? "#D00" : "#0F0",
                    boxShadow: `0px 0px 5px 0px ${
                        props.value === 0 ? "#D00" : "#0F0"
                    }`,
                }}
            ></span>
            {props.value === 0 ? "No" : "Yes"}
        </div>
    );
}

export default AlertExceptionMandatoryCell;
