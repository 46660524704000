import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function UpdateBanner() {
    const [showNextUpdateAlert, setShowNextUpdateAlert] = useState(false);
    const [downtimeMessage, setDowntimeMessage] = useState("");

    const { nextUpdateTime } = useSelector((state) => state.global);

    useEffect(() => {
        if (nextUpdateTime) {
            setShowNextUpdateAlert(true);

            const start = moment(nextUpdateTime.start_time)
                .utcOffset("+0000")
                .format("MM/DD/Y HH:mm");
            const end = moment(nextUpdateTime.end_time)
                .utcOffset("+0000")
                .format("MM/DD/Y HH:mm");
            const sameDate = start.substring(0, 10) === end.substring(0, 10);
            setDowntimeMessage(
                `${
                    sameDate ? `on ${start.substring(0, 10)}` : ""
                } from ${start.substring(sameDate ? 11 : 0)} to ${end.substring(
                    sameDate ? 11 : 0
                )} UTC`
            );
        }
    }, [nextUpdateTime]);

    return showNextUpdateAlert ? (
        <div className="update-banner">
            Update: Please note that there is a scheduled maintenance downtime{" "}
            {downtimeMessage}
        </div>
    ) : null;
}

export default UpdateBanner;
