import React from "react";
import Select from "../../../components/common/filters/Select/NewSelect";

function SelectCellEditor(props) {
    const { selectedOptions, setSelectedOptions, options, ...rest } = props;

    return (
        <>
            <Select
                options={options}
                value={selectedOptions}
                onChange={setSelectedOptions}
                isMulti={false}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                menuShouldBlockScroll
                {...rest}
            />
        </>
    );
}

export default SelectCellEditor;
