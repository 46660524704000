import React from "react";
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { protectedRoutes } from "../../../containers/common/Routes";
import { setNavigateAfterUnsavedEdit } from "../../../store/features/global/global";

import "./Header.scss";

const Breadcrumbs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const pathnames = pathname
        .split("/")
        .filter((path) =>
            protectedRoutes?.find((pRoute) => {
                return pRoute.breadcrumbPath === path;
            })
        )
        .map((pn) => ({ name: pn, source: "url" }));

    const { extraBreadcrumbPath, triggerUnsavedEdit } = useSelector(
        (state) => state.global
    );

    if (extraBreadcrumbPath.length) {
        extraBreadcrumbPath.forEach((path) => {
            pathnames.push({ name: path.label, source: "programmed" });
        });
    }

    return (
        <MUIBreadcrumbs
            className="breadcrumb-container"
            aria-label="breadcrumb"
        >
            {pathname.includes("config") ? (
                <Typography className="active" key={"configurationAndAlert"}>
                    Configuration & Alert
                </Typography>
            ) : null}
            {pathnames.map((path, index) => {
                const isLast =
                    index ===
                    (extraBreadcrumbPath.length
                        ? pathnames.findIndex((pn) => pn.source !== "url")
                        : pathnames.length) -
                        1;
                const currentRoute = protectedRoutes?.find((pRoute) => {
                    return pRoute.breadcrumbPath === path.name;
                });

                return isLast || path.source !== "url" ? (
                    <Typography
                        className="active"
                        key={currentRoute?.key || path.name}
                    >
                        {currentRoute?.title || path.name}
                    </Typography>
                ) : (
                    <Link
                        key={currentRoute?.key}
                        onClick={() => {
                            if (triggerUnsavedEdit)
                                dispatch(
                                    setNavigateAfterUnsavedEdit(() =>
                                        navigate(currentRoute?.path)
                                    )
                                );
                            else navigate(currentRoute?.path);
                        }}
                    >
                        {currentRoute?.title}
                    </Link>
                );
            })}
        </MUIBreadcrumbs>
    );
};

export default Breadcrumbs;
