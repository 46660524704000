import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useRef,
} from "react";
import PropTypes from "prop-types";
import Select, { components, defaultTheme } from "react-select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import _, { find, indexOf, cloneDeep, debounce, isEmpty } from "lodash";
import { useSelector } from "react-redux";

import "../Filters.scss";

const { colors } = defaultTheme;
const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    menuList: () => ({ maxHeight: "280px", overflowY: "auto" }),
    noOptionsMessage: (styles) => ({
        ...styles,
        height: "35px",
    }),
};

const reducer = (state, action) => {
    switch (action.type) {
        case "OPTION_INIT":
            return { ...state, isLoading: true };
        case "OPTION_ERROR":
            return { ...state, isLoading: false, isError: true };
        case "OPTION_SUCCESS":
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: [...state.data, ...action.payload],
            };
        case "OPTION_SET":
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: [...action.payload],
            };
        case "OPTION_RESET":
            return { ...state, isLoading: false, isError: false, data: [] };
        case "SEARCH_INIT":
            return { ...state, isSearching: true };
        case "SEARCH_SUCCESS":
            return {
                ...state,
                isSearching: false,
                // searchData: [...state.searchData, ...action.payload],
                searchData: [...action.payload],
            };
        case "SEARCH_RESET":
            return { ...state, isSearching: false, searchData: [] };
        default:
            break;
    }
};

const clickCallback =
    (
        isOpen,
        setisOpen,
        identifier,
        dropdownClose,
        triggerUnsavedEdit,
        setShowConfirmNavigateAway,
        setNavigateFunc
    ) =>
    (e) => {
        if (
            !e.target.closest(`#dropdown-wrapper-${identifier}`) &&
            !e.target.closest(`#custom-dropdown-menu-${identifier}`)
        ) {
            dropdownClose();

            return;
        }

        if (
            e.target.closest(`#dropdown-wrapper-${identifier}`) &&
            !e.target.closest(`#custom-dropdown-menu-${identifier}`)
        ) {
            if (isOpen) {
                dropdownClose();
            } else {
                if (
                    triggerUnsavedEdit &&
                    setShowConfirmNavigateAway &&
                    setNavigateFunc
                ) {
                    setShowConfirmNavigateAway(true);
                    setNavigateFunc(() => {
                        return () => {
                            setTimeout(() => {
                                setisOpen(true);
                            }, 0);
                        };
                    });
                } else setisOpen(true);
            }

            return;
        }
    };

function MultiSelect(props) {
    const [isOpen, setisOpen] = useState(false);
    const [optionSelected, setOptionSelected] = useState(
        props.selectedOptions || []
    );
    const [prevDependency, setprevDependency] = useState(null);
    const [searchPage, setsearchPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [flag_edit, setFlagEdit] = useState(false);
    const [identifier, setIdentifier] = useState(null);

    const { triggerUnsavedEdit } = useSelector((state) => state.global);

    const firstTimeRender = useRef(true);
    const [{ isLoading, data, isSearching }, dispatch] = useReducer(reducer, {
        isLoading: false,
        isError: false,
        data: [],
        isSearching: false,
        searchData: [],
    });

    const getDependency = (params) => {
        //todo - calculate the dependency selections for current select
        //that is find all selected values before selecting the current

        let dependency = cloneDeep(props.dependency);
        let index = indexOf(
            dependency,
            find(dependency, { field: props.filter_keyword })
        );
        if (index !== -1) {
            dependency = dependency.slice(0, index);
            return dependency;
        } else {
            return dependency;
        }
    };

    const fetchSearch = useCallback(() => {
        if (searchValue.length > 0) {
            let filteredValues = props.initialData.filter((item) =>
                item.label.toUpperCase().includes(searchValue.toUpperCase())
            );
            dispatch({
                type: "SEARCH_SUCCESS",
                payload: filteredValues,
            });
            dispatch({
                type: "OPTION_SET",
                payload: filteredValues,
            });
        } else {
            dispatch({ type: "SEARCH_RESET" });
            dispatch({
                type: "OPTION_SET",
                payload: props.initialData,
            });
        }
    }, [searchPage, searchValue, prevDependency]);

    useEffect(() => {
        if (!firstTimeRender.current) {
            fetchSearch(searchPage, searchValue);
        }
        return () => {};
    }, [searchValue]);

    useEffect(() => {
        if (!isEmpty(props.initialData) && isOpen) {
            dispatch({
                type: "OPTION_SET",
                payload: props.initialData,
            });
        }
        if (isEmpty(props.initialData) && !isEmpty(data)) {
            dispatch({
                type: "OPTION_SET",
                payload: [],
            });
        }
    }, [props.initialData]);

    useEffect(() => {
        if (props.selectedOptions && props.selectedOptions.length > 0) {
            setOptionSelected(props.selectedOptions);
        } else setOptionSelected([]);
    }, [props.selectedOptions]);

    useEffect(() => {
        firstTimeRender.current = false;

        setIdentifier(Math.floor(Math.random() * 1e9));

        return () => {};
    }, []);

    useEffect(() => {
        if (props.reset) {
            setFlagEdit(false);
            setOptionSelected([]);
        }
    }, [props.reset]);

    const dropdownClose = useCallback(() => {
        //todo -
        // verify if there is difference between the previous selected values and current
        // if yes send selected options list to parent on close to save
        // else if selected values is null remove the option from the selected value of parent
        // else do nothing
        dispatch({ type: "SEARCH_RESET" });
        setsearchPage(1);
        setSearchValue("");

        if (flag_edit && !props.updateSelectedOnEachSelection) {
            props.updateSelected({
                selectedItems: optionSelected,
                filterName: props.type,
            });
            setFlagEdit(false);
        }
        setisOpen(false);
    }, [flag_edit, optionSelected, props]);

    useEffect(() => {
        const callback = clickCallback(
            isOpen,
            setisOpen,
            identifier,
            dropdownClose,
            triggerUnsavedEdit,
            props.setShowConfirmNavigateAway,
            props.setNavigateFunc
        );

        document.addEventListener("click", callback);

        return () => {
            document.removeEventListener("click", callback);
        };
    }, [isOpen, identifier, dropdownClose]);

    const dropdownOpen = async (params) => {
        //load the data on initial dropdown click
        // let initialData = props.filterData.filter(item => item.key === props.filter_keyword);
        if (isEmpty(props.initialData)) {
            props.onDropdownOpen && props.onDropdownOpen();
        } else if (!_.isEmpty(props.initialData) && props.forceApiCall) {
            props.onDropdownOpen && props.onDropdownOpen();
            dispatch({
                type: "OPTION_SET",
                payload: props.initialData,
            });
        } else {
            dispatch({
                type: "OPTION_SET",
                payload: props.initialData,
            });
        }

        setprevDependency(getDependency());

        //todo - check if the dependency list of selected  value has changed or not
        // if yes set all state to initial and fire new data fetch req
        // else do nothing
    };

    useEffect(() => {
        if (isOpen) {
            const target = document.querySelector(
                `#custom-dropdown-menu-${identifier}`
            );

            const { left: selfLeft, bottom: selfBottom } =
                target.getBoundingClientRect();
            // Target is outside the viewport from the bottom
            if (
                !document
                    .elementFromPoint(selfLeft + 5, selfBottom - 5)
                    ?.isSameNode(target)
            ) {
                //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
                target.scrollIntoView(false);
            }

            const { left: parentLeft, top: parentTop } =
                target.parentElement.getBoundingClientRect();
            // Target is outside the view from the top
            if (
                !document
                    .elementsFromPoint(parentLeft + 5, parentTop + 5)[2]
                    ?.isSameNode(target.parentElement)
            ) {
                // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
                target.parentElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }
    }, [isOpen, identifier]);

    const dropdownSelectedMessage = () => {
        //todo-
        // items selected
        if (optionSelected.length) {
            if (!props.is_multiple_selection) {
                return `${
                    optionSelected &&
                    optionSelected[0] &&
                    optionSelected[0].label
                }`;
            } else if (
                optionSelected.length ===
                (props.initialData && props.initialData.length)
            ) {
                return "All Selected";
            } else {
                return `${optionSelected.length} ${
                    optionSelected.length > 1 ? " items" : " item"
                } selected`;
            }
        }
        return props.placeholder ? props.placeholder : "Select";
    };

    const onSelectAll = () => {
        //todo -
        // select all options on clicked
        //select only if there is data to pe selected and not all selected
        if (props.isDisabled) return;

        setFlagEdit(true);
        const temp = cloneDeep(optionSelected);
        const tempNames = temp.map((e) => e.value);
        data.forEach((dt) => {
            if (!tempNames.includes(dt.value)) temp.push(dt);
        });
        setOptionSelected(temp);
        if (props.updateSelectedOnEachSelection) {
            props.updateSelected({ selectedItems: temp });
        }
    };

    const onClearAll = () => {
        //todo -
        //clear all options on clicked if there is something to be cleared
        if (props.isDisabled) return;
        if (optionSelected.length) {
            const temp = data.map((dt) => dt.value);
            const newTempVal = optionSelected.filter(
                (o) => !temp.includes(o.value)
            );
            setFlagEdit(true);
            setOptionSelected(newTempVal);
            if (props.updateSelectedOnEachSelection) {
                props.updateSelected({ selectedItems: newTempVal });
            }
        }
    };

    const onChange = (selected) => {
        //on option selected
        if (props.isDisabled) return;
        let selectedItems = props.is_multiple_selection ? selected : [selected];
        setOptionSelected(selectedItems);
        if (props.updateSelectedOnEachSelection) {
            props.updateSelected({ selectedItems });
            !props.is_multiple_selection && setisOpen(!isOpen);
        }
        setFlagEdit(true);
    };

    const search = useCallback(
        debounce((searchKey) => {
            setSearchValue(searchKey);
        }, 200),
        []
    );

    const handleSearch = (event) => {
        //todo check if search input values are spaces or special characters
        //before calling search
        search(event.target.value);
    };

    return (
        <Dropdown
            isOpen={isOpen}
            identifier={identifier}
            onClose={dropdownClose}
            className={
                props.isDisabled
                    ? "dropdown-wrapper"
                    : `dropdown-wrapper ${props.className}`
            }
            id={`dropdown-wrapper-${identifier}`}
            target={
                <button
                    id="dropdown-button"
                    data-testid="dropdown-button"
                    onClick={dropdownOpen}
                    isselected={`${isOpen}`}
                    className={`dropdown-button ${
                        dropdownSelectedMessage() !== "Select"
                            ? "selected"
                            : "placeholder"
                    } ${props.isDisabled && "disabled-button"}`}
                >
                    <span className="selected-text">
                        {dropdownSelectedMessage()}
                    </span>
                    <span className="toggle-icon">
                        <ChevronDown />
                    </span>
                </button>
            }
        >
            {!props.hideSearch && (
                <div>
                    <div className="search-select__control">
                        <div className="search-select__value-container">
                            <div
                                className="multi-select__input"
                                style={{ display: "inline-block" }}
                            >
                                <input
                                    id="search-select-input"
                                    autoCapitalize="none"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="false"
                                    tabIndex="0"
                                    type="text"
                                    aria-autocomplete="list"
                                    placeholder="Search..."
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <div className="multi-select__indicators">
                            <div
                                style={{
                                    color: "rgb(204, 204, 204)",
                                    height: "16px",
                                    width: "16px",
                                }}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 20 20"
                                    focusable="false"
                                    role="presentation"
                                >
                                    <path
                                        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                                        fill="currentColor"
                                        fillRule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="filter-options-group">
                {!props.hideClearSelection && (
                    <div className="filter-options-item">
                        <div
                            id="dropdown-clear-btn"
                            data-testid="dropdown-clear"
                            className="filter-options-item-text"
                            onClick={onClearAll}
                            onKeyDown={() => {}}
                        >
                            <FilterAltIcon
                                sx={{
                                    padding: "0px",
                                    height: "16px",
                                    width: "16px",
                                }}
                            />
                            <label>Clear Selection</label>
                            {props.label ? (
                                <span style={{ textTransform: "uppercase" }}>
                                    from {props.label}
                                </span>
                            ) : null}
                        </div>
                    </div>
                )}
                {props.is_multiple_selection && !props.hideSelectAll && (
                    <div className="filter-options-item">
                        <div
                            id="select-all-btn"
                            className="filter-options-item-text"
                            onClick={onSelectAll}
                            onKeyDown={() => {}}
                        >
                            {props.initialData.length &&
                            optionSelected.length &&
                            data.filter((dt) =>
                                optionSelected.find((o) => o.value === dt.value)
                            ).length === data.length ? (
                                <CheckBoxIcon
                                    sx={{
                                        padding: "0px",
                                        height: "16px",
                                        width: "16px",
                                    }}
                                />
                            ) : (
                                <CheckBoxOutlineBlankIcon
                                    sx={{
                                        padding: "0px",
                                        height: "16px",
                                        width: "16px",
                                    }}
                                />
                            )}
                            <label>Select All</label>
                            {props.label ? (
                                <span style={{ textTransform: "uppercase" }}>
                                    from {props.label}
                                </span>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
            <Select
                //style select
                className="multi-select-container"
                classNamePrefix="multi-select"
                styles={selectStyles}
                placeholder="Search..."
                //dropdown behaviour change
                menuIsOpen
                autoFocus
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                isMulti={props.is_multiple_selection ? true : false}
                //custom components
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    Option,
                    NoOptionsMessage,
                    MenuList,
                    LoadingMessage,
                }}
                //custom functions and data load
                options={data}
                value={optionSelected}
                isLoading={isLoading || isSearching}
                onChange={onChange}
                onMenuOpen={props.onMenuOpen}
                menuShouldScrollIntoView={false}
                captureMenuScroll={false}
                menuShouldBlockScroll={false}
            />
        </Dropdown>
    );
}

export default MultiSelect;

//custom select components

const LoadingMessage = (props) => {
    let loadingElement = [];
    for (let i = 0; i < 10; i++) {
        loadingElement.push(
            <div
                className="multi-select__option"
                style={{ padding: " 8px 12px" }}
            >
                <div className="checkbox">
                    <input type="checkbox" checked="" onChange={() => null} />
                    <label htmlFor="checkbox">
                        <span>Loading...</span>
                    </label>
                </div>
            </div>
        );
    }
    return <>{loadingElement}</>;
};

const MenuList = (props) => {
    let loadingElement = [];
    loadingElement.push(props.children);
    if (props.isLoading) {
        for (let i = 0; i < 4; i++) {
            loadingElement.push(
                <div
                    className="multi-select__option"
                    style={{ padding: " 8px 12px" }}
                >
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            checked=""
                            onChange={() => null}
                        />
                        <label htmlFor="checkbox">
                            <span>Loading...</span>
                        </label>
                    </div>
                </div>
            );
        }
    }
    return (
        <components.MenuList className="menulistScroll" {...props}>
            {loadingElement}
        </components.MenuList>
    );
};

const Option = (props) => {
    const isMulti = props.selectProps.isMulti;
    const isSelected = props.isSelected;

    return (
        <components.Option
            {...props}
            className={`select-option ${
                !isMulti && isSelected ? "single-select highlight" : ""
            }`}
        >
            <div className="checkbox">
                {isMulti ? (
                    <>
                        <input
                            type="checkbox"
                            data-testid={`${props.label}select`}
                            checked={props.isSelected}
                            onChange={() => null}
                        />
                        <label htmlFor="checkbox">
                            <div data-testid={props.label}>{props.label}</div>
                        </label>
                    </>
                ) : (
                    <label>
                        <div
                            data-testid={`${props.label}select`}
                        >
                            {props.label}
                        </div>
                    </label>
                )}
            </div>
        </components.Option>
    );
};

Option.propTypes = {
    label: PropTypes.string,
};

const NoOptionsMessage = (props) => {
    return (
        <components.NoOptionsMessage {...props}>
            <div>
                <p className="filter-no-data">No Data</p>
            </div>
        </components.NoOptionsMessage>
    );
};

// component to create dropdown dropdown
const Menu = (props) => {
    const shadow = "hsla(218, 50%, 10%, 0.1)";
    return (
        <div
            style={{
                position: "absolute",
                marginTop: 8,
                paddingBottom: 20,
                width: "100%",
                zIndex: 2,
            }}
            id={`custom-dropdown-menu-${props.identifier}`}
        >
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: 2,
                    boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                }}
                {...props}
            />
        </div>
    );
};

const Dropdown = ({
    children,
    isOpen,
    target,
    onClose,
    className,
    identifier,
    id,
}) => (
    <div
        style={{ position: "relative", lineHeight: "20px" }}
        className={className}
        id={id}
    >
        {target}
        {isOpen ? <Menu identifier={identifier}>{children}</Menu> : null}
    </div>
);

const Svg = (p) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
        role="presentation"
        {...p}
    />
);
const DropdownIndicator = () => (
    <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);
const ChevronDown = () => (
    <Svg style={{ marginRight: -6 }}>
        <path
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </Svg>
);
