import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { API } from "../../../utils/axios/index";
import { eventListFormatter } from "../../../utils/helpers/eventHelper";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
	requestStart,
	requestComplete,
	requestFail,
	toastError,
	requestCompleteNoLoader
} from "../global/global";
import { setFilteredData } from "../filters/filters";

const initialState = {
	eventsList: {},
	eventDetails: {},
	activeEventId: null,
};

const eventSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		setEventList(state, action) {
			state.eventsList = action.payload;
		},
		setEventDetails(state, action) {
			state.eventDetails = action.payload;
		},
		setActiveEventId(state, action) {
			state.activeEventId = action.payload;
		},
	},
});

export const { setEventList, setEventDetails, setActiveEventId } =
	eventSlice.actions;
export default eventSlice.reducer;

export const getEvents =
	({ payload, dateRange = "ty", shouldSaveFilters = false, filterName, from }) =>
		(dispatch, getState) => {
			dispatch(requestStart());
			return API.post("/get-events", payload)
				.then((response) => {
					if (response.status === 200) {
						if (response.data) {
							if (_.isEmpty(response.data?.data)) {
								dispatch(
									toastError(
										"There is no data for the selected filters"
									)
								);
							}
							const { data } = response.data;
							dispatch(setEventList({
								...getState().event.eventsList,
								[dateRange]: data
							}));
							if (shouldSaveFilters) {
								const eventsFilterData = eventListFormatter(data);
								const filtersDataPayload = {
									payload: eventsFilterData || [],
									filterName,
									dataType: "options",
									activeScreen: from,
								};
								dispatch(setFilteredData(filtersDataPayload));
							}
							dispatch(requestComplete());

						}
					}
				})
				.catch((error) => {
					console.log(error);
					const errorMessage = setErrorMessage(error);
					dispatch(requestFail(errorMessage));
				});
		};

export const createEvent = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.post("/events", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, message } = response.data;
				// dispatch(setEventList(data));

				dispatch(requestComplete(message));

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const updateEventDetails = (payload) => (dispatch) => {
	dispatch(setEventDetails(payload));
};

export const getEventDetails = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/events-details", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data } = response.data;
				dispatch(setEventDetails(data));
				dispatch(requestComplete());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const editEvent = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.put("/events", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, message } = response.data;
				// dispatch(setEventDetails(data));
				dispatch(requestComplete(message));

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const deleteEvent = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/events/delete", payload)
		.then((response) => {
			if (response.status === 200) {
				const { message } = response.data;
				dispatch(requestComplete(message));
				return true;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const lockEvent = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.put("/events/lock", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, message } = response.data;
				// dispatch(setEventDetails(data));
				dispatch(requestComplete(message));

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};
