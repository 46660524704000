import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { AgGridReact } from "ag-grid-react";
import EditIcon from "@mui/icons-material/Edit";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import Tooltip from "@mui/material/Tooltip";
import ClearAllFilterButton from "../../common/clearAllFilterButton/ClearAllFilterButton";

import {
    fetchExceptions,
    editExceptions,
    setExceptions as setDispatchExceptions,
} from "../../../store/features/exception/exception";
import { fallbackFormatter } from "../../../utils/helpers/formatter";
import SwitchCellRenderer from "../../../utils/reactTable/cellRenderers/SwitchCellRenderer";
import {
    setTriggerUnsavedEdit,
    toastError,
} from "../../../store/features/global/global";
import { onFilterChanged } from "../../../utils/reactTable/callbacks/callbacks";
import TableRowCount from "../../common/tableRowCount/TableRowCount";
import useTableRowCount from "../../common/tableRowCount/useTableRowCount";
import TooltipRenderer from "../../../utils/reactTable/tooltipRenderer/TooltipRenderer";

import "../../../utils/reactTable/tableRenderer/AgGridTable.scss";
import styles from "./ExceptionConfig.module.scss";
import { useCallbackPrompt } from "../../../utils/customHooks/useCallbackPrompt";
import Dialog from "../../common/dialog/Dialog";

export const SUPER_USER = 1;
export const PRICING_TEAM = 2;
export const MERCHANT = 3;
export const DEFAULT = 4;

export function validateMandatoryToggleAccess(
    role_details,
    allowed_regions,
    data
) {
    if (role_details[SUPER_USER]) {
        return true;
    }

    if (role_details[PRICING_TEAM]) {
        const foundIndex = allowed_regions.findIndex(
            (region) => region.value.toString() === data.region.value
        );

        if (foundIndex !== -1) {
            return true;
        }
    }

    return false;
}

export function validateEnableToggleAccess(
    role_details,
    allowed_regions,
    data
) {
    if (role_details[SUPER_USER]) {
        return true;
    }

    if (role_details[PRICING_TEAM] || role_details[MERCHANT]) {
        const foundIndex = allowed_regions.findIndex(
            (region) => region.value.toString() === data.region.value
        );

        if (foundIndex !== -1) {
            return true;
        }
    }

    return false;
}

export function shouldDisableEnableToggle(data) {
    if (data.is_mandatory) {
        return true;
    }

    return false;
}

function ExceptionConfig() {
    const dispatch = useDispatch();
    const gridRef = useRef();

    const [selectedExceptions, setSelectedExceptions] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState({});
    const [exceptionsToBeSaved, setExceptionsToBeSaved] = useState([]);
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);
    const [exceptions, setExceptions] = useState([]);

    const permExceptions = useSelector((state) => state.exception.exceptions);
    const { allowed_feature_actions, allowed_regions, role_details } =
        useSelector((state) => state.global.userPermissions);
    const { triggerUnsavedEdit } = useSelector((state) => state.global);

    const [showPrompt, confirmNavigation, cancelNavigation] =
        useCallbackPrompt(triggerUnsavedEdit);
    const handleNavigationPrompt = (confirm) => {
        if (confirm) {
            dispatch(setTriggerUnsavedEdit(null));
            confirmNavigation();
        } else {
            cancelNavigation();
        }
    };

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    useEffect(() => {
        if (isEqual(permExceptions, exceptions) && !isEmpty(permExceptions))
            return;

        if (!isEmpty(permExceptions)) {
            setExceptions(permExceptions);
            return;
        }

        dispatch(fetchExceptions()).then((res) => {
            setExceptions(res);
        });
    }, [permExceptions]);

    useEffect(() => {
        return () => {
            dispatch(setDispatchExceptions([]));
        };
    }, []);

    useEffect(() => {
        const newExceptionsToBeSaved = [];
        const editedExceptions = Object.values(editData);

        for (const editedException of editedExceptions) {
            const foundIndex = selectedExceptions.findIndex(
                (selectedException) =>
                    selectedException.data.id === editedException.id
            );

            if (foundIndex === -1) {
                continue;
            }

            const selectedException = selectedExceptions[foundIndex].data;

            if (!isEqual(selectedException, editedException)) {
                newExceptionsToBeSaved.push(editedException);
            }
        }

        setExceptionsToBeSaved(newExceptionsToBeSaved);
    }, [editData, selectedExceptions]);

    const onExceptionDataChange = useCallback(
        (node, isMandatoryColumn, newValue) => {
            const { data } = node;
            const editDataCopy = cloneDeep(editData);

            editDataCopy[data.id][node.column.colId] = newValue ? 1 : 0;

            if (isMandatoryColumn && newValue) {
                editDataCopy[data.id].is_enabled = 1;
            }

            setEditData(editDataCopy);
        },
        [editData]
    );

    const onEditClick = () => {
        if (!allowed_feature_actions.exception?.edit) {
            return;
        }

        const selectedExceptionsData = cloneDeep(
            selectedExceptions.map(
                (selectedException) => selectedException.data
            )
        );
        const newEditData = {};

        for (const selectedExceptionData of selectedExceptionsData) {
            newEditData[selectedExceptionData.id] = selectedExceptionData;
        }

        setEditData(newEditData);

        setEditMode(true);
        dispatch(setTriggerUnsavedEdit("exceptions"));
    };

    const onSync = () => {
        if (!allowed_feature_actions.exception?.edit) {
            return;
        }

        const editedExceptionsData = exceptionsToBeSaved.map(
            ({ id, is_enabled, is_mandatory }) => ({
                id: +id,
                is_enabled,
                is_mandatory,
            })
        );

        dispatch(
            editExceptions({
                edit_config_dict: editedExceptionsData,
            })
        )
            .then(() => {
                dispatch(fetchExceptions());
                dispatch(setTriggerUnsavedEdit(null));

                setSelectedExceptions([]);
                setEditMode(false);
                gridRef?.current?.api?.deselectAll();
            })
            .catch((err) => {
                dispatch(toastError(err?.message || err));
            });
    };

    const onExceptionEditDiscardClick = () => {
        dispatch(setTriggerUnsavedEdit(null));
        setEditMode(false);
        setEditData({});
    };

    const onExceptionSelect = useCallback(
        (event) => {
            const newSelectedExceptions =
                gridRef.current.api.getSelectedNodes();

            setSelectedExceptions(newSelectedExceptions);

            if (newSelectedExceptions.length === 0) {
                setEditMode(false);
            }

            onTableSelectChange(event);
        },
        [editMode, allowed_regions]
    );

    const isExceptionSelectable = useCallback(
        (data) => {
            if (!allowed_feature_actions.exception?.edit) {
                return false;
            }

            if (!allowed_regions) {
                return false;
            }

            const foundIndex = allowed_regions.findIndex(
                (regionObj) => regionObj.value.toString() === data.region.value
            );

            if (foundIndex === -1) {
                return false;
            }

            if (
                !validateMandatoryToggleAccess(
                    role_details,
                    allowed_regions,
                    data
                ) &&
                data.is_mandatory
            ) {
                return false;
            }

            return !editMode;
        },
        [role_details, allowed_regions, allowed_feature_actions, editMode]
    );

    const colDefs = useMemo(
        () => [
            {
                headerName: "",
                field: "",
                width: 50,
                flex: 0,
                sortable: false,
                filter: false,
                pinned: true,
                lockPinned: true,
                valueFormatter: () => "",
                checkboxSelection: () =>
                    allowed_feature_actions.exception?.edit && !editMode,
                headerCheckboxSelection: () =>
                    allowed_feature_actions.exception?.edit &&
                    !editMode &&
                    exceptions.filter(isExceptionSelectable).length,
                headerCheckboxSelectionFilteredOnly: true,
            },
            {
                headerName: "EXCEPTION",
                field: "name",
                pinned: true,
                lockPinned: true,
                tooltipComponent: TooltipRenderer,
                tooltipField: "name",
            },
            {
                headerName: "DESCRIPTION",
                field: "description",
                tooltipComponent: TooltipRenderer,
                tooltipField: "description",
            },
            {
                headerName: "REGION",
                field: "region",
                valueFormatter: ({ value }) => value?.label,
                filterValueGetter: (data) =>
                    data.getValue("region")?.label ?? "",
                comparator: (a, b) => (a.label > b.label ? 1 : -1),
            },
            {
                headerName: "CHANNEL",
                field: "channel",
                valueFormatter: ({ value }) => value?.[0]?.label,
                filterValueGetter: (data) =>
                    data
                        .getValue("channel")
                        ?.map((obj) => obj.label)
                        .join(",") ?? "",
                comparator: (a, b) => ((a ?? "") > (b ?? "") ? 1 : -1),
            },
            {
                headerName: "THRESHOLD",
                field: "threshold",
                filterParams: {
                    filters: [
                        {
                            filter: "agTextColumnFilter",
                        },
                        {
                            filter: "agSetColumnFilter",
                        },
                    ],
                },
            },
            {
                headerName: "ENABLED",
                field: "is_enabled",
                cellRenderer: (props) => (
                    <SwitchCellRenderer
                        cellProps={props}
                        editMode={
                            editMode &&
                            validateEnableToggleAccess(
                                role_details,
                                allowed_regions,
                                props.data
                            )
                        }
                        selectedOptions={selectedExceptions}
                        // setData={setExceptions}
                        onChange={onExceptionDataChange}
                        disabled={shouldDisableEnableToggle(
                            editData[props.data.id] ?? {}
                        )}
                        editData={editData}
                    />
                ),
                filterValueGetter: (data) =>
                    data.getValue("is_enabled") ? "yes" : "no",
            },
            {
                headerName: "MANDATORY",
                field: "is_mandatory",
                cellRenderer: (props) => (
                    <SwitchCellRenderer
                        cellProps={props}
                        editMode={
                            editMode &&
                            validateMandatoryToggleAccess(
                                role_details,
                                allowed_regions,
                                props.data
                            )
                        }
                        selectedOptions={selectedExceptions}
                        // setData={setExceptions}
                        onChange={onExceptionDataChange}
                        isMandatoryColumn
                        editData={editData}
                    />
                ),
                filterValueGetter: (data) =>
                    data.getValue("is_mandatory") ? "yes" : "no",
            },
        ],
        [
            exceptions,
            editMode,
            selectedExceptions,
            allowed_regions,
            editData,
            role_details,
            allowed_feature_actions,
            onExceptionDataChange,
            isExceptionSelectable,
        ]
    );

    const processCellForClipboard = useCallback((params) => {
        const col = params.column.getColId();
        if (col === "region") return params.value?.label;
        if (col === "channel") {
            return params.value[0]?.label;
        }
        if (["is_enabled", "is_mandatory"].includes(col))
            return params.value ? "Yes" : "No";
        return params.value;
    }, []);

    const isRowSelectable = useCallback(
        ({ data }) => {
            return isExceptionSelectable(data);
        },
        [isExceptionSelectable]
    );

    const refreshList = () => {
        gridRef?.current?.api?.deselectAll();

        dispatch(fetchExceptions()).then((res) => {
            setExceptions(res);
        });
    };

    const onGridReady = () => {
        gridRef.current.api.addEventListener("filterChanged", (event) => {
            setIsAnyFilterApplied(event.api.isAnyFilterPresent());
        });
    };

    return (
        <div className={`${styles.container} p-20`}>
            <Dialog
                showModal={showPrompt}
                handleModalAction={handleNavigationPrompt}
                modalContent="Are you sure you want to navigate away? There might be unsaved changes"
                confirmText="Proceed"
                denyText="Cancel"
                showCloseIcon={false}
            />
            <div
                className={`${styles["page-header"]} align-center justify-space-between`}
            >
                <div
                    style={{
                        display: "flex",
                        height: "35px",
                        alignItems: "center",
                    }}
                >
                    Exceptions
                </div>

                {selectedExceptions.length ? (
                    <div className="button-group-flex">
                        {!editMode ? (
                            <>
                                <Tooltip title="Refresh" enterDelay={300}>
                                    <Button
                                        variant="contained"
                                        onClick={refreshList}
                                    >
                                        <RefreshOutlinedIcon fontSize="small" />
                                    </Button>
                                </Tooltip>
                                <ClearAllFilterButton
                                    isVisible={isAnyFilterApplied}
                                    agGridApi={gridRef?.current?.api}
                                />
                                {allowed_feature_actions.exception?.edit ? (
                                    <Tooltip title="Edit selected exception">
                                        <Button
                                            variant="contained"
                                            onClick={onEditClick}
                                            disabled={
                                                !allowed_feature_actions
                                                    .exception?.edit
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {allowed_feature_actions.exception?.edit ? (
                                    <Tooltip title="Sync edited exception">
                                        <Button
                                            variant="contained"
                                            onClick={onSync}
                                            disabled={
                                                !allowed_feature_actions
                                                    .exception?.edit ||
                                                !exceptionsToBeSaved.length
                                            }
                                        >
                                            <SaveOutlinedIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    onClick={onExceptionEditDiscardClick}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="button-group-flex">
                        <Tooltip title="Refresh" enterDelay={300}>
                            <Button variant="contained" onClick={refreshList}>
                                <RefreshOutlinedIcon fontSize="small" />
                            </Button>
                        </Tooltip>
                        <ClearAllFilterButton
                            isVisible={isAnyFilterApplied}
                            agGridApi={gridRef?.current?.api}
                        />
                    </div>
                )}
            </div>
            <div
                style={{ height: "calc(100vh - 300px)" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={exceptions}
                    columnDefs={colDefs}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    onSelectionChanged={onExceptionSelect}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        resizable: true,
                        filter: "agMultiColumnFilter",
                        floatingFilter: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        showDisabledCheckboxes:
                            allowed_feature_actions.exception?.edit &&
                            !editMode,
                        valueFormatter: fallbackFormatter,
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        },
                    }}
                    suppressRowClickSelection
                    suppressClickEdit
                    onFilterChanged={onFilterChanged}
                    onModelUpdated={onTableModelUpdate}
                    enableRangeSelection
                    processCellForClipboard={processCellForClipboard}
                    isRowSelectable={isRowSelectable}
                />
            </div>
            <TableRowCount count={rowCount} selected={selectedRowCount} />
        </div>
    );
}

export default ExceptionConfig;
