import React from "react";
import { Chip, Tooltip } from "@mui/material";

const FilterSummary = (props) => {
    // const summary = [
    // 	{
    // 		label: "Division",
    // 		value: ["Furniture", "Party Supplies"],
    // 	},
    // 	{
    // 		label: "Department",
    // 		value: ["Upholstery, Cutlery"],
    // 	},
    // 	{
    // 		label: "Class",
    // 		value: ["Sofas"],
    // 	},
    // ];

    const getChips = (filterType) => {
        if (!filterType) {
            return null;
        }

        return (
            <>
                <label class="chip-label">{filterType.label}</label>
                {filterType.value.slice(0, 2).map((filterValue) => {
                    return (
                        <Chip className="summary-chip" label={filterValue} />
                    );
                })}
                {filterType.value.length > 2 ? (
                    <Tooltip title={filterType.value.slice(2).join(", ")}>
                        <Chip
                            className="summary-chip last"
                            label={`+${filterType.value.length - 2}`}
                        />
                    </Tooltip>
                ) : null}
            </>
        );
    };

    return (
        <div className={`filter-summary-container ${props.className}`}>
            {props.summary?.length ? (
                props.summary.map((filterType) =>
                    filterType?.value?.length ? getChips(filterType) : null
                )
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        color: "#758498",
                        fontSize: "14px",
                    }}
                >
                    <span>No filter selected</span>
                </div>
            )}
        </div>
    );
};

export default FilterSummary;
