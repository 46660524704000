import { Button, Modal } from "@mui/material";
import React from "react";

import styles from "./dialog.module.scss";

function Dialog(props) {
    const {
        showModal,
        handleModalAction,
        modalType,
        modalTitle = "",
        modalContent = "",
        confirmText = "Confirm",
        denyText = "Cancel",
        showCloseIcon = true,
    } = props;

    return (
        <Modal
            open={showModal}
            aria-labelledby="delete-market"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className={styles["modal-container"]}>
                {modalTitle ? (
                    <h2 className={styles.title}>{modalTitle}</h2>
                ) : null}
                {showCloseIcon ? (
                    <span className={styles["close-icon"]}>&#10005;</span>
                ) : null}
                <div>{modalContent}</div>
                <div className={`${styles["modal-buttons"]} button-group-flex`}>
                    <Button
                        variant="contained"
                        color={modalType || "primary"}
                        onClick={() => {
                            handleModalAction(true);
                        }}
                    >
                        {confirmText}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            handleModalAction(false);
                        }}
                    >
                        {denyText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default Dialog;
