import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyMainDataQuery } from "../../../../store/features/workbench/buyersScreen";
import { userUpdateColumnSettings } from "../../../../store/features/workbench/workbench";
import ClearAllFilterButton from "../../../common/clearAllFilterButton/ClearAllFilterButton";
import MultiSelect from "../../../common/filters/Select/Select";
import TableRowCount from "../../../common/tableRowCount/TableRowCount";
import useTableRowCount from "../../../common/tableRowCount/useTableRowCount";
import LoadingOverlay from "../../../ui/loader/Loader";
import styles from "../EUPricePlanning.module.scss";
import TableSettings, { tableSettingTabIds } from "../TableSettings";
import { getMaterialFetchData } from "../euHelper";
import FilterShowcaseBar from "./FilterShowcaseBar";
import {
    MARKETS_TYPE,
    calculateTyVsLyData,
    changeMetrics,
    checkRowIsGrandTotal,
    costUsedList,
    domUpdateForgrandTotal,
    downloadHandler,
    getValueForSumAgg,
    gridOptions,
    metricVersionOptions,
    setSelectedItemInList,
    updateColumnGroupColor,
    useRowData,
    valueFormatterPivotTable,
    vistLeafNodesColumns,
    getUnitsFieldKey,
    getPriceKey,
} from "./utils";

let rowData = [];
const getMarketNameAndYear = (season, year) => {
    if (season?.toLowerCase() === "total") {
        return { marketName: "", marketNameWithYear: "" };
    }
    const marketName = season?.toLowerCase().includes(MARKETS_TYPE.PRE)
        ? yearsAndMarkets.markets.pre
        : yearsAndMarkets.markets.main;
    const marketNameWithYear = marketName + " " + year;
    return { marketName, marketNameWithYear };
};

const getAllLeafChildren = (params) => {
    return checkRowIsGrandTotal(params)
        ? rowData.map((item) => ({ data: item }))
        : params.rowNode.allLeafChildren;
};

const getRawParentList = (params) => {
    if (params.rowNode.level === 0) {
        return rowData.map((item) => ({ data: item }));
    } else {
        return params.rowNode?.parent?.allLeafChildren || [];
    }
};

const getFilterList = (list, marketNameWithYear, year) => {
    return (
        list
            .filter((item) => marketNameWithYear in item.data)
            .map((item) => item.data[marketNameWithYear][year]) || []
    );
};

const getParentList = (rawParentList, marketNameWithYear, year) => {
    return (
        rawParentList
            ?.filter((item) => marketNameWithYear in item.data)
            ?.map((item) => item.data[marketNameWithYear][year]) || []
    );
};

const calcFilterParentList = (
    params,
    season,
    year,
    marketNameWithYear,
    calculateParent = true
) => {
    const list = getAllLeafChildren(params);
    const rawParentList = calculateParent ? getRawParentList(params) : [];

    let filterList = [];
    let parentList = [];

    if (season === "Total") {
        const preMarketObj = getMarketNameAndYear(MARKETS_TYPE.PRE, year);
        const mainMarketNameObj = getMarketNameAndYear(MARKETS_TYPE.MAIN, year);

        filterList = [
            ...getFilterList(list, preMarketObj.marketNameWithYear, year),
            ...getFilterList(list, mainMarketNameObj.marketNameWithYear, year),
        ];

        if (calculateParent) {
            parentList = [
                ...getParentList(
                    rawParentList,
                    preMarketObj.marketNameWithYear,
                    year
                ),
                ...getParentList(
                    rawParentList,
                    mainMarketNameObj.marketNameWithYear,
                    year
                ),
            ];
        }
    } else {
        filterList = getFilterList(list, marketNameWithYear, year);

        if (calculateParent) {
            parentList = getParentList(rawParentList, marketNameWithYear, year);
        }
    }

    return [filterList, parentList];
};


const sumAggregate = (params) => {
    const { season, year, timePeriod, marketNameWithYear } =
        params.colDef.cellRendererParams;

    let [filterList] = calcFilterParentList(
        params,
        season,
        year,
        marketNameWithYear,
        false
    );

    const thisYear = filterList.reduce((a, b) => {
        return a + getValueForSumAgg(params, b);
    }, 0);

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(season, yearsAndMarkets.years.ly),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const marginPercAggregate = (params) => {
    const { season, timePeriod } = params.colDef.cellRendererParams;

    const marginSum = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                metricUnit: "margin",
            },
        },
    });

    const salesSum = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                metricUnit: "sales",
            },
        },
    });

    const marginPercThis = (marginSum / salesSum) * 100;

    if (timePeriod !== "tyVly") return marginPercThis;

    const marginPercLast = marginPercAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(season, yearsAndMarkets.years.ly),
            },
        },
    });

    return (marginPercThis - marginPercLast) * 100;
};

const AICAggregate = (params) => {
    const {
        season,
        year,
        typeOfUnit,
        levelOfUnit,
        timePeriod,
        marketNameWithYear,
    } = params.colDef.cellRendererParams;

    const unitsFieldKey = getUnitsFieldKey(levelOfUnit, typeOfUnit);

    let [filterList] = calcFilterParentList(
        params,
        season,
        year,
        marketNameWithYear,
        false
    );

    const unitSumWithCost = filterList.reduce((a, b) => {
        const cost = b?.calculatedCost || 0;
        const unit = b?.[unitsFieldKey] || 0;
        return a + cost * unit;
    }, 0);

    const unitSumOfCost = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                metricUnit: "units",
            },
        },
    });

    const thisYear = unitSumWithCost / unitSumOfCost;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = AICAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(season, yearsAndMarkets.years.ly),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const mixAggregate = (params) => {
    const {
        season,
        marketNameWithYear,
        typeOfUnit,
        levelOfUnit,
        timePeriod,
        year,
    } = params.colDef.cellRendererParams;

    if (checkRowIsGrandTotal(params)) {
        if (timePeriod === "tyVly") {
            return 0;
        }

        return 100;
    }

    let [filterList, parentList] = calcFilterParentList(
        params,
        season,
        year,
        marketNameWithYear,
        true
    );

    const unitsFieldKey = getUnitsFieldKey(levelOfUnit, typeOfUnit);
    const priceKey = getPriceKey(levelOfUnit, channel);

    const unitSumWithPrice = filterList.reduce((a, b) => {
        const price = b?.[priceKey] || 0;
        const unit = b?.[unitsFieldKey] || 0;
        return a + price * unit;
    }, 0);

    const unitSumWithPriceOneLevelHigher = parentList.reduce(
        (a, b) => a + (b?.[unitsFieldKey] * b?.[priceKey] || 0),
        0
    );

    const thisYear = (unitSumWithPrice / unitSumWithPriceOneLevelHigher) * 100;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = mixAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(season, yearsAndMarkets.years.ly),
            },
        },
    });

    return (thisYear - lastYear) * 100;
};

const AIPAggregate = (params) => {
    const {
        season,
        marketNameWithYear,
        typeOfUnit,
        levelOfUnit,
        timePeriod,
        year,
    } = params.colDef.cellRendererParams;

    let [filterList] = calcFilterParentList(
        params,
        season,
        year,
        marketNameWithYear,
        false
    );

    const unitsFieldKey = getUnitsFieldKey(levelOfUnit, typeOfUnit);
    const priceKey = getPriceKey(levelOfUnit, channel);

    const unitSumWithPrice = filterList.reduce((a, b) => {
        const price = b?.[priceKey] || 0;
        const unit = b?.[unitsFieldKey] || 0;
        return a + price * unit;
    }, 0);

    const unitSum = filterList.reduce((a, b) => {
        const unit = b?.[unitsFieldKey] || 0;
        return a + unit;
    }, 0);

    const thisYear = unitSumWithPrice / unitSum;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = AIPAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(season, yearsAndMarkets.years.ly),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const markupAggregate = (params) => {
    const { season, levelOfUnit, timePeriod } =
        params.colDef.cellRendererParams;

    if (season === "Total") {
        const returnValNumTotal = AIPAggregate({
            ...params,
            colDef: {
                ...params.colDef,
                cellRendererParams: {
                    ...params.colDef.cellRendererParams,
                    season: "Total",
                },
            },
        });
        const returnValDenTotal = AIPAggregate({
            ...params,
            colDef: {
                ...params.colDef,
                cellRendererParams: {
                    ...params.colDef.cellRendererParams,
                    season: "Total",
                    levelOfUnit: levelOfUnit === "dtc" ? "wholesale" : "dtc",
                },
            },
        });

        return returnValNumTotal / returnValDenTotal;
    }

    const returnValNumThis = AIPAggregate(params);
    const returnValDenThis = AIPAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                levelOfUnit: levelOfUnit === "dtc" ? "wholesale" : "dtc",
            },
        },
    });

    const thisYear = returnValNumThis / returnValDenThis;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = markupAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(season, yearsAndMarkets.years.ly),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const aggregateFuncDefs = {
    sumAggregate,
    marginPercAggregate,
    AICAggregate,
    mixAggregate,
    AIPAggregate,
    markupAggregate,
};

const getBorderClass = (props) => {
    const {
        louIdx,
        touChildren,
        touIdx,
        muChildren,
        muIdx,
        tpChildren,
        tpIdx,
        tempSeasonChildren,
        isLastBigCol,
    } = props;
    let borderClass = "";

    if (louIdx === touChildren.length - 1) {
        borderClass = "l-border";
    }

    if (louIdx === touChildren.length - 1 && touIdx === muChildren.length - 1) {
        borderClass = "xl-border";
    }

    if (
        louIdx === touChildren.length - 1 &&
        touIdx === muChildren.length - 1 &&
        muIdx === tpChildren.length - 1
    ) {
        borderClass = "xxl-border";
    }

    if (
        louIdx === touChildren.length - 1 &&
        touIdx === muChildren.length - 1 &&
        muIdx === tpChildren.length - 1 &&
        tpIdx === tempSeasonChildren.length - 1
    ) {
        borderClass = "xxxl-border";
        if (isLastBigCol) borderClass = "";
    }

    return borderClass;
};

const saleModeListMapping = (params) => {
    const {
        lou,
        louIdx,
        tou,
        touIdx,
        appliedTableSettings,
        isLastBigCol,
        tempSeasonChildren,
        tpChildren,
        seasonLabel,
        tpIdx,
        year,
        tp,
        muChildren,
        muIdx,
        mu,
        touChildren,
        touHeaderClass,
    } = params;

    let borderClass = getBorderClass({
        louIdx,
        touChildren,
        touIdx,
        muChildren,
        muIdx,
        tpChildren,
        tpIdx,
        tempSeasonChildren,
        isLastBigCol,
    });

    return {
        ...lou,
        headerName: channel === "OUTLET" ? "Outlet" : lou.label,
        headerClass:
            (louIdx === touChildren.length - 1 ? touHeaderClass : "extra-border") + " roll-up-sale-mode-header",
        width:
            appliedTableSettings.view_data.columnWidth === "1"
                ? lou.width || 130
                : 130,
        aggFunc: aggregateFuncDefs[mu.aggFunc],
        cellRendererParams: {
            ...getMarketNameAndYear(seasonLabel, year),
            year,
            season: seasonLabel,
            metricUnit: mu.value,
            typeOfUnit: tou.value,
            levelOfUnit: lou.value,
            timePeriod: tp.value,
        },
        valueFormatter: valueFormatterPivotTable,
        cellClass: "extra-border justify-right " + borderClass,
    };
};

const typeOfUnitListMapping = (params) => {
    const {tou, touIdx, muChildren, muHeaderClass} = params;
    const touChildren = tou.children.filter(
        (item) =>
            item.isSelected &&
            (channel !== "OUTLET" || item.label.toLowerCase() === "dtc")
    );
    const touHeaderClass =
        touIdx === muChildren.length - 1
            ? muHeaderClass
            : "extra-border l-border";
    return {
        ...tou,
        headerName: tou.label,
        headerClass: touHeaderClass + " left-sticky-label",
        children: touChildren
            // sale mode
            .map((lou, louIdx) =>
                saleModeListMapping({
                    lou,
                    louIdx,
                    touChildren,
                    touHeaderClass,
                    ...params,
                })
            ),
    };
};

const metricsListMapping = (params) => {
    const { mu, muIdx, tpChildren, tpHeaderClass } = params;
    const muChildren = mu.children.filter((item) => item.isSelected);
    const muHeaderClass =
        muIdx === tpChildren.length - 1
            ? tpHeaderClass
            : "extra-border xl-border";
    return {
        ...mu,
        headerName: mu.label,
        headerClass: muHeaderClass + " left-sticky-label",
        children: muChildren
            // data type
            .map((tou, touIdx) =>
                typeOfUnitListMapping({
                    tou,
                    touIdx,
                    muChildren,
                    muHeaderClass,
                    ...params,
                })
            ),
    };
};

const seasonListMapping = ({
    tp,
    tpIdx,
    seasonLabel,
    selectedMetricVersion,
    appliedTableSettings,
    isLastBigCol,
    headerClass,
    tempSeasonChildren,
}) => {
    let year = 0;
    let headerName = "";

    if (tp.value === "thisYear") {
        year = yearsAndMarkets?.years?.ty;
        headerName = year;
    } else if (tp.value === "lastYear") {
        year = yearsAndMarkets?.years?.ly;
        headerName = year;
    } else {
        headerName = `${yearsAndMarkets?.years?.ty} vs ${yearsAndMarkets?.years?.ly}`;
        year = yearsAndMarkets?.years?.ty;
    }

    const tpChildren = tp.children.filter((item) => item.isSelected);
    const tpHeaderClass =
        tpIdx === tempSeasonChildren.length - 1
            ? headerClass
            : "extra-border xxl-border";
    const headerBgClass = ` sub-sub-header-${
        tpIdx % 2 === 0 ? "gray" : "blue"
    }`;
    return {
        ...tp,
        headerName,
        headerClass: tpHeaderClass + headerBgClass + " left-sticky-label",
        children: tpChildren
            // metrics
            .map((mu, muIdx) =>
                metricsListMapping({
                    mu,
                    muIdx,
                    seasonLabel,
                    selectedMetricVersion,
                    appliedTableSettings,
                    isLastBigCol,
                    headerClass,
                    tempSeasonChildren,
                    tpChildren,
                    tpHeaderClass,
                    tpIdx,
                    year, 
                    tp
                })
            ),
    };
};

const seasonChildren = (
    s,
    seasonLabel,
    selectedMetricVersion,
    appliedTableSettings,
    isLastBigCol,
    headerClass
) => {
    const tempSeasonChildren = s.children.filter((item, index) => {
        if (item.isSelected) {
            if (selectedMetricVersion[0]?.value === "ty") {
                return index === 0;
            } else {
                return true;
            }
        } else {
            return false;
        }
    });
    return tempSeasonChildren.map((tp, tpIdx) =>
        seasonListMapping({
            tp,
            tpIdx,
            seasonLabel,
            selectedMetricVersion,
            appliedTableSettings,
            isLastBigCol,
            headerClass,
            tempSeasonChildren,
        })
    );
};

let yearsAndMarkets = {
    markets: {
        pre: "Pre-fall",
        main: "Fall",
    },
    years: {
        ty: 2023,
        ly: 2022,
    },
};

let throttlingToggle = false;
let channel = null;
function EUBuyersScreen(props) {
    const { page, filterPage, currentTab } = props;
    const [appliedTableSettings, setAppliedTableSettings] = useState(null);

    const {
        workbench: {
            general: {
                selectedMarkets,
                selectedRegions,
                selectedChannels,
                priceFileId,
                columnSettings,
                selectedViewIndex,
            },
            [page]: { selectedPlanGroup },
        },
        filters: {
            savedFilters: { [filterPage]: selectedFilters },
        },
    } = useSelector((state) => state);

    channel = selectedChannels[0].label;

    const gridRef = useRef();
    const dispatch = useDispatch();

    const [fetchMainData, { data: mainData, isFetching }] =
        useLazyMainDataQuery();

    const [selectedMetricVersion, setSelectedMetricVersion] = useState([
        {
            label: "Show TY only",
            value: "ty",
        },
    ]);

    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);
    const [showTableSettings, setShowTableSettings] = useState(false);
    const [multiplyingFactor, setMultiplyingFactor] = useState("1");
    const [selectedCostUsed, setSelectedCostUsed] = useState(costUsedList);

    const [rowCount, selectedRowCount, onTableModelUpdate] = useTableRowCount();
    rowData = useRowData({
        rawTableData: mainData,
        lastYear: yearsAndMarkets.years.ly,
        multiplyingFactor,
        costUsed: selectedCostUsed,
    });

    const payloadBuilder = useCallback(() => {
        const payload = {
            ...getMaterialFetchData(
                selectedFilters,
                selectedMarkets,
                selectedRegions,
                selectedChannels,
                priceFileId
            ),
            metric_version:
                metricVersionOptions.findIndex(
                    (item) => item.value === selectedMetricVersion[0].value
                ) + 1,
        };

        if (
            selectedPlanGroup?.product_hierarchy ||
            selectedPlanGroup?.merchandise_hierarchy ||
            selectedPlanGroup?.plan_hierarchy
        ) {
            payload.products = {};
            Object.keys(selectedPlanGroup?.product_hierarchy || {}).forEach(
                (key) => {
                    payload.products[key] =
                        selectedPlanGroup?.product_hierarchy[key].map(
                            (item) => item.value
                        );
                }
            );
            Object.keys(selectedPlanGroup?.merchandise_hierarchy || {}).forEach(
                (key) => {
                    payload.products[key] =
                        selectedPlanGroup?.merchandise_hierarchy[key].map(
                            (item) => item.value
                        );
                }
            );
            Object.keys(selectedPlanGroup?.plan_hierarchy || {}).forEach(
                (key) => {
                    payload.products[key] = selectedPlanGroup?.plan_hierarchy[
                        key
                    ].map((item) => item.value);
                }
            );
        }
        return payload;
    }, [
        selectedPlanGroup,
        selectedFilters,
        selectedMarkets,
        selectedRegions,
        selectedChannels,
        priceFileId,
        selectedMetricVersion,
    ]);

    const onRefreshData = useCallback(() => {
        const payload = payloadBuilder();
        fetchMainData(payload);
    }, [payloadBuilder, fetchMainData]);

    useEffect(() => {
        if (currentTab === "buyer") onRefreshData();
    }, [onRefreshData]);

    useEffect(() => {
        if (currentTab === "buyer" && !mainData?.table_data?.length)
            onRefreshData();
    }, [currentTab]);

    const { views, viewData } = useMemo(() => {
        const views = cloneDeep(
            columnSettings[tableSettingTabIds.BUYING_ROLL_UPS]
        );
        const viewData =
            views[selectedViewIndex[tableSettingTabIds.BUYING_ROLL_UPS]]
                .view_data;

        return { views, viewData };
    }, [columnSettings, selectedViewIndex]);

    const onTableDataChange = useCallback(
        (event) => {
            updateColumnGroupColor();
            domUpdateForgrandTotal();
            onTableModelUpdate(event);
        },
        [onTableModelUpdate]
    );

    const onColumnResized = (params) => {
        if (throttlingToggle) {
            return;
        }
        throttlingToggle = true;
        setTimeout(() => (throttlingToggle = false), 100);

        if (viewData.columnWidth === "1") {
            params.columns.forEach((column) => {
                if (column.colDef.colId === "ag-Grid-AutoColumn") {
                    viewData.hierarchyOptions.forEach((item) => {
                        item.width = column?.getActualWidth() || 300;
                    });
                } else {
                    vistLeafNodesColumns((item) => {
                        if (column.colDef.id === item.id) {
                            item.width = column?.getActualWidth() || 130;
                        }
                    }, viewData?.columns);
                }
            });

            dispatch(
                userUpdateColumnSettings({
                    tabId: tableSettingTabIds.BUYING_ROLL_UPS,
                    views,
                })
            );
        }
    };

    const autoGroupColumnDef = useMemo(
        () => ({
            ...gridOptions.autoGroupColumnDef,
            width:
                appliedTableSettings?.view_data.columnWidth === "1"
                    ? appliedTableSettings?.view_data?.hierarchyOptions?.[0]
                          ?.width || 300
                    : 300,
        }),
        [appliedTableSettings]
    );

    const colDefs = useMemo(() => {
        const seasons = {};
        const years = {};
        const keys = [];

        if (!mainData?.table_data?.length) return [];

        mainData?.table_data.forEach((dt) => {
            let market = Object.keys(dt)[0];
            market = market?.split("/");
            market = market[market.length - 1];
            keys.push(market);
        });

        keys?.forEach((key) => {
            seasons[key.substring(0, key.length - 5)] = 1;
            years[key.substring(key.length - 4)] = 1;
        });

        yearsAndMarkets = {
            markets: {
                [MARKETS_TYPE.PRE]: Object.keys(seasons).find((item) =>
                    item.toLowerCase().includes(MARKETS_TYPE.PRE)
                ),
                [MARKETS_TYPE.MAIN]: Object.keys(seasons).find(
                    (item) => !item.toLowerCase().includes(MARKETS_TYPE.PRE)
                ),
            },
            years: {
                ty: Math.max(...Object.keys(years)),
                ly: Math.min(...Object.keys(years)),
            },
        };

        const hierarchyCols =
            appliedTableSettings?.view_data?.hierarchyOptions
                ?.filter((item) => item.isSelected)
                ?.map((h, index) => ({
                    ...h,
                    field: h.value,
                    headerName: h.label,
                    enableoup: true,
                    rowGroup: true,
                    rowGroupIndex: index,
                    headerClass: "hierarchy-header",
                })) || [];

        const metricCols =
            appliedTableSettings?.view_data.columns?.filter(
                (item) => item.isSelected
            ) || [];

        if (appliedTableSettings?.view_data) {
            return [
                ...hierarchyCols,
                ...metricCols.map((s, idx) => {
                    let seasonLabel = "";
                    if (s.label.toLowerCase() === MARKETS_TYPE.PRE) {
                        seasonLabel = yearsAndMarkets.markets.pre;
                    } else if (s.label.toLowerCase() === MARKETS_TYPE.MAIN) {
                        seasonLabel = yearsAndMarkets.markets.main;
                    } else {
                        seasonLabel = "Total";
                    }

                    const isLastCol = metricCols.length - 1 === idx;

                    const headerClass = !isLastCol
                        ? "extra-border xxxl-border"
                        : "";
                    const headerBgClass = ` sub-header-${
                        idx % 2 === 0 ? "gray" : "blue"
                    }`;

                    return {
                        ...s,
                        headerName: seasonLabel,
                        headerClass:
                            headerClass + headerBgClass + " left-sticky-label",
                        children: seasonChildren(
                            s,
                            seasonLabel,
                            selectedMetricVersion,
                            appliedTableSettings,
                            isLastCol,
                            headerClass
                        ),
                    };
                }),
            ];
        }
        return [];
    }, [appliedTableSettings, mainData, selectedMetricVersion]);

    useEffect(() => {
        updateClearAllFilterStatus();
        onTableSettingsApply(
            tableSettingTabIds.BUYING_ROLL_UPS,
            columnSettings[tableSettingTabIds.BUYING_ROLL_UPS][
                selectedViewIndex[tableSettingTabIds.BUYING_ROLL_UPS]
            ]
        );
    }, []);

    useEffect(() => {
        viewData.selectedMetricVersion = selectedMetricVersion;

        dispatch(
            userUpdateColumnSettings({
                tabId: tableSettingTabIds.BUYING_ROLL_UPS,
                views,
            })
        );
    }, [selectedMetricVersion]);

    const onGridReady = () => {
        gridRef.current.api.addEventListener("filterChanged", (event) => {
            updateClearAllFilterStatus();
        });
    };

    const updateClearAllFilterStatus = () => {
        setIsAnyFilterApplied(gridRef?.current?.api?.isAnyFilterPresent());
    };

    const onTableSettingsApply = (tabId, view) => {
        console.log(tabId, view);
        setShowTableSettings(false);
        setAppliedTableSettings(cloneDeep(view));
    };

    const onTableSettingsClose = () => {
        setShowTableSettings(false);
    };

    const onSettingsClick = () => {
        setShowTableSettings(true);
    };

    const filterItemUpdate = (params) => {
        setSelectedItemInList(
            appliedTableSettings.view_data.hierarchyOptions,
            params.hierarchyOptions
        );

        changeMetrics(params.metrics, appliedTableSettings.view_data, true);

        setAppliedTableSettings(cloneDeep(appliedTableSettings));

        views[selectedViewIndex[tableSettingTabIds.BUYING_ROLL_UPS]].view_data =
            appliedTableSettings.view_data;

        dispatch(
            userUpdateColumnSettings({
                tabId: tableSettingTabIds.BUYING_ROLL_UPS,
                views,
            })
        );
    };

    const multiplyingFactorChangeHandler = (event) => {
        setMultiplyingFactor(event.target.value);
    };

    const multiplyingFactorBlurHandler = (event) => {
        const value = event.target.value;

        if (value <= 0) {
            setMultiplyingFactor(1);
        }
    };

    return (
        <div
            className="buyers-screen-container"
            style={{ position: "relative" }}
        >
            <LoadingOverlay showLoader={isFetching} isLocalLoader />
            <div
                className="align-center justify-space-between"
                style={{ columnGap: "12px" }}
            >
                <div
                    className="align-center"
                    style={{
                        maxWidth: "calc(100% - 644px)",
                        marginRight: "12px",
                    }}
                >
                    {selectedPlanGroup ? (
                        <div
                            style={{
                                minWidth: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <span>Selected plan group : </span>
                            <Tooltip title={selectedPlanGroup.name}>
                                <strong>{selectedPlanGroup.name}</strong>
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
                <div
                    className="align-center flex-wrap"
                    style={{ justifyContent: "flex-end", gap: "20px" }}
                >
                    <div className={`align-center`}>
                        <span
                            className={`select-label no-wrap ${styles["input-label"]}`}
                        >
                            Metric Version
                        </span>
                        <div style={{ width: "240px" }}>
                            <MultiSelect
                                className="input"
                                initialData={metricVersionOptions}
                                isDisabled={yearsAndMarkets.years.ly === 2019}
                                selectedOptions={selectedMetricVersion}
                                updateSelected={(data) =>
                                    setSelectedMetricVersion(data.selectedItems)
                                }
                                hideSearch
                                hideClearSelection
                                updateSelectedOnEachSelection
                            />
                        </div>
                    </div>
                    {selectedMetricVersion?.length > 0 &&
                        selectedMetricVersion[0]?.label !==
                            metricVersionOptions[0]?.label && (
                            <div className={`align-center`}>
                                <span
                                    className={`select-label no-wrap ${styles["input-label"]}`}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    LY Multiplying Factor
                                </span>
                                <div style={{ width: "240px" }}>
                                    <TextField
                                        type="number"
                                        min="1"
                                        value={multiplyingFactor}
                                        onChange={
                                            multiplyingFactorChangeHandler
                                        }
                                        onBlur={multiplyingFactorBlurHandler}
                                    />
                                </div>
                            </div>
                        )}
                    <div className={`align-center`}>
                        <span
                            className={`select-label no-wrap ${styles["input-label"]}`}
                        >
                            Cost of Origin
                        </span>
                        <div style={{ width: "240px" }}>
                            <MultiSelect
                                className="input"
                                initialData={costUsedList}
                                selectedOptions={selectedCostUsed}
                                updateSelected={(data) =>
                                    setSelectedCostUsed(data.selectedItems)
                                }
                                hideSearch
                                hideClearSelection
                                updateSelectedOnEachSelection
                                is_multiple_selection
                            />
                        </div>
                    </div>
                    <ClearAllFilterButton
                        isVisible={isAnyFilterApplied}
                        agGridApi={gridRef?.current?.api}
                    />
                    <Tooltip title="Refresh buying rollups">
                        <Button variant="contained" onClick={onRefreshData}>
                            <RefreshOutlinedIcon fontSize="small" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Download Report">
                        <Button
                            variant="contained"
                            onClick={() =>
                                downloadHandler("Buyer's Roll Up", gridRef)
                            }
                        >
                            <DownloadOutlinedIcon fontSize="small" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Table settings">
                        <Button variant="contained" onClick={onSettingsClick}>
                            <SettingsIcon fontSize="small" />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <FilterShowcaseBar
                hierarchyOptions={appliedTableSettings?.view_data?.hierarchyOptions.filter(
                    (item) => item.isSelected
                )}
                metrics={appliedTableSettings?.view_data?.columns[0].children[0].children.filter(
                    (item) => item.isSelected
                )}
                filterItemUpdate={filterItemUpdate}
            />
            <div
                style={{ height: "calc(100vh - 340px)", marginTop: "15px" }}
                className="ag-theme-alpine buyers-screen-table"
            >
                <AgGridReact
                    className={`${styles["ag-grid-table"]}`}
                    {...{
                        ...gridOptions,
                        ref: gridRef,
                        rowData,
                        onGridReady: onGridReady,
                        columnDefs: colDefs,
                        onModelUpdated: onTableDataChange,
                        autoGroupColumnDef,
                        onColumnResized,
                    }}
                />
            </div>
            {rowCount > 1 && (
                <TableRowCount count={rowCount} selected={selectedRowCount} />
            )}
            {showTableSettings ? (
                <>
                    <div className="drawer-inner-full-height">
                        <TableSettings
                            buyingRollUpsSettings
                            onApply={onTableSettingsApply}
                            onClose={onTableSettingsClose}
                            selectedChannels={selectedChannels}
                        />
                    </div>
                    <div className="drawer-inner-full-height-cover" />
                </>
            ) : null}
        </div>
    );
}

EUBuyersScreen.propTypes = {
    page: PropTypes.string,
    filterPage: PropTypes.string,
    currentTab: PropTypes.string,
};

export default EUBuyersScreen;
