import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQueryForRTKQuery } from "../../../utils/axios";

export const buyersScreenAPI = createApi({
    reducerPath: "buyersScreen",
    baseQuery: axiosBaseQueryForRTKQuery({
        extendedUrl: "",
    }),

    endpoints(builder) {
        return {
            mainData: builder.query({
                query: (body) => ({
                    url: "/buyers-rollup",
                    method: "post",
                    data: body,
                }),
                providesTags: ["buyers-screen"],
                transformResponse: (res) => res.data.data,
            }),
        };
    },
});

export const { useLazyMainDataQuery } = buyersScreenAPI;
