import React from "react";
import { Tooltip, Button } from "@mui/material";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

function ClearAllFilterButton({ isVisible, agGridApi, onClick }) {
  return (
    <>
      {isVisible ? (
        <Tooltip title="Reset All Filters" enterDelay={300}>
          <Button
            variant="contained"
            onClick={() => {
              agGridApi?.setFilterModel(null);
              onClick && onClick();
            }}
          >
            <FilterAltOffOutlinedIcon />
          </Button>
        </Tooltip>
      ) : (
        ""
      )}
    </>
  );
}

export default ClearAllFilterButton;
