import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { experimentalStyled as styled } from "@mui/material/styles";

import { API } from "../../../utils/axios";
import Select from "../filters/Select/Select";
import { isEmpty } from "lodash";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "0 0.7rem 0 0",
    textAlign: "left",
    boxShadow: "none",
    // color: theme.palette.text.secondary,
}));

function FlatFilter(props) {
    const { screenName, selectedFilters, onFilterSelect, disabled } = props;

    const [options, setOptions] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [filterList, setFilterList] = useState([]);

    const filterCategories = useSelector(
        (store) => store.global.screenConfig[screenName]?.filter_options
    );
    const elementLabels = useSelector((store) => store.global.elementLabels);

    useEffect(() => {
        filterCategories?.forEach((category) => {
            const { type: categoryId, filter_type, filter_endpoint } = category;

            if (filter_type === "product_hierarchy") {
                const payload = {
                    allow_only_active_products: true,
                    hierarchy_type: categoryId,
                    filters: {
                        product_hierarchy: {},
                        store_hierarchy: {},
                    },
                };

                API.post(filter_endpoint, payload).then((res) => {
                    setOptions((prevValue) => {
                        const newValue = { ...prevValue };

                        newValue[categoryId] = res.data.data;

                        return newValue;
                    });
                });
            } else if (filter_type === "region_hierarchy") {
                API.get(filter_endpoint).then((res) => {
                    setOptions((prevValue) => {
                        const newValue = { ...prevValue };

                        newValue[categoryId] = res.data.data;

                        return newValue;
                    });
                });
            }
        });
    }, [filterCategories]);

    useEffect(() => {
        setSelectedOptions(selectedFilters ?? {});
    }, [selectedFilters]);

    const onSelectChange = useCallback(
        (categoryId, selectedItems) => {
            setSelectedOptions((prevValue) => {
                const newValue = { ...prevValue };

                if (isEmpty(selectedItems)) {
                    delete newValue[categoryId];
                } else {
                    newValue[categoryId] = selectedItems;
                }

                onFilterSelect(newValue);

                return newValue;
            });
        },
        [onFilterSelect]
    );

    useEffect(() => {
        const list = filterCategories?.map((hierarchy) => {
            const { type: categoryId, is_mandatory: mandatoryFlag } = hierarchy;

            return (
                <Grid key={categoryId} item xs={2} sm={2} md={3} lg={2}>
                    <Item className="filterGroup">
                        <label className="select-label">
                            {elementLabels[categoryId]}
                            {mandatoryFlag ? (
                                <span style={{ color: "red" }}> * </span>
                            ) : null}
                        </label>
                        <Select
                            initialData={options?.[categoryId] || []}
                            selectedOptions={
                                selectedOptions?.[categoryId] || []
                            }
                            updateSelected={(data) =>
                                onSelectChange(categoryId, data.selectedItems)
                            }
                            is_multiple_selection
                            forceApiCall
                            isDisabled={disabled}
                        />
                    </Item>
                </Grid>
            );
        });

        setFilterList(list);
    }, [
        filterCategories,
        elementLabels,
        options,
        selectedOptions,
        onSelectChange,
    ]);

    return (
        <Paper
            className="filter-group-container"
            elevation={0}
            sx={{ paddingTop: 0, paddingBottom: "8px" }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 6, md: 12, lg: 10 }}
                >
                    {filterList}
                </Grid>
            </Box>
        </Paper>
    );
}

export default FlatFilter;
