import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _, { isEmpty, cloneDeep, map } from "lodash";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Select from "./Select/Select";
import DateRangePickerComponent from "../../ui/dateRangePicker/DateRangePicker";
import {
    setSelectedFilters,
    getFilterOptions,
    setSelectedFilterData,
    setFiltersData,
    setLastSavedFilters,
    resetFilterOptions,
    setRetainFilters,
} from "../../../store/features/filters/filters";
import { getEvents } from "../../../store/features/event/event";
import {
    resetFilterData,
    getAllFiltersData,
    getFilterList,
    getUpdatedFilterListForSelectedItems,
} from "../../../utils/helpers/filterHelper";
import { DEFAULT_DATE_FORMAT } from "../../../constants/Constants";
import "./Filters.scss";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "0 0.7rem 0 0",
    textAlign: "left",
    boxShadow: "none",
    // color: theme.palette.text.secondary,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FilterGroup = (props) => {
    const { onFilterSelect } = props;

    const location = useLocation();
    const [warningPopup, setWarningPopup] = useState({
        status: false,
        msg: "",
    });
    const [resetOptions, setResetOptions] = useState(false);

    const [filtersOptions, setFiltersOptions] = useState([]); //stores common configurations like filter label, multi select or single select etc
    const [filtersData, setFiltersDataObj] = useState([]);
    const [screenName, setScreenName] = useState(null);
    const [filterConfig, setFilterConfig] = useState(null);
    const [disableFilterEditing, setDisableFilterEditing] = useState(false);

    const selectedFilters = useSelector(
        (state) => state.filters.savedFilters[props.screen]
    );

    useEffect(() => {
        if (
            props.retainFilters &&
            props.lastSavedFilters &&
            props.lastSavedFilters[props.retainFilters]
        ) {
            props.setRetainFilters(false);
            let options =
                props.lastSavedFilters &&
                cloneDeep(props.lastSavedFilters[props.retainFilters]);
            if (
                location &&
                !isEmpty(location.state) &&
                options &&
                !isEmpty(options.filters) &&
                !isEmpty(options.filters.filters) &&
                !isEmpty(options.filtersData)
            ) {
                const { id, name, filterDetails } = location.state;
                if (
                    filterDetails &&
                    filterDetails.key === "product_hierarchy"
                ) {
                    let productItems = {
                        value: id,
                        label: name,
                    };
                    options.filters.filters.product_filters[
                        filterDetails.value
                    ] = [id];
                    options.filtersData[filterDetails.value] = {
                        options: [productItems],
                        selectedItems: [productItems],
                        selectedItemsArray: [id],
                    };
                }
            }
            props.setLastSavedFilters({
                data: options,
                from: props.screen,
            });
            props.setFiltersData({
                data: (options && options.filtersData) || {},
                from: props.screen,
            });
        } else {
            if (props.retainFilters) {
                props.setRetainFilters(false);
            }

            //resets all filters data except first filter data start
            let newFiltersData = resetFilterData(
                props.filtersData,
                props.retainArr,
                props.actionPerformed
            );
            //resets all filters data except first filter data end
            props.setFiltersData({ data: newFiltersData, from: props.screen });
        }
        return () => {
            props.resetFilterOptions(screenName);
        };
    }, []);

    useEffect(() => {
        if (isEmpty(filtersOptions)) {
            let options = getFilterList(props.screenConfig, props.screen);
            let filtersOptionsArr = (options && options.filter_options) || [];
            if (!isEmpty(filtersOptionsArr)) {
                setFiltersOptions(filtersOptionsArr);
                //get 1st filter options when user lands on the page code,
                let param = filtersOptionsArr[0] || {};
                const filterData = props.filtersData[param.type] || [];
                if (isEmpty(filterData.options)) {
                    updateDependency({
                        ...param,
                        //set default selected item if channel
                        filterIndex: param.type === "store_h1" ? 0 : null,
                        defaultSelectedItem:
                            param.type === "store_h1"
                                ? [{ label: "B&M", value: "B&M" }]
                                : [],
                    });
                }
            }
            if (
                props.screenConfig[props.screen] &&
                props.screenConfig[props.screen].filter_config &&
                !isEmpty(props.screenConfig[props.screen].filter_config)
            ) {
                //if filter config exists for this screen filters store it in a state for further uses
                setFilterConfig(props.screenConfig[props.screen].filter_config);
            }
        }
    }, [props.screenConfig]);

    useEffect(() => {
        if (screenName !== props.screen && !isEmpty(props.screen)) {
            //storing active screen name in state
            setScreenName(props.screen);
        }
    }, [props.screen]);

    useEffect(() => {
        if (isEmpty(filtersData)) {
            //fetch screen relavant filter config and store it in state
            let filtersDataObj = cloneDeep(props.filtersData || {});
            if (!isEmpty(filtersDataObj)) {
                setFiltersDataObj(filtersDataObj);
            }
        }
        if (screenName === props.activeScreen) {
            setFiltersDataObj(props.filtersData);
        }
    }, [props.filtersData]);

    const updateSelected = ({ selectedItems, filterName, filterIndex }) => {
        props.setSelectedFilterData({
            data: selectedItems,
            filterName,
            from: screenName,
        });
        if (
            !isEmpty(filterConfig) &&
            filterConfig[filterName] &&
            !selectedItems.startDate
        ) {
            //if filter config is present for seleted filter, then handle those in here
            let newfiltersOptions = getUpdatedFilterListForSelectedItems({
                filterConfig: filterConfig[filterName],
                screenConfig: props.screenConfig,
                from: props.screen,
                selectedItems,
            });
            //getUpdatedFilterListForSelectedItems helper method handles all of the filter options related updates
            // all changes has handled in this helper method
            setFiltersOptions(newfiltersOptions);
        }
    };

    const handleWarningClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setWarningPopup({ status: false, msg: "" });
    };

    const handleReset = () => {
        setResetOptions(true);
        let newFilterData = resetFilterData(filtersData);

        props.setFiltersData({ data: newFilterData, from: screenName });
        props.setLastSavedFilters({ data: {}, from: props.screen });
        setResetOptions(false);

        props.onReset && props.onReset();
    };

    const getDataFromFiltersData = (name, data, key) => {
        if (name === "dateRange") {
            return data[name];
        }
        return data[name] && data[name][key] ? data[name][key] : [];
    };

    const validateFilters = ({ filterIndex, updatedFitlersData }) => {
        const newFiltersData = updatedFitlersData || props.filtersData;
        for (let i = 0; i < filterIndex; i++) {
            let type = filtersOptions[i] && filtersOptions[i].type;

            let selectedItems = getDataFromFiltersData(
                type,
                newFiltersData,
                "selectedItems"
            );

            if (
                filtersOptions[i] &&
                !filtersOptions[i].type.includes("dateRange") &&
                filtersOptions[i].is_mandatory &&
                isEmpty(selectedItems)
            ) {
                return false;
            }
            let sDate = getDataFromFiltersData(
                type,
                newFiltersData,
                "startDate"
            );
            let eDate = getDataFromFiltersData(type, newFiltersData, "endDate");

            if (
                filtersOptions[i] &&
                filtersOptions[i].type.includes("dateRange") &&
                filtersOptions[i].is_mandatory &&
                (isEmpty(sDate) || isEmpty(eDate))
            ) {
                return false;
            }
        }
        return true;
    };

    const actionCallHandler = ({
        filterEndpoint,
        filterEndpointMethod,
        type,
        filterType,
        req,
        selectedItems,
        updateLastSaved,
    }) => {
        let reqObj = {
            filters: req,
            hierarchy_type: type,
            allow_only_active_products: props.allowInactiveProducts || true,
        };

        if (type === "events") {
            reqObj = {
                ...reqObj,
                calendar: {
                    start_date: moment(
                        reqObj?.filters?.dateRange?.startDate
                    ).format(DEFAULT_DATE_FORMAT),
                    end_date: moment(
                        reqObj?.filters?.dateRange?.endDate
                    ).format(DEFAULT_DATE_FORMAT),
                },
            };
            props.getEvents({
                payload: reqObj,
                dateRange: "ty",
                shouldSaveFilters: true,
                from: screenName || props.screen,
                filterName: type,
            });
        } else {
            props.getFilterOptions({
                requestObject: reqObj,
                filterName: type,
                from: screenName || props.screen,
                selectedItems,
                filterEndpoint,
                filterEndpointMethod,
                updateLastSaved,
                filterfromExisting: props.filterfromExisting,
            });
        }
    };

    const updateDependency = ({
        type,
        filter_endpoint,
        filter_endpoint_method,
        filter_type,
        filterIndex,
        updatedFitlersData,
        defaultSelectedItem,
        updateLastSaved,
    }) => {
        let filterEndpoint = filter_endpoint;
        let filtersDataArr = updatedFitlersData || filtersData;
        let options = getDataFromFiltersData(type, filtersDataArr, "options");
        const callApi = filtersDataArr[type] && filtersDataArr[type].callApi;

        if (
            disableFilterEditing ||
            (type && options && !callApi && !isEmpty(options)) ||
            !validateFilters({ filterIndex, updatedFitlersData })
        ) {
            return;
        }

        let req = {
            product_hierarchy: {},
            store_hierarchy: {},
        };

        if (filter_endpoint_method === "post") {
            map(filtersOptions, (item, index) => {
                if (index < filterIndex) {
                    let selected = getDataFromFiltersData(
                        item.type,
                        filtersDataArr,
                        "selectedItemsArray"
                    );
                    // if (isEmpty(selected)) return; //uncomment if u want empty filters removed
                    if (item.type === "dateRange") {
                        req[item.filter_type] = selected;
                    } else {
                        req[item.filter_type][item.type] = selected; //this goes as filters req obj in api calls
                    }
                }
            });
        } else if (filter_endpoint_method === "get") {
            for (
                let index = 0;
                index < filtersOptions.length && index < filterIndex;
                index++
            ) {
                let selected = props.selectedFilters[
                    filtersOptions[index].type
                ].map((obj) => obj.value);

                filterEndpoint = filterEndpoint.replace(
                    `{param${index + 1}}`,
                    selected.join("-")
                );
            }
        }

        if (type) {
            actionCallHandler({
                filterEndpoint: filterEndpoint,
                filterEndpointMethod: filter_endpoint_method,
                type,
                filterType: filter_type,
                req,
                selectedItems: defaultSelectedItem,
                updateLastSaved,
            }); //calls next filters action, to get it's options
        }
    };

    const onFilterHandler = async () => {
        if (!validateFilters({ filterIndex: filtersOptions.length })) {
            setWarningPopup({
                status: true,
                msg: "Please select mandatory filters",
            });
            return;
        }

        let filtersDataObj = getAllFiltersData({
            data: filtersData,
            list: filtersOptions,
        });
        props.setLastSavedFilters({
            data: {
                filters: cloneDeep(filtersDataObj),
                filtersData: cloneDeep(filtersData),
                //calendarRange: this.state.calendarRange,
            },
            from: screenName,
        });
        const noData = await props.onFilter(filtersDataObj);
        if (noData) {
            setWarningPopup({
                status: true,
                msg: "There is no data for the selected filters. Please try with a different combination.",
            });
        }
    };
    const [focusedInput, setfocusedInput] = useState(null);

    // render the type of filter
    // initialData props used to send the options data
    const renderContent = (param, i) => {
        let filterlabel = props.elementLabels[param.type];
        let options = getDataFromFiltersData(
            param.type,
            filtersData,
            "options"
        );
        let selectedItems = getDataFromFiltersData(
            param.type,
            filtersData,
            "selectedItems"
        );
        const callApi =
            filtersData[param.type] && filtersData[param.type].callApi;
        return (
            <Item className="filterGroup">
                <label>
                    {filterlabel}
                    {param.is_mandatory ? (
                        <span style={{ color: "red" }}> * </span>
                    ) : null}
                </label>
                {param.filter_type === "dateRange" && (
                    <DateRangePickerComponent
                        startDate={selectedItems?.startDate}
                        endDate={selectedItems?.endDate}
                        focusedInput={focusedInput}
                        onDatesChange={(startDate, endDate) =>
                            updateSelected({
                                selectedItems: { startDate, endDate },
                                filterName: "dateRange",
                            })
                        }
                        onFocusChange={(input) => setfocusedInput(input)}
                        dateFormat={DEFAULT_DATE_FORMAT}
                    />
                )}
                {(param.filter_type === "product_hierarchy" ||
                    param.filter_type === "store_hierarchy") && (
                    <Select
                        {...param}
                        filterIndex={i}
                        initialData={options}
                        selectedOptions={
                            props.selectedFilters?.[param.type] || []
                        }
                        reset={resetOptions}
                        dependency={selectedItems}
                        updateSelected={(data) => {
                            updateSelected({ ...data, filterIndex: i });
                            onFilterSelect(data);
                        }}
                        onDropdownOpen={() => {
                            updateDependency({ ...param, filterIndex: i });
                        }}
                        forceApiCall={callApi}
                        className={
                            (disableFilterEditing
                                ? "dropdown-wrapper-disabled "
                                : "") +
                            ((props.actionPerformed === "edit" ||
                                props.actionPerformed === "clone") &&
                            (param.type === "channel" ||
                                param.type === "hierarchy1")
                                ? "dropdown-wrapper-disabled"
                                : "")
                        }
                    />
                )}
                {param.filter_type === "events" && (
                    <Select
                        {...param}
                        filterIndex={i}
                        initialData={options}
                        selectedOptions={selectedItems}
                        reset={resetOptions}
                        dependency={selectedItems}
                        updateSelected={(data) =>
                            updateSelected({ ...data, filterIndex: i })
                        }
                        onDropdownOpen={() =>
                            updateDependency({ ...param, filterIndex: i })
                        }
                        forceApiCall={callApi}
                        className={
                            (disableFilterEditing
                                ? "dropdown-wrapper-disabled "
                                : "") +
                            ((props.actionPerformed === "edit" ||
                                props.actionPerformed === "clone") &&
                            (param.type === "channel" ||
                                param.type === "hierarchy1")
                                ? "dropdown-wrapper-disabled"
                                : "")
                        }
                    />
                )}
            </Item>
        );
    };

    return (
        <Paper className="filter-group-container" elevation={0}>
            <Box sx={{ flexGrow: 1, margin: "0 10px" }}>
                <Snackbar
                    open={warningPopup.status}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    autoHideDuration={6000}
                    onClose={handleWarningClose}
                >
                    <Alert
                        onClose={handleWarningClose}
                        severity="info"
                        sx={{ width: "100%" }}
                    >
                        {warningPopup.msg}
                    </Alert>
                </Snackbar>
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 6, md: 12, lg: 10 }}
                >
                    {filtersOptions &&
                        filtersOptions.map(
                            (filter, i) =>
                                // Show filter only if hidden is set to false or component explicitly unhides it
                                (filter.hidden === undefined ||
                                    (filter.hidden &&
                                        !_.isEmpty(props.showHiddenFilters) &&
                                        props.showHiddenFilters?.includes(
                                            filter.type
                                        ))) && (
                                    <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={3}
                                        lg={2}
                                        key={i}
                                    >
                                        {renderContent(filter, i)}
                                    </Grid>
                                )
                        )}
                </Grid>

                {props.showFilterButtons && (
                    <>
                        <Divider sx={{ margin: "6px 0" }} />
                        <Stack className="filter-buttons" direction="row">
                            <Button
                                variant="contained"
                                color="primary"
                                id="filter-btn"
                                onClick={onFilterHandler}
                                disabled={disableFilterEditing}
                                className={`${
                                    props.saveFilterButtonLabel?.length > 8
                                        ? `save-filters`
                                        : ``
                                }`}
                            >
                                {!props.saveFilterButtonLabel && (
                                    <FilterAltOutlinedIcon
                                        sx={{
                                            height: "16px",
                                            width: "16px",
                                            paddingRight: "4px",
                                        }}
                                    />
                                )}{" "}
                                {props.saveFilterButtonLabel || "Submit"}
                            </Button>
                            <Button
                                variant="outlined"
                                id="reset-btn"
                                onClick={handleReset}
                                disabled={
                                    disableFilterEditing ||
                                    props.actionPerformed
                                }
                                className={`${
                                    props.resetFilterButtonLabel?.length > 8
                                        ? `save-filters`
                                        : ``
                                }`}
                            >
                                {!props.resetFilterButtonLabel && (
                                    <RefreshOutlinedIcon
                                        sx={{
                                            height: "16px",
                                            width: "16px",
                                            paddingRight: "4px",
                                        }}
                                    />
                                )}{" "}
                                {props.resetFilterButtonLabel || "Reset"}
                            </Button>
                        </Stack>
                    </>
                )}
                {props.showResetButton && (
                    <>
                        <Divider sx={{ margin: "6px 0" }} />
                        <Stack className="filter-buttons" direction="row">
                            <Button
                                variant="outlined"
                                id="reset-btn"
                                onClick={handleReset}
                                disabled={
                                    disableFilterEditing ||
                                    props.actionPerformed
                                }
                                className={`${
                                    props.resetFilterButtonLabel?.length > 8
                                        ? `save-filters`
                                        : ``
                                }`}
                            >
                                {!props.resetFilterButtonLabel && (
                                    <RefreshOutlinedIcon
                                        sx={{
                                            height: "16px",
                                            width: "16px",
                                            paddingRight: "4px",
                                        }}
                                    />
                                )}{" "}
                                {props.resetFilterButtonLabel || "Reset"}
                            </Button>
                        </Stack>
                    </>
                )}
            </Box>
        </Paper>
    );
};

const mapStateToProps = (store) => {
    return {
        filtersData: store.filters.filtersData,
        screenConfig: store.global.screenConfig,
        elementLabels: store.global.elementLabels,
        activeScreen: store.filters.activeScreen,
        lastSavedFilters: store.filters.lastSavedFilters,
        retainFilters: store.filters.retainFilters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedFilters: (data) => dispatch(setSelectedFilters(data)),
        getFilterOptions: (data) => dispatch(getFilterOptions(data)),
        setSelectedFilterData: (data) => dispatch(setSelectedFilterData(data)),
        setFiltersData: (data) => dispatch(setFiltersData(data)),
        setLastSavedFilters: (data) => dispatch(setLastSavedFilters(data)),
        resetFilterOptions: (data) => dispatch(resetFilterOptions(data)),
        setRetainFilters: (data) => dispatch(setRetainFilters(data)),
        getEvents: (data) => dispatch(getEvents(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterGroup);
