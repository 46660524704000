import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { API } from "../../../utils/axios/index";

const US = "US MSRP";
const EURO = "EURO STANDARD";
const PRICE_RULE_API = "/price-rules";

const priceRulesList = {
    list: [],
    priceRuleCreatedMessage: "",
    cloneConflictedPriceList: [],
    cloneShowConfirmationModal: false,
};

const step1InitialState = {
    name: "",
    notes: "",
};

const step2InitialState = {
    id: "",
    market: null,
    region: null,
    channel: null,
};

const step3InitialState = {
    products: {},
};

const step4InitialState = {
    mappings: [],
    priceLists: [],
    allChannelPriceLists: [],
    invisiblePriceLists: [],
    priceTagList: [],
};

const step5InitialState = {
    basePriceLists: [],
};

const step6InitialState = {
    retailPriceRules: [],
    wholesalePriceRules: [],
};

const step6point5InitialState = {
    discountTableId: null,
};

const step7InitialState = {
    finalEuroRetailPriceValues: [],
    finalEuroWholesalePriceValues: [],
    finalLATAMRetailPriceValues: [],
    finalLATAMWholesalePriceValues: [],
    finalOutletPriceValues: [],
};

const initialState = {
    // state for the list
    ...priceRulesList,

    // state for price rule creation
    completedStep: [0], // the number is in an array to track changes to the reference which is not possible with a number
    status: 0,

    ...step1InitialState,
    ...step2InitialState,
    ...step3InitialState,
    ...step4InitialState,
    ...step5InitialState,
    ...step6point5InitialState,
    ...step6InitialState,
    ...step7InitialState,
};

function sortPriceList(list) {
    list.sort((item1, item2) => {
        if (item1.region !== item2.region) {
            return item1.region.toLowerCase() < item2.region.toLowerCase()
                ? -1
                : 1;
        }

        if (item1.channel !== item2.channel) {
            return item1.channel.toLowerCase() < item2.channel.toLowerCase()
                ? -1
                : 1;
        }

        if (item1.market_name !== item2.market_name) {
            return item1.market_name.toLowerCase() <
                item2.market_name.toLowerCase()
                ? -1
                : 1;
        }

        return item1.rule_name.toLowerCase() < item2.rule_name.toLowerCase()
            ? -1
            : 1;
    });
}

const priceRuleSlice = createSlice({
    name: "priceRule",
    initialState,
    reducers: {
        // PRICE RULES LIST
        setList(state, action) {
            sortPriceList(action.payload);

            state.list = action.payload;
        },
        setPriceRuleCreatedMessage(state, action) {
            state.priceRuleCreatedMessage = action.payload;
        },
        setCloneConflictedPriceList(state, action) {
            state.cloneConflictedPriceList = action.payload;
        },
        setCloneShowConfirmationModal(state, action) {
            state.cloneShowConfirmationModal = action.payload;
        },

        // CREATE PRICE RULE
        setCompletedStep(state, action) {
            state.completedStep = [action.payload];
        },
        setStatus(state, action) {
            state.status = action.payload;
        },

        // STEP 1
        setName(state, action) {
            state.name = action.payload;
        },
        setNotes(state, action) {
            state.notes = action.payload;
        },

        // STEP 2
        setId(state, action) {
            state.id = action.payload;
        },
        setMarket(state, action) {
            state.market = action.payload;
        },
        setRegion(state, action) {
            state.region = action.payload;
        },
        setChannel(state, action) {
            state.channel = action.payload;
        },

        // STEP 3
        setProducts(state, action) {
            state.products = action.payload;
        },

        // STEP 4
        setMappings(state, action) {
            state.mappings = action.payload;
        },
        setPriceLists(state, action) {
            state.priceLists = action.payload;
        },
        setInvisiblePriceLists(state, action) {
            state.invisiblePriceLists = action.payload;
        },
        setAllChannelPriceLists(state, action) {
            state.allChannelPriceLists = action.payload;
        },
        setAllPriceLists(state, action) {
            state.priceLists = action.payload.priceLists;
            state.invisiblePriceLists = action.payload.invisiblePriceLists;
        },
        setPriceTagList(state, action) {
            state.priceTagList = action.payload;
        },

        // STEP 5
        setBasePriceLists(state, action) {
            state.basePriceLists = action.payload;
        },

        // STEP 6
        setRetailPriceRules(state, action) {
            state.retailPriceRules = action.payload;
        },
        setWholesalePriceRules(state, action) {
            state.wholesalePriceRules = action.payload;
        },

        // STEP 6.5
        setDiscountTableId(state, action) {
            state.discountTableId = action.payload;
        },

        // STEP 7
        setFinalEuroRetailPriceValues(state, action) {
            state.finalEuroRetailPriceValues = action.payload.sort(
                (a, b) => a["EURO STANDARD"] - b["EURO STANDARD"]
            );
        },
        setFinalEuroWholesalePriceValues(state, action) {
            state.finalEuroWholesalePriceValues = action.payload.sort(
                (a, b) => a["EURO STANDARD"] - b["EURO STANDARD"]
            );
        },
        setFinalLATAMRetailPriceValues(state, action) {
            state.finalLATAMRetailPriceValues = action.payload.sort(
                (a, b) => a["US MSRP"] - b["US MSRP"]
            );
        },
        setFinalLATAMWholesalePriceValues(state, action) {
            state.finalLATAMWholesalePriceValues = action.payload.sort(
                (a, b) => a["US MSRP"] - b["US MSRP"]
            );
        },
        setFinalOutletPriceValues(state, action) {
            state.finalOutletPriceValues = action.payload?.sort(
                (a, b) => a["EURO STANDARD"] - b["EURO STANDARD"]
            );
        },

        // set all state for edit flow
        setStep1(state, action) {
            state.name = action.payload.rule_name;
            state.notes = action.payload.notes;
        },
        setStep2(state, action) {
            state.id = action.payload.id;
            state.market = action.payload.market_id;
            state.region = action.payload.region;
            state.channel = action.payload.channel;
        },
        setStep3(state, action) {
            state.products = action.payload.products;
        },
        setStep4(state, action) {
            state.mappings = action.payload.mappings;
        },
        setStep5(state, action) {
            state.basePriceLists = action.payload.basePriceLists;
        },
        setStep6(state, action) {
            state.retailPriceRules = action.payload.retailPriceRules;
            state.wholesalePriceRules = action.payload.wholesalePriceRules;
        },
        setStep6point5(state, action) {
            state.discountTableId = action.payload.discount_config_id;
        },
        setStep7(state, action) {
            state.finalEuroRetailPriceValues =
                action.payload.finalEuroRetailPriceValues.sort(
                    (a, b) => a["EURO STANDARD"] - b["EURO STANDARD"]
                );
            state.finalEuroWholesalePriceValues =
                action.payload.finalEuroWholesalePriceValues.sort(
                    (a, b) => a["EURO STANDARD"] - b["EURO STANDARD"]
                );
            state.finalLATAMRetailPriceValues =
                action.payload.finalLATAMRetailPriceValues.sort(
                    (a, b) => a["US MSRP"] - b["US MSRP"]
                );
            state.finalLATAMWholesalePriceValues =
                action.payload.finalLATAMWholesalePriceValues.sort(
                    (a, b) => a["US MSRP"] - b["US MSRP"]
                );
            state.finalOutletPriceValues =
                action.payload.finalOutletPriceValues?.sort(
                    (a, b) => a["EURO STANDARD"] - b["EURO STANDARD"]
                );
        },

        // these are functions for resetting future steps on save of a previous step
        // step 1 doesn't have it's own resetter as it just contains a name and would not impact further steps
        resetForStep6point5(state) {
            for (let key in step7InitialState) {
                state[key] = step7InitialState[key];
            }
        },
        resetForStep6(state) {
            for (let key in step7InitialState) {
                state[key] = step7InitialState[key];
            }
        },
        resetForStep5(state) {
            for (let key in step7InitialState) {
                state[key] = step7InitialState[key];
            }
        },
        resetForStep4(state) {
            for (let key in step6InitialState) {
                state[key] = step6InitialState[key];
            }
            priceRuleSlice.caseReducers.resetForStep5(state);
        },
        resetForStep2(state) {
            for (let key in step4InitialState) {
                state[key] = step4InitialState[key];
            }
            priceRuleSlice.caseReducers.resetForStep4(state);
        },
        resetForStep3(state) {
            // THE US MSRP BASE PRICE LIST IS PRODUCED ON THE BASIS OF THE HIERARCHY
            // SELECTED, UNLIKE THE EURO ONES WHICH ARE UPLOADED BY THE USER
            state.finalLATAMRetailPriceValues = [];
            state.finalLATAMWholesalePriceValues = [];
        },
        resetAllSteps(state) {
            for (let key in step1InitialState) {
                state[key] = step1InitialState[key];
            }
            for (let key in step2InitialState) {
                state[key] = step2InitialState[key];
            }
            for (let key in step3InitialState) {
                state[key] = step3InitialState[key];
            }
            for (let key in step5InitialState) {
                state[key] = step5InitialState[key];
            }
            state.completedStep = [0];
            state.status = 0;
            priceRuleSlice.caseReducers.resetForStep2(state);
        },
    },
});

export const {
    setList,
    setPriceRuleCreatedMessage,
    setCloneConflictedPriceList,
    setCloneShowConfirmationModal,

    setCompletedStep,
    setStatus,

    // STEP 1
    setName,
    setNotes,

    // STEP 2
    setId,
    setMarket,
    setRegion,
    setChannel,

    // STEP 3
    setProducts,

    // STEP 4
    setMappings,
    setPriceLists,
    setInvisiblePriceLists,
    setAllChannelPriceLists,
    setAllPriceLists,
    setPriceTagList,

    // STEP 5
    setBasePriceLists,

    // STEP 6
    setRetailPriceRules,
    setWholesalePriceRules,

    // STEP 6.5
    setDiscountTableId,

    // STEP 7
    setFinalEuroRetailPriceValues,
    setFinalEuroWholesalePriceValues,
    setFinalLATAMRetailPriceValues,
    setFinalLATAMWholesalePriceValues,
    setFinalOutletPriceValues,

    setStep1,
    setStep2,
    setStep3,
    setStep4,
    setStep5,
    setStep6,
    setStep6point5,
    setStep7,

    resetForStep6point5,
    resetForStep6,
    resetForStep5,
    resetForStep4,
    resetForStep3,
    resetForStep2,
    resetAllSteps,
} = priceRuleSlice.actions;

export default priceRuleSlice.reducer;

// API FOR LISTING PRICE RULES
export const fetchPriceRuleList = () => (dispatch) => {
    return API.get(PRICE_RULE_API)
        .then((res) => dispatch(setList(res.data.data)))
        .catch((err) => {
            console.log("err, pricerules, apis, fetchlist");
            throw err?.response?.data?.message || err?.message || err;
        });
};
export const fetchFilteredPriceRuleList = (body) => (dispatch) => {
    return API.post(`${PRICE_RULE_API}-filter`, body)
        .then((res) => dispatch(setList(res.data.data)))
        .catch((err) => {
            console.log("err, pricerules, apis, fetchfilteredlist");
            throw err?.response?.data?.message || err?.message || err;
        });
};

// API FOR CLONING PRICE RULE
export const getMarketsForClone = (payload) => {
    return API.get(
        `/markets?excl_market_id=${payload.market_id}&region=${payload.region}&channel=${payload.channel}`
    );
};
export const clonePriceRule = (payload) => {
    return API.post(`${PRICE_RULE_API}/clone`, payload);
};

export const priceListOfPriceRuleConflictCheck = (payload) => {
    return API.post(
        `${PRICE_RULE_API}/clone/default-price-list`,
        payload
    );
};

// API FOR DELETING PRICE RULE
export const deletePriceRule = (payload) => {
    return API.delete(`delete-price-rule?rule_id=${payload}`, { data: {} });
};

// APIS FOR STEP 1
export const validateRuleName = (payload) =>
    API.post(`${PRICE_RULE_API}/validate/names`, payload);
export const saveRuleNameAndNotes = (payload) =>
    API.put("/price-rule-name", payload);

// APIS FOR STEP 2
export const createPriceRule =
    (payload, method = "post") =>
    (dispatch) => {
        return API[method](PRICE_RULE_API, payload)
            .then((res) => {
                return dispatch(setId(res.data.data.rule_id));
            })
            .catch((err) => {
                console.log("err, pricerules, apis, create");
                throw err?.response?.data?.message || err?.message || err;
            });
    };
export const getMarketsDetails = (name) => {
    return API.post("/markets/market-details", { market_name: name });
};

// APIS FOR STEP 3
export const savePriceRuleProductHierarchy =
    (payload, method = "post") =>
    (dispatch) => {
        return API[method]("/price-rule-hierarchies", payload)
            .then(() => dispatch(setProducts(payload.products)))
            .catch((err) => {
                console.log("err, pricerules, apis, savestep3");
                throw err?.response?.data?.message || err?.message || err;
            });
    };

// APIS FOR STEP 4
export const fetchPriceTagList = () => (dispatch) => {
    return API.get("/price-tags").then((res) => {
        return dispatch(setPriceTagList(res.data?.data));
    });
};
const tempSortFunc = (a, b) => (a.market_name > b.market_name ? 1 : -1);
export const fetchPriceLists = (body) => (dispatch) =>
    API.get("/markets/market-names")
        .then((result) => {
            const res = result.data.data
                .sort(tempSortFunc)
                .map((e) => ({ value: e.id, label: e.market_name }));

            return API.get(
                `/price-lists?region=${body.region}&starting_market=${
                    res.find((e) => +e.value === +body.market).label
                }&channel=${body.channel}`
            )
                .then(({ data: { data } }) => {
                    const formattedDt = data.map((e, idx) => ({
                        idx,
                        label: e.name.replace(" ", "").toLowerCase(),
                    }));
                    if (
                        formattedDt.filter((e) => e.label.includes("eur"))
                            .length > 0
                    ) {
                        let eurStd = formattedDt.find((e) => {
                            const x = e.label;
                            return (
                                x.includes("eur") &&
                                (x.includes("std") || x.includes("standard"))
                            );
                        });
                        if (!eurStd)
                            eurStd = formattedDt.find((e) =>
                                e.label.includes("eur")
                            );
                        return dispatch(
                            setPriceLists([
                                {
                                    ...data[eurStd.idx],
                                    label: data[eurStd.idx].name,
                                    price_list_id: data[eurStd.idx].id,
                                },
                                ...data
                                    .filter((e, idx) => +idx !== +eurStd.idx)
                                    .map((e) => ({
                                        ...e,
                                        label: e.name,
                                        price_list_id: e.id,
                                    }))
                                    .sort((a, b) =>
                                        a.label > b.label ? 1 : -1
                                    ),
                            ])
                        );
                    }

                    return dispatch(
                        setPriceLists(
                            data.map((e) => ({
                                ...e,
                                label: e.name,
                                price_list_id: e.id,
                            }))
                        )
                    );
                })
                .catch((err) => {
                    console.log("err, pricerules, apis, fetchmarketnames");
                    throw err;
                });
        })
        .catch((err) => {
            console.log("err, pricerules, apis, fetchpricelists");
            throw err;
        });
export const fetchUSMSRPPriceList = () => (dispatch) => {
    return API.get("price-lists").then((res) => {
        const temp = res.data.data.find((pl) => pl.name === "US MSRP");

        dispatch(
            setAllChannelPriceLists(
                res.data.data.map((e) => ({
                    ...e,
                    label: e.name,
                    price_list_id: e.id,
                }))
            )
        );
        return dispatch(
            setInvisiblePriceLists([
                {
                    ...temp,
                    label: temp.name,
                    price_list_id: temp.id,
                },
            ])
        );
    });
};
export const savePriceMappings =
    (payload, method = "post") =>
    (dispatch) => {
        return API[method]("/price-rule-price-list-mappings", payload).then(
            (res) => {
                const temp = [];
                for (let row of res.data.data) {
                    const newRow = {};
                    newRow.price_rule_price_list_mapping_id = row.id;
                    newRow.price_list_id = row.price_list_id;
                    newRow.retail_clone_of_price_list_id =
                        row.retail_clone_of_price_list_id;
                    newRow.retail_price_tag_id = row.retail_price_tag_id;
                    newRow.wholesale_clone_of_price_list_id =
                        row.wholesale_clone_of_price_list_id;
                    newRow.wholesale_price_tag_id = row.wholesale_price_tag_id;
                    temp.push(newRow);
                }
                return dispatch(setMappings(temp));
            }
        );
    };
export const uploadLogicSetupFile = (file, id) => {
    const formData = new FormData();
    formData.append("file", file);

    return API.post(`/price-rule/step4?rule_id=${id}`, formData).catch(
        (err) => {
            console.log("err, pricerules, apis, uploadlogic");
            throw err?.response?.data?.message || err?.message || err;
        }
    );
};
export const downloadInvalidLogicFileReport = (id) => {
    return API.get(`price_list_mapping/invalid_values/${id}`).catch((err) => {
        console.log("err, pricerules, apis, downloadinvalidlogicfilereport");
        throw err?.response?.data?.message || err?.message || err;
    });
};

// APIS FOR STEP 5
export const saveOrValidateBasePriceLists = (
    payload,
    method = "post",
    id,
    validate
) => {
    return API[method](
        `/base-price-values${validate ? "/validate" : ""}?rule_id=${id}`,
        payload
    ).then((res) => {
        if (validate) {
            if (isEmpty(res.data.data)) {
                if (res.data.type === "global") throw res.data.message;
                else return res.data.message;
            } else return res.data.data;
        } else return res;
    });
};
export const downloadBasePriceListErrorReport = (id) => {
    return API.get(`base-price-values/invalid_values/${id}`);
};

// APIS FOR STEP 6
export const saveRetailPriceValues =
    (payload, method = "post") =>
    (dispatch) => {
        return API[method]("/msrp-attributes", payload).then((res) => {
            return dispatch(setRetailPriceRules(res.data.data));
        });
    };
export const saveWholesalePriceValues =
    (payload, method = "post") =>
    (dispatch) => {
        return API[method]("/wholesale-attributes", payload).then((res) => {
            return dispatch(setWholesalePriceRules(res.data.data));
        });
    };
export const uploadRulesFile = (file, id) => {
    const formData = new FormData();
    formData.append("file", file);

    return API.post(`/price-rule/step6?rule_id=${id}`, formData).catch(
        (err) => {
            console.log("err, pricerules, apis, uploadrulesfile");
            throw err?.response?.data?.message || err?.message || err;
        }
    );
};
export const downloadInvalidRulesFileReport = (id) => {
    return API.get(`price_rule_step6/invalid_values/${id}`, {
        responseType: "blob",
    }).catch((err) => {
        console.log("err, pricerules, apis, downloadinvalidrulesfilereport");
        throw err?.response?.data?.message || err?.message || err;
    });
};

// APIS FOR STEP 6.5
export const saveDiscountId = (body) =>
    API.post("/insert-discount-config-id", body).catch((err) => {
        console.log("err, pricerules, apis, savingDiscountId");
        throw err?.response?.data?.message || err?.message || err;
    });

// APIS FOR STEP 7
export const finalDataMaker = (data, key = "generated_value") => {
    const retVal = [];

    if (!data) {
        return retVal;
    }

    for (let row of data) {
        const missingRowNum = row.row_num - retVal.length;
        if (missingRowNum > 0)
            for (let i = 0; i < missingRowNum; i++) {
                retVal.push({});
            }
        retVal[row.row_num - 1][row.price_list_name] = row[key];
    }
    return retVal;
};
export const calculateFinalEuroRetailValues =
    (payload) => (dispatch, getStore) => {
        return API.post(
            `${PRICE_RULE_API}/msrp-price?id=${payload}&base_price_list_type=${EURO}`
        ).then((res) => {
            const {
                priceRules: { channel, allChannelPriceLists },
            } = getStore();

            const fullPricePLs = new Set(
                allChannelPriceLists
                    .filter((e) => e.channel === "FULL-PRICE")
                    .map((e) => e.id)
            );
            const outletPLs = new Set(
                allChannelPriceLists
                    .filter((e) => e.channel === "OUTLET")
                    .map((e) => e.id)
            );

            const fullPriceData = channel.includes("FULL-PRICE")
                ? res.data.data.filter((e) => fullPricePLs.has(e.price_list_id))
                : [];
            const outletData = channel.includes("OUTLET")
                ? res.data.data.filter((e) => outletPLs.has(e.price_list_id))
                : [];

            dispatch(
                setFinalEuroRetailPriceValues(finalDataMaker(fullPriceData))
            );
            dispatch(setFinalOutletPriceValues(finalDataMaker(outletData)));
        });
    };
export const calculateFinalLATAMRetailValues = (payload) => (dispatch) => {
    return API.post(
        `${PRICE_RULE_API}/msrp-price?id=${payload}&base_price_list_type=${US}`
    ).then((res) => {
        dispatch(
            setFinalLATAMRetailPriceValues(
                finalDataMaker(res.data.data, "msrp_value")
            )
        );

        dispatch(
            setFinalLATAMWholesalePriceValues(
                finalDataMaker(res.data.data, "wholesale_value")
            )
        );
    });
};
export const calculateFinalEuroWholesaleValues = (payload) => (dispatch) => {
    return API.post(
        `${PRICE_RULE_API}/wholesale?id=${payload}&base_price_list_type=${EURO}`
    ).then((res) => {
        dispatch(
            setFinalEuroWholesalePriceValues(finalDataMaker(res.data.data))
        );
    });
};
export const applyPriceRule = (payload, channel) => {
    return Promise.all([
        API.put(`price-rules/update_price_rule_status/${payload}`, {
            status: 1,
            stage: channel.includes("OUTLET") ? 8 : 7,
        }),
        API.post(
            `refresh-price-file-material-details-price-rules?rule_id=${payload}`,
            {}
        ),
    ]);
};

// PREFETCH APIS FOR EDIT FLOW
export const getStep2 = (payload) => async (dispatch) => {
    const res = await API.get(`${PRICE_RULE_API}/${payload}`);

    dispatch(setStep1(res.data.data));
    dispatch(setStep2(res.data.data));
    dispatch(setStep6point5(res.data.data));

    await Promise.all([
        dispatch(fetchPriceTagList()),
        dispatch(
            fetchPriceLists({
                region: res.data.data.region,
                channel: res.data.data.channel,
                market: res.data.data.market_id,
            })
        ),
        dispatch(fetchUSMSRPPriceList()),
    ]);
    return res.data.data;
};
export const getStep3 = (payload) => async (dispatch) => {
    const res = await API.get(
        `${PRICE_RULE_API}/${payload.id}/price-rule-hierarchies`
    );
    return dispatch(setStep3({ products: res.data.data }));
};
export const getStep4 = (payload) => async (dispatch) => {
    const res = await API.get(
        `${PRICE_RULE_API}/${payload.id}/price-rule-price-list-mappings`
    );
    return dispatch(setStep4({ mappings: res.data.data }));
};
export const getStep5 = (payload) => async (dispatch) => {
    const res = await API.get(
        `${PRICE_RULE_API}/${payload.id}/base-price-values`
    );
    return dispatch(setStep5({ basePriceLists: res.data.data }));
};
export const getStep6 = (payload) => async (dispatch, getStore) => {
    const {
        priceRules: { channel },
    } = getStore();

    let promiseList = [
        API.get(`${PRICE_RULE_API}/${payload.id}/msrp-attributes`),
    ];

    if (channel.includes("FULL-PRICE")) {
        promiseList.push(
            API.get(`${PRICE_RULE_API}/${payload.id}/wholesale-attributes`)
        );
    }
    const res = await Promise.all(promiseList);
    return dispatch(
        setStep6({
            retailPriceRules: res[0].data.data,
            wholesalePriceRules: res[1]?.data.data || [],
        })
    );
};
export const getStep7 = (payload) => async (dispatch, getStore) => {
    const {
        priceRules: { channel, allChannelPriceLists },
    } = getStore();

    const promiseList = [
        API.get(
            `${PRICE_RULE_API}/fetch_msrp_wholesale_prices/${payload.id}?base_price_list_type=${EURO}`
        ),
    ];

    if (channel.includes("FULL-PRICE")) {
        promiseList.push(
            API.get(
                `${PRICE_RULE_API}/fetch_msrp_wholesale_prices/${payload.id}?base_price_list_type=${US}`
            )
        );
    }

    const res = await Promise.all(promiseList);

    const fullPricePLs = new Set(
        allChannelPriceLists
            .filter((e) => e.channel === "FULL-PRICE")
            .map((e) => e.id)
    );
    const outletPLs = new Set(
        allChannelPriceLists
            .filter((e) => e.channel === "OUTLET")
            .map((e) => e.id)
    );

    const fullPriceData = channel.includes("FULL-PRICE")
        ? res[0]?.data?.data?.generated_prices?.filter((e) =>
              fullPricePLs.has(e.price_list_id)
          )
        : [];
    const outletData = channel.includes("OUTLET")
        ? res[0]?.data?.data?.generated_prices?.filter((e) =>
              outletPLs.has(e.price_list_id)
          )
        : [];

    return dispatch(
        setStep7({
            finalEuroRetailPriceValues: fullPriceData
                ? finalDataMaker(fullPriceData, "msrp_value")
                : [],
            finalEuroWholesalePriceValues: fullPriceData
                ? finalDataMaker(fullPriceData, "wholesale_value")
                : [],
            finalLATAMRetailPriceValues: res[1]?.data?.data?.generated_prices
                ? finalDataMaker(
                      res?.[1].data.data.generated_prices,
                      "msrp_value"
                  )
                : [],
            finalLATAMWholesalePriceValues: res[1]?.data?.data?.generated_prices
                ? finalDataMaker(
                      res?.[1].data.data.generated_prices,
                      "wholesale_value"
                  )
                : [],
            finalOutletPriceValues: outletData
                ? finalDataMaker(outletData, "msrp_value")
                : [],
        })
    );
};
