import { createApi } from "@reduxjs/toolkit/query/react";
import { API, axiosBaseQueryForRTKQuery } from "../../../utils/axios";

export const configurationAndAlertAPI = createApi({
    reducerPath: "configurationAndAlert",
    baseQuery: axiosBaseQueryForRTKQuery({
        extendedUrl: "",
    }),
    // tagTypes: [],

    endpoints(builder) {
        return {
            // APIS FOR PRICE LISTS CONFIG
            priceList: builder.query({
                query: (body) => ({
                    url: "/price-lists",
                    method: "get",
                    ...body,
                }),
                providesTags: ["priceList"],
                transformResponse: (result) =>
                    result.data.data
                        .map((e, idx) => ({
                            ...e,
                            pricelistName: e.name,
                            id: idx + 1,
                        }))
                        .sort((obj1, obj2) => {
                            if (obj1.region !== obj2.region) {
                                return obj1.region.toLowerCase() <
                                    obj2.region.toLowerCase()
                                    ? -1
                                    : 1;
                            }

                            if (obj1.channel !== obj2.channel) {
                                return obj1.channel.toLowerCase() <
                                    obj2.channel.toLowerCase()
                                    ? -1
                                    : 1;
                            }

                            return obj1.name.toLowerCase() <
                                obj2.name.toLowerCase()
                                ? -1
                                : 1;
                        }),
            }),
            addPriceList: builder.mutation({
                query: (body) => ({
                    url: "/price-lists",
                    method: "post",
                    ...body,
                }),
                invalidatesTags: ["priceList"],
            }),

            getRegion: builder.query({
                query: (body) => ({
                    url: "/regions",
                    method: "get",
                    ...body,
                }),
                providesTags: ["region-list"],
                transformResponse: (result) =>
                    result.data.data.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                    ),
            }),
            getChannel: builder.query({
                query: (body) => ({
                    url: `/regions/${body.regionId}/channels`,
                    method: "get",
                    ...body,
                }),
                providesTags: ["channel-list"],
                transformResponse: (result) =>
                    result.data.data.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                    ),
            }),
            getCurrency: builder.query({
                query: (body) => ({
                    url: "/currencies",
                    method: "get",
                    ...body,
                }),
                providesTags: ["currency-list"],
                transformResponse: (result) =>
                    result.data.data.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                    ),
            }),
            getMarket: builder.query({
                query: () => ({
                    url: "/markets/market-names",
                    method: "get",
                }),
                providesTags: ["market-list"],
                transformResponse: (result) =>
                    result.data.data
                        .sort((a, b) =>
                            a.market_name > b.market_name ? 1 : -1
                        )
                        .map((e) => ({ value: e.id, label: e.market_name })),
            }),

            // APIS FOR DISCOUNT CONFIG
            discountConfigTable: builder.query({
                query: () => ({
                    url: "/discount_configs",
                    method: "get",
                }),
                providesTags: ["discountConfig"],
                transformResponse: (data) => data.data.data,
            }),
            discountTablesForMarket: builder.query({
                query: (body) => ({
                    url: `/discount_configs_market/${body}`,
                    method: "get",
                }),
                transformResponse: (data) => data.data.data,
            }),
            cloneDiscountConfig: builder.mutation({
                query: (body) => ({
                    url: "/clone_discount_config",
                    method: "post",
                    ...body,
                }),
                invalidatesTags: ["discountConfig"],
            }),
            deleteDiscountConfig: builder.mutation({
                query: (body) => ({
                    url: "/delete_discount_config",
                    method: "post",
                    ...body,
                }),
                invalidatesTags: ["discountConfig"],
            }),
            getDiscountTableStep1: builder.query({
                query: (body) => ({
                    url: `/discount_config/${body}`,
                    method: "get",
                }),
                providesTags: ["discountTable"],
            }),
            getDiscountTableStep2: builder.query({
                query: (body) => ({
                    url: `/discount_mapping_prices/${body}`,
                    method: "get",
                }),
                providesTags: ["discountTable"],
            }),
            createDiscountConfig: builder.mutation({
                query: (body) => ({
                    url: "/create_discount_config",
                    method: "post",
                    ...body,
                }),
                invalidatesTags: ["discountConfig"],
            }),
            editDiscountConfig: builder.mutation({
                query: (body) => ({
                    url: "/edit_discount_config",
                    method: "post",
                    ...body,
                }),
                invalidatesTags: ["discountTable"],
            }),
            editDiscountGroup: builder.mutation({
                query: (body) => ({
                    url: "/edit_discount_group",
                    method: "post",
                    ...body,
                }),
                invalidatesTags: ["discountConfig"],
            }),
            marketsForChannel: builder.query({
                query: (body) => ({
                    url: `/markets?channel=OUTLET`,
                    method: "get",
                    ...body,
                }),
                transformResponse: (result) => {
                    let list =
                        result?.data?.data
                            ?.filter((market) =>
                                market.channel.includes("OUTLET")
                            )
                            .sort((a, b) =>
                                a.market_name.localeCompare(b.market_name)
                            )
                            .map(
                                (market) =>
                                    `${market.id}==${market.market_name}`
                            ) || [];
                    return Array.from(new Set(list)).map((market) => {
                        const details = market.split("==");
                        return { value: details[0], label: details[1] };
                    });
                },
            }),
        };
    },
});

export const {
    usePriceListQuery,
    useAddPriceListMutation,

    useGetRegionQuery,
    useLazyGetChannelQuery,
    useGetCurrencyQuery,
    useGetMarketQuery,

    useDiscountConfigTableQuery,
    useDiscountTablesForMarketQuery,
    useGetDiscountTableStep1Query,
    useGetDiscountTableStep2Query,
    useLazyGetDiscountTableStep2Query,
    useCreateDiscountConfigMutation,
    useEditDiscountConfigMutation,
    useEditDiscountGroupMutation,
    useDeleteDiscountConfigMutation,
    useCloneDiscountConfigMutation,
    useMarketsForChannelQuery,
    endpoints,
} = configurationAndAlertAPI;

export const validateDiscountFile = ({ body, fileName, discount_mapping_id }) =>
    API.post(
        `/discount_mapping_values/validate?discount_mapping_id=${discount_mapping_id}&file_name=${fileName}`,
        body
    );

export const downloadInvalidDiscountFileReport = ({ id }) =>
    API.post(`/invalid-discounts/${id}`);

export const validateDiscountTableName = (body) =>
    API.post("/validate_discount_name", body);
