import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQueryForRTKQuery } from "../../../utils/axios";

export const auditLogDownloadAPI = createApi({
    reducerPath: "auditLogDownload",
    baseQuery: axiosBaseQueryForRTKQuery({
        extendedUrl: "",
    }),
    // tagTypes: ["auditLogDownloadList"],

    endpoints(builder) {
        return {
            auditLogDownloadList: builder.query({
                query: (body) => ({
                    url: "/price_material_audit_log_downloads",
                    method: "get",
                    ...body,
                }),
                providesTags: ["auditLogDownloadList"],
                transformResponse: (result) => result.data.data,
            }),
        };
    },
});

export const { useAuditLogDownloadListQuery } = auditLogDownloadAPI;
