/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-escape */
import React from "react";
import moment from "moment";
import numeral from "numeral";
import _ from "lodash";

export const setErrorMessage = (error) => {
    let errorMessage = "Something went wrong. Try again";
    if (error.response) {
        const { data } = error.response;
        errorMessage = data && data.message ? data.message : errorMessage;
    }
    if (error.response && error.response.status === 404) {
        errorMessage = "We couldn't find what you're looking for";
    }
    if (error.response && error.response.status === 418) {
        // let url = `/under-maintenance`;
        // window.history.pushState({ urlPath: url }, "", url);
        // window.location.reload();
    }
    // if (
    // 	error.response &&
    // 	(error.response.status == 403 || error.response.status == 401)
    // ) {
    // 	errorMessage = data && data.detail ? data.detail : "Unauthorized";
    // }
    return errorMessage;
};

export const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            return resolve({
                fileName: file.name,
                base64: reader.result,
            });
        };
        reader.onerror = (error) => {
            console.log(error);
            return reject(error);
        };
    });
};

export const isArrayEmpty = (data = []) => {
    if (!data) return true;
    if (data && data.length === 0) return true;
    return false;
};

export const isArrayEqual = (array1, array2) => {
    let isEqual = false;
    if (array1 && array2 && array1.length === array2.length) {
        isEqual = array1.every((element, index) => {
            return element === array2[index];
        });
    }
    return isEqual;
};

export const arrayHasDuplicates = (array1) => {
    const array2 = array1.map((v) => v.toLowerCase());
    return array2.some((val, i) => array2.indexOf(val.toLowerCase()) !== i);
};

export const uniqueArray = (array1) => {
    return array1.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

export const htmlEncode = (text) => {
    const charMapping = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#039;",
        ",": "&comma;",
    };
    return text.replace(/[&<>"',]/g, (m) => {
        return charMapping[m];
    });
};

export const htmlDecode = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.documentElement.textContent;
};

export const htmlTagDecode = (text) => {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export const dateFormatter = (date, format) => {
    switch (format) {
        case "hh:mm A":
            return moment(date).format("hh:mm A");
        default:
            return moment(date, "MM-DD-YYYY");
    }
};

export const getMonth = (date) => {
    const newDate = moment(date, "MM/DD/YYYY");
    const monthName = newDate.format("MMMM");
    const monthId = newDate.format("M");
    return {
        monthName,
        monthId,
    };
};

export const getYear = (date) => {
    const newDate = moment(date, "MM/DD/YYYY");
    return newDate.format("YYYY");
};

export const formatThousandSeparators = (num) => {
    if (Math.abs(num) < 0.00001) return num;
    return numeral(num).format("0,0.00");
};

export const base64Regex = new RegExp(
    "^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$"
);

export const isArrayMissingItems = (array1, array2) => {
    const missingItems = _.difference(array1, array2);
    if (missingItems && missingItems.length) {
        return true;
    }
    return false;
};

export const strTrim = (string) => {
    if (typeof string === "string") {
        return string?.replace(/\s+/g, " ").trim();
    }
    return string;
};

export const numberColFilterParams = {
    filters: [
        {
            filter: "agTextColumnFilter",
        },
        {
            filter: "agSetColumnFilter",
            filterParams: {
                comparator: (a, b) => {
                    if (isNaN(a)) {
                        if (isNaN(b)) return 0;
                        return -1;
                    }
                    if (isNaN(b)) return 1;
                    return parseFloat(a) - parseFloat(b);
                },
            },
        },
    ],
};

export class IdleTimer {
    constructor() {
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
    }

    updateExpiredTime() {
        this.currentTime = Date.now();
        if (this.timeoutTracker) {
            return;
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("_lastActivityAt", this.currentTime);
            this.timeoutTracker = null;
        }, 2000);
    }

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        localStorage.removeItem("_lastActivityAt");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}
