import {
	toDollarWithDecimal,
	decimalFormatter,
	toUnit,
	toDollar,
	oneDecimalFormatter,
	toPercentage,
	toPercWithOneDecimal,
	toUnitDraft,
	toUnitNoSeparator,
	statusCellStyling,
	weekFormatter,
	scenarioFormatter,
	metricsFormatter,
	dateRangeFormatter,
	toPercentageWithDecimalDraft,
	toPercentageInto100WithDecimalDraft,
	fallbackFormatter,
	epocFormatter,
	clearanceEventFormatter,
	performanceFormatter,
	doorbusterFormatter,
} from "../../../helpers/formatter";

export const formattersNew = {
	//fallbackFormatter
	fallbackFormatter: fallbackFormatter,

	//toUnitNoSeparator
	toUnitNoSeparator: toUnitNoSeparator,

	// toUnit
	inventory_current_oh: toUnit,
	inventory_current_oo: toUnit,
	inventory_current_it: toUnit,
	inventory_total: toUnit,
	inventory_clearance_inventory: toUnit,
	total_current_inventory: toUnit,
	last_four_weeks_ros: toUnit,
	stores_count: toUnit,

	weeks_of_supply: toUnit,

	total_inventory: toUnit,

	sales_units: toUnit,
	sales_units_planned: toUnit,
	sales_units_current_discount: toUnit,
	sales_units_ia_recommended: toUnit,
	sales_units_finalized: toUnit,
	sales_units_cd: toUnit,
	sales_units_ia: toUnit,
	sales_units_fin: toUnit,
	sales_units_baseline: toUnit,
	sales_units_target: toUnit,
	sales_units_ia_incremental: toUnit,
	units_target: toUnit,
	units_ia: toUnit,
	units_incremental_ia: toUnit,
	units_finalized: toUnit,
	units_incremental_finalized: toUnit,

	total_inv_units_ia: toUnit,
	total_inv_units_cd: toUnit,
	total_inv_units_fin: toUnit,
	total_inv_units_fin_approved: toUnit,
	touched_inv_units_ia: toUnit,
	touched_inv_units_cd: toUnit,
	touched_inv_units_fin: toUnit,
	touched_inv_units_fin_approved: toUnit,
	units_touched_last_finalized: toUnit,
	units_touched_ia: toUnit,
	units_touched_finalized: toUnit,
	units_baseline: toUnit,
	sales_units_scenario_1: toUnit,
	sales_units_scenario_2: toUnit,
	incremental_sales_units_ia_recommended: toUnit,
	incremental_sales_units_scenario_1: toUnit,
	incremental_sales_units_scenario_2: toUnit,

	//toDollar
	gross_margin: toDollar,
	gross_margin_planned: toDollar,
	gross_margin_current_discount: toDollar,
	gross_margin_ia_recommended: toDollar,
	gross_margin_finalized: toDollar,
	markdown_budget: toDollar,
	revenue_target: toDollar,
	revenue_incremental_ia: toDollar,
	revenue_incremental_finalized: toDollar,
	gross_margin_target: toDollar,
	gross_margin_baseline: toDollar,
	gross_margin_ia_incremental: toDollar,
	gross_margin_ia: toDollar,
	gross_margin_incremental_ia: toDollar,
	gross_margin_incremental_finalized: toDollar,
	base_price: toDollar,
	margin_ia_recommended: toDollar,
	margin_scenario_1: toDollar,
	margin_scenario_2: toDollar,
	sales_ia_recommended: toDollar,
	sales_scenario_1: toDollar,
	sales_scenario_2: toDollar,
	incremental_margin_ia_recommended: toDollar,
	incremental_margin_scenario_1: toDollar,
	incremental_margin_scenario_2: toDollar,
	incremental_sales_ia_recommended: toDollar,
	incremental_sales_scenario_1: toDollar,
	incremental_sales_scenario_2: toDollar,

	price: toDollarWithDecimal,
	sales_dollars: toDollar,
	sales_dollars_planned: toDollar,
	sales_dollars_current_discount: toDollar,
	sales_dollars_ia_recommended: toDollar,
	sales_dollars_finalized: toDollar,
	sales_dollars_baseline: toDollar,
	sales_dollars_target: toDollar,
	sales_dollars_ia_incremental: toDollar,

	revenue_baseline: toDollar,
	revenue_ia: toDollar,
	revenue_finalized: toDollar,

	markdown_projected: toDollar,
	markdown_incremenatl: toDollar,

	aur: toDollarWithDecimal,
	aur_planned: toDollarWithDecimal,
	aur_current_discount: toDollarWithDecimal,
	aur_ia_recommended: toDollarWithDecimal,
	aur_finalized: toDollarWithDecimal,

	//toPercentage
	decisions_reg_current_discount: toPercentage,
	decisions_reg_ia_recommended: toPercentage,
	decisions_reg_finalized: toPercentage,
	decisions_first_current_discount: toPercentage,
	decisions_first_ia_recommended: toPercentage,
	decisions_first_finalized: toPercentage,
	decisions_further_current_discount: toPercentage,
	decisions_further_ia_recommended: toPercentage,
	decisions_further_finalized: toPercentage,
	decisions_epoc_current_discount: toPercentage,
	decisions_epoc_ia_recommended: toPercentage,
	decisions_epoc_finalized: toPercentage,

	gross_margin_percent_planned: toPercentage,
	gross_margin_percent_current_discount: toPercentage,
	gross_margin_percent_ia_recommended: toPercentage,
	gross_margin_percent_finalized: toPercentage,

	sell_through_percent_planned: toPercentage,
	sell_through_percent_current_discount: toPercentage,
	sell_through_percent_ia_recommended: toPercentage,
	sell_through_percent_finalized: toPercentage,

	gross_margin_deviation_current_discount: toPercentage,
	gross_margin_deviation_ia_recommended: toPercentage,
	gross_margin_deviation_finalized: toPercentage,

	sales_units_deviation_current_discount: toPercentage,
	sales_units_deviation_ia_recommended: toPercentage,
	sales_units_deviation_finalized: toPercentage,

	sales_dollars_deviation_current_discount: toPercentage,
	sales_dollars_deviation_ia_recommended: toPercentage,
	sales_dollars_deviation_finalized: toPercentage,

	aur_deviation_current_discount: toPercentage,
	aur_deviation_ia_recommended: toPercentage,
	aur_deviation_finalized: toPercentage,

	gross_margin_percent_deviation_current_discount: toPercentage,
	gross_margin_percent_deviation_ia_recommended: toPercentage,
	gross_margin_percent_deviation_finalized: toPercentage,

	sell_through_percent_deviation_current_discount: toPercentage,
	sell_through_percent_deviation_ia_recommended: toPercentage,
	sell_through_percent_deviation_finalized: toPercentage,

	current_sell_through_percent: toPercentage,
	lw_sell_through_percent: toPercentage,
	last_reg_discount: toPercentage,

	//toPercWithOneDecimal
	margin_lly: toPercWithOneDecimal,

	//toDollarWithDecimal
	decisions_pricing_current: toDollarWithDecimal,
	decisions_pricing_ia_recommended: toDollarWithDecimal,
	decisions_pricing_finalized: toDollarWithDecimal,

	//toPercentageWithDecimalDraft
	overall_sell_through: toPercentageWithDecimalDraft,
	gross_margin_percent: toPercentageWithDecimalDraft,
	st_percent: toPercentageWithDecimalDraft,
	effective_discount_cd: toPercentageWithDecimalDraft,
	effective_discount_ia: toPercentageWithDecimalDraft,
	effective_discount_fin: toPercentageWithDecimalDraft,
	epoc_effective_discount: toPercentageWithDecimalDraft,
	ia_recommended: toPercentageWithDecimalDraft,
	discount: toPercentageWithDecimalDraft,

	total_effective_discount_ia: toPercentageWithDecimalDraft,
	total_effective_discount_cd: toPercentageWithDecimalDraft,
	total_effective_discount_fin: toPercentageWithDecimalDraft,
	total_effective_discount_fin_approved: toPercentageWithDecimalDraft,
	touched_effective_discount_ia: toPercentageWithDecimalDraft,
	touched_effective_discount_cd: toPercentageWithDecimalDraft,
	touched_effective_discount_fin: toPercentageWithDecimalDraft,
	touched_effective_discount_fin_approved: toPercentageWithDecimalDraft,
	perc_clearance_penetration: toPercentageInto100WithDecimalDraft,
	stores_percentage: toPercentageWithDecimalDraft,

	//oneDecimalFormatter
	wos: oneDecimalFormatter,

	//decimalFormatter
	"lw_metrices.lw_build": decimalFormatter,
	final_score: decimalFormatter,
	percentile_rank: decimalFormatter,

	//scenarioFormatter
	scenario: scenarioFormatter,
	epoc_metric: scenarioFormatter,

	//metricsFormatter
	metrics: metricsFormatter,

	//toUnitDraft
	planned_units: toUnitDraft,

	//statusCellStyling
	status: statusCellStyling,

	//weekFormatter
	week_num: weekFormatter,

	//dateRangeFormatter
	"start_of_week, end_of_week": dateRangeFormatter,

	//epocFormatter
	epoc: epocFormatter,

	//clearanceEentFormnatter
	event: clearanceEventFormatter,
	clearance_lifecycle: clearanceEventFormatter,

	//performanceFormatter
	performance: performanceFormatter,

	//doorbusterFormatter
	doorbuster: doorbusterFormatter,
	clean_ticket: doorbusterFormatter,
	pcd_change_indicator: doorbusterFormatter,
};
