import _ from "lodash";
import moment from "moment";

export const getFiscalWeekAndYear = (
	year,
	weekNum,
	fiscalCalendarDetails,
	isOnlyWeek
) => {
	let fiscalWeeks = fiscalCalendarDetails && fiscalCalendarDetails[year];

	if (_.isEmpty(fiscalWeeks)) return null;

	return isOnlyWeek
		? fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fw
		: {
				week: fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fw,
				year: fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fy,
		  };
};

export const getFiscalWeekArray = (
	startDate,
	endDate,
	daterangeOrWeek,
	fiscalCalendarDetails
) => {
	let newSDate = _.cloneDeep(startDate);
	let newEDate = _.cloneDeep(startDate);
	let weekNumList = [];
	let yearList = [];

	while (moment(newEDate).isBefore(endDate)) {
		let fiscalData = getFiscalWeekAndYear(
			moment(newSDate).year(),
			moment(newSDate).week(),
			fiscalCalendarDetails
		);
		// let year = moment(newSDate).year();
		if (fiscalData.week === null) break;
		weekNumList.push(parseInt(fiscalData.week));
		yearList.push(parseInt(fiscalData.year));
		newEDate = moment(newSDate).add(6, "d");
		newSDate = moment(newSDate).add(7, "d");
	}
	return { weekNumList, yearList };
};

export const pastWeekFutureWeekHandler = (
	startDate,
	endDate,
	daterangeOrWeek,
	fiscalCalDetails
) => {
	let calPastWeeks = [];
	let calFutureWeeks = [];
	let crrntDate = moment().format("YYYY-MM-DD");

	for (
		let i = startDate;
		i <= endDate;
		i = moment(i).add(1, "week").format("YYYY-MM-DD")
	) {
		if (moment(i).isBefore(crrntDate, "week")) {
			//current week belongs to future week so use isBefore
			let fiscalData = getFiscalWeekAndYear(
				moment(i).year(),
				moment(i).week(),
				fiscalCalDetails
			);
			calPastWeeks.push({
				weekNum: parseInt(fiscalData.week), //moment(i).week(),
				yearNum: parseInt(fiscalData.year), //moment(i).year(),
			});
		} else {
			let fiscalData = getFiscalWeekAndYear(
				moment(i).year(),
				moment(i).week(),
				fiscalCalDetails
			);

			calFutureWeeks.push({
				weekNum: parseInt(fiscalData.week), //moment(i).week(),
				yearNum: parseInt(fiscalData.year), //moment(i).year(),
			});
		}
	}
	return {
		calPastWeeks,
		calFutureWeeks,
	};
};

export const editPlanDateHandler = (
	startDate,
	endDate,
	daterangeOrWeek,
	fiscalCalDetails
) => {
	let sDate = _.cloneDeep(startDate);
	let eDate = _.cloneDeep(endDate);
	let crrntDate = moment().format("YYYY-MM-DD");

	if (moment(sDate).isBefore(crrntDate, "week")) {
		let startDay = moment(sDate).day();
		sDate = moment().day(startDay).format("YYYY-MM-DD");
	}
	let { calPastWeeks, calFutureWeeks } = pastWeekFutureWeekHandler(
		_.cloneDeep(startDate),
		_.cloneDeep(endDate),
		daterangeOrWeek,
		fiscalCalDetails
	);
	let { weekNumList, yearList } = getFiscalWeekArray(
		sDate,
		eDate,
		daterangeOrWeek,
		fiscalCalDetails
	);
	let { weekNumList: originalWeekList, yearList: originalYearList } =
		getFiscalWeekArray(
			startDate,
			endDate,
			daterangeOrWeek,
			fiscalCalDetails
		);

	return {
		startDate: sDate,
		endDate: eDate,
		originalSDate: _.cloneDeep(startDate),
		originalEDate: _.cloneDeep(endDate),
		originalWeekList,
		originalYearList,
		calPastWeeks,
		calFutureWeeks,
		weekNumList,
		yearList,
	};
};

export const formatCalendarData = (calendar) => {
	const fiscalQuarters = [];
	const activeQuarter = [];
	calendar.map((period) => {
		const fiscalQuarter = {};
		// Exclude all dates less than Q3 for 2022
		if (
			(period.fiscal_year === 2022 && period.fiscal_quarter > 2) ||
			period.fiscal_year !== 2022
		) {
			const quarterExists = fiscalQuarters.findIndex(
				(quarter) => quarter.value === period.fiscal_quarter
			);
			if (quarterExists !== -1) {
				const fiscalMonthsTemp = _.cloneDeep(
					fiscalQuarters[quarterExists].fiscalMonths
				);
				const fiscalMonth = {};
				fiscalMonth.value = period.fiscal_month;
				fiscalMonth.label =
					period.fiscal_month_name || "M" + period.fiscal_month;
				fiscalMonth.minDate = period.min_date;
				fiscalMonth.maxDate = period.max_date;
				fiscalMonth.fiscal_quarter = period.fiscal_quarter;
				fiscalMonth.fiscal_year = period.fiscal_year;
				fiscalMonthsTemp.push(fiscalMonth);
				fiscalQuarters[quarterExists].fiscalMonths = fiscalMonthsTemp;
			} else {
				fiscalQuarter.value = period.fiscal_quarter;
				fiscalQuarter.label =
					"Q" + period.fiscal_quarter + " " + period.fiscal_year;
				fiscalQuarter.fiscalYear = period.fiscal_year;
				const fiscalMonths = [];
				const fiscalMonth = {};
				fiscalMonth.value = period.fiscal_month;
				fiscalMonth.label =
					period.fiscal_month_name || "M" + period.fiscal_month;
				fiscalMonth.minDate = period.min_date;
				fiscalMonth.maxDate = period.max_date;
				fiscalMonth.fiscal_quarter = period.fiscal_quarter;
				fiscalMonth.fiscal_year = period.fiscal_year;
				fiscalMonths.push(fiscalMonth);
				fiscalQuarter.fiscalMonths = fiscalMonths;
				fiscalQuarters.push(fiscalQuarter);
			}
			// Check if current date is between min date and max date
			// If yes, set current quarter
			var currentDate = moment().format("YYYY-MM-DD");
			var startDate = moment(period.min_date).format("YYYY-MM-DD");
			var endDate = moment(period.max_date).format("YYYY-MM-DD");

			if (
				moment(currentDate).isBetween(startDate, endDate) ||
				moment(currentDate).isSame(startDate) ||
				moment(currentDate).isSame(endDate)
			) {
				activeQuarter.push({
					value: period.fiscal_quarter,
					label:
						"Q" + period.fiscal_quarter + " " + period.fiscal_year,
					fiscal_year: period.fiscal_year,
					fiscal_month: period.fiscal_month,
				});
			}
		}
	});
	const calendarData = {};
	calendarData.fiscalQuarters = fiscalQuarters;
	calendarData.activeQuarter = activeQuarter;
	return calendarData;
};

export const getCalendarRequestObj = () => {
	return {
		calendar_date: moment(new Date()).format("YYYY-MM-DD"),
		range: 1,
		aggregation_level: ["fiscal_year", "fiscal_quarter", "fiscal_month"],
	};
};

export const getDefaultTimeline = (fiscalCalendarData) => {
	const defaultTimeline = [];
	if (
		!_.isEmpty(fiscalCalendarData) &&
		!_.isEmpty(fiscalCalendarData.activeQuarter)
	) {
		const activeQuarter = fiscalCalendarData.fiscalQuarters.find(
			(quarter) =>
				quarter.value === fiscalCalendarData.activeQuarter[0].value
		);
		if (activeQuarter && !_.isEmpty(activeQuarter)) {
			activeQuarter.fiscalMonths.map((month) => {
				const eachMonth = {};
				eachMonth.fiscal_year = month.fiscal_year;
				eachMonth.fiscal_quarter = month.fiscal_quarter;
				eachMonth.fiscal_month = month.value;
				eachMonth.start_date = month.minDate;
				eachMonth.end_date = month.maxDate;
				defaultTimeline.push(eachMonth);
			});
		}
	}

	if (_.isEmpty(defaultTimeline)) {
		defaultTimeline.push({
			fiscal_year: new Date().getFullYear(),
		});
	}
	return defaultTimeline;
};

export const getSelectedTimeline = (screenName, selectedTimeline) => {
	const timeline = [];
	let frequency = null;
	if (selectedTimeline && !_.isEmpty(selectedTimeline[screenName])) {
		const months = _.cloneDeep(selectedTimeline[screenName].month);
		months.map((month) => {
			const eachMonth = {};
			eachMonth.fiscal_year = month.fiscal_year;
			eachMonth.fiscal_quarter = month.fiscal_quarter;
			eachMonth.fiscal_month = month.value;
			eachMonth.start_date = month.minDate;
			eachMonth.end_date = month.maxDate;
			timeline.push(eachMonth);
		});
		frequency =
			!_.isEmpty(selectedTimeline[screenName].frequency) &&
			selectedTimeline[screenName].frequency.value;
	}
	return {
		timeline,
		frequency,
	};
};
