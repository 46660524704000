import React from "react";
import pageStyles from "./EUPlanningRollUps.module.css";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
export default function FilterShowcaseBar(props) {
    const hierarchyOptions = cloneDeep(props.hierarchyOptions);
    const metrics = cloneDeep(props.metrics);

    const onNavigationClick = (isLeft) => {
        const element = document.getElementsByClassName(
            pageStyles["filter-showcase-details"]
        );
        element[0].scrollBy({
            left: isLeft ? -200 : 200, // Adjust the amount you want to scroll
            behavior: "smooth", // Optional: smooth scrolling animation
        });
    };

    const filterItemClickHandler = (list, index) => {
        list[index].isSelected = false;
        props.filterItemUpdate({
            hierarchyOptions: hierarchyOptions.filter(
                (item) => item.isSelected
            ),
            metrics: metrics.filter((item) => item.isSelected),
        });
    };

    const getFilterSection = (list) =>
        list?.map((item, index) => (
            <button
                className={`${pageStyles["filter-items"]}`}
                style={{ border: "none" }}
                key={item.label}
                onClick={() => filterItemClickHandler(list, index)}
                onKeyDown={() => {}}
            >
                <span>{item.label}</span>
                <span className={`${pageStyles["cross-icon"]}`}>&times;</span>
            </button>
        ));

    return (
        <div className={`${pageStyles["filter-showcase-container"]}`}>
            <div className={`${pageStyles["filter-showcase-details"]}`}>
                Selected Pivot Settings:
                <div className={`${pageStyles["filter-section"]}`}>
                    <span className={`${pageStyles["filter-title"]}`}>
                        Hierarchy
                    </span>
                    {getFilterSection(hierarchyOptions)}
                </div>
                <div className={`${pageStyles["filter-section"]}`}>
                    <span className={`${pageStyles["filter-title"]}`}>
                        Metrics
                    </span>
                    {getFilterSection(metrics)}
                </div>
            </div>
            <div className={`${pageStyles["filter-showcase-navigation"]}`}>
                <button onClick={() => onNavigationClick(true)}>&lt;</button>
                <button onClick={() => onNavigationClick(false)}>&gt;</button>
            </div>
        </div>
    );
}

FilterShowcaseBar.propTypes = {
    hierarchyOptions: PropTypes.array,
    metrics: PropTypes.array,
    filterItemUpdate: PropTypes.func,
};
