import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Popover from "@mui/material/Popover";
import { IconButton, Tooltip } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CircleIcon from "@mui/icons-material/Circle";

import { ReactComponent as Unlocked } from "../../../assets/images/lock_open_right.svg";
import { ReactComponent as PartialLocked } from "../../../assets/images/partial_lock.svg";
import {
    checkIfMaterialProcessedForCountryPrice,
    checkMaterialSelectionValidity,
    isMaterialBeingSynced,
} from "./euHelper";

import styles from "./MaterialAlertException.module.scss";

const EXCEPTION = "exception";
const ALERT = "alert";

export function getMaterialLockStatus(appliedPriceListTableSettings, data) {
    const allowedPriceListsForLock =
        appliedPriceListTableSettings?.view_data?.columns
            ?.filter((e) => e.isSelected)
            .map((e) => e.name);

    const values = Object.values(data)?.filter(
        (val) => val?.price_file_generated_price_uid
    );
    const isAnyLocked = values?.find(
        (val) =>
            allowedPriceListsForLock.includes(val.price_list_name) &&
            (+val.is_msrp_locked === 1 || +val.is_whsl_locked === 1)
    );
    const isAnyUnlocked = values?.find(
        (val) =>
            allowedPriceListsForLock.includes(val.price_list_name) &&
            (+val.is_msrp_locked === 0 || +val.is_whsl_locked === 0)
    );
    if (isAnyLocked && isAnyUnlocked) return 1;
    else if (isAnyLocked) return 2;
    return 0;
}

function PopoverItem(props) {
    const { type, data } = props;

    // const [displayDontShowAgain, setDisplayDontShowAgain] = useState(false);

    // const { allowed_regions, role_details } = useSelector(
    //     (state) => state.global.userPermissions
    // );

    // useEffect(() => {
    //     setDisplayDontShowAgain(
    //         !data.is_mandatory &&
    //             (role_details[SUPER_USER] ||
    //                 ((role_details[PRICING_TEAM] || role_details[MERCHANT]) &&
    //                     allowed_regions.findIndex(
    //                         (regionObj) => regionObj.label === region
    //                     ) !== -1))
    //     );
    // }, [data.is_mandatory, role_details, allowed_regions, region]);

    // const onCheckboxChange = (e) => {
    //     setItemInDontShowAgainList(data, e.target.checked);
    // };

    return (
        <div>
            <div className={`${styles["popover-title"]}`}>
                {type === EXCEPTION ? "Exception" : "Alert"} (
                {data.is_mandatory ? "mandatory" : "optional"})
            </div>
            <div className={`${styles["popover-desc"]}`}>
                {data.description}
            </div>
            {/* {displayDontShowAgain && (
                <div
                    className={`${styles["popover-dont-show-again-container"]} align-center`}
                >
                    <Checkbox size="small" onChange={onCheckboxChange} />
                    <div>Don't show again</div>
                </div>
            )} */}
        </div>
    );
}

function MaterialStatusIndicators(props) {
    const { data, page, section } = props;

    const [isNotAccessible, setIsNotAccessible] = useState(false);
    const [isBeingGenerated, setIsBeingGenerated] = useState(false);
    const [isBeingSynced, setIsBeingSynced] = useState(false);

    const {
        general: { countryPriceRequestMaterials },
        [page]: { countryLevelPrices },
    } = useSelector((state) => state.workbench);
    const { role_details, allowed_regions, allowed_channel_hierarchies } =
        useSelector((state) => state.global.userPermissions);

    useEffect(() => {
        const result = checkMaterialSelectionValidity(
            role_details,
            allowed_regions,
            allowed_channel_hierarchies,
            data
        );

        setIsNotAccessible(!result);
    }, [data, role_details, allowed_regions, allowed_channel_hierarchies]);

    useEffect(() => {
        const result = checkIfMaterialProcessedForCountryPrice(
            data.id,
            countryPriceRequestMaterials
        );

        setIsBeingGenerated(result);
    }, [data, countryPriceRequestMaterials]);

    useEffect(() => {
        const result = isMaterialBeingSynced(data, countryLevelPrices);

        setIsBeingSynced(result);
    }, [data, countryLevelPrices]);

    return (
        <div className={`${styles["status-container"]} align-center`}>
            <Tooltip title="Access restricted" arrow>
                <DoNotDisturbIcon
                    className={`${styles["not-accessible"]} ${
                        !isNotAccessible ? styles["disabled"] : ""
                    }`}
                    fontSize="small"
                />
            </Tooltip>
            {section === "alertsAndExceptions" ? (
                <Tooltip title="Regional price generation in progress" arrow>
                    <HourglassTopIcon
                        className={`${styles["being-generated"]} ${
                            !isBeingGenerated ? styles["disabled"] : ""
                        }`}
                        fontSize="small"
                    />
                </Tooltip>
            ) : null}
            {section === "alertsAndExceptions" ? (
                <Tooltip title="Being synced to GFE" arrow>
                    <CircleIcon
                        fontSize="small"
                        className={`${styles["being-synced"]} ${
                            !isBeingSynced ? styles["disabled"] : ""
                        }`}
                    />
                </Tooltip>
            ) : null}
        </div>
    );
}

function MaterialAlertException(props) {
    const {
        data,
        value,
        section,
        page,
        toggleLock,
        editMode,
        appliedPriceListTableSettings,
    } = props;

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [exceptions, setExceptions] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [exceptionMandatory, setExceptionMandatory] = useState([]);
    const [exceptionElements, setExceptionElements] = useState([]);
    const [alertElements, setAlertElements] = useState([]);
    const [popoverContent, setPopoverContent] = useState(null);
    const [dontShowAgainMap, setDontShowAgainMap] = useState({});
    const [alertExceptionType, setAlertExceptionType] = useState(ALERT);

    const alertsAndExceptions = useSelector(
        (state) => state.workbench.general[section]
    );
    const { role_details, allowed_regions, allowed_channel_hierarchies } =
        useSelector((state) => state.global.userPermissions);
    const openPopover = Boolean(anchorEl);

    const setItemInDontShowAgainList = (item, setFlag) => {
        setDontShowAgainMap((prevValue) => {
            const dontShowAgainMapCopy = {
                ...prevValue,
            };

            if (setFlag) {
                dontShowAgainMapCopy[item.id] = item;
            } else {
                delete dontShowAgainMapCopy[item.id];
            }

            return dontShowAgainMapCopy;
        });
    };

    useEffect(() => {
        const alerts = [];
        const mandatoryExceptions = [];
        const optionalExceptions = [];
        const materialAlertsExceptions =
            alertsAndExceptions[data.material_number + data.season_code];

        if (Array.isArray(materialAlertsExceptions)) {
            for (const entry of materialAlertsExceptions) {
                const { entry_type, is_mandatory } = entry;

                if (entry_type === 1) {
                    alerts.push(entry);
                } else if (entry_type === 2) {
                    if (is_mandatory) {
                        mandatoryExceptions.push(entry);
                    } else {
                        optionalExceptions.push(entry);
                    }
                }
            }
        }

        const exceptions = mandatoryExceptions.concat(optionalExceptions);

        setAlerts(alerts);
        setExceptions(exceptions);
    }, [alertsAndExceptions, data]);

    useEffect(() => {
        let isExceptionMandatory = false;

        setExceptionElements(
            exceptions.map((exception, index) => {
                const { is_mandatory } = exception;

                if (is_mandatory) {
                    isExceptionMandatory = true;
                }

                return (
                    <>
                        <PopoverItem
                            type={EXCEPTION}
                            data={exception}
                            region={data.region}
                            setItemInDontShowAgainList={
                                setItemInDontShowAgainList
                            }
                        />
                        {index !== exceptions.length - 1 ? (
                            <div
                                className={`${styles["popover-divider"]}`}
                            ></div>
                        ) : null}
                    </>
                );
            })
        );

        setExceptionMandatory(isExceptionMandatory);

        setAlertElements(
            alerts.map((alert, index) => {
                return (
                    <>
                        <PopoverItem
                            type={ALERT}
                            data={alert}
                            region={data.region}
                            setItemInDontShowAgainList={
                                setItemInDontShowAgainList
                            }
                        />
                        {index !== alerts.length - 1 ? (
                            <div
                                className={`${styles["popover-divider"]}`}
                            ></div>
                        ) : null}
                    </>
                );
            })
        );
    }, [alerts, exceptions, data]);

    const handlePopoverOpen = (event, type) => {
        setAnchorEl(event.currentTarget);

        setAlertExceptionType(type);

        if (type === EXCEPTION) {
            setPopoverContent(exceptionElements);
        } else if (type === ALERT) {
            setPopoverContent(alertElements);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setDontShowAgainMap({});
    };

    // const onProceedClick = () => {
    //     const edit_config_dict = Object.values(dontShowAgainMap).map((e) => ({
    //         id: +e.id,
    //         is_enabled: 0,
    //     }));
    //     let editAPICall;

    //     if (alertExceptionType === ALERT) {
    //         editAPICall = editAlerts;
    //     } else if (alertExceptionType === EXCEPTION) {
    //         editAPICall = editExceptions;
    //     }

    //     dispatch(editAPICall({ edit_config_dict })).then(() => {
    //         if (selectedMarket && selectedRegion && selectedChannel) {
    //             const { value: marketId } = selectedMarket;
    //             const { label: region } = selectedRegion;
    //             const { label: channel } = selectedChannel;

    //             if (section === "alertsAndExceptions") {
    //                 const materialIds = materials.map(
    //                     (material) =>
    //                         material.material_number + material.season_code
    //                 );

    //                 dispatch(
    //                     getAlertsAndExceptions({
    //                         screen: "price_grid",
    //                         material_ids: materialIds,
    //                         market_id: marketId,
    //                         region,
    //                         channel,
    //                     })
    //                 );
    //             }

    //             if (section === "countryPriceAlertsAndExceptions") {
    //                 const materialIds = countryLevelPrices.map(
    //                     (material) =>
    //                         material.material_number + material.season_code
    //                 );

    //                 dispatch(
    //                     getAlertsAndExceptions({
    //                         screen: "country_level_price",
    //                         material_ids: materialIds,
    //                         market_id: marketId,
    //                         region,
    //                         channel,
    //                     })
    //                 );
    //             }
    //         }
    //     });

    //     handlePopoverClose();
    // };

    // const onCancelClick = () => {
    //     handlePopoverClose();
    // };

    const isLocked = useMemo(() => {
        if (section !== "countryPriceAlertsAndExceptions") return null;

        return getMaterialLockStatus(appliedPriceListTableSettings, data);
    }, [data, section, appliedPriceListTableSettings]);

    return (
        <div
            className={`${styles["container"]} align-center justify-space-between`}
        >
            {section === "countryPriceAlertsAndExceptions" ? (
                <>
                    <IconButton
                        style={{
                            color: "#1d1d1d",
                            margin: "0 2px 0 -8px",
                            paddingRight: isLocked === 1 ? "2px" : undefined,
                            opacity: checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                data
                            )
                                ? 1
                                : 0,
                            pointerEvents: checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                data
                            )
                                ? undefined
                                : "none",
                        }}
                        onClick={() => {
                            toggleLock(isLocked === 2 ? 0 : 1, "row", data);
                        }}
                        disabled={editMode}
                    >
                        {isLocked === 2 ? (
                            <LockOutlinedIcon style={{ fontSize: "initial" }} />
                        ) : isLocked === 1 ? (
                            <PartialLocked
                                style={{ height: "20px", width: "20px" }}
                            />
                        ) : (
                            <Unlocked style={{ height: "17px" }} />
                        )}
                    </IconButton>
                </>
            ) : null}
            <div>{value}</div>
            <div className="align-center">
                <MaterialStatusIndicators
                    data={data}
                    page={page}
                    section={section}
                />
                <div className={`${styles["icon-container"]} align-center`}>
                    <Tooltip title="Exceptions" arrow>
                        <PriorityHighOutlinedIcon
                            className={`${styles["exception"]} ${
                                exceptionMandatory ? styles["mandatory"] : ""
                            } ${
                                exceptions.length === 0
                                    ? styles["disabled"]
                                    : "pointer"
                            }`}
                            fontSize="10px"
                            onClick={(event) =>
                                exceptions.length !== 0 &&
                                handlePopoverOpen(event, EXCEPTION)
                            }
                        />
                    </Tooltip>
                    <Tooltip title="Alerts" arrow>
                        <WarningAmberOutlinedIcon
                            className={`${styles["alert"]} ${
                                alerts.length === 0
                                    ? styles["disabled"]
                                    : "pointer"
                            }`}
                            fontSize="10px"
                            onClick={(event) =>
                                alerts.length !== 0 &&
                                handlePopoverOpen(event, ALERT)
                            }
                        />
                    </Tooltip>
                </div>
            </div>
            <Popover
                id="mouse-over-popover"
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className={`${styles["popover-container"]}`}>
                    {popoverContent}
                </div>
                {/* <div className={`${styles["action-container"]} align-center`}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={onProceedClick}
                        disabled={isEmpty(dontShowAgainMap)}
                    >
                        Proceed
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={onCancelClick}
                    >
                        Cancel
                    </Button>
                </div> */}
            </Popover>
        </div>
    );
}

export default MaterialAlertException;
