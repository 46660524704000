import moment from "moment";

export const calendarConfig = {
    fstDayOfWk: 1, // Monday
    fstMoOfYr: 0, // Jan
};

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_START_DATE = moment()
    .add(60, "days")
    .format(DEFAULT_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
    .add(105, "days")
    .format(DEFAULT_DATE_FORMAT);

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 1;

export const singleSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        height: "32px",
        minHeight: "32px",
        fontSize: "0.8rem",
        border: "1px solid #cccccc",
        top: "2px",
        cursor: "pointer",
        boxShadow: "none",
        "&:hover": {
            border: "1px solid #cccccc",
        },
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: "32px",
        padding: "0 6px",
    }),
    input: (provided, state) => ({
        ...provided,
        margin: "0px",
        color: "transparent",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "32px",
        // minHeight: "32px",
        padding: "6px",
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    indicatorContainer: (provided, state) => ({
        ...provided,
        padding: "0px 8px",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "2px 4px 2px",
        fontSize: "0.8rem",
    }),
    menuList: (provided, state) => ({
        ...provided,
        fontSize: "0.8rem",
        padding: 0,
    }),
};

export const screenNames = {
    decisionDashboard: "DECISION_DASHBOARD",
    decisionDashboardPromoWeekTable: "DECISION_DASHBOARD",
    decisionDashboardEventPromoTable: "DECISION_DASHBOARD",
    decisionDashboardEventWeekTable: "DECISION_DASHBOARD",
    decisionDashboardPromoWeekEditTable: "DECISION_DASHBOARD",
    decisionDashboardProductDetailsTable: "DECISION_DASHBOARD",
    createEvent: "CREATE_EVENT",
    createEventBasicDetails: "CREATE_EVENT_BASIC_DETAILS",
    createEventApplicability: "CREATE_EVENT_APPLICABILITY",
    createPromo: "CREATE_PROMO",
    createPromoSelectProducts: "CREATE_PROMO_SELECT_PRODUCTS",
    createPromoProductSelectionTable: "CREATE_PROMO_SELECT_PRODUCTS",
    createPromoSelectStores: "CREATE_PROMO_SELECT_STORES",
    promoWorkbenchAllPromos: "PROMO_WORKBENCH",
    marketingCalendarEventsTable: "MARKETING_CALENDAR",
    marketingCalendarPromoTable: "MARKETING_CALENDAR",
    marketingCalendar: "MARKETING_CALENDAR",
    promoSimulator: "PROMO_SIMULATOR",
    promoSimulatorDetailedMetricTable: "PROMO_SIMULATOR",
    priceListConfig: "PRICE_LIST_CONFIG",
    viewPlanGroupTable: "VIEW_PLAN_GROUP_TABLE",
    viewAlertTable: "VIEW_ALERT_TABLE",
    viewExceptionTable: "VIEW_EXCEPTION_TABLE",
    viewPriceRulesTable: "VIEW_PRICE_RULE_TABLE",
    euPriceFileHistory: "EU_PRICE_FILE_HISTORY",
    msrpTable: "MSRP_TABLE",
    workbenchViewPlanGroupTable: "WORKBENCH_VIEW_PLAN_GROUP",
};

export const tableNames = {
    createPromoProductSelectionTable: "product_table",
    promoWorkbenchAllPromos: "promo_workbench_all_promo_table",
    marketingCalendarPromoTable: "marketing_calendar_promo_table",
    marketingCalendarEventsTable: "events_calendar_data",
    promoSimulator: "scenario_table",
    promoSimulatorDetailedMetricTable: "scenario_details_table",
    decisionDashboardPromoWeekTable: "decision_dashboard_promo_week_table",
    decisionDashboardEventPromoTable: "decision_dashboard_events_promo_table",
    decisionDashboardEventWeekTable: "decision_dashboard_events_week_table",
    decisionDashboardPromoWeekEditTable:
        "decision_dashboard_promo_week_edit_table",
    decisionDashboardProductDetailsTable:
        "decision_dashboard_product_details_table",
    priceListConfig: "price_list_table",
    viewPlanGroupTable: "view_plan_group_table",
    viewAlertTable: "view_alert_table",
    viewExceptionTable: "view_exception_table",
    viewPriceRulesTable: "view_price_rule_table",
    euPriceFileHistory: "eu_price_file_history",
    msrpTable: "msrp_table",
    workbenchViewPlanGroupTable: "workbench_view_plan_group_table",
};

export const DECISION_DASHBOARD = {
    AGGREGATE_METRIC_COLUMNS: [
        "gross_margin_target",
        "finalized_margin",
        "finalized_baseline_margin",
        "finalized_incremental_margin",
        "projected_margin",
        "revenue_target",
        "finalized_revenue",
        "finalized_baseline_revenue",
        "finalized_incremental_revenue",
        "projected_revenue",
        "sales_units_target",
        "finalized_sales_units",
        "finalized_baseline_sales_units",
        "finalized_incremental_sales_units",
        "projected_sales_units",
    ],
    frequencyOptions: [
        {
            label: "Weekly",
            value: "weekly",
        },
        {
            label: "Monthly",
            value: "monthly",
        },
        {
            label: "Quarterly",
            value: "quarterly",
        },
    ],
    defaultFrequency: {
        label: "Weekly",
        value: "weekly",
    },
    graph: {
        aggregations: {
            timeline: "weekly",
        },
        style: {
            target: {
                dashStyle: "Solid",
                color: "#F49125",
            },
            actual: {
                dashStyle: "Solid",
                color: "#65A0EF",
            },
            current_discount: {
                dashStyle: "Solid",
                color: "#8373FF",
            },
            projected: {
                dashStyle: "Solid",
                color: "#C95BAC",
            },
            finalized: {
                dashStyle: "Solid",
                color: "#13AD91",
            },
            ly: {
                dashStyle: "Solid",
                color: "#CECECE",
            },
            lly: {
                dashStyle: "Solid",
                color: "#F4E532",
            },
        },
        metrics: ["gross_margin", "revenue", "sales_units"],
        // data_types: ["target", "projected", "finalized", "actual"],
        data_types: ["finalized"],
        variance_types: [
            ["planned", "current_discount"],
            ["planned", "ia_recommended"],
            ["planned", "finalized"],
        ],
    },
    cumulative: {
        aggregations: {},
        metrics: ["gross_margin", "sales_dollars", "sales_units"],
        data_types: [
            "planned",
            "actual",
            "current_discount",
            "ia_recommended",
            "finalized",
            "ly",
            "lly",
        ],
        variance_types: [
            ["planned", "current_discount"],
            ["planned", "ia_recommended"],
            ["planned", "finalized"],
        ],
    },
    table: {
        metrics: [
            "gross_margin",
            "sales_dollars",
            "sales_units",
            "aur",
            "gross_margin_percent",
            "auc",
            "msrp",
        ],
        data_types: [
            "planned",
            "current_discount",
            "ia_recommended",
            "finalized",
        ],
        variance_types: [
            ["planned", "current_discount"],
            ["planned", "ia_recommended"],
            ["planned", "finalized"],
        ],
    },
    eventsList: [
        {
            label: "event1",
            value: "event1",
        },
        {
            label: "event2",
            value: "event2",
        },
        {
            label: "event3",
            value: "event3",
        },
        {
            label: "event4",
            value: "event4",
        },
        {
            label: "event5",
            value: "event5",
        },
        {
            label: "event6",
            value: "event6",
        },
    ],
    promotionsList: [
        {
            label: "promo1",
            value: "promo1",
        },
        {
            label: "promo2",
            value: "promo2",
        },
        {
            label: "promo3",
            value: "promo3",
        },
        {
            label: "promo4",
            value: "promo4",
        },
        {
            label: "promo5",
            value: "promo5",
        },
        {
            label: "promo6",
            value: "promo6",
        },
    ],
    metricsOptions: [
        {
            label: "GM$",
            value: "gross_margin",
        },
        {
            label: "Sales $",
            value: "sales_dollars",
        },
        {
            label: "Sales Unit",
            value: "sales_units",
        },
    ],
};

export const CREATE_PROMO = {
    promoProductSelection: [
        {
            label: "All Skus",
            value: "all",
        },
        {
            label: "Current Selection",
            value: "current",
        },
    ],
    createPromoSteps: [
        {
            label: "Products",
            // title: "Select Products",
        },
        {
            label: "Store",
            // title: "Select Stores",
        },
        {
            label: "Discount Rules",
            // title: "Discount Rules",
        },
        {
            label: "Scenario",
            // title: "Create Scenario",
        },
        // {
        // 	label: "Promo Target",
        // 	title: "Set Promo Target",
        // },
    ],
};

export const PROMO_WORKBENCH = {
    promoStatusList: [
        {
            label: "All",
            value: "all",
        },
        {
            label: "Draft/Copied",
            value: 0,
        },
        {
            label: "To Finalize",
            value: 2,
        },
        {
            label: "Finalized",
            value: 4,
        },
        {
            label: "Archived",
            value: 6,
        },
    ],
};

export const CREATE_EVENT = {
    createEventSteps: [
        {
            label: "Basic Event Details",
            // title: "Select Basic Event Details",
            // title: "Create Event",
        },
        {
            label: "Event Applicability",
            // title: "Select Event Applicability",
        },
    ],
    basicEventInitialState: {
        name: "",
        submit_offer_by: null,
        start_date: null,
        end_date: null,
        customer_segment: [],
        marketing_notes: "",
        event_type: [],
        event_objective: [],
        event_objective_description: "",
    },
    productSettingInitialState: {
        product_h1: [],
        product_h2: [],
        brand: [],
        product_h3: [],
        product_h4: [],
    },
    storeSelectionInitialState: {
        // channel:[],
        store_h1: [],
        store_h3: [],
        store_h2: [],
        store_h5: [],
    },
    basicEventMandatoryFields: [
        "name",
        "start_date",
        "end_date",
        "marketing_notes",
        "submit_offer_by",
    ],
    productSettingMandatoryFields: ["product_h1", "product_h2"],
    eventObjectiveList: [
        {
            label: "Traffic/Units",
            value: "traffic_units",
        },
        {
            label: "Margin",
            value: "margin",
        },
        {
            label: "Revenue",
            value: "revenue",
        },
        {
            label: "Other",
            value: "other",
        },
    ],
    eventTypeOptions: [
        {
            value: "digital",
            label: "Digital",
        },
        {
            value: "print",
            label: "Print",
        },
    ],
};

export const MARKETING_CALENDAR = {
    calendar_frequency: [
        {
            label: "Week",
            value: "week",
        },
        {
            label: "Month",
            value: "month",
        },
        // {
        // 	label: "Quarter",
        // 	value: "quarter",
        // },
        // {
        // 	label: "Year",
        // 	value: "year",
        // },
    ],
};

export const VALID_OFFER_TYPES = {
    percent_off: "% Off",
    extra_amount_off: "$ Off",
    fixed_price: "PP",
    bxgy: "BXGY",
};

export const PRODUCT_HIERARCHY = [
    {
        label: "Overall",
        value: "overall",
    },
    {
        label: "Division",
        value: "product_h1",
    },
    {
        label: "Department",
        value: "product_h2",
    },
    {
        label: "Class",
        value: "product_h3",
    },
    {
        label: "Sub Class",
        value: "product_h4",
    },
    {
        label: "SKU",
        value: "product_h5",
    },
];

export const PROMO_SIMULATOR = {
    DETAILED_METRICS: ["original", "incremental", "baseline"],
};

export const MODEL_API = {
    WORKBENCH_AGGREGATE_METRICS: {
        MODEL_ID: 1,
        COLUMNS: [
            "target_gross_margin",
            "finalized_gross_margin",
            "finalized_baseline_gross_margin",
            "finalized_incremental_gross_margin",
            "projected_gross_margin",
            "target_revenue",
            "finalized_revenue",
            "finalized_baseline_revenue",
            "finalized_incremental_revenue",
            "projected_revenue",
            "target_sales_units",
            "finalized_sales_units",
            "finalized_baseline_sales_units",
            "finalized_incremental_sales_units",
            "projected_sales_units",
        ],
    },
    METRICS_EVENT_TIME: {
        MODEL_ID: 4,
    },
    METRICS_PROMO_TIME: {
        MODEL_ID: 3,
    },
    METRICS_BULK_PROMO_EDIT: {
        MODEL_ID: 5,
    },
    METRICS_EVENT_PRODUCTS: {
        MODEL_ID: 8,
    },
    METRICS_PROMO_PRODUCTS: {
        MODEL_ID: 7,
    },
};

export const HISTORICAL_DATA = [
    "Pre-fall 2019",
    "Pre-fall 2020",
    "Pre-fall 2021",
    "Pre-fall 2022",
    "Pre-fall 2023",
    "Fall 2019",
    "Fall 2020",
    "Fall 2021",
    "Fall 2022",
    "Fall 2023",
    "Cruise 2018/Pre-Spring 2019",
    "Cruise 2019/Pre-Spring 2020",
    "Cruise 2020/Pre-Spring 2021",
    "Cruise 2021/Pre-Spring 2022",
    "Cruise 2022/Pre-Spring 2023",
    "Cruise 2023/Pre-Spring 2024",
    "Spring 2019",
    "Spring 2020",
    "Spring 2021",
    "Spring 2022",
    "Spring 2023",
    "Spring 2024",
];

export const MIN_INACTIVE_DURATION = 3600000;
export const INTERVAL_API_DURATION = 1200000;


export const MIN_INACTIVE_DURATION_FOR_SIGNOUT = 21600000;
// export const MIN_INACTIVE_DURATION_FOR_SIGNOUT = 600000;
export const INETRVAL_TO_CHECK_ACTIVITY_FOR_SIGNOUT = 10000;
