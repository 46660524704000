import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import AgGridTableRenderer from "../../../utils/reactTable/tableRenderer/AgGridTableRenderer";

import styles from "./EUPricePlanning.module.scss";

function EUPricPlanningDetailsSlider(props) {
    const { setShowSlidingOverlay } = props;

    const onSlidingOverlayClose = () => {
        setShowSlidingOverlay(false);
    };

    return (
        <div className={`${styles["sliding-overlay-container"]}`}>
            <div
                className={`${styles["content"]} flex-column justify-space-between`}
            >
                <div>
                    <div
                        className={`${styles["page-header"]} align-center justify-space-between`}
                    >
                        <div className={`${styles["page-title"]}`}>
                            MSRP History
                        </div>
                        <CloseIcon
                            className={`${styles["close-btn"]} pointer`}
                            onClick={onSlidingOverlayClose}
                        />
                    </div>
                    <div className="align-center">
                        {/* <img /> */}
                        <div>
                            <div>
                                <span className={`${styles["title"]}`}>
                                    Material No:{" "}
                                </span>
                                999234888
                            </div>
                            <div>
                                <span className={`${styles["title"]}`}>
                                    Current MSRP:{" "}
                                </span>
                                $155
                            </div>
                        </div>
                    </div>
                    <AgGridTableRenderer
                        data={[
                            {
                                msrp: "msrp",
                                marketSeason: "marketSeason",
                                madeBy: "madeBy",
                                validFrom: "validFrom",
                                validTo: "validTo",
                                comment: "comment",
                            },
                        ]}
                        from="msrpTable"
                        rowSelectionHandler={() => {}}
                        onFilterChanged={() => {}}
                        autoSizeAllColumns
                    />
                </div>
                <div style={{ textAlign: "left" }}>
                    <Button variant="contained" onClick={onSlidingOverlayClose}>
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default EUPricPlanningDetailsSlider;
