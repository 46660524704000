import React from "react";
import "../index.css";

const NoData = (props) => {
	return (
		<div className="no-table-data">
			<p>{props.description}</p>
		</div>
	);
};

export default NoData;
