import { createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
    requestStart,
    requestComplete,
    requestFail,
    setUserPermissions,
} from "../global/global";

const initialState = {
    token: null,
    userName: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        isAuthenticated(state, action) {
            const { token, user_name } = action.payload;
            state.token = token;
            state.userName = user_name;
        },
    },
});

export const { isAuthenticated } = authSlice.actions;
export default authSlice.reducer;

function onSuccessLogin(dispatch, response) {
    const { data, user_id } = response.data;
    const {
        allowed_channel_hierarchies,
        allowed_feature_actions,
        allowed_hierarchies,
        allowed_regions,
        role_details,
        token,
        user_name,
    } = data;

    const allowed_feature_actions_map = {};

    if (allowed_feature_actions)
        for (const { feature_name, action_name } of allowed_feature_actions) {
            if (!feature_name || !action_name) {
                continue;
            }

            if (!allowed_feature_actions_map[feature_name]) {
                allowed_feature_actions_map[feature_name] = {};
            }

            allowed_feature_actions_map[feature_name][action_name] = true;
        }

    const role_details_map = {};

    if (role_details)
        for (const { role_id } of role_details) {
            role_details_map[role_id] = true;
        }

    const allowed_channel_hierarchies_map = {};

    if (allowed_channel_hierarchies)
        for (const channelId in allowed_channel_hierarchies) {
            const hierarchyMap = allowed_channel_hierarchies[channelId];

            allowed_channel_hierarchies_map[channelId] = {};

            for (const hierarchyId in hierarchyMap) {
                const hierarchyObjs = hierarchyMap[hierarchyId];

                allowed_channel_hierarchies_map[channelId][hierarchyId] = {};

                for (const hierarchyObj of hierarchyObjs) {
                    allowed_channel_hierarchies_map[channelId][hierarchyId][
                        hierarchyObj.value
                    ] = true;
                }
            }
        }

    localStorage.setItem("userId", user_id);
    localStorage.setItem("token", token);
    localStorage.setItem("user", user_name);
    localStorage.setItem(
        "allowed_feature_actions",
        JSON.stringify(allowed_feature_actions_map)
    );
    localStorage.setItem(
        "allowed_hierarchies",
        JSON.stringify(allowed_hierarchies)
    );
    localStorage.setItem("allowed_regions", JSON.stringify(allowed_regions));
    localStorage.setItem("role_details", JSON.stringify(role_details_map));
    localStorage.setItem(
        "allowed_channel_hierarchies",
        JSON.stringify(allowed_channel_hierarchies_map)
    );

    dispatch(isAuthenticated(data));
    dispatch(
        setUserPermissions({
            userId: user_id,
            allowed_feature_actions: allowed_feature_actions_map,
            allowed_hierarchies,
            allowed_regions,
            role_details: role_details_map,
            allowed_channel_hierarchies: allowed_channel_hierarchies_map,
        })
    );
}

export const funcForHardRefresh = () => {
    localStorage.clear();

    navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
            registration.unregister();
        });
    });

    caches.keys().then((keyList) => {
        return Promise.all(
            keyList.map((key) => {
                return caches.delete(key);
            })
        );
    });
};

export const signIn = (payload) => (dispatch) => {
    dispatch(requestStart());

    API.post("/login", payload)
        .then((response) => {
            if (response.status === 200) {
                funcForHardRefresh();
                onSuccessLogin(dispatch, response);
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

export const signOut = () => (dispatch) => {
    dispatch(requestStart());
    API.post("/logout")
        .then((response) => {
            if (response.status === 200) {
                dispatch(requestComplete());
                window.location.href = "/";

                funcForHardRefresh();

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

export const samlLoginCallback = (payload) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/samlCustomLogin", payload)
        .then((response) => {
            console.log("SSO", response);
            if (response.status === 200) {
                onSuccessLogin(dispatch, response);
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));

            throw error;
        });
};
