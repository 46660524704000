import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { Button, Modal } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { ReactComponent as CsvIcon } from "../../../assets/images/csv.svg";
import { ReactComponent as XlsIcon } from "../../../assets/images/xls.svg";
import {
    disableLoader,
    toastError,
} from "../../../store/features/global/global";
import {
    deletedUploadedPriceFile, downloadInvalidDiscount,
    uploadDiscountFile
} from "../../../store/features/workbench/workbench";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

let templateData = [
    {
        "Material Number": "323536614001",
        "Season Code": "151",
        Discount: "20",
    },
    {
        "Material Number": "323536614003",
        "Season Code": "224",
        Discount: "10",
    },
    {
        "Material Number": "323536614007",
        "Season Code": "999",
        Discount: "10",
    },
];

const templateCols = [
    { field: "Material Number", cellClass: "bigNumber" },
    { field: "Season Code" },
    { field: "Discount" },
];

const excelStyles = [
    {
        id: "bigNumber",
        numberFormat: {
            format: "0",
        },
    },
];

let discountFileId;
function DiscountUploadModal({
    showModal,
    setShowModel,
    selectedMaterials,
    selectedMarkets,
    selectedRegions,
    selectedChannels,
    refreshTable
}) {
    const [pageIdx, setPageIdx] = useState(0);
    const [uploadFile, setUploadFile] = useState(null);
    const [invalidMaterialsMessage, setInvalidMaterialsMessage] = useState();
    const [validMaterialCount, setValidMaterialCount] = useState();
    const [csvMaterialCount, setCsvMaterialCount] = useState();
    const [validMaterialWithoutPed, setValidMaterialWithoutPed] = useState();
    const gridRef = useRef();
    const dispatch = useDispatch();

    const getTemplateData = useMemo(() => {
        if (selectedMaterials?.length > 0) {
            return selectedMaterials.map((item) => ({
                "Material Number": item.data.material_number,
                "Season Code": item.data.season_code,
                Discount: item.data?.discount || "",
            }));
        }

        return templateData;
    }, [selectedMaterials]);

    useEffect(() => {
        setPageIdx(0);
        setUploadFile(null);
    }, [showModal]);

    const downloadTemplateLinks = (type) => (
        <button
            className={`${styles["download-template"]} ${styles["button-link"]}`}
            onClick={(e) => onDownloadTemplate(e, type)}
        >
            Download {type} template
        </button>
    );

    const onGoToMaterialsClick = (isInsert = "disabled") => {
        const data = {
            file: uploadFile,
            marketId: selectedMarkets[0]?.value,
            region: selectedRegions[0]?.label,
            channel: selectedChannels[0]?.label,
            isInsert,
        };

        dispatch(uploadDiscountFile(data))
            .then((res) => {

                discountFileId = res.price_file_id;
                if (res.status) {
                    setShowModel(false)
                    refreshTable();
                } else {
                    setPageIdx(2);
                    setInvalidMaterialsMessage(res.message);
                    setValidMaterialCount(res.validCount);
                    setCsvMaterialCount(res.csvMaterialCount);
                    setValidMaterialWithoutPed(res.validMaterialWithoutPed);
                }
            })
            .catch(() => {});
    };

    const uploadPages = [
        {
            title: "Upload Discount File",
            render: () => {
                return (
                    <Dropzone
                        accept={{ "*": [".csv", ".xlsx"] }}
                        multiple={false}
                        onDropAccepted={onFileDropAccepted}
                        onDropRejected={onFileDropRejected}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                className={styles["dropzone-wrapper"]}
                                {...getRootProps()}
                            >
                                <div
                                    className={`${styles["dropzone-container"]} flex-column align-center`}
                                >
                                    <input {...getInputProps()} />
                                    <div
                                        className={`${styles["upload-icon-container"]} align-center justify-center`}
                                    >
                                        <FileUploadOutlinedIcon
                                            fontSize="large"
                                            style={{
                                                height: "48px",
                                                width: "48px",
                                            }}
                                        />
                                    </div>
                                    <p style={{ margin: "20px 0px 10px" }}>
                                        Drag and drop .csv/.xlsx file here or{" "}
                                        <span className={`${styles["browse"]}`}>
                                            Browse
                                        </span>
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        Uploading requires .csv format{" "}
                                        {downloadTemplateLinks("csv")}
                                        <br />
                                        Or .xlsx format
                                        {downloadTemplateLinks("xlsx")}
                                    </p>
                                    {uploadFile ? (
                                        <p>
                                            <strong>Selected file:</strong>{" "}
                                            <em
                                                className={`${styles["modal-upload-file-name"]}`}
                                            >
                                                {uploadFile?.name}
                                            </em>
                                        </p>
                                    ) : null}
                                </div>
                                <div style={{ display: "none" }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={getTemplateData}
                                        columnDefs={templateCols}
                                        excelStyles={excelStyles}
                                    />
                                </div>
                            </div>
                        )}
                    </Dropzone>
                );
            },
        },
        {
            title: "Discount File to Upload",
            render: () => {
                return (
                    <div
                        className={`${styles["upload-file-details-container"]}`}
                    >
                        <div
                            className={`${styles["upload-file-icon"]} ${styles["upload-file-icon-xls"]}`}
                        >
                            {uploadFile.type !== "text/csv" ? (
                                <XlsIcon
                                    className={`MuiSvgIcon-root`}
                                    style={{
                                        height: "38px",
                                        width: "38px",
                                    }}
                                />
                            ) : (
                                <CsvIcon
                                    className={`MuiSvgIcon-root`}
                                    style={{
                                        height: "38px",
                                        width: "38px",
                                    }}
                                />
                            )}
                        </div>
                        <div
                            className={`${styles["upload-file-details-section"]}`}
                        >
                            <div
                                className={`${styles["upload-file-details-title"]}`}
                            >
                                File Name
                            </div>
                            <div className={`${styles["upload-file-name"]}`}>
                                {uploadFile.name}
                            </div>
                            <div
                                className={`${styles["upload-file-change"]}`}
                                onClick={onChangeFileClick}
                                onKeyUp={() => {}}
                            >
                                Change File to Upload
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Confirm Uploaded File",
            render: () => {
                return (
                    <>
                        <div
                            className={`${styles["invalid-material-message"]} ${
                                validMaterialCount === csvMaterialCount &&
                                validMaterialWithoutPed === null
                                    ? `${styles["missing-gpm"]}`
                                    : ""
                            }`}
                        >
                            {validMaterialCount === csvMaterialCount &&
                            validMaterialWithoutPed === null ? (
                                <InfoOutlinedIcon
                                    style={{ color: "#0055af" }}
                                />
                            ) : (
                                <WarningOutlinedIcon
                                    style={{ color: "#DA1E28" }}
                                />
                            )}
                            <div>
                                {invalidMaterialsMessage}
                                <div
                                    className={
                                        styles["download-invalid-materials"]
                                    }
                                    onClick={onDownloadInvalidMaterials}
                                    style={
                                        !invalidMaterialsMessage
                                            ? { paddingTop: 0 }
                                            : {}
                                    }
                                    onKeyUp={() => {}}
                                >
                                    <DownloadOutlinedIcon fontSize="small" />
                                    Download Report
                                </div>
                            </div>
                        </div>
                        {validMaterialCount ? (
                            <div className={styles["proceed-message"]}>
                                Do you want to proceed with the
                                {validMaterialCount !== csvMaterialCount
                                    ? " remaining"
                                    : ""}{" "}
                                {validMaterialCount} materials?{" "}
                                {validMaterialWithoutPed?.length
                                    ? `(${validMaterialWithoutPed})`
                                    : ""}
                            </div>
                        ) : null}
                        <div
                            style={{
                                textAlign: "left",
                                marginTop: `${
                                    validMaterialCount ? "0" : "24px"
                                }`,
                            }}
                            className={`button-group-flex ${styles["button-group-flex"]}`}
                        >
                            <Button
                                onClick={onChangeFileClick}
                                variant="outlined"
                            >
                                {validMaterialCount ? "No, " : ""}Upload again
                            </Button>
                            {validMaterialCount ? (
                                <Button
                                    onClick={() =>
                                        onGoToMaterialsClick("enabled")
                                    }
                                    variant="contained"
                                >
                                    Yes, Proceed
                                </Button>
                            ) : null}
                        </div>
                    </>
                );
            },
        },
    ];

    const onDownloadInvalidMaterials = () => {
        downloadInvalidDiscount({ id: discountFileId })
            .then((res) => {
                const prefix = "data:text/csv;charset=utf-8,";

                const downloadData =
                    uploadFile.type === "text/csv"
                        ? prefix + encodeURIComponent(res.data)
                        : res.data;

                const anchorEle = document.createElement("a");
                anchorEle.href = downloadData;
                anchorEle.download = `Invalid Materials Report ${new Date().toLocaleString()}`;
                document.body.appendChild(anchorEle);
                anchorEle.click();
                document.body.removeChild(anchorEle);
            })
            .catch((err) => {
                dispatch(toastError(err?.message || err));
                dispatch(disableLoader());
            });
    };

    const onChangeFileClick = () => {
        if (pageIdx === 2) deletedUploadedPriceFile({ id: discountFileId });
        setUploadFile(null);
        setPageIdx(0);
        discountFileId = null;
    };

    const onDownloadTemplate = (e, type) => {
        e.stopPropagation();

        const fileName = "PriceSmart_Workbench_Discount_File_Template";
        if (type === "csv") {
            gridRef.current.api.exportDataAsCsv({
                fileName,
            });
        } else {
            gridRef.current.api.exportDataAsExcel({
                fileName,
            });
        }
    };

    const onFileDropAccepted = (files) => {
        setUploadFile(files[0]);
        setPageIdx(1);
    };

    const onFileDropRejected = (files) => {
        const errorMessage =
            files?.[0]?.errors?.[0].message ??
            "File format not supported. Please upload a csv or xslx file.";
        dispatch(toastError(errorMessage));
        setUploadFile(null);
        setPageIdx(0);
    };

    return (
        <Modal
            open={showModal}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className={styles["modal-container"]}>
                <h2 className={styles.title}>{uploadPages[pageIdx].title}</h2>
                <span
                    className={styles["close-icon"]}
                    onClick={() => setShowModel(false)}
                >
                    &#10005;
                </span>
                <div className={styles["discount-body"]}>
                    {uploadPages[pageIdx].render()}
                </div>
                {pageIdx !== 2 && uploadFile ? (
                    <div className={`${styles["modal-actions-container"]}`}>
                        <Button
                            variant="contained"
                            onClick={() => onGoToMaterialsClick()}
                            disabled={!uploadFile}
                        >
                            Go to Materials
                        </Button>
                    </div>
                ) : null}
            </div>
        </Modal>
    );
}

export default DiscountUploadModal;

DiscountUploadModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModel: PropTypes.func,
    selectedMaterials: PropTypes.array,
    selectedMarkets: PropTypes.array,
    selectedRegions: PropTypes.array,
    selectedChannels: PropTypes.array,
    refreshTable: PropTypes.func,
};

// "data": {
//         "price_file_id": 2841,
//         "valid_material_count": 1,
//         "valid_material_without_ped": "All the Material+Season is not expected for this Market.",
//         "csv_material_count": 1
//     },
