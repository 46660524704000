import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "impact-ui";
import "./Loader.scss";

const LoadingOverlay = (props) => {
    // IF YOU WANT TO USE IT AS A LOCAL (COMPONENT LOADER),
    // ADD POSITION: 'RELATIVE' TO THE PARENT COMPONENT
    const {
        showLoader,
        isLocalLoader,
        size = "large",
        showLoadingText = true,
        customStyle = {},
    } = props;
    if (!showLoader) {
        return "";
    }

    return (
        <div
            className="loader-fullscreen"
            style={
                isLocalLoader ? { position: "absolute", ...customStyle } : {}
            }
        >
            <Spinner size={size} showLoadingText={showLoadingText} />
        </div>
    );
};

LoadingOverlay.propTypes = {
    showLoader: PropTypes.bool,
    isLocalLoader: PropTypes.bool,
    size: PropTypes.string,
    showLoadingText: PropTypes.bool,
    customStyle: PropTypes.shape,
};

export default LoadingOverlay;
