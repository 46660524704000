import React, { useRef, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Drawer,
    Tooltip,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    styled,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../../assets/images/logo.png";
import logo_no_text from "../../../assets/images/logo_no_text.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "../../../store/features/auth/auth";
import { setNavigateAfterUnsavedEdit } from "../../../store/features/global/global";

import "./SideBar.scss";

const sidebarIcon = (itemIcon, itemTitle) => {
    if (itemIcon instanceof Object) {
        return itemIcon;
    } else {
        return <img className="icon" src={itemIcon} alt={itemTitle} />;
    }
};

const StyledListItem = styled(ListItem)({
    width: "100%",
    // padding-left: 16px;
    // padding-right: 16px;
    // text-decoration: none;
    "&:hover": {
        color: "white",
        backgroundcolor: "grey",
    },
    "&.Mui-selected": {
        color: "white",
        backgroundColor: "rgb(189, 189, 189, 0.3)",
    },
});

const iconStyle = {
    color: "#fff",
    fontSize: 28,
    position: "relative",
    display: "flex",
};

function getSelectedMenuItemIndex(menuItems) {
    for (
        let menuItemIndex = 0;
        menuItemIndex < menuItems.length;
        menuItemIndex++
    ) {
        const menuItem = menuItems[menuItemIndex];

        if (menuItem?.children?.length) {
            for (
                let subMenuItemIndex = 0;
                subMenuItemIndex < menuItem.children.length;
                subMenuItemIndex++
            ) {
                const subMenuItem = menuItem.children[subMenuItemIndex];

                if (
                    subMenuItem.link ===
                    `/${window.location.pathname.split("/")[1]}`
                ) {
                    return [menuItemIndex, subMenuItemIndex];
                }
            }
        } else if (
            menuItem.link === `/${window.location.pathname.split("/")[1]}`
        ) {
            return [menuItemIndex, -1];
        }
    }

    return [-1, -1];
}

const SideBar = ({ options, pathPrefix, match, location, theme, ...props }) => {
    const wrapperRef = useRef();
    const [isActive, setisActive] = useState(false);
    const [[selectedIndex, selectedSubIndex], setSelectedIndices] = useState(
        getSelectedMenuItemIndex(options)
    );

    const { triggerUnsavedEdit } = useSelector((state) => state.global);

    const navigate = useNavigate();
    const locationParams = useLocation();

    const toggleSideBarExpansionHandler = (expand) => {
        setisActive((isActive) => !isActive);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick, false);
        return () => {
            document.removeEventListener("mousedown", handleClick, false);
        };
    }, []);

    useEffect(() => {
        setSelectedIndices(getSelectedMenuItemIndex(options));
    }, [options, locationParams]);

    const handleClick = (e) => {
        if (wrapperRef.current.contains(e.target)) {
            return;
        }
        setisActive(false);
    };

    const menuItemClicked = (menuItem, menuItemIndex) => {
        const navigateFunc = () => {
            setisActive(false);
            setSelectedIndices([menuItemIndex, -1]);
            navigate(menuItem.link);
        };

        if (menuItem.children?.length) {
            setisActive(true);
        } else {
            if (triggerUnsavedEdit) {
                props.setNavigateAfterUnsavedEdit(navigateFunc);
            } else navigateFunc();
        }
    };

    const subMenuItemClicked = (
        subMenuItem,
        subMenuItemIndex,
        menuItemIndex
    ) => {
        const navigateFunc = () => {
            setisActive(false);
            setSelectedIndices([menuItemIndex, subMenuItemIndex]);
            navigate(subMenuItem.link);
        };

        if (triggerUnsavedEdit) {
            props.setNavigateAfterUnsavedEdit(navigateFunc);
        } else navigateFunc();
    };

    return (
        <Drawer
            variant="permanent"
            ref={wrapperRef}
            className={`drawer ${isActive ? "drawerOpen" : "drawerClose"}`}
            classes={{
                paper: `${isActive ? "drawerOpen" : "drawerClose"}`,
            }}
            id="sidebar-drawer"
        >
            <div className={`logo ${isActive ? "drawerOpen" : "drawerClose"}`}>
                <img
                    src={isActive ? logo : logo_no_text}
                    className="logoImage"
                    alt="PriceSmart Logo"
                    style={{
                        maxWidth: "100px",
                        height: isActive ? "35px" : "31px",
                    }}
                />
            </div>
            <div className="sidebarMiddle">
                <List className="menuItems" sx={{ padding: 0 }}>
                    {options.map((item, itemIndex) => (
                        <li>
                            {!item.children?.length ? (
                                <StyledListItem
                                    id={"sidebar-item-" + itemIndex}
                                    className="menuItem"
                                    button
                                    selected={selectedIndex === itemIndex}
                                    onClick={() => {
                                        menuItemClicked(item, itemIndex);
                                    }}
                                    key={item.key}
                                >
                                    <div className="listitemSidebar">
                                        <Tooltip title={item.title}>
                                            <ListItemIcon>
                                                {sidebarIcon(
                                                    item.icon,
                                                    item.title
                                                )}
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: "0.9rem",
                                            }}
                                            primary={item.title}
                                        />
                                    </div>
                                </StyledListItem>
                            ) : (
                                <div
                                    className={`${
                                        itemIndex === selectedIndex
                                            ? "Mui-selected "
                                            : ""
                                    }listitemSidebar`}
                                    onClick={() => {
                                        menuItemClicked(item, itemIndex);
                                    }}
                                    onKeyDown={() => {}}
                                >
                                    <Tooltip title={item.title}>
                                        <ListItemIcon>
                                            {sidebarIcon(item.icon, item.title)}
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: "0.9rem",
                                        }}
                                        primary={item.title}
                                    />
                                </div>
                            )}
                            {isActive && item.children?.length ? (
                                <ul>
                                    {item.children.map(
                                        (subMenuItem, subMenuItemIndex) => (
                                            <li
                                                key={subMenuItem.key}
                                                className={`sub-menu-item ${
                                                    selectedIndex ===
                                                        itemIndex &&
                                                    selectedSubIndex ===
                                                        subMenuItemIndex
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    subMenuItemClicked(
                                                        subMenuItem,
                                                        subMenuItemIndex,
                                                        itemIndex
                                                    )
                                                }
                                                onKeyDown={() => {}}
                                            >
                                                {subMenuItem.title}
                                            </li>
                                        )
                                    )}
                                </ul>
                            ) : null}
                        </li>
                    ))}
                </List>
                <div
                    className={
                        isActive
                            ? "sidebarToggleIcon left"
                            : "sidebarToggleIcon right"
                    }
                    onClick={() => toggleSideBarExpansionHandler()}
                    onKeyDown={() => {}}
                >
                    {!isActive && <ArrowForwardIcon sx={iconStyle} />}
                    {isActive && <ArrowBackIcon sx={iconStyle} />}
                </div>

                <div className="sidebarFooter">
                    <Link
                        to={triggerUnsavedEdit ? "" : "/"}
                        onClick={() => {
                            if (!triggerUnsavedEdit) props.signOut();
                        }}
                        id="logout"
                    >
                        <LogoutIcon
                            onClick={() => {
                                if (triggerUnsavedEdit)
                                    props.setNavigateAfterUnsavedEdit(() => {
                                        navigate("/");
                                        props.signOut();
                                    });
                            }}
                        />
                    </Link>
                </div>
            </div>
        </Drawer>
    );
};

SideBar.defaultProps = {
    options: [],
    pathPrefix: "",
    theme: {
        width: "var(--sidebar-width)",
        activeWidth: "var(--sidebar-active-width)",
        fontColor: "var(--sidebar-font-color)",
        fontSize: "var(--sidebar-font-size)",
        selectedColor: "var(--sidebar-selected-color)",
        hoverColor: "var(--sidebar-hover-color)",
        bgColor: "var(--sidebar-background-color)",
        padding: "var(--sidebar-padding)",
        slideTime: "var(--sidebar-slide-time)",
    },
};

SideBar.propTypes = {
    options: PropTypes.array,
    pathPrefix: PropTypes.string,
    theme: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => {
            dispatch(signOut());
        },
        setNavigateAfterUnsavedEdit: (val) => {
            dispatch(setNavigateAfterUnsavedEdit(val));
        },
    };
};

export default connect(null, mapDispatchToProps)(SideBar);
