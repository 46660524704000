import PropTypes from "prop-types";

import PriceListLevelImageCell from "../cellRenderers/PriceListLevelImageCell";
import styles from "./TooltipRenderer.module.scss";

function TooltipRenderer(props) {
    return (
        <div className={`${styles["container"]}`}>
            {props.custom?.valueGetter
                ? props.custom.valueGetter(props)
                : props.value ?? "-"}
        </div>
    );
}

export default TooltipRenderer;

export const ImageTooltipRenderer = (props) => {
    const { value } = props;
    return <PriceListLevelImageCell data={{ value }} size="600px" />;
};

ImageTooltipRenderer.propTypes = {
    value: PropTypes.string.isRequired,
};
