import merchandiseImage from "../../../assets/images/merchandise.png";
import forecastingImage from "../../../assets/images/forecasting.png";
import integrationImage from "../../../assets/images/integration.png";

const marketingInfo = [
    {
        title: "One Merchandising Platform",
        description:
            "One single end to end platform for all merchandising processes",
        img: merchandiseImage,
    },
    {
        title: "One AI powered accurate forecasting",
        description:
            "A single forecasting powering all the modules creating a single source of truth",
        img: forecastingImage,
    },
    {
        title: "Seamless Integration between processes",
        description:
            "Seamless flow of information making the process efficient and effective",
        img: integrationImage,
    },
];

export default marketingInfo;
