import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import Select from "../filters/Select/Select";
import { API } from "../../../utils/axios";

// import styles from "./MerchHierarchyFilter.module.scss";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "0 0.7rem 0 0",
    textAlign: "left",
    boxShadow: "none",
    // color: theme.palette.text.secondary,
}));

function MerchHierarchyFilter(props) {
    const { screenName, selectedFilters, onFilterSelect } = props;

    const [options, setOptions] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [filterList, setFilterList] = useState([]);

    const hierarchyConfig = useSelector(
        (store) => store.global.screenConfig[screenName].filter_options
    );
    const elementLabels = useSelector((store) => store.global.elementLabels);

    useEffect(() => {
        setSelectedOptions(selectedFilters ?? {});
    }, [selectedFilters]);

    useEffect(() => {
        const payload = {
            allow_only_active_products: true,
            hierarchy_type: hierarchyConfig[0].type,
            filters: {
                product_hierarchy: {},
                store_hierarchy: {},
            },
        };

        API.post("/filters", payload).then((res) => {
            setOptions((prevValue) => {
                const newValue = { ...prevValue };

                newValue[hierarchyConfig[0].type] = res.data.data;

                return newValue;
            });
        });
    }, []);

    // useEffect(() => {
    //     onFilterSelect(selectedOptions);
    // }, [selectedOptions]);

    const onSelectChange = useCallback(
        (hierarchyIndex, selectedItems) => {
            const hierarchyId = "product_h" + hierarchyIndex;

            setSelectedOptions((prevValue) => {
                const newValue = { ...prevValue };

                newValue[hierarchyId] = selectedItems;

                for (let index = hierarchyIndex + 1; index <= 8; index++) {
                    delete newValue["product_h" + index];
                }

                onFilterSelect(newValue);

                return newValue;
            });

            setOptions((prevValue) => {
                const newValue = { ...prevValue };

                for (let index = hierarchyIndex + 1; index <= 8; index++) {
                    newValue["product_h" + index] = [];
                }

                return newValue;
            });

            if (hierarchyIndex < 8) {
                const payload = {
                    allow_only_active_products: true,
                    hierarchy_type: "product_h" + (hierarchyIndex + 1),
                    filters: {
                        product_hierarchy: {},
                        store_hierarchy: {},
                    },
                };

                for (let index = 1; index < hierarchyIndex; index++) {
                    payload.filters.product_hierarchy["product_h" + index] =
                        selectedOptions["product_h" + index].map(
                            (obj) => obj.value
                        );
                }

                payload.filters.product_hierarchy[
                    "product_h" + hierarchyIndex
                ] = selectedItems.map((obj) => obj.value);

                API.post("/filters", payload).then((res) => {
                    setOptions((prevValue) => {
                        const newValue = { ...prevValue };

                        newValue["product_h" + (hierarchyIndex + 1)] =
                            res.data.data;

                        return newValue;
                    });
                });
            }
        },
        [selectedOptions, onFilterSelect]
    );

    const onSelectOpen = useCallback(
        (hierarchyIndex) => {
            if (
                hierarchyIndex > 1 &&
                selectedOptions["product_h" + (hierarchyIndex - 1)]?.length &&
                !options["product_h" + hierarchyIndex]?.length
            ) {
                const payload = {
                    allow_only_active_products: true,
                    hierarchy_type: "product_h" + hierarchyIndex,
                    filters: {
                        product_hierarchy: {},
                        store_hierarchy: {},
                    },
                };

                for (let index = 1; index < hierarchyIndex; index++) {
                    payload.filters.product_hierarchy["product_h" + index] =
                        selectedOptions["product_h" + index].map(
                            (obj) => obj.value
                        );
                }

                API.post("/filters", payload).then((res) => {
                    setOptions((prevValue) => {
                        const newValue = { ...prevValue };

                        newValue["product_h" + hierarchyIndex] = res.data.data;

                        return newValue;
                    });
                });
            }
        },
        [options, selectedOptions]
    );

    useEffect(() => {
        const list = hierarchyConfig.map((hierarchy) => {
            const { type: hierarchyId, is_mandatory: mandatoryFlag } =
                hierarchy;
            const hierarchyIndex = parseInt(hierarchyId.split("_h")[1]);

            return (
                <Grid item xs={2} sm={2} md={3} lg={2}>
                    <Item className="filterGroup">
                        <label>
                            {elementLabels[hierarchyId]}
                            {mandatoryFlag ? (
                                <span style={{ color: "red" }}> * </span>
                            ) : null}
                        </label>
                        <Select
                            initialData={options?.[hierarchyId] || []}
                            selectedOptions={
                                selectedOptions?.[hierarchyId] || []
                            }
                            updateSelected={(data) =>
                                onSelectChange(
                                    hierarchyIndex,
                                    data.selectedItems
                                )
                            }
                            onDropdownOpen={() => onSelectOpen(hierarchyIndex)}
                            is_multiple_selection
                            forceApiCall
                        />
                    </Item>
                </Grid>
            );
        });

        setFilterList(list);
    }, [
        hierarchyConfig,
        elementLabels,
        options,
        selectedOptions,
        onSelectChange,
        onSelectOpen,
    ]);

    return (
        <Paper className="filter-group-container" elevation={0}>
            <Box sx={{ flexGrow: 1, margin: "0 10px" }}>
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 6, md: 12, lg: 10 }}
                >
                    {filterList}
                </Grid>
            </Box>
        </Paper>
    );
}

export default MerchHierarchyFilter;
