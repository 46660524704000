function PlanGroupProdCatCell(props) {
    return (
        <>
            {props.value?.product_h9?.map((obj) => obj.label).join?.(",") ??
                "-"}
        </>
    );
}

export default PlanGroupProdCatCell;
