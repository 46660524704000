import React, { useEffect, useState } from "react";
import _ from "lodash";
import "../tableRenderer/AgGridTable.scss";

const EventName = (props) => {
	console.log(props);
	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	return (
		<div className="event-name-container">
			<span className="event-status">
				O
			</span>
			<span className="event-name">{cellValue} </span>
		</div>
	);
};;

export default EventName
