import numeral from "numeral";
import { useMemo } from "react";
import { fallbackFormatter } from "../../../../utils/helpers/formatter";

export const metricVersionOptions = [
    { label: "Show TY only", value: "ty" },
    { label: "Show TY and LY", value: "ty-ly" },
    { label: 'Show TY and "TY vs. LY"', value: "ty-ty-ly" },
];

export const costUsedList = [
    { label: "TFG", value: "TFG" },
    { label: "Std Cost", value: "Std Cost" },
    { label: "None", value: "None" },
];

export const SUPER_SEASONS = {
    SPRING: "spring",
    FALL: "fall",
};

export const MARKETS_TYPE = {
    PRE: "pre",
    MAIN: "main",
};

export const getCost = (obj, multiplyingFactor) => {
    return (obj?.tfg_manual || obj?.std_cost || 0) * (multiplyingFactor || 1);
};

export const getUnitsFieldKey = (levelOfUnit, typeOfUnit) =>
    [
        levelOfUnit,
        typeOfUnit === "actual" ? "actual" : "projection",
        "units",
    ].join("_");

export const getPriceKey = (levelOfUnit, channel) => {
    if (channel === "OUTLET") {
        return "store_price";
    }

    return levelOfUnit === "wholesale" ? "wholesale_price" : "msrp_price";
};

export const calculateTyVsLyData = (thisYear, lastYear) =>
    ((thisYear - lastYear) * 100) / lastYear;

export const valueFormatterPivotTable = (params) => {
    const value = params.value;

    if (Number(value) !== value || value === Infinity || value === -Infinity) {
        return "-";
    }

    const raw = numeral(Math.abs(value) < 0.00005 ? 0 : value);

    if (params.colDef.cellRendererParams.timePeriod === "tyVly") {
        if (
            ["margin_perc", "mix"].includes(
                params.colDef.cellRendererParams.metricUnit
            )
        )
            return raw.format("+0,0") + " bps";
        return raw.format("0.00") + "%";
    }

    const metric = params.colDef.cellRendererParams.metricUnit;

    switch (metric) {
        case "sales":
        case "margin":
            return "€ " + raw.format("0,0");
        case "aic":
        case "aip":
            return "€ " + raw.format("0,0.00");
        case "margin_perc":
        case "mix":
            return raw.format("0.00") + "%";
        case "units":
            return raw.format("0,0");
        default:
            // markup
            return raw.format("0,0.00");
    }
};

export const sortFunc = (a, b) => {
    if (a.isDefault) return 0;

    const textA = a.label.toUpperCase();
    const textB = b.label.toUpperCase();
    if (textA < textB) return -1;
    return textA > textB ? 1 : 0;
};

export const hierarchyOptions = [
    { value: "product_h2_name", label: "Merch Org" },
    { value: "product_h3_name", label: "Gender" },
    { value: "product_h4_name", label: "Brand Group" },
    { value: "product_h5_name", label: "Brand" },
    { value: "product_h6_name", label: "Merch Segment" },
    { value: "product_h7_name", label: "Merch Size Group" },
    { value: "product_h8_name", label: "Merch Category" },
    {
        value: "product_h9_name",
        label: "Product Category",
    },
    { value: "product_h10_name", label: "Product Class" },
    { value: "product_h11_name", label: "Product Subclass" },
    { value: "product_h12_name", label: "Plan Level 1" },
    { value: "product_h13_name", label: "Plan Level 2" },
    { value: "product_h14_name", label: "Plan Level 3" },
    { value: "product_h15_name", label: "Plan Level 4" },
    { value: "product_h16_name", label: "Plan Level 5" },
    {
        value: "planning_brand",
        label: "Planning Brand",
        isDefault: true,
    },
    {
        value: "planning_sub_brand",
        label: "Planning Sub Brand",
        isDefault: true,
    },
    {
        value: "product_h1_name",
        label: "Merch Division",
        isDefault: true,
    },
    { value: "season_code", label: "Season Code" },
    { value: "season_name", label: "Season Name" },
].sort(sortFunc);

export const metricUnitsOptions = [
    {
        value: "units",
        label: "Units",
        aggFunc: "sumAggregate",
        isDefault: true,
    },
    {
        value: "sales",
        label: "Sales",
        aggFunc: "sumAggregate",
        isDefault: true,
    },
    {
        value: "margin",
        label: "Margin",
        aggFunc: "sumAggregate",
        isDefault: true,
    },
    {
        value: "margin_perc",
        label: "Margin %",
        aggFunc: "marginPercAggregate",
    },
    {
        value: "aic",
        label: "AIC",
        aggFunc: "AICAggregate",
    },
    {
        value: "mix",
        label: "Mix",
        aggFunc: "mixAggregate",
    },
    {
        value: "aip",
        label: "AIP",
        aggFunc: "AIPAggregate",
    },
    {
        value: "markup",
        label: "Markup",
        aggFunc: "markupAggregate",
    },
].sort(sortFunc);

export const timePeriodOptions = [
    { value: "thisYear", label: "This Year" },
    { value: "lastYear", label: "Last Year" },
    { value: "tyVly", label: "This Year VS Last Year" },
];

export const seasonOptions = [
    { value: "total", label: "Total" },
    { value: "previousMarket", label: "Pre" },
    { value: "currentMarket", label: "Main" },
];

export const typeOfUnitOptions = [
    { value: "projected", label: "Projected" },
    { value: "actual", label: "Actual" },
];

export const levelOfUnitOptions = [
    { value: "dtc", label: "DTC" },
    { value: "wholesale", label: "WHSL" },
];

export const rowBackgroundColors = [
    "#ffffff",
    "#e6f7fa",
    "#cceef5",
    "#b3e6ef",
    "#99deea",
];

export const getRowStyle = (params) => {
    if (params.node.key === "Grand Total") {
        return {
            background: "#d8d8d8",
        };
    } else if (params.node.group) {
        let rowGroupIndex = params.node.rowGroupIndex;

        return {
            background: rowBackgroundColors[rowGroupIndex],
        };
    } else {
        return null;
    }
};

export const updateColumnGroupColor = () => {
    new Array(5).fill("").forEach((_, index) => {
        let className = `.ag-row-group-indent-${index + 1}`;

        document.querySelectorAll(className).forEach((element) => {
            element.parentNode.style.background =
                rowBackgroundColors[index + 1];
        });

        className = `.ag-row-group-indent-${index + 0}`;

        document.querySelectorAll(className).forEach((element) => {
            if (element.innerText.toLowerCase().includes("grand total")) {
                element.parentNode.style.background = "#d8d8d8";
            }
        });
    });
};

const grandTotalComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
    if (nodeA.key === "Grand Total") {
        if (valueA === undefined) {
            return isDescending ? 1 : -1;
        }
        return isDescending ? -1 : 1;
    }

    if (nodeB.key === "Grand Total") {
        if (valueB === undefined) {
            return isDescending ? -1 : 1;
        }
        return isDescending ? 1 : -1;
    }
};

const comparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
    if (nodeA.key === "Grand Total" || nodeB.key === "Grand Total") {
        return grandTotalComparator(valueA, valueB, nodeA, nodeB, isDescending);
    }

    if (valueA !== valueB) {
        return valueA > valueB ? 1 : -1; // Sort alphabetically
    } else {
        return isDescending ? valueB - valueA : valueA - valueB;
    }
};

export const defaultColDef = {
    menuTabs: ["filterMenuTab", "generalMenuTab"],
    valueFormatter: fallbackFormatter,
    cellStyle: {
        display: "flex",
        alignItems: "center",
    },
    cellClassRules: {
        rightAlign: (params) =>
            params.colDef.value === "wholesale" ||
            params.colDef.value === "dtc",
    },
    suppressMenu: true,
    resizable: true,
    sortable: true,
    comparator,
};

const processCellForClipboard = (params) => {
    return params.value;
};

const onBodyScroll = () => {
    updateColumnGroupColor();
    domUpdateForgrandTotal();
};

export const gridOptions = {
    pivotMode: true,
    suppressClickEdit: true,
    enableRangeSelection: true,
    suppressMovableColumns: true,
    suppressAggFuncInHeader: true,
    suppressRowClickSelection: true,
    suppressCopyRowsToClipboard: true,
    rowSelection: "multiple",
    defaultColDef,
    excelStyles: [
        {
            id: "bigNumber",
            numberFormat: {
                format: "0",
            },
        },
        {
            id: "rightAlign",
            alignment: {
                horizontal: "Right",
            },
        },
    ],
    autoGroupColumnDef: {
        headerName: "Hierarchy Levels",
        pinned: "left",
        width: 300,
    },
    processCellForClipboard,
    getRowStyle,
    onBodyScroll,
};

export const vistLeafNodesColumns = (fb, list = []) => {
    if (list?.length) {
        list.forEach((item) => {
            fb(item);

            if ("children" in item) {
                vistLeafNodesColumns(fb, item.children);
            }
        });
    }
};

const removeElement = (element) => {
    element.querySelector(".ag-group-contracted")?.remove();
    element.querySelector(".ag-group-child-count")?.remove();
};

export const domUpdateForgrandTotal = () => {
    const elementList = document.querySelectorAll(
        ".ag-pinned-left-cols-container .ag-cell-last-left-pinned"
    );

    elementList?.forEach((element) => {
        if (element.innerHTML.toLowerCase().includes("grand total")) {
            removeElement(element);
            setTimeout(() => {
                removeElement(element);
            }, 100);
            element.addEventListener("click", (event) => {
                event.stopPropagation();
            });
        }
    });
};

const processCellCallback = (params) => {
    if(params?.column?.colDef?.cellRendererParams){
        return valueFormatterPivotTable({
            ...params?.column,
            value: params.value
        });
    }

    return params.value;
};

export const downloadHandler = (title, gridRef) => {
    gridRef.current.api.exportDataAsExcel({
        processCellCallback,
        fileName: `${title} ${new Date().toLocaleString()}`,
        shouldRowBeSkipped: (params) => {
            const isGrandTotal = params.node.key === "Grand Total";
            const isNotGrandTotalOrTopLevel =
                isGrandTotal && params.node.rowGroupIndex !== 0;

            return isNotGrandTotalOrTopLevel;
        },
    });
};

export const checkRowIsGrandTotal = (params) =>
    !!params.rowNode.allLeafChildren?.[0]?.data?.isGrandTotal;

export const setSelectedItemInList = (list, selectedList) => {
    if (list?.length > 0) {
        list.forEach((item) => {
            const selectedItem = selectedList.find(
                (selectedItem) => selectedItem.label === item.label
            );

            item.isSelected = !!selectedItem;
        });
    } else {
        console.error("Empty array for update", { list, selectedList });
    }
};

export const changeMetrics = (updatedList, viewData, buyingRollUpsSettings) => {
    const processItemList = (itemList) => {
        setSelectedItemInList(
            itemList,
            updatedList.filter((item) => item.isSelected)
        );
    };

    if (buyingRollUpsSettings) {
        viewData.columns.forEach((markets) => {
            markets.children.forEach((years) => {
                processItemList(years.children);
            });
        });
    } else {
        viewData.columns.forEach((markets, index) => {
            if (index === 0) {
                markets.children.forEach((years) => {
                    processItemList(years.children);
                });
            } else {
                markets.children.forEach((market) => {
                    market.children.forEach((year) => {
                        processItemList(year.children);
                    });
                });
            }
        });
    }
};

export const getValueForSumAgg = (params, obj) => {
    const { metricUnit, levelOfUnit, typeOfUnit } =
        params.colDef.cellRendererParams;

    if (metricUnit === "margin") {
        return calculateMarginValue(obj, levelOfUnit, typeOfUnit);
    } else {
        return calculateOtherValue(obj, metricUnit, levelOfUnit, typeOfUnit);
    }
};

const calculateMarginValue = (obj, levelOfUnit, typeOfUnit) => {
    if (
        obj.channel !== "OUTLET" &&
        typeOfUnit === "projected" &&
        shouldSkipProjection(obj, levelOfUnit)
    ) {
        return 0;
    }

    const priceKey = getPriceKey(levelOfUnit, obj.channel);
    const fieldKey = getMarginFieldKey(levelOfUnit, typeOfUnit);

    return Number(
        ((obj?.[priceKey] || 0) - (obj.calculatedCost || 0)) *
        (obj?.[fieldKey] || 0)
    );
};

const shouldSkipProjection = (obj, levelOfUnit) => {
    return (
        obj.gfe_material_status !== "ACTIVE" ||
        (levelOfUnit === "wholesale" && !obj.itp_flag_whsl) ||
        (levelOfUnit === "dtc" && !obj.itp_flag_dtc)
    );
};

const getMarginFieldKey = (levelOfUnit, typeOfUnit) => {
    return [
        levelOfUnit,
        typeOfUnit === "actual" ? "actual" : "projection",
        "units",
    ].join("_");
};

const calculateOtherValue = (obj, metricUnit, levelOfUnit, typeOfUnit) => {
    const fieldKey = (
        metricUnit === "units"
            ? [
                  levelOfUnit,
                  typeOfUnit === "actual" ? "actual" : "projection",
                  metricUnit,
              ]
            : [metricUnit, levelOfUnit, typeOfUnit]
    ).join("_");

    return Number(obj?.[fieldKey] || 0);
};


const getHierarchyData = (valueUsed) => {
    return hierarchyOptions.reduce((pre, item) => {
        let value = valueUsed;
        if (typeof valueUsed == "object") {
            value = valueUsed[item.value];
        }

        return { ...pre, [item.value]: value };
    }, {});
};

const filterRowData = (item, key, year, costUsed) => {

    const isTfgAvailable = item[key[0]][year]?.tfg_manual > 0;
    const isStdCostAvailable = item[key[0]][year]?.std_cost > 0;

    const passed =
        costUsed.length === 0 ||
        (costUsed.find((item) => item.value === "TFG") && isTfgAvailable) ||
        (costUsed.find((item) => item.value === "Std Cost") &&
            isStdCostAvailable) ||
        (costUsed.find((item) => item.value === "None") &&
            !isTfgAvailable &&
            !isStdCostAvailable);

    return passed;
};

export const useRowData = ({
    rawTableData,
    lastYear,
    multiplyingFactor,
    costUsed = [],
}) => {
    const actualRowData = useMemo(() => {
        let list = [];
        rawTableData?.table_data?.forEach((row) => {
            const key = Object.entries(row)[0];
            key[0] = key[0].split("/");
            key[0] = key[0][key[0].length - 1];
            const year = Number(key[0].substring(key[0].length - 4));

            list = list.concat(
                key[1]
                    ?.map((e) => ({
                        ...getHierarchyData(e),
                        [key[0]]: {
                            [key[0].substring(key[0].length - 4)]: {
                                ...e,
                                calculatedCost: getCost(
                                    e,
                                    lastYear === year ? multiplyingFactor : 1
                                ),
                            },
                        },
                    }))
                    .filter(item => filterRowData(item, key, year, costUsed))
            );
        });

        list.push({
            ...getHierarchyData("Grand Total"),
            isGrandTotal: true,
        });

        return list;
    }, [lastYear, rawTableData, multiplyingFactor, costUsed]);

    return actualRowData;
};
