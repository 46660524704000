import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isEmpty } from "lodash";

import CustomAccordion from "../customAccordian/CustomAccordion";
import FilterGroup from "./FilterGroup";
import MerchHierarchyFilter from "../merchHierarchyFilter/MerchHierarchyFilter";
import ProductHierarchyFilter from "../productHierarchyFilter/ProductHierarchyFilter";
import PlanHierarchyFilter from "../planHierarchyFilter/PlanHierarchyFilter";
import RegionHierarchyFilter from "../regionHierarchyFilter/RegionHierarchyFilter";
import FlatFilter from "../flatFilter/FlatFilter";

import styles from "./FilterOverlay.module.scss";

function FilterOverlay(props) {
    const { screen, show, onFilterSubmit, onClose, hideRegionAndChannel } =
        props;

    const [showSaveFilter, setShowSaveFilter] = useState(false);
    const [selectedRegionFilters, setSelectedRegionFilters] = useState({});
    const [selectedMerchFilters, setSelectedMerchFilters] = useState({});
    const [selectedProductFilters, setSelectedProductFilters] = useState({});
    const [selectedPlanFilters, setSelectedPlanFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});

    const savedFilters = useSelector(
        (state) => state.filters.savedFilters[screen]
    );

    useEffect(() => {
        // setSelectedFilters(savedFilters);

        const {
            SELECT_FILTERS_REGION_CHANNEL: selectedRegionFilters,
            SELECT_FILTERS_MERCH: selectedMerchFilters,
            SELECT_FILTERS_PRODUCT: selectedProductFilters,
            SELECT_FILTERS_PLANNING: selectedPlanFilters,
        } = savedFilters;

        setSelectedRegionFilters(selectedRegionFilters);
        setSelectedMerchFilters(selectedMerchFilters);
        setSelectedProductFilters(selectedProductFilters);
        setSelectedPlanFilters(selectedPlanFilters);
    }, [savedFilters]);

    const closeOverlay = () => {
        onClose();
    };

    const onCloseFilterClick = () => {
        // setSelectedFilters(savedFilters);
        closeOverlay();
    };

    const onRegionFilterSelect = useCallback((data) => {
        setSelectedRegionFilters(data);
    }, []);

    const onMerchFilterSelect = useCallback((data) => {
        setSelectedMerchFilters(data);
        // setSelectedProductFilters({});
        // setSelectedPlanFilters({});
    }, []);

    const onProductFilterSelect = useCallback((data) => {
        setSelectedProductFilters(data);
    }, []);

    const onPlanFilterSelect = useCallback((data) => {
        setSelectedPlanFilters(data);
    }, []);

    const onFilterSubmitClick = () => {
        const submittedFilters = {};

        if (!isEmpty(selectedRegionFilters)) {
            submittedFilters.SELECT_FILTERS_REGION_CHANNEL =
                selectedRegionFilters;
        }

        if (!isEmpty(selectedMerchFilters)) {
            submittedFilters.SELECT_FILTERS_MERCH = selectedMerchFilters;
        }

        if (!isEmpty(selectedProductFilters)) {
            submittedFilters.SELECT_FILTERS_PRODUCT = selectedProductFilters;
        }

        if (!isEmpty(selectedPlanFilters)) {
            submittedFilters.SELECT_FILTERS_PLANNING = selectedPlanFilters;
        }

        onFilterSubmit(submittedFilters);

        closeOverlay();
    };

    const onFilterClearClick = () => {
        // setSelectedFilters({});
        setSelectedRegionFilters({});
        setSelectedMerchFilters({});
        setSelectedProductFilters({});
        setSelectedPlanFilters({});
    };

    const onSaveFilterClick = () => {
        setShowSaveFilter(true);
    };

    const onFilterSaveClick = () => {
        setShowSaveFilter(false);
    };

    const onFilterSaveCancelClick = () => {
        setShowSaveFilter(false);
    };

    return (
        show && (
            <>
                <div className={`${styles.wrapper}`}>
                    <div className={`${styles.container}`}>
                        <div
                            className={`${styles.header} align-center justify-space-between`}
                        >
                            <div className={`${styles.title}`}>Filters</div>
                            <Button
                                className={`${styles["tertiary-button"]}`}
                                onClick={onCloseFilterClick}
                            >
                                Close Filter
                            </Button>
                        </div>
                        <div className={`${styles.divider}`}></div>
                        <div className={`${styles.body}`}>
                            {!hideRegionAndChannel && (
                                <>
                                    <CustomAccordion
                                        label="Region & Channel Selection"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_REGION_CHANNEL"
                                            onFilterSelect={
                                                onRegionFilterSelect
                                            }
                                            selectedFilters={
                                                selectedRegionFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div className={`${styles.divider}`}></div>
                                </>
                            )}
                            <CustomAccordion
                                label="Brand Group Selection"
                                defaultExpanded={false}
                            >
                                <FlatFilter
                                    screenName="SELECT_FILTERS_BRAND_GROUP"
                                    onFilterSelect={onMerchFilterSelect}
                                    selectedFilters={selectedMerchFilters}
                                />
                            </CustomAccordion>
                            <div className={`${styles.divider}`}></div>
                            <CustomAccordion
                                label="Merch Division Selection"
                                defaultExpanded={false}
                            >
                                <FlatFilter
                                    screenName="SELECT_FILTERS_MERCH_DIVISION"
                                    onFilterSelect={onMerchFilterSelect}
                                    selectedFilters={selectedMerchFilters}
                                />
                            </CustomAccordion>
                            <div className={`${styles.divider}`}></div>
                            <CustomAccordion
                                label="Merch Hierarchy Selection"
                                defaultExpanded={false}
                            >
                                <FlatFilter
                                    screenName="SELECT_FILTERS_MERCH"
                                    onFilterSelect={onMerchFilterSelect}
                                    selectedFilters={selectedMerchFilters}
                                />
                            </CustomAccordion>
                            <div className={`${styles.divider}`}></div>
                            <CustomAccordion
                                label="Product Hierarchy Selection"
                                defaultExpanded={false}
                            >
                                <FlatFilter
                                    screenName="SELECT_FILTERS_PRODUCT"
                                    onFilterSelect={onProductFilterSelect}
                                    selectedFilters={selectedProductFilters}
                                />
                            </CustomAccordion>
                            <div className={`${styles.divider}`}></div>
                            <CustomAccordion
                                label="Planning Hierarchy Selection"
                                defaultExpanded={false}
                            >
                                <FlatFilter
                                    screenName="SELECT_FILTERS_PLANNING"
                                    onFilterSelect={onPlanFilterSelect}
                                    selectedFilters={selectedPlanFilters}
                                />
                            </CustomAccordion>
                            <div className={`${styles.divider}`}></div>
                            <div className={`${styles["action-container"]}`}>
                                <Button
                                    variant="contained"
                                    onClick={onFilterSubmitClick}
                                >
                                    Submit
                                </Button>
                                <Button
                                    className={`${styles["tertiary-button"]}`}
                                    onClick={onFilterClearClick}
                                >
                                    Clear Filter
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: "100vh",
                        zIndex: 10,
                        bottom: 0,
                        left: 0,
                        top: 0,
                        right: 0,
                        position: "fixed",
                        backgroundColor: "#1d1d1d",
                        opacity: "70%",
                    }}
                />
            </>
        )
    );
}

export default FilterOverlay;
