import PropTypes from "prop-types";
import { useState } from "react";
import { ReactComponent as ImageNotFound } from "../../../assets/images/image_not_found.svg";

function PriceListLevelImageCell(props) {
    const [notFound, setNotFound] = useState(false);

    return (
        <div className="align-center justify-center" style={{ height: "100%" }}>
            {notFound ? (
                <div
                    style={{
                        background: "white",
                        height: 50,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <ImageNotFound
                        className="MuiSvgIcon-root"
                        style={{
                            width: "50px",
                            color: "#AFAFAF",
                        }}
                    />
                </div>
            ) : (
                <img
                    style={{
                        maxWidth: props.size || "50px",
                        maxHeight: props.size || "50px",
                    }}
                    alt="material"
                    src={props.data?.value}
                    onError={() => {
                        setNotFound(true);
                    }}
                />
            )}
        </div>
    );
}

PriceListLevelImageCell.propTypes = {
    size: PropTypes.string,
};

export default PriceListLevelImageCell;
