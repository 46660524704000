import { useState, useEffect } from "react";
import { map, isEmpty } from "lodash";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

const OPTIONS = [
	"Expected future inventory drop",
	"Change in financial plan",
	"Change in assortment plan",
	"Low forecast confidence",
	"others",
];

export const CommentCellRendrer = (props) => {
	const [selected, setSelected] = useState("");
	const [userInputComment, setUserInputComment] = useState("");
	useEffect(() => {
		if (props.value && props.value.length) {
			const cellVal = OPTIONS.includes(props.value)
				? props.value
				: "others";
			setSelected(cellVal);
			if (cellVal === "others") {
				setUserInputComment(props.value);
			}
		}
	}, []);
	const handleChange = (e) => {
		let val = e.target.value;
		setSelected(val);
		props.setValue(val);
	};
	const handleUserInput = (e) => {
		setUserInputComment(e.target.value);
	};
	const onBlurHandler = (e) => {
		props.setValue(e.target.value);
	};
	return (
		<>
			<FormControl className="comment-dropdown">
				<Select
					displayEmpty
					autoWidth
					value={selected}
					onChange={handleChange}
					input={<OutlinedInput />}
					id="comment-simple-select"
					inputProps={{ "aria-label": "Without label" }}
				>
					<MenuItem disabled value="">
						<em>Add Comment</em>
					</MenuItem>
					{map(OPTIONS, (option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<br />
			{selected === "others" && (
				<input
					placeholder="Add Comment"
					id="user-input-comment"
					value={userInputComment}
					onChange={handleUserInput}
					onBlur={onBlurHandler}
				/>
			)}
		</>
	);
};
export const CommentDropDown = (props) => {
	return <span>{props.value}</span>;
};

export const CommentCellRendrer1 = (props) => {
	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
	const [userInputComment, setUserInputComment] = useState("");
	const [inputActive, setInputActive] = useState(false);

	const handleChange = (e) => {
		setUserInputComment(e.target.value);
	};

	const onBlurHandler = (e) => {
		setInputActive(false);
	};
	const onFoucsHandler = (e) => {
		setInputActive(true);
	};
	return (
		<>
			<Button
				className={
					inputActive
						? "comment-button user-input-active"
						: "comment-button"
				}
				variant="outlined"
				endIcon={<ExpandMoreIcon />}
			>
				<div className="comment">
					{cellValue ? cellValue : "Select"}&nbsp;&nbsp;&nbsp;
				</div>
			</Button>
			<br />
			<Input
				placeholder="Add Comment"
				// inputProps={ariaLabel}
				value={userInputComment}
				onChange={handleChange}
				onBlur={onBlurHandler}
				onFocus={onFoucsHandler}
			/>
			{/* {cellValue === "others" && ( */}
			{/* <TextField
				hiddenLabel
				// id="outlined-hidden-label-small"
				// // id="outlined-basic"
				// label="Add Comment"
				// variant="outlined"
				// size="small"

				label="Add Comment"
				id="filled-hidden-label-small"
				variant="filled"
				size="small"
				value={userInputComment}
				onChange={handleChange}
			/> */}
			{/* )} */}
		</>
	);
};
