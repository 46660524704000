export const onFilterChanged = (event) => {
    const filteredRowCount = event.api.getDisplayedRowCount();

    if (filteredRowCount === 0) {
        event.api.showNoRowsOverlay();
    } else {
        event.api.hideOverlay();
    }

    // event.api.deselectAll();
};
