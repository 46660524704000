import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _, { map, isEmpty } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

const DiscountEditCell = (props) => {
	const [cellData, setCellData] = useState({});
	const [offerTypeOptions, setOfferTypeOptions] = useState({});
	const [selectedOfferType, setSelectedOfferType] = useState("");
	const [offerValueOptions, setOfferValueOptions] = useState({});
	const [selectedOfferValue, setSelectedOfferValue] = useState("");

	useEffect(() => {
		if (!isEmpty(props.value)) {
			const { data, colDef } = props;
			const { value, offer_type, offer_value } = props.value;
			const newCellData = {
				...props.value,
				data_type: data.scenario,
			};
			let offerValOptions = {};
			offerValOptions[value] = {
				offer_name: value,
				benefit_value: offer_value,
			};
			setCellData(newCellData);
			setOfferTypeOptions(colDef.offerTypeOptions);
			setSelectedOfferType(offer_type);
			setSelectedOfferValue(value);
			setOfferValueOptions(offerValOptions);
		}
	}, []);

	const offerTypeChangeHandler = async (e) => {
		let val = e.target.value;
		setSelectedOfferType(val);
		let newCellData = { ...cellData, offer_type: val };
		setCellData(newCellData);
		if (val !== selectedOfferType) {
			const { offerVal, benefit_value, effective_price_point } =
				await onOfferValueOpen({
					offerType: val,
				});
			newCellData.value = offerVal;
			newCellData.offer_value = benefit_value;
			newCellData.effectivePricePoint = effective_price_point;
		}
		props.setValue(newCellData);
		props.columnApi.autoSizeColumn(props.column.colId, false);
	};

	const offerValueOptionsFormatter = (options) => {
		let newOfferValues = {},
			newOfferVal = "";
		map(options, (offerVal, i) => {
			newOfferValues[offerVal.offer_name] = offerVal;
			if (i === 0) {
				newOfferVal = offerVal.offer_name;
			}
		});
		return { newOfferValues, newOfferVal };
	};

	const offerValueChangeHandler = (e) => {
		let val = e.target.value;
		setSelectedOfferValue(val);
		let newCellData = {
			...cellData,
			value: val,
			offer_value:
				offerValueOptions[val] && offerValueOptions[val].benefit_value,
			effectivePricePoint:
				offerValueOptions[val] &&
				offerValueOptions[val].effective_price_point,
		};
		setCellData(newCellData);
		props.setValue(newCellData);
	};

	const onOfferValueOpen = async ({ offerType }) => {
		const updatedOfferType = offerType || selectedOfferType;

		if (
			(props.promoValues &&
				!isEmpty(props.promoValues[updatedOfferType]) &&
				offerValueOptions &&
				Object.keys(offerValueOptions).length < 2) ||
			(offerType &&
				props.promoValues &&
				!isEmpty(props.promoValues[updatedOfferType]))
		) {
			// cases :
			// 1. when options are already present in redux store but not yet updated in local state
			// 2. when type is updated and options are already present in redux store
			const { newOfferValues, newOfferVal } = offerValueOptionsFormatter(
				props.promoValues[updatedOfferType]
			);
			setOfferValueOptions(newOfferValues);
			offerType && setSelectedOfferValue(newOfferVal);
			return {
				offerVal: newOfferVal,
				benefit_value:
					newOfferValues[newOfferVal] &&
					newOfferValues[newOfferVal].benefit_value,
				effective_price_point:
					newOfferValues[newOfferVal] &&
					newOfferValues[newOfferVal].effective_price_point,
			};
		}
		if (
			isEmpty(props.promoValues) ||
			(props.promoValues && isEmpty(props.promoValues[updatedOfferType]))
		) {
			const offerValues =
				!_.isEmpty(props.promoValues) &&
				props.promoValues[updatedOfferType];
			const { newOfferValues, newOfferVal } = offerValueOptionsFormatter(
				offerValues || []
			);
			setOfferValueOptions(newOfferValues);
			offerType && setSelectedOfferValue(newOfferVal);
			return {
				offerVal: newOfferVal,
				benefit_value:
					newOfferValues[newOfferVal] &&
					newOfferValues[newOfferVal].benefit_value,
				effective_price_point:
					newOfferValues[newOfferVal] &&
					newOfferValues[newOfferVal].effective_price_point,
			};
		}
	};

	const editDiscountHandler = () => {
		const { data } = props;

		if (
			!data.scenario_id &&
			!cellData?.isPastEvent
		) {
			return (
				<div className="edit-cell-container">
					<div className="offer-type-container">
						<FormControl className="offer-type-drop-down">
							<Select
								displayEmpty
								autoWidth
								value={selectedOfferType}
								onChange={offerTypeChangeHandler}
								input={<OutlinedInput />}
								id="comment-simple-select"
								inputProps={{ "aria-label": "Without label" }}
							>
								{map(
									Object.keys(offerTypeOptions),
									(option) => (
										<MenuItem key={option} value={option}>
											{offerTypeOptions[option]}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>
					</div>
					<div className="offer-value-container">
						<FormControl className="offer-type-drop-down">
							<Select
								displayEmpty
								autoWidth
								value={selectedOfferValue}
								onOpen={onOfferValueOpen}
								onChange={offerValueChangeHandler}
								input={<OutlinedInput />}
								className=""
								id="comment-simple-select-1"
								inputProps={{ "aria-label": "Without label" }}
							>
								{map(
									Object.keys(offerValueOptions),
									(option) => (
										<MenuItem key={option} value={option}>
											{
												offerValueOptions[option]
													.offer_name
											}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>
					</div>
					{/* {inputFieldFormatter()} */}
				</div>
			);
		} else if (
			!data.scenario_id &&
			cellData?.isPastEvent
		) {
			return (
				<div className="edit-cell-container">
					<div className="offer-type-container">
						<FormControl
							className="offer-type-drop-down"
							style={{
								backgroundColor: "#ededed",
								cursor: "not-allowed",
							}}
						>
							<Select
								displayEmpty
								autoWidth
								value={selectedOfferType}
								onChange={offerTypeChangeHandler}
								input={<OutlinedInput />}
								id="comment-simple-select"
								inputProps={{ "aria-label": "Without label" }}
								disabled={true}
								sx={{ pointerEvents: "none" }}
							>
								{map(
									Object.keys(offerTypeOptions),
									(option) => (
										<MenuItem key={option} value={option}>
											{offerTypeOptions[option]}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>
					</div>
					<div className="offer-value-container">
						<FormControl
							className="offer-type-drop-down"
							style={{
								backgroundColor: "#ededed",
								cursor: "not-allowed",
							}}
						>
							<Select
								displayEmpty
								autoWidth
								value={selectedOfferValue}
								onOpen={onOfferValueOpen}
								onChange={offerValueChangeHandler}
								input={<OutlinedInput />}
								className=""
								id="comment-simple-select-1"
								inputProps={{ "aria-label": "Without label" }}
								disabled={true}
								sx={{ pointerEvents: "none" }}
							>
								{map(
									Object.keys(offerValueOptions),
									(option) => (
										<MenuItem key={option} value={option}>
											{
												offerValueOptions[option]
													.offer_name
											}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>
					</div>
				</div>
			);
		} else {
			return <div className="discount-cell">{selectedOfferValue}</div>;
		}
	};

	return <>{!isEmpty(cellData) && editDiscountHandler()}</>;
};

const mapStateToProps = (store) => {
	return {
		promoValues: store.promo.promoValues,
		lastSavedFilters: store.filters.lastSavedFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountEditCell);
