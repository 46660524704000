export const sortRegions = (regions) => {
    regions.sort((regObj1, regObj2) =>
        regObj1.label < regObj2.label ? -1 : 1
    );
};

export const sortChannels = (channels) => {
    channels.sort((channel1, channel2) =>
        channel1.label < channel2.label ? -1 : 1
    );
};

export const sortMarketOptions = (markets) => {
    markets.sort((market1, market2) =>
        market1.label < market2.label ? -1 : 1
    );
};

export const sortMarkets = (markets) => {
    markets.sort((marketObj1, marketObj2) =>
        marketObj1.market_name < marketObj2.market_name ? -1 : 1
    );
};
