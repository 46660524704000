import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { authConfig } from "../../../utils/firebaseConfig";

const app = firebase.initializeApp({
    apiKey: authConfig.apiKey,
    authDomain: authConfig.authDomain,
});

export const auth = app.auth();
export default app;
export const firebaseobj = firebase;
