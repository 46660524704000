export const data = [
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 1,
      "fiscal_week": 4,
      "weekly_start_date": "02/19/2023",
      "weekly_end_date": "02/25/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 107.4272,
      "finalized_sales": 577.4213,
      "finalized_sales_unit": 447.79425,
      "baseline_sales_units": 447.6134,
      "baseline_revenue": 577.4213,
      "baseline_margin": 107.4272,
      "incremental_sales_units": 0.18078455,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -37547.55,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 49,
      "weekly_start_date": "01/01/2023",
      "weekly_end_date": "01/07/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 262.69223,
      "finalized_sales": 1039.8145,
      "finalized_sales_unit": 442.4742,
      "baseline_sales_units": 404.14188,
      "baseline_revenue": 1010.3546,
      "baseline_margin": 262.69223,
      "incremental_sales_units": 38.332355,
      "incremental_revenue": 29.459751,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -65264.945,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 2,
      "fiscal_week": 5,
      "weekly_start_date": "02/27/2023",
      "weekly_end_date": "02/27/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 74.18543,
      "finalized_sales": 285.32858,
      "finalized_sales_unit": 119.60749,
      "baseline_sales_units": 114.13144,
      "baseline_revenue": 285.32858,
      "baseline_margin": 74.18543,
      "incremental_sales_units": 5.4760504,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -17642.105,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 52,
      "weekly_start_date": "01/22/2023",
      "weekly_end_date": "01/28/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 208.2219,
      "finalized_sales": 1119.1925,
      "finalized_sales_unit": 867.75696,
      "baseline_sales_units": 867.5913,
      "baseline_revenue": 1119.1925,
      "baseline_margin": 208.2219,
      "incremental_sales_units": 0.16571803,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -72761.414,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 1,
      "fiscal_week": 1,
      "weekly_start_date": "01/29/2023",
      "weekly_end_date": "02/04/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 306.5899,
      "finalized_sales": 1185.6527,
      "finalized_sales_unit": 504.53305,
      "baseline_sales_units": 471.67676,
      "baseline_revenue": 1179.1919,
      "baseline_margin": 306.5899,
      "incremental_sales_units": 32.856304,
      "incremental_revenue": 6.4607964,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -74418.625,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 49,
      "weekly_start_date": "01/01/2023",
      "weekly_end_date": "01/07/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 243.42612,
      "finalized_sales": 1308.4154,
      "finalized_sales_unit": 1014.44116,
      "baseline_sales_units": 1014.2753,
      "baseline_revenue": 1308.4154,
      "baseline_margin": 243.42612,
      "incremental_sales_units": 0.16571803,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -85060.89,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 51,
      "weekly_start_date": "01/15/2023",
      "weekly_end_date": "01/20/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 146.50345,
      "finalized_sales": 581.1412,
      "finalized_sales_unit": 247.29411,
      "baseline_sales_units": 225.38991,
      "baseline_revenue": 563.4748,
      "baseline_margin": 146.50345,
      "incremental_sales_units": 21.904202,
      "incremental_revenue": 17.666386,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -36475.883,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 1,
      "fiscal_week": 2,
      "weekly_start_date": "02/05/2023",
      "weekly_end_date": "02/11/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 98.918236,
      "finalized_sales": 531.6855,
      "finalized_sales_unit": 412.30353,
      "baseline_sales_units": 412.15927,
      "baseline_revenue": 531.6855,
      "baseline_margin": 98.918236,
      "incremental_sales_units": 0.14419834,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -34571.65,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 1,
      "fiscal_week": 3,
      "weekly_start_date": "02/12/2023",
      "weekly_end_date": "02/18/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 105.93048,
      "finalized_sales": 569.3762,
      "finalized_sales_unit": 441.5471,
      "baseline_sales_units": 441.37698,
      "baseline_revenue": 569.3762,
      "baseline_margin": 105.93048,
      "incremental_sales_units": 0.17002471,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -37023.715,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 2,
      "fiscal_week": 5,
      "weekly_start_date": "02/26/2023",
      "weekly_end_date": "02/28/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 38.084335,
      "finalized_sales": 204.70331,
      "finalized_sales_unit": 158.71701,
      "baseline_sales_units": 158.68472,
      "baseline_revenue": 204.70331,
      "baseline_margin": 38.084335,
      "incremental_sales_units": 0.032279544,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -13308.42,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 51,
      "weekly_start_date": "01/15/2023",
      "weekly_end_date": "01/21/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 205.578,
      "finalized_sales": 1104.9818,
      "finalized_sales_unit": 856.74506,
      "baseline_sales_units": 856.57495,
      "baseline_revenue": 1104.9818,
      "baseline_margin": 205.578,
      "incremental_sales_units": 0.17002471,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -71838.08,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 32,
      "products": 4,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": 0,
      "targets_sales": 0,
      "targets_sales_unit": 0,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 50,
      "weekly_start_date": "01/08/2023",
      "weekly_end_date": "01/13/2023",
      "ia_projected_gm": 359.2561,
      "ia_projected_sales": 1659.387,
      "ia_projected_sales_unit": 1023.4751,
      "ia_recommended": 1188,
      "finalized_gm": 183.12157,
      "finalized_sales": 984.2784,
      "finalized_sales_unit": 763.1988,
      "baseline_sales_units": 763.00653,
      "baseline_revenue": 984.2784,
      "baseline_margin": 183.12157,
      "incremental_sales_units": 0.19226445,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -37412.008,
      "offer_type": "extra_amount_off",
      "offer_value": "0.5",
      "name": "Promo Sid 2",
      "start_date": "01/08/2023",
      "end_date": "01/13/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 1,
      "fiscal_week": 1,
      "weekly_start_date": "01/29/2023",
      "weekly_end_date": "02/04/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 140.7649,
      "finalized_sales": 756.6113,
      "finalized_sales_unit": 586.68604,
      "baseline_sales_units": 586.5204,
      "baseline_revenue": 756.6113,
      "baseline_margin": 140.7649,
      "incremental_sales_units": 0.16571803,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -49193.637,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 50,
      "weekly_start_date": "01/09/2023",
      "weekly_end_date": "01/14/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 222.45996,
      "finalized_sales": 881.4906,
      "finalized_sales_unit": 375.1024,
      "baseline_sales_units": 342.2461,
      "baseline_revenue": 855.61523,
      "baseline_margin": 222.45996,
      "incremental_sales_units": 32.856304,
      "incremental_revenue": 25.875393,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -55327.6,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 50,
      "weekly_start_date": "01/08/2023",
      "weekly_end_date": "01/14/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 241.07918,
      "finalized_sales": 1295.8007,
      "finalized_sales_unit": 1004.67755,
      "baseline_sales_units": 1004.49664,
      "baseline_revenue": 1295.8007,
      "baseline_margin": 241.07918,
      "incremental_sales_units": 0.18078455,
      "incremental_revenue": 0,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -84242.2,
      "offer_type": "bxgy",
      "offer_value": "B1G2",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2023,
      "fiscal_quarter": 1,
      "fiscal_month": 1,
      "fiscal_week": 3,
      "weekly_start_date": "02/13/2023",
      "weekly_end_date": "02/18/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 301.1803,
      "finalized_sales": 1166.0948,
      "finalized_sales_unit": 496.21063,
      "baseline_sales_units": 463.35428,
      "baseline_revenue": 1158.3859,
      "baseline_margin": 301.1803,
      "incremental_sales_units": 32.856304,
      "incremental_revenue": 7.7091637,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -73191.06,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    },
    {
      "promo_id": 152,
      "products": 5,
      "stores": 1,
      "event_id": 62,
      "event_name": "Event Sid",
      "targets_gm": null,
      "targets_sales": null,
      "targets_sales_unit": null,
      "fiscal_year": 2022,
      "fiscal_quarter": 4,
      "fiscal_month": 12,
      "fiscal_week": 52,
      "weekly_start_date": "01/22/2023",
      "weekly_end_date": "01/28/2023",
      "ia_projected_gm": null,
      "ia_projected_sales": null,
      "ia_projected_sales_unit": null,
      "ia_recommended": null,
      "finalized_gm": 253.22543,
      "finalized_sales": 1005.58826,
      "finalized_sales_unit": 427.90997,
      "baseline_sales_units": 389.57758,
      "baseline_revenue": 973.9441,
      "baseline_margin": 253.22543,
      "incremental_sales_units": 38.332355,
      "incremental_revenue": 31.644386,
      "incremental_margin": 0,
      "performance": null,
      "mark_down_projected_budget": -63116.71,
      "offer_type": "extra_amount_off",
      "offer_value": "1.5",
      "name": "Test Promo Sc Name",
      "start_date": "01/01/2023",
      "end_date": "02/28/2023",
      "status": 2,
      "average_basket_size": null,
      "no_of_txn": null,
      "units_per_txn": null
    }
  ]