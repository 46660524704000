import _, { isEmpty, map, findIndex, cloneDeep } from "lodash";
import { formattersNew } from "./Config";
import { tableNames, screenNames } from "../../../../constants/Constants";
import EditButtonCell from "../../cellRenderers/EditButtonCell";
import DiscountEditCell from "../../cellRenderers/DiscountEditCell";
import EpocEditCell from "../../cellRenderers/EpocEditCell";
import AppendLockIconCell from "../../cellRenderers/LockIconCell";
import { CommentCellRendrer } from "../../cellRenderers/CommentCell";
import { DropdownCellRendrer } from "../../cellRenderers/DropdownCell";
import FinalizeCellEdit from "../../cellRenderers/FinalizeCellEdit";
import EditAndCloneCell from "../../cellRenderers/EditAndCloneCell";
import LockUnlockCell from "../../cellRenderers/LockUnlockCell";
import PromoName from "../../cellRenderers/PromoName";
import { HeroCell } from "../../cellRenderers/HeroCell";
import PerformanceChip from "../../cellRenderers/PerformanceCell";
import TextEditableCell from "../../cellRenderers/TextEditCell";
import TextButtonCell from "../../cellRenderers/TextButtonCell";
import MarketRegionCell from "../../cellRenderers/MarketRegionCell";
import MarketChannelCell from "../../cellRenderers/MarketChannelCell";
import AlertExceptionRegionCell from "../../cellRenderers/AlertExceptionRegionCell";
import AlertExceptionChannelCell from "../../cellRenderers/AlertExceptionChannelCell";
import AlertExceptionEnabledCell from "../../cellRenderers/AlertExceptionEnabledCell";
import AlertExceptionMandatoryCell from "../../cellRenderers/AlertExceptionMandatoryCell";
import PlanGroupRegionCell from "../../cellRenderers/PlanGroupRegionCell";
import PlanGroupChannelCell from "../../cellRenderers/PlanGroupChannelCell";
import PlanGroupBrandCell from "../../cellRenderers/PlanGroupBrandCell";
import PlanGroupMerchDivCell from "../../cellRenderers/PlanGroupMerchDivCell";
import PlanGroupMerchOrgCell from "../../cellRenderers/PlanGroupMerchOrgCell";
import PlanGroupProdCatCell from "../../cellRenderers/PlanGroupProdCatCell";
import PlanGroupProdClassCell from "../../cellRenderers/PlanGroupProdClassCell";
import PlanGroupProdSubclassCell from "../../cellRenderers/PlanGroupProdSubclassCell";
import EventName from "../../cellRenderers/EventName";
import {
    PerformanceFormatter,
    ExceptionFormatter,
    ShowTypeAndValue,
    ScenarioNameRendrer,
    viewByLabelRendrer,
} from "../../cellRenderers/AgGridCellRendrers";

var hiddenCols = {};
// var LYColNames = [];
// var LLYColNames = [];

export const AgGridColumnFormatter = (props) => {
    hiddenCols = {};
    let { screenConfig, from } = props;
    let data = cloneDeep(screenConfig);
    let screen = screenNames[from];
    let table = tableNames[from];
    let tableConfig =
        data[screen] &&
        data[screen].table_config &&
        data[screen].table_config[table];
    // Check for props.undideColumns and change tableConfig accordingly
    if (!_.isEmpty(props.unhideColumns)) {
        const tableConfigUpdated = _.cloneDeep(tableConfig);
        tableConfigUpdated?.table_header.forEach((header, index) => {
            if (props.unhideColumns.includes(header.key)) {
                tableConfigUpdated.table_header[index].hidden = false;
            }
            // Check for nested columns
            if (!_.isEmpty(header.columns)) {
                header.columns.forEach((col, i) => {
                    if (props.unhideColumns.includes(col.key)) {
                        tableConfigUpdated.table_header[index].columns[
                            i
                        ].hidden = false;
                    }
                });
            }
        });
        tableConfig = tableConfigUpdated;
    }
    let tableHeader = tableConfig && tableConfig.table_header;
    let mainColdef = [];
    let extraAgGridProps = {};
    let isDynamicColsPresent = false;
    if (isEmpty(tableConfig) || isEmpty(tableHeader)) return [];
    map(tableHeader, (header, idx) => {
        if (header.type === "static_column") {
            staticColumnHandler({
                header,
                idx,
                extraAgGridProps,
                mainColdef,
                props,
                tableConfig,
                screen,
            });
        } else {
            isDynamicColsPresent = true;

            if (
                (header.key === "timeline" && !isEmpty(props.data)) ||
                (header.key === "date_range" && !isEmpty(props.data))
            ) {
                const rowData = props.data[0];
                let dynamicCols = [];
                for (let key in rowData) {
                    if (
                        key !== "scenario" &&
                        findIndex(tableHeader, { key: key }) === -1
                    ) {
                        let newHeader = {
                            ...header,
                            header: key,
                            key: key,
                        };
                        dynamicCols.push(newHeader);
                    }
                }
                map(dynamicCols, (dynamicHeader, dynamicIdx) => {
                    staticColumnHandler({
                        header: dynamicHeader,
                        idx: dynamicIdx + idx,
                        extraAgGridProps,
                        mainColdef,
                        props,
                        tableConfig,
                        screen,
                    });
                });
            }
        }
    });

    const metricData = metricHandler(tableConfig, hiddenCols);

    console.log("************mainColdef", mainColdef);
    return {
        mainColdef,
        isDynamicColsPresent,
        // LYColNames,
        // LLYColNames,
        extraAgGridProps,
        ...metricData,
    };
};

const staticColumnHandler = ({
    header,
    idx,
    extraAgGridProps,
    mainColdef,
    props,
    tableConfig,
    screen,
}) => {
    if (!isEmpty(header.columns)) {
        let newHeader = columnsHandler({
            ...props,
            column: header,
            dynamicData: null,
            headerIdx: idx,
            extraAgGridProps,
            tableConfig,
            screen,
        });
        mainColdef.push(newHeader);
    } else {
        let additionalProps = {};
        if (header.action) {
            additionalProps = actionHandler({
                props: { ...props },
                extraAgGridProps,
                header,
                tableConfig,
                screen,
                decisionDashboardViewBy: props.decisionDashboardViewBy,
            });
        }
        if (header.row_group) {
            extraAgGridProps.groupDisplayType = "custom"; // "multipleColumns" //"groupRows"; //"singleColumn"; //;
            extraAgGridProps.groupHideOpenParents = true;
            // extraAgGridProps.groupRemoveLowestSingleChildren = true;
            let groupHeader = rowGroupHandler({
                ...props,
                column: header,
                additionalProps,
            });
            mainColdef.push(groupHeader);
        }

        if (header.row_span) {
            additionalProps = rowSpanHandler({
                props: { ...props },
                extraAgGridProps,
            });
        }

        let newHeader = columnHandler({
            ...props,
            column: header,
            additionalProps,
            extraAgGridProps,
        });
        mainColdef.push(newHeader);
    }
};
const actionHandler = ({
    props,
    extraAgGridProps,
    header,
    tableConfig,
    screen,
    decisionDashboardViewBy,
}) => {
    let additionalProps = {};
    if (header.action === "edit_button") {
        additionalProps = {
            cellRenderer: EditButtonCell,
            cellClass: "edit-button-cell",
            width: 330,
            screenName: screen,
            decisionDashboardViewBy,
        };
    } else if (header.action === "locked") {
        additionalProps = {
            cellRenderer: AppendLockIconCell,
            cellClass: "lock-icon-cell",
            width: 200,
            suppressSizeToFit: true,
        };
    } else if (header.action === "lockUnlock") {
        console.log("**********props", props);
        const { handleLockUnlock } = props;
        additionalProps = {
            cellRenderer: LockUnlockCell,
            cellClass: "lock-unlock-icon-cell",
            width: 330,
            suppressSizeToFit: true,
            cellRendererParams: {
                handleLockUnlock,
                checkbox: true,
            },
        };
    } else if (header.action === "performance") {
        additionalProps = {
            cellRenderer: PerformanceChip,
            cellClass: "performanceCell",
            width: 200,
            suppressSizeToFit: true,
        };
    } else if (header.action === "comment") {
        additionalProps = {
            cellRenderer: CommentCellRendrer,
            cellClass: "comment-cell",
        };
    } else if (header.action === "discount_edit") {
        additionalProps = {
            cellRenderer: DiscountEditCell,
            cellClass: "discont-edit-cell",
            minWidth: 200,
            suppressSizeToFit: true,
            offerTypeOptions: props.offerTypes || [],
            screenName: screen,
        };
    } else if (header.action === "epoc_edit") {
        additionalProps = {
            cellRenderer: EpocEditCell,
            screenName: screen,
        };
    } else if (header.action === "select_doorBuster") {
        additionalProps = {
            cellRenderer: DropdownCellRendrer,
            cellClass: "comment-cell",
        };
    } else if (header.action === "finalize_edit") {
        additionalProps = {
            cellRenderer: FinalizeCellEdit,
            minWidth: 200,
            offerTypeOptions: tableConfig.offer_type_options,
            activeTab: props.decisionDashboardActiveTab,
            activeTabLevel: props.decisionDashboardViewBy,
            screenName: screen,
        };
    } else if (header.action === "show_type_and_value") {
        additionalProps = {
            cellRenderer: ShowTypeAndValue,
            screenName: screen,
            offerTypeOptions: tableConfig.offer_type_options,
        };
    } else if (header.action === "promo_name") {
        additionalProps = {
            cellRenderer: PromoName,
            screenName: screen,
        };
    } else if (header.action === "edit_clone_button") {
        additionalProps = {
            cellRenderer: EditAndCloneCell,
            cellClass: "edit-clone-button-cell",
            editGroupHandler: props.editGroupHandler,
        };
    } else if (header.action === "hero_sku") {
        additionalProps = {
            cellRenderer: HeroCell,
            cellClass: "hero-sku-cell",
        };
    } else if (header.action === "event_name") {
        additionalProps = {
            cellRenderer: EventName,
            cellClass: "event-name",
        };
    } else if (header.action === "textButton") {
        console.log("**********props", props);
        const { handleButtonClick } = props;
        additionalProps = {
            cellRenderer: TextButtonCell,
            cellClass: "text-button",
            cellRendererParams: {
                handleButtonClick,
            },
        };
    } else if (header.action === "market_region") {
        additionalProps = {
            cellRenderer: MarketRegionCell,
        };
    } else if (header.action === "market_channel") {
        additionalProps = {
            cellRenderer: MarketChannelCell,
        };
    } else if (header.action === "alert_exception_region") {
        additionalProps = {
            cellRenderer: AlertExceptionRegionCell,
        };
    } else if (header.action === "alert_exception_channel") {
        additionalProps = {
            cellRenderer: AlertExceptionChannelCell,
        };
    } else if (header.action === "alert_exception_enabled") {
        additionalProps = {
            cellRenderer: AlertExceptionEnabledCell,
        };
    } else if (header.action === "alert_exception_mandatory") {
        additionalProps = {
            cellRenderer: AlertExceptionMandatoryCell,
        };
    } else if (header.action === "plan_group_region") {
        additionalProps = {
            cellRenderer: PlanGroupRegionCell,
        };
    } else if (header.action === "plan_group_channel") {
        additionalProps = {
            cellRenderer: PlanGroupChannelCell,
        };
    } else if (header.action === "plan_group_brand") {
        additionalProps = {
            cellRenderer: PlanGroupBrandCell,
        };
    } else if (header.action === "plan_group_merch_div") {
        additionalProps = {
            cellRenderer: PlanGroupMerchDivCell,
        };
    } else if (header.action === "plan_group_merch_org") {
        additionalProps = {
            cellRenderer: PlanGroupMerchOrgCell,
        };
    } else if (header.action === "plan_group_prod_cat") {
        additionalProps = {
            cellRenderer: PlanGroupProdCatCell,
        };
    } else if (header.action === "plan_group_prod_class") {
        additionalProps = {
            cellRenderer: PlanGroupProdClassCell,
        };
    } else if (header.action === "plan_group_prod_subclass") {
        additionalProps = {
            cellRenderer: PlanGroupProdSubclassCell,
        };
    }

    return additionalProps;
};

const columnsHandler = (props) => {
    let { column, dynamicData, extraAgGridProps, tableConfig } = props;
    let mainCol = [];
    let parentHeader = dynamicData ? dynamicData.header : column.header;
    map(column.columns, (header) => {
        let coldef = [];
        if (!isEmpty(header.columns)) {
            coldef = columnsHandler({
                ...props,
                column: header,
                dynamicData: null,
            });
        } else if (!isEmpty(header.action)) {
            let additionalProps = actionHandler({
                props: { ...props },
                extraAgGridProps,
                header,
                tableConfig,
                screen: props.screen,
            });
            coldef = columnHandler({
                ...props,
                column: header,
                additionalProps,
            });
        } else {
            coldef = columnHandler({
                ...props,
                column: header,
                parentHeader,
                dynamicData,
            });
        }
        mainCol.push(coldef);
    });

    if (
        props.decisionDashboardViewBy &&
        props.decisionDashboardViewBy.columns_to_show &&
        props.decisionDashboardViewBy.columns_to_show.includes(
            "decisions_reg_actual_current_discount"
        ) &&
        findIndex(column.columns, {
            key: "decisions_reg_current_discount",
        }) !== -1
    ) {
        parentHeader = "Effective Discounts (Decisions for upcoming PCD)";
    }
    return {
        headerName: parentHeader,
        children: mainCol,
    };
};

const columnHandler = (props) => {
    let {
        column,
        dynamicData,
        additionalProps,
        extraAgGridProps,
        headerMinWidth,
    } = props;
    if (
        column.hidden &&
        props.decisionDashboardViewBy &&
        (props.decisionDashboardViewBy.label === column.header ||
            (props.decisionDashboardViewBy.columns_to_show &&
                props.decisionDashboardViewBy.columns_to_show.includes(
                    column.key
                )))
    ) {
        column.hidden = false;
    }
    if (column.hidden) {
        hiddenCols[column.key] = 1;
    }
    // if (column.header === "LY") {
    // 	LYColNames.push(column.key);
    // }
    // if (column.header === "LLY") {
    // 	LLYColNames.push(column.key);
    // }
    let accessor = dynamicData ? dynamicData.key + column.key : column.key;
    let valueFormatter =
        formattersNew[column.key] || formattersNew.fallbackFormatter;

    if (isEmpty(additionalProps)) {
        if (isEmpty(extraAgGridProps.frameworkComponents)) {
            extraAgGridProps.frameworkComponents = {};
        }
        if (accessor === "performance") {
            additionalProps = {
                cellRenderer: PerformanceFormatter,
            };
        } else if (accessor === "exception" || accessor === "alert_status") {
            additionalProps = {
                cellRenderer: ExceptionFormatter,
            };
        } else if (accessor === "view_by_name" && !column.action) {
            additionalProps = {
                cellRenderer: viewByLabelRendrer,
            };
        }
    }
    if (
        accessor === "decisions_reg_finalized" ||
        accessor === "decisions_reg_actual_finalized" ||
        accessor === "decisions_first_finalized" ||
        accessor === "decisions_further_finalized" ||
        accessor === "decisions_epoc_finalized" ||
        accessor === "decisions_pricing_finalized" ||
        accessor === "decisions_first_actual_finalized" ||
        accessor === "decisions_further_actual_finalized"
    ) {
        additionalProps = {
            ...additionalProps,
            cellClass: "decisions-finalized-cell",
        };
    }
    if (column.row_group) {
        return {
            field: accessor,
            rowGroup: column.row_group,
            hide: true,
            keepAlwaysHidden: true,
        };
    }
    let newColdef = {
        headerName: column.header,
        field: accessor,
        filter: column.filter
            ? column.ag_filter_type || "agTextColumnFilter"
            : false,
        sortable: column.sorting,
        pinned: column.pinned,
        lockPinned: column.lockPinned,
        hide: column.hidden,
        aggFunc: column.aggregate,
        valueFormatter,
        autoHeight: true,
        editable: false,
        minWidth: headerMinWidth,
        default_sort: column.default_sort,
        unSortIcon: true,
        ...additionalProps,
    };
    if (column.filter) {
        let defaultFilterParams = {
            defaultOption: column.filter_default
                ? column.filter_default
                : "contains",
            defaultFilterText: column.filter_default_text
                ? column.filter_default_text
                : null,
            suppressAndOrCondition: true,
            buttons: ["reset"],
            setFilterByDefault: column.filter_default ? true : false,
        };
        if (formattersNew[column.key]) {
            defaultFilterParams = {
                textMatcher: (params) => {
                    const { filterOption, value, filterText, colDef } = params;
                    const filterTextLowerCase = filterText.toLowerCase();
                    const formattedVal = colDef.valueFormatter({
                        value,
                        skipSignAddition: true,
                    });
                    const valueLowerCase = formattedVal
                        .toString()
                        .toLowerCase();

                    switch (filterOption) {
                        case "contains":
                            return (
                                valueLowerCase.indexOf(filterTextLowerCase) >= 0
                            );
                        case "notContains":
                            return (
                                valueLowerCase.indexOf(filterTextLowerCase) ===
                                -1
                            );
                        case "equals":
                            return valueLowerCase === filterTextLowerCase;
                        case "notEqual":
                            return valueLowerCase != filterTextLowerCase;
                        case "startsWith":
                            return (
                                valueLowerCase.indexOf(filterTextLowerCase) ===
                                0
                            );
                        case "endsWith":
                            let index =
                                valueLowerCase.lastIndexOf(filterTextLowerCase);
                            return (
                                index >= 0 &&
                                index ===
                                    valueLowerCase.length -
                                        filterTextLowerCase.length
                            );
                        default:
                            // should never happen
                            console.warn("invalid filter type " + filterOption);
                            return false;
                    }
                },
                ...defaultFilterParams,
            };
        }
        newColdef = {
            ...newColdef,
            filterParams: defaultFilterParams,
        };
    }
    if (column.width) {
        newColdef.width = column.width;
    }
    if (column.filterValueGetter) {
        newColdef.filterValueGetter = column.filterValueGetter;
    }
    if (column.cellStyle) {
        newColdef.cellStyle = column.cellStyle;
    }
    if (
        column.pinned &&
        (accessor === "view_by_code" ||
            accessor === "is_locked" ||
            accessor === "clearance_lifecycle" ||
            accessor === "performance" ||
            accessor === "exception")
    ) {
        newColdef.minWidth = 50;
        newColdef.width = 120;
    }
    if (column.pinned && accessor === "product_bins") {
        newColdef.minWidth = 50;
        newColdef.width = 90;
    }
    if (accessor === "event") {
        newColdef.maxWidth = 85;
        newColdef.minWidth = 80;
    }
    if (accessor === "epoc_metric") {
        newColdef.maxWidth = 130;
    }
    if (accessor === "name" && column.header === "Item Group Name") {
        newColdef.minWidth = 200;
    }
    if (accessor === "code" || accessor === "alert_status") {
        newColdef.minWidth = 130;
    }
    if (accessor === "name" && column.header === "Style description") {
        newColdef.minWidth = 300;
    }

    if (column.checkbox_selection && !column.row_group) {
        newColdef = {
            ...newColdef,
            headerCheckboxSelection:
                column.checkbox_selection && !props.hideHeaderCheckbox,
            headerCheckboxSelectionFilteredOnly: column.checkbox_selection,
            checkboxSelection:
                column.checkbox_selection && !props.disableCheckbox,
        };
    }

    if (additionalProps && additionalProps.cellRenderer) {
        newColdef.cellStyle = {
            // lineHeight: "33px",
            // paddingTop: "5px",
            // paddingBottom: "5px",
        };
    }
    return newColdef;
};

const rowGroupHandler = (props) => {
    let { column, additionalProps, dynamicData, hideRowCollapseIcon } = props;
    let accessor = dynamicData ? dynamicData.key + column.key : column.key;
    // let valueFormatter = formattersNew[column.key] || formattersNew.fallbackFormatter;
    if (
        column.hidden &&
        props.decisionDashboardViewBy &&
        (props.decisionDashboardViewBy.label === column.header ||
            (props.decisionDashboardViewBy.columns_to_show &&
                props.decisionDashboardViewBy.columns_to_show.includes(
                    column.key
                )))
    ) {
        column.hidden = false;
    }
    if (column.hidden) {
        hiddenCols[column.key] = 1;
    }
    let newColdef = {
        headerName: column.header,
        showRowGroup: accessor,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: {
            suppressCount: true, //The count of each row group is removed
            checkbox: column.checkbox_selection,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true,
            sortable: column.sorting,
        },
        headerCheckboxSelection:
            column.checkbox_selection && !props.hideHeaderCheckbox,
        sortable: column.sorting,
        checkbox_selection: column.checkbox_selection,
        pinned: column.pinned,
        lockPinned: column.lockPinned,
        hide: column.hidden,
        // valueFormatter,
        // autoHeight: true,
        minWidth: 200,
        cellClass: hideRowCollapseIcon ? "hide-row-collapse-icon" : "",
        ...additionalProps,
    };
    if (column.key === "scenario") {
        newColdef.cellRendererParams.innerRenderer = ScenarioNameRendrer;
    }
    if (column.action === "inlineEditCell") {
        newColdef.cellRendererParams.innerRenderer = TextEditableCell;
        newColdef.cellRendererParams.handleInlineEdit = props.handleInlineEdit;
        newColdef.minWidth = 300;
    }

    return newColdef;
};

const metricHandler = (tableConfig, hiddenColumns) => {
    let addMetrics = (tableConfig && tableConfig.add_metrics_new) || [];
    let hiddenColsCopy = { ...hiddenColumns };
    const metricsConfig = {};
    let selectedMetrics = [];
    let selectedLabels = [];

    for (var i = 0, len = addMetrics.length; i < len; i++) {
        const item = addMetrics[i];
        //add metrics name as key and store all objects that falls under that metric in an array
        if (
            metricsConfig[item.metric_name] &&
            metricsConfig[item.metric_name].options
        ) {
            metricsConfig[item.metric_name].options.push(item);
        } else
            metricsConfig[item.metric_name] = {
                type: item.metric_type,
                label: item.metric_name,
                options: [item],
            };

        //if columns are hidden by default, then don't add them to selectedMetrics and selectedLabels
        let isSelected = true;
        if (!isEmpty(hiddenColsCopy)) {
            for (
                var j = 0, optionLen = item.columns_included.length;
                j < optionLen;
                j++
            ) {
                const colKey = item.columns_included[j];
                // if columns is hidden then don't add it to selectedMetrics
                if (hiddenColsCopy[colKey]) {
                    isSelected = false;
                    delete hiddenColsCopy[colKey];
                    break;
                }
            }
        }
        if (isSelected) {
            //add item to selectedMetric array
            selectedMetrics.push(item);
            //add metric name to selectedLabels array if it doesn't already exists
            if (selectedLabels.indexOf(item.metric_name) === -1)
                selectedLabels.push(item.metric_name);
        }
    }
    return { metricsConfig, selectedMetrics, selectedLabels };
};

const rowSpanHandler = (args) => {
    let additionalProps = {
        rowSpan: args.props.handleRowSpan,
        cellClassRules: {
            "show-cell": "value !== undefined",
        },
    };

    return additionalProps;
};

// const metricHandlerOld = (tableConfig) => {
// 	let addMetrics = tableConfig && tableConfig.add_metrics;
// 	let addMetrics1 = tableConfig && tableConfig.add_metrics_new;
// 	let tableHeader = tableConfig && tableConfig.table_header;
// 	let labels = [];
// 	let columnOptions = [];
// 	const metrics_config = {};

// 	for (let key in addMetrics) {
// 		if (key !== "fixed") {
// 			labels.push(key);
// 			let metricOptions = addMetrics[key];

// 			for (let metric in metricOptions) {
// 				if (metric === "lw_metrices") {
// 					let metricList = null;
// 					map(tableHeader, (column) => {
// 						let colKey = column.key;
// 						if (
// 							colKey &&
// 							colKey.includes("lw_metrices") &&
// 							!colKey.includes("current_md")
// 						) {
// 							metricList = !metricList
// 								? colKey
// 								: metricList + ", " + colKey;
// 						}
// 					});
// 					columnOptions.push({
// 						label: metricOptions[metric],
// 						value: metricList,
// 						metricName: key,
// 					});
// 				} else {
// 					columnOptions.push({
// 						label: metricOptions[metric],
// 						value: metric,
// 						metricName: key,
// 					});
// 				}
// 			}
// 		}
// 	}
// 	for (var i = 0, len = addMetrics1.length; i < len; i++) {
// 		const item = addMetrics1[i];
// 		metrics_config[item.metric_name] = item;
// 	}
// 	return {
// 		labels,
// 		columnOptions,
// 	};
// };
