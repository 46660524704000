import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import {
	setRetainFilters,
	setLastSelectedFiltersForUser,
} from "../../../store/features/filters/filters";

const EditAndCloneCell = (props) => {
	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	const buttonClicked = (action) => {
		props.colDef &&
			props.colDef.editGroupHandler({
				itemGroupID: cellValue,
				action: action,
			});
	};
	const renderCellData = () => {
		if (cellValue === undefined) return "";
		if (cellValue) {
			return (
				<div>
					<span className="edit-clone-text" title={cellValue}>
						{cellValue}
					</span>
					&nbsp;
					{props.value && (
						<>
							<Button
								variant="outlined"
								sx={{ fontSize: "12px" }}
								className="edit-button"
								onClick={() => buttonClicked("edit")}
							>
								<BorderColorOutlinedIcon
									className="edit-button-icon"
									sx={{
										fontSize: "12px",
										minWidth: "20px",
										height: "0.8rem",
									}}
								/>
							</Button>
							<Button
								variant="outlined"
								sx={{ fontSize: "12px" }}
								className="clone-button"
								onClick={() => buttonClicked("clone")}
							>
								<ContentCopyIcon
									className="clone-button-icon"
									sx={{
										fontSize: "12px",
										minWidth: "20px",
										height: "0.8rem",
									}}
								/>
							</Button>
						</>
					)}
				</div>
			);
		}
		return "-";
	};

	return <>{renderCellData()}</>;
};

const mapStateToProps = (store) => {
	return {
		lastSavedFilters: store.filters.lastSavedFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRetainFilters: (data) => dispatch(setRetainFilters(data)),
		setLastSelectedFiltersForUser: (data) =>
			dispatch(setLastSelectedFiltersForUser(data)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAndCloneCell);
