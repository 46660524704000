import React from "react";
import _ from "lodash";
import LockIcon from "@mui/icons-material/Lock";

export default (props) => {
	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	return (
		<>
			<span>{cellValue} </span>
			{!_.isEmpty(props.data) && props.data.isLocked && (
				<LockIcon
					sx={{ fontSize: 14, position: "relative", top: "2px" }}
				/>
			)}
		</>
	);
};
