import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
	DateRangePicker,
	isInclusivelyBeforeDay,
	isInclusivelyAfterDay,
	SingleDatePicker,
} from "react-dates";
import moment from "moment";
import { NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER } from "../../../constants/Constants";
import "./DateRangePicker.scss";

const DateRangePickerComponent = (props) => {
	const [focusedInput, setfocusedInput] = useState(null);
	const [type, setType] = useState(props.type || "range");
	const [yearArray, setyearArray] = useState([moment().year()]);
	const [focused, setFocused] = useState(null);
	const configureYear = () => {
		let yearArr = [moment().year()];
		for (let i = 0; i < NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER; i++) {
			if (props.disableType && props.disableType.includes("Future")) {
				yearArr.push(yearArr[i] - 1);
			} else {
				yearArr.push(yearArr[i] + 1);
			}
		}
		if (props.startYear) {
			// Start year from 2019 incase of cluster plan
			let startYear = props.startYear;
			let pastYearArray = [];
			while (startYear < yearArr[0]) {
				pastYearArray.push(startYear);
				startYear++;
			}
			yearArr.unshift(...pastYearArray);
		}
		setyearArray(yearArr);
	};

	const checkStartEndRanges = (day) => {
		/**
		 * This method checks what dates to disable along with the PAST/FUTURE disabled conditions
		 * Once you select start date and end date, dates before of start date and dates after end
		 * date would be disabled
		 */
		if (props.startDate && props.endDate) {
			return (
				!isInclusivelyBeforeDay(day, props.endDate) ||
				!isInclusivelyAfterDay(day, props.startDate)
			);
		}
		if (props.startDate) {
			return !isInclusivelyAfterDay(day, props.startDate);
		}
		if (props.endDate) {
			return !isInclusivelyBeforeDay(day, props.endDate);
		}
		return false;
	};

	const isOutsideRange = (day) => {
		switch (props.disableType) {
			case "disableOnlyPast":
				//disables strictly past i.e current/today's date is not included
				return moment(day).isBefore() ? true : false;
			case "disablePast":
				//disables past i.e current/today's date is included
				return moment(day).isSame(moment(), "days") ||
					moment(day).isBefore()
					? true 
					: false;
					// : checkStartEndRanges(day);
			case "disableOnlyFuture":
				//disables strictly future i.e current/today's date is not included
				return moment(day).isAfter() ? true : checkStartEndRanges(day);
			case "disableFuture":
				//disables future i.e current/today's date is included
				return moment(day).isSame(moment(), "days") ||
					moment(day).isAfter()
					? true
					: checkStartEndRanges(day);
			case "customRange":
				//we can pass custom range which evaluates if the date to be disabled or not
				//For example: disabling specific dates or only fridays etc..
				return moment(day).isSameOrAfter(props.minDate, "days") &&
					moment(day).isSameOrBefore(props.maxDate, "days")
					? false
					: true;
			default:
			//By default, it doesn't disable any date
			// return checkStartEndRanges(day);
			// Do nothing
		}
	};

	const isDayBlocked = (date) => {
		if (focusedInput === "startDate") {
			return (
				props.enabledStartDays &&
				!props.enabledStartDays.includes(moment(date).format("dddd"))
			);
		}
		if (focusedInput === "endDate") {
			return (
				props.enabledEndDays &&
				!props.enabledEndDays.includes(moment(date).format("dddd"))
			);
		}
		return false;
	};

	useEffect(() => {
		configureYear();
	}, []);

	const onFocusChange = (focused) => {
		setFocused(focused);
	};

	const onDatesChange = (startDate, endDate) => {
		props.onDatesChange(startDate, endDate);
	};

	return (
		<>
			{type == "range" && (
				<div id={"dateRangePicker"}>
					<DateRangePicker
						startDatePlaceholderText="Start Date"
						endDatePlaceholderText="End Date"
						startDateId={props.startDateId || "startDate"}
						endDateId={props.endDateId || "endDate"}
						startDate={props.startDate}
						endDate={props.endDate}
						displayFormat={props.dateFormat}
						onDatesChange={({ startDate, endDate }) => {
							onDatesChange(startDate, endDate);
						}}
						focusedInput={focusedInput}
						onFocusChange={(focusedInp) => {
							setfocusedInput(focusedInp);
						}}
						showDefaultInputIcon
						inputIconPosition="ICON_AFTER_POSITION"
						hideKeyboardShortcutsPanel
						firstDayOfWeek={1}
						isOutsideRange={isOutsideRange}
						minimumNights={0}
						readOnly
						// small
						// autoFocus
						// withPortal={!props.noPortal}
						// preventScroll
						// onClose={props.onClose}
						disabled={props.disabled}
						// isDayBlocked={isDayBlocked}
						// hideKeyboardShortcutsPanel
						// numberOfMonths={props.numberOfMonths || 2} //default number of months is 2 else it can be passed from props
					/>
				</div>
			)}
			{type == "day" && (
				<div id={"dateRangePicker"}>
					<SingleDatePicker
						onOutsideClick={true}
						numberOfMonths={1}
						date={props.date} // momentPropTypes.momentObj or null
						onDateChange={(date) => props.onDateChange(date)} // PropTypes.func.isRequired
						focused={focused} // PropTypes.bool
						onFocusChange={({ focused }) => setFocused(focused)}
						id={props.id || "day_picker"} // PropTypes.string.isRequired,
						disabled={props.disabled || false}
						// displayFormat={"MM/DD/YYYY"}
						showClearDate={true}
						isOutsideRange={isOutsideRange}
						readOnly
						showDefaultInputIcon
						inputIconPosition="after"
						hideKeyboardShortcutsPanel
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, null)(DateRangePickerComponent);
