import React from "react";
import _ from "lodash";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { color } from "highcharts";
import "../tableRenderer/AgGridTable.scss"

export default (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    const handleClick = () => {
        console.log("*********props", props);
       
        props.handleLockUnlock(props.data);
    }

    return (
        <div className="event-name-container">
            <span>{cellValue} </span>
            {!_.isEmpty(props.data) && (props.data.is_locked ? <LockOutlinedIcon
                sx={{ color: "#75849080" }}
                onClick={handleClick}
                /> :
                <LockOpenOutlinedIcon
                sx={{ color: "#758490" }}
                onClick={handleClick}
                />)}
        </div>
    );
};