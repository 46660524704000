import { createSlice } from "@reduxjs/toolkit";

import { API } from "../../../utils/axios/index";
import {
    requestComplete,
    requestFail,
    requestStart,
    toastError,
    toastMessage,
} from "../global/global";

const initialState = {
    exceptions: [],
};

const exceptionSlice = createSlice({
    name: "exception",
    initialState,
    reducers: {
        setExceptions(state, action) {
            state.exceptions = action.payload;
        },
    },
});

function sortExceptions(exceptions) {
    exceptions.sort((exception1, exception2) => {
        if (exception1.region.label !== exception2.region.label) {
            return exception1.region.label.toLowerCase() <
                exception2.region.label.toLowerCase()
                ? -1
                : 1;
        }

        if (exception1.channel.label !== exception2.channel.label) {
            return exception1.channel.label.toLowerCase() <
                exception2.channel.label.toLowerCase()
                ? -1
                : 1;
        }

        return exception1.name.toLowerCase() < exception2.name.toLowerCase()
            ? -1
            : 1;
    });
}

export const { setExceptions } = exceptionSlice.actions;

export default exceptionSlice.reducer;

export const fetchExceptions = () => (dispatch) => {
    dispatch(requestStart());

    return API.get("/alert-exceptions/2")
        .then((response) => {
            const { data: exceptions } = response.data;

            sortExceptions(exceptions);

            dispatch(requestComplete());
            dispatch(setExceptions(exceptions));

            return exceptions;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Exceptions fetch failed";

            dispatch(toastError(message));
        });
};

export const editExceptions = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/edit_alert_exceptions_config", data)
        .then(() => {
            dispatch(
                requestComplete({
                    success: true,
                    successMessage: "Exceptions edited successfully.",
                })
            );

            // return dispatch(fetchExceptions());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Exceptions edit failed";

            dispatch(requestFail(message));
        });
};
