import StarIcon from "@mui/icons-material/Star";

export const HeroCell = (props) => {
	const handleClick = () => {
		props.setValue(!props.value);
	};

	return (
		<>
			<StarIcon
				onClick={handleClick}
				className={`hero-sku ${props.value ? "yellow" : ""}`}
			/>
		</>
	);
};
