import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CustomAccordion.scss";

const CustomAccordion = (props) => {
    const {
        children,
        label,
        defaultExpanded = true,
        expanded = true,
        handleAccordion,
        childProps,
        titleProps,
    } = props;

    return (
        <div>
            <Accordion
                defaultExpanded={defaultExpanded}
                disableGutters
                className="accordion-container"
                expanded={handleAccordion ? expanded : undefined}
                onChange={handleAccordion}
                sx={{
                    "&:before": {
                        display: "none",
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordion-header"
                    id={props.id ? props.id : "label"}
                    {...titleProps}
                >
                    <p className="accordion-title">{label}</p>
                </AccordionSummary>
                <AccordionDetails
                    style={{ padding: 0 }}
                    {...childProps}
                    className={`accordion-content ${
                        childProps?.className || ""
                    }`}
                >
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default CustomAccordion;
