import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { AgGridReact } from "ag-grid-react";
import { cloneDeep, isEmpty } from "lodash";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import emptyStateImage from "../../../../assets/images/Empty State.png";
import { toastError } from "../../../../store/features/global/global.js";
import { getMarketNames } from "../../../../store/features/market/market";
import { useLazyMainDataQuery } from "../../../../store/features/workbench/planningScreen.js";
import {
    getColumnSettings,
    userUpdateColumnSettings,
} from "../../../../store/features/workbench/workbench.js";
import CustomAccordion from "../../../common/customAccordian/CustomAccordion.jsx";
import MultiSelect from "../../../common/filters/Select/Select.jsx";
import FlatFilter from "../../../common/flatFilter/FlatFilter";
import TableRowCount from "../../../common/tableRowCount/TableRowCount.jsx";
import useTableRowCount from "../../../common/tableRowCount/useTableRowCount.jsx";
import LoadingOverlay from "../../../ui/loader/Loader.jsx";
import TableSettings, { tableSettingTabIds } from "../TableSettings.jsx";
import { getMaterialFetchData } from "../euHelper";
import pageStyles from "./EUPlanningRollUps.module.css";
import FilterShowcaseBar from "./FilterShowcaseBar.jsx";
import {
    MARKETS_TYPE,
    SUPER_SEASONS,
    calculateTyVsLyData,
    changeMetrics,
    checkRowIsGrandTotal,
    costUsedList,
    domUpdateForgrandTotal,
    downloadHandler,
    getPriceKey,
    getUnitsFieldKey,
    getValueForSumAgg,
    gridOptions,
    metricVersionOptions,
    setSelectedItemInList,
    updateColumnGroupColor,
    useRowData,
    valueFormatterPivotTable,
    vistLeafNodesColumns,
} from "./utils";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "0 0.7rem 0 0",
    textAlign: "left",
    boxShadow: "none",
}));
export const page = "filterByAttributes";

const getMarketNameAndYear = (superSeasonName, season, year) => {
    const marketName = season?.toLowerCase().includes(MARKETS_TYPE.PRE)
        ? yearsAndMarkets.markets[superSeasonName]?.[MARKETS_TYPE.PRE]
        : yearsAndMarkets.markets[superSeasonName]?.[MARKETS_TYPE.MAIN];
    const marketNameWithYear = marketName + " " + year;
    return { marketName, marketNameWithYear };
};

const getFilteredList = (params, isParent) => {
    let list = [];
    if (checkRowIsGrandTotal(params)) {
        list = rowData.map((item) => ({ data: item }));
    } else if (isParent) {
        if (params.rowNode.level === 0) {
            list = rowData.map((item) => ({ data: item }));
        } else {
            list = params.rowNode?.parent?.allLeafChildren || [];
        }
    } else {
        list = params.rowNode.allLeafChildren;
    }

    return list;
};

const getAllFourMarketFilteredList = (params, isParent) => {
    const { year } = params.colDef.cellRendererParams;
    let filterList = [];

    const preSpringMarketObj = getMarketNameAndYear(
        SUPER_SEASONS.SPRING,
        MARKETS_TYPE.PRE,
        year
    );

    const spingMarketObj = getMarketNameAndYear(
        SUPER_SEASONS.SPRING,
        MARKETS_TYPE.MAIN,
        year
    );

    const preFallMarketObj = getMarketNameAndYear(
        SUPER_SEASONS.FALL,
        MARKETS_TYPE.PRE,
        year
    );

    const fallMarketObj = getMarketNameAndYear(
        SUPER_SEASONS.FALL,
        MARKETS_TYPE.MAIN,
        year
    );

    let list = getFilteredList(params, isParent);

    [
        preSpringMarketObj,
        spingMarketObj,
        preFallMarketObj,
        fallMarketObj,
    ].forEach((marketObj) => {
        filterList = [
            ...filterList,
            ...(list
                ?.filter((item) => marketObj.marketNameWithYear in item.data)
                ?.map((item) => {
                    return item.data[marketObj.marketNameWithYear][year];
                }) || []),
        ];
    });

    return filterList;
};

const getSuperSeasonFilteredList = (params, isParent) => {
    const { superSeasonName, year } = params.colDef.cellRendererParams;
    let filterList = [];

    let list = getFilteredList(params, isParent);

    const preMarketObj = getMarketNameAndYear(
        superSeasonName,
        MARKETS_TYPE.PRE,
        year
    );
    const mainMarketNameObj = getMarketNameAndYear(
        superSeasonName,
        MARKETS_TYPE.MAIN,
        year
    );

    [preMarketObj, mainMarketNameObj].forEach((marketObj) => {
        filterList = [
            ...(filterList || []),
            ...(list
                ?.filter((item) => marketObj.marketNameWithYear in item.data)
                ?.map((item) => {
                    return item.data[marketObj.marketNameWithYear][year];
                }) || []),
        ];
    });

    return filterList;
};

const calcFilterParentList = (
    params,
    isFromSuperSeasonTotal,
    season,
    marketNameWithYear,
    year,
    calcParentAlso = true
) => {
    let parentList = [];
    let filterList = [];

    if (isFromSuperSeasonTotal) {
        filterList = getAllFourMarketFilteredList(params);
        if (calcParentAlso)
            parentList = getAllFourMarketFilteredList(params, true);
    } else if (season === "Total") {
        filterList = getSuperSeasonFilteredList(params);
        if (calcParentAlso)
            parentList = getSuperSeasonFilteredList(params, true);
    } else {
        filterList = getFilteredList(params, false)
            ?.filter((item) => marketNameWithYear in item.data)
            .map((item) => {
                return item.data[marketNameWithYear][year];
            });

        if (calcParentAlso)
            parentList = getFilteredList(params, true)
                ?.filter((item) => marketNameWithYear in item.data)
                .map((item) => {
                    return item.data[marketNameWithYear][year];
                });
    }

    return [filterList, parentList];
};

const sumAggregate = (params) => {
    const {
        season,
        year,
        timePeriod,
        marketNameWithYear,
        isFromSuperSeasonTotal,
        superSeasonName,
    } = params.colDef.cellRendererParams;

    let [filterList] = calcFilterParentList(
        params,
        isFromSuperSeasonTotal,
        season,
        marketNameWithYear,
        year,
        false
    );

    const thisYear = filterList.reduce((a, b) => {
        return a + getValueForSumAgg(params, b);
    }, 0);

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(
                    superSeasonName,
                    season,
                    yearsAndMarkets.years.ly
                ),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const marginPercAggregate = (params) => {
    const { season, timePeriod, superSeasonName } =
        params.colDef.cellRendererParams;

    const marginSum = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                metricUnit: "margin",
                timePeriod: "ty",
            },
        },
    });

    const salesSum = sumAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                metricUnit: "sales",
                timePeriod: "ty",
            },
        },
    });

    const marginPercThis = (marginSum / salesSum) * 100;

    if (timePeriod !== "tyVly") return marginPercThis;

    const marginPercLast = marginPercAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(
                    superSeasonName,
                    season,
                    yearsAndMarkets.years.ly
                ),
            },
        },
    });

    return (marginPercThis - marginPercLast) * 100;
};

const AICAggregate = (params) => {
    const {
        season,
        year,
        typeOfUnit,
        levelOfUnit,
        timePeriod,
        marketNameWithYear,
        isFromSuperSeasonTotal,
        superSeasonName,
    } = params.colDef.cellRendererParams;

    const unitsFieldKey = getUnitsFieldKey(levelOfUnit, typeOfUnit);

    let [filterList] = calcFilterParentList(
        params,
        isFromSuperSeasonTotal,
        season,
        marketNameWithYear,
        year,
        false
    );

    const unitSumWithCost = filterList.reduce((a, b) => {
        const cost = b?.calculatedCost || 0;
        const unit = b?.[unitsFieldKey] || 0;
        return a + cost * unit;
    }, 0);

    const unitSumOfCost = filterList.reduce((a, b) => {
        const unit = b?.[unitsFieldKey] || 0;
        return a + unit;
    }, 0);

    const thisYear = unitSumWithCost / unitSumOfCost;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = AICAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(
                    superSeasonName,
                    season,
                    yearsAndMarkets.years.ly
                ),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const mixAggregate = (params) => {
    const {
        season,
        marketNameWithYear,
        typeOfUnit,
        levelOfUnit,
        timePeriod,
        year,
        isFromSuperSeasonTotal,
        superSeasonName,
    } = params.colDef.cellRendererParams;

    if (checkRowIsGrandTotal(params)) {
        if (timePeriod === "tyVly") {
            return 0;
        }

        return 100;
    }

    let [filterList, parentList] = calcFilterParentList(
        params,
        isFromSuperSeasonTotal,
        season,
        marketNameWithYear,
        year,
        true
    );

    const priceKey = getPriceKey(levelOfUnit, selectedChannel);
    const unitsFieldKey = getUnitsFieldKey(levelOfUnit, typeOfUnit);

    const unitSumWithPriceOneLevelHigher = parentList.reduce(
        (a, b) => a + (b?.[unitsFieldKey] * b?.[priceKey] || 0),
        0
    );

    const unitSumWithPrice = filterList.reduce((a, b) => {
        const price = b?.[priceKey] || 0;
        const unit = b?.[unitsFieldKey] || 0;
        return a + price * unit;
    }, 0);

    const thisYear = (unitSumWithPrice / unitSumWithPriceOneLevelHigher) * 100;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = mixAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(
                    superSeasonName,
                    season,
                    yearsAndMarkets.years.ly
                ),
            },
        },
    });

    return (thisYear - lastYear) * 100;
};

const AIPAggregate = (params) => {
    const {
        season,
        marketNameWithYear,
        typeOfUnit,
        levelOfUnit,
        timePeriod,
        year,
        isFromSuperSeasonTotal,
        superSeasonName,
    } = params.colDef.cellRendererParams;

    let [filterList] = calcFilterParentList(
        params,
        isFromSuperSeasonTotal,
        season,
        marketNameWithYear,
        year,
        false
    );

    const priceKey = getPriceKey(levelOfUnit, selectedChannel);
    const unitsFieldKey = getUnitsFieldKey(levelOfUnit, typeOfUnit);

    const unitSum = filterList.reduce((a, b) => {
        const unit = b?.[unitsFieldKey] || 0;
        return a + unit;
    }, 0);

    const unitSumWithPrice = filterList.reduce((a, b) => {
        const price = b?.[priceKey] || 0;
        const unit = b?.[unitsFieldKey] || 0;
        return a + price * unit;
    }, 0);

    const thisYear = unitSumWithPrice / unitSum;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = AIPAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(
                    superSeasonName,
                    season,
                    yearsAndMarkets.years.ly
                ),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const markupAggregate = (params) => {
    const {
        season,
        levelOfUnit,
        timePeriod,
        isFromSuperSeasonTotal,
        year,
        superSeasonName,
    } = params.colDef.cellRendererParams;

    if (isFromSuperSeasonTotal) {
        return (
            markupAggregate({
                ...params,
                colDef: {
                    ...params.colDef,
                    cellRendererParams: {
                        ...params.colDef.cellRendererParams,
                        superSeasonName: SUPER_SEASONS.SPRING,
                        isFromSuperSeasonTotal: false,
                        ...getMarketNameAndYear(
                            SUPER_SEASONS.SPRING,
                            season,
                            year
                        ),
                    },
                },
            }) +
            markupAggregate({
                ...params,
                colDef: {
                    ...params.colDef,
                    cellRendererParams: {
                        ...params.colDef.cellRendererParams,
                        superSeasonName: SUPER_SEASONS.FALL,
                        isFromSuperSeasonTotal: false,
                        ...getMarketNameAndYear(
                            SUPER_SEASONS.FALL,
                            season,
                            year
                        ),
                    },
                },
            })
        );
    }

    if (season === "Total") {
        const returnValNumTotal = AIPAggregate({
            ...params,
            colDef: {
                ...params.colDef,
                cellRendererParams: {
                    ...params.colDef.cellRendererParams,
                    season: "Total",
                },
            },
        });
        const returnValDenTotal = AIPAggregate({
            ...params,
            colDef: {
                ...params.colDef,
                cellRendererParams: {
                    ...params.colDef.cellRendererParams,
                    season: "Total",
                    levelOfUnit: levelOfUnit === "dtc" ? "wholesale" : "dtc",
                },
            },
        });

        return returnValNumTotal / returnValDenTotal;
    }

    const returnValNumThis = AIPAggregate(params);
    const returnValDenThis = AIPAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                levelOfUnit: levelOfUnit === "dtc" ? "wholesale" : "dtc",
            },
        },
    });

    const thisYear = returnValNumThis / returnValDenThis;

    if (timePeriod !== "tyVly") return thisYear;

    const lastYear = markupAggregate({
        ...params,
        colDef: {
            ...params.colDef,
            cellRendererParams: {
                ...params.colDef.cellRendererParams,
                year: yearsAndMarkets.years.ly,
                timePeriod: "ly",
                ...getMarketNameAndYear(
                    superSeasonName,
                    season,
                    yearsAndMarkets.years.ly
                ),
            },
        },
    });

    return calculateTyVsLyData(thisYear, lastYear);
};

const aggregateFuncDefs = {
    sumAggregate,
    marginPercAggregate,
    AICAggregate,
    mixAggregate,
    AIPAggregate,
    markupAggregate,
};

let yearsAndMarkets = {
    markets: {
        fall: {
            pre: "Pre-fall",
            main: "Fall",
        },
        spring: {
            pre: "Pre-Spring",
            main: "Spring",
        },
    },
    years: {
        ty: 2023,
        ly: 2022,
    },
};

const seasons = {};
const years = {};
let keys = [];

const superSeason = ["Spring", "Fall"].map((item) => ({
    label: item,
    value: item,
}));
const market = ["Pre", "Main"].map((item) => ({ label: item, value: item }));
const regionList = ["EMEA", "APAC"].map((item) => ({
    label: item,
    value: item,
}));
const channel = ["FULL-PRICE", "OUTLET"].map((item) => ({ label: item, value: item }));
let throttlingToggle = false;

const getBorderClass = (props) => {
    const {
        louIdx,
        touChildren,
        touIdx,
        muChildren,
        muIdx,
        tpChildren,
        tpIdx,
        yearsChildren,
        mcIdx,
        marketsChildren,
        isLastBigCol,
    } = props;
    let borderClass = "";

    if (louIdx === touChildren.length - 1) {
        borderClass = "l-border";
    }

    if (louIdx === touChildren.length - 1 && touIdx === muChildren.length - 1) {
        borderClass = "xl-border";
    }

    if (
        louIdx === touChildren.length - 1 &&
        touIdx === muChildren.length - 1 &&
        muIdx === tpChildren.length - 1
    ) {
        borderClass = "xxl-border";
    }

    if (
        louIdx === touChildren.length - 1 &&
        touIdx === muChildren.length - 1 &&
        muIdx === tpChildren.length - 1 &&
        tpIdx === yearsChildren.length - 1
    ) {
        borderClass = "xxxl-border";
    }

    if (
        louIdx === touChildren.length - 1 &&
        touIdx === muChildren.length - 1 &&
        muIdx === tpChildren.length - 1 &&
        tpIdx === yearsChildren.length - 1
    ) {
        if (mcIdx !== undefined) {
            if (mcIdx === marketsChildren.length - 1) {
                borderClass = "xxxxl-border";
                if (isLastBigCol) borderClass = "";
            }
        } else borderClass = "xxxxl-border";
    }

    return borderClass;
};

const cellRenderer = (props) => {
    const {
        lou,
        louIdx,
        touChildren,
        touHeaderClass,
        appliedTableSettings,
        mu,
        superSeasonName,
        seasonLabel,
        year,
        isFromSuperSeasonTotal,
        tp,
        tou,
        ...rest
    } = props;

    let borderClass = getBorderClass({
        louIdx,
        touChildren,
        ...rest,
    });

    return {
        ...lou,
        headerName: selectedChannel === "OUTLET" ? "Outlet" : lou.label,
        headerClass:
            louIdx === touChildren.length - 1
                ? touHeaderClass
                : "extra-border roll-up-sale-mode-header",
        width:
            appliedTableSettings.view_data.columnWidth === "1"
                ? lou.width || 130
                : 130,
        aggFunc: aggregateFuncDefs[mu.aggFunc],
        cellRendererParams: {
            ...getMarketNameAndYear(superSeasonName, seasonLabel, year),
            isFromSuperSeasonTotal,
            year,
            superSeasonName,
            season: seasonLabel,
            metricUnit: mu.value,
            typeOfUnit: tou.value,
            levelOfUnit: lou.value,
            timePeriod: tp.value,
        },
        valueFormatter: valueFormatterPivotTable,
        cellClass: "extra-border justify-right " + borderClass,
    };
};

let rowData;
let selectedChannel;
function EUPlanningRollUp() {
    const gridRef = useRef();
    const dispatch = useDispatch();

    const [appliedTableSettings, setAppliedTableSettings] = useState(null);
    const [isSubmitOnce, setIsSubmitOnce] = useState(false);
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    const [selectedMerchFilters, setSelectedMerchFilters] = useState({});
    const [selectedAttributeFilters, setSelectedAttributeFilters] = useState(
        {}
    );
    const [selectedPlanFilters, setSelectedPlanFilters] = useState({});
   
    const {
        workbench: {
            general: { columnSettings, selectedViewIndex },
        },
    } = useSelector((state) => state);
    const [showTableSettings, setShowTableSettings] = useState(false);

    let [fetchMainData, { data: mainData, isFetching }] =
        useLazyMainDataQuery();

    // required filters
    const [requiredFiltersBeforeFetch, setRequiredFiltersBeforeFetch] = useState({});
    const [requiredFilters, setRequiredFilters] = useState({});

    const [rowCount, selectedRowCount, onTableModelUpdate] = useTableRowCount();
    const [yearsList, setYearsList] = useState([]);
    const [selectedProductFilters, setSelectedProductFilters] = useState({});
    const [isComponentLoaded, setIsComponentLoaded] = useState(false);
    const [rawTableData, setRawTableData] = useState(null);
    const { priceFileId } = useSelector((state) => state.workbench.general);

    const { views, viewData } = useMemo(() => {
        const views = cloneDeep(
            columnSettings[tableSettingTabIds.PLANNING_ROLL_UPS]
        );
        const viewData =
            views[selectedViewIndex[tableSettingTabIds.PLANNING_ROLL_UPS]]
                .view_data;

        return { views, viewData };
    }, [columnSettings, selectedViewIndex]);

    selectedChannel = requiredFilters?.selectedChannels?.[0].label;
    useEffect(() => {
        dispatch(getColumnSettings());

        getMarketNames().then((markets) => {
            let years = markets.map((item) =>
                item.market_name.substring(item.market_name.length - 4)
            );

            years = [...new Set(years)]
                .sort((a, b) => Number(a) - Number(b))
                .map((item) => ({ label: item, value: item }));
            setYearsList(years);
        });
        setIsComponentLoaded(true);
    }, []);

    useEffect(() => {
        setRawTableData(isFetching ? null : mainData);
    }, [mainData, isFetching]);

    useEffect(() => {
        if (!isFetching) {
            onTableSettingsApply(
                tableSettingTabIds.PLANNING_ROLL_UPS,
                columnSettings[tableSettingTabIds.PLANNING_ROLL_UPS][
                    selectedViewIndex[tableSettingTabIds.PLANNING_ROLL_UPS]
                ]
            );
        }
    }, [isFetching, isSubmitOnce]);

    useEffect(() => {
        if (
            yearsList?.length &&
            requiredFiltersBeforeFetch.selectedYear?.length &&
            yearsList[0]?.label ===
                requiredFiltersBeforeFetch.selectedYear[0]?.label
        ) {
            setRequiredFiltersBeforeFetch({
                ...requiredFiltersBeforeFetch,
                multiplyingFactor: 1,
                selectedMetricVersion: [metricVersionOptions[0]],
            });
        }
    }, [requiredFiltersBeforeFetch.selectedYear, yearsList]);

    const onMerchFilterSelect = useCallback(
        (data) => {
            setSelectedMerchFilters(data);
        },
        [setSelectedMerchFilters]
    );

    const onAttributeFilterSelect = useCallback((data) => {
        setSelectedAttributeFilters(data);
    }, []);

    const onProductFilterSelect = useCallback((data) => {
        setSelectedProductFilters(data);
    }, []);

    const onPlanFilterSelect = useCallback((data) => {
        setSelectedPlanFilters(data);
    }, []);

    const onRefreshData = () => {
        const payload = {
            method_type: "GET",
            region: requiredFiltersBeforeFetch.selectedRegions[0]?.label,
            channel: requiredFiltersBeforeFetch.selectedChannels[0]?.label,
            year: requiredFiltersBeforeFetch.selectedYear[0]?.label,
            super_season: superSeason.map((item) => item.label),
            market: market.map((item) => item.label),
            metric_version: 2,
            products: getProductAttributes(),
        };

        fetchMainData(payload);
        setRequiredFilters(cloneDeep(requiredFiltersBeforeFetch));
        setRawTableData(null);
        setIsSubmitOnce(true);
    };

    rowData = useRowData({
        rawTableData,
        lastYear: yearsAndMarkets.years.ly,
        multiplyingFactor: requiredFilters.multiplyingFactor,
        costUsed: requiredFilters.selectedCostUsed,
    });

    const onTableDataChange = (event) => {
        updateColumnGroupColor();
        domUpdateForgrandTotal();
        onTableModelUpdate(event);
    };

    const onColumnResized = (params) => {
        if (throttlingToggle) {
            return;
        }
        throttlingToggle = true;
        setTimeout(() => (throttlingToggle = false), 100);

        if (viewData.columnWidth === "1") {
            params.columns.forEach((column) => {
                if (column.colDef.colId === "ag-Grid-AutoColumn") {
                    viewData.hierarchyOptions.forEach((item) => {
                        item.width = column?.getActualWidth() || 300;
                    });
                } else {
                    vistLeafNodesColumns((item) => {
                        if (column.colDef.id === item.id) {
                            item.width = column?.getActualWidth() || 130;
                        }
                    }, viewData?.columns);
                }
            });

            dispatch(
                userUpdateColumnSettings({
                    tabId: tableSettingTabIds.PLANNING_ROLL_UPS,
                    views,
                })
            );
        }
    };

    const autoGroupColumnDef = useMemo(
        () => ({
            ...gridOptions.autoGroupColumnDef,
            width:
                appliedTableSettings?.view_data.columnWidth === "1"
                    ? appliedTableSettings?.view_data?.hierarchyOptions?.[0]
                          ?.width || 300
                    : 300,
        }),
        [appliedTableSettings]
    );

    const mappingForYears = (
        years,
        seasonLabel,
        isFromSuperSeasonTotal,
        superSeasonName,
        { isLastBigCol, marketHeaderClass, marketsChildren, mcIdx }
    ) => {
        const yearsChildren = years.children.filter((item) => {
            const selectedIndex =
                metricVersionOptions.findIndex(
                    (item) => item.value === requiredFilters.selectedMetricVersion[0].value
                ) + 1;

            return (
                item.value === "thisYear" ||
                (item.value === "lastYear" && selectedIndex >= 2) ||
                (item.value === "tyVly" && selectedIndex === 3)
            );
        });

        const yearsListMapping = (tp, tpIdx) => {
            const headerBgClass = ` sub-sub-header-${
                tpIdx % 2 === 0 ? "blue" : "gray"
            }`;
            let year = 0;
            let headerName = "";

            if (tp.value === "thisYear") {
                year = yearsAndMarkets?.years?.ty;
                headerName = year;
            } else if (tp.value === "lastYear") {
                year = yearsAndMarkets?.years?.ly;
                headerName = year;
            } else {
                headerName = `${yearsAndMarkets?.years?.ty} vs ${yearsAndMarkets?.years?.ly}`;
                year = yearsAndMarkets?.years?.ty;
            }

            const tpChildren = tp.children.filter((item) => item.isSelected);
            const tpHeaderClass =
                tpIdx === yearsChildren.length - 1
                    ? marketHeaderClass
                    : "extra-border xxl-border";

            const metricsListMapping = (mu, muIdx) => {
                const muChildren = mu.children.filter(
                    (item) => item.isSelected
                );
                const muHeaderClass =
                    muIdx === tpChildren.length - 1
                        ? tpHeaderClass
                        : "extra-border xl-border";

                const typeOfUnitListMapping = (tou, touIdx) => {
                    const touChildren = tou.children.filter(
                        (item) =>
                            item.isSelected &&
                            (selectedChannel !== "OUTLET" ||
                                item.label.toLowerCase() === "dtc")
                    );

                    const touHeaderClass =
                        touIdx === muChildren.length - 1
                            ? muHeaderClass
                            : "extra-border l-border";
                    return {
                        ...tou,
                        headerName: tou.label,
                        headerClass: touHeaderClass + " left-sticky-label",
                        children: touChildren
                            // sale mode
                            ?.map((lou, louIdx) =>
                                cellRenderer({
                                    lou,
                                    louIdx,
                                    touChildren,
                                    touIdx,
                                    muChildren,
                                    muIdx,
                                    tpChildren,
                                    tpIdx,
                                    yearsChildren,
                                    mcIdx,
                                    marketsChildren,
                                    isLastBigCol,
                                    touHeaderClass,
                                    appliedTableSettings,
                                    mu,
                                    superSeasonName,
                                    seasonLabel,
                                    year,
                                    isFromSuperSeasonTotal,
                                    tp,
                                    tou,
                                })
                            ),
                    };
                };

                return {
                    ...mu,
                    headerName: mu.label,
                    headerClass: muHeaderClass + " left-sticky-label",
                    children: muChildren
                        // data type
                        .map((tou, touIdx) =>
                            typeOfUnitListMapping(tou, touIdx)
                        ),
                };
            };
            return {
                ...tp,
                headerName,
                headerClass:
                    tpHeaderClass + headerBgClass + " left-sticky-label",
                children: tpChildren
                    // metrics
                    .map((mu, muIdx) => metricsListMapping(mu, muIdx)),
            };
        };

        return (
            yearsChildren?.map((tp, tpIdx) => yearsListMapping(tp, tpIdx)) || []
        );
    };

    const mappingForMarket = (
        markets,
        superSeasonName,
        isLastBigCol,
        borderClass
    ) => {
        keys = [];
        rawTableData?.table_data.forEach((dt) => {
            let market = Object.keys(dt)[0];
            market = market.split("/");
            market = market[market.length - 1];
            keys.push(market);
        });

        keys?.forEach((key) => {
            seasons[key.substring(0, key.length - 5)] = 1;
            years[key.substring(key.length - 4)] = 1;
        });

        yearsAndMarkets.markets = {
            [SUPER_SEASONS.SPRING]: {
                [MARKETS_TYPE.PRE]: Object.keys(seasons).find(
                    (item) =>
                        item.toLowerCase().includes(MARKETS_TYPE.PRE) &&
                        item.toLowerCase().includes(SUPER_SEASONS.SPRING)
                ),
                [MARKETS_TYPE.MAIN]: Object.keys(seasons).find(
                    (item) =>
                        !item.toLowerCase().includes(MARKETS_TYPE.PRE) &&
                        item.toLowerCase().includes(SUPER_SEASONS.SPRING)
                ),
            },
            [SUPER_SEASONS.FALL]: {
                [MARKETS_TYPE.PRE]: Object.keys(seasons).find(
                    (item) =>
                        item.toLowerCase().includes(MARKETS_TYPE.PRE) &&
                        item.toLowerCase().includes(SUPER_SEASONS.FALL)
                ),
                [MARKETS_TYPE.MAIN]: Object.keys(seasons).find(
                    (item) =>
                        !item.toLowerCase().includes(MARKETS_TYPE.PRE) &&
                        item.toLowerCase().includes(SUPER_SEASONS.FALL)
                ),
            },
        };

        const marketsChildren = markets.children.filter((item) => {
            if (item.label.toLowerCase() === "total") {
                return true;
            } else {
                return requiredFilters.selectedMarkets
                    .map((item) => item.label)
                    .includes(item.label);
            }
        });

        return marketsChildren.map((s, mcIdx) => {
            let seasonLabel = "";
            if (s.label.toLowerCase() === MARKETS_TYPE.PRE) {
                seasonLabel =
                    yearsAndMarkets.markets[superSeasonName][MARKETS_TYPE.PRE];
            } else if (s.label.toLowerCase() === MARKETS_TYPE.MAIN) {
                seasonLabel =
                    yearsAndMarkets.markets[superSeasonName][MARKETS_TYPE.MAIN];
            } else {
                seasonLabel = "Total";
            }

            let marketHeaderClass =
                mcIdx === marketsChildren.length - 1
                    ? borderClass
                    : "extra-border xxxl-border";
            const headerBgClass = ` sub-header-${
                mcIdx % 2 === 0 ? "gray" : "blue"
            }`;

            return {
                ...s,
                headerName: seasonLabel,
                headerClass:
                    marketHeaderClass + headerBgClass + " left-sticky-label",
                children: mappingForYears(
                    s,
                    seasonLabel,
                    false,
                    superSeasonName,
                    { isLastBigCol, marketHeaderClass, marketsChildren, mcIdx }
                ),
            };
        });
    };

    const colDefs = useMemo(() => {
        if (!rawTableData?.table_data) return [];

        yearsAndMarkets.years = {
            ty: requiredFilters?.selectedYear[0]?.label,
            ly: requiredFilters?.selectedYear[0]?.label - 1,
        };

        const metricCols = appliedTableSettings?.view_data.columns?.filter(
            (item, index) => {
                if (index === 0) {
                    return true;
                }
                return requiredFilters.selectedSuperSeasons
                    .map((item) => item.label)
                    .includes(item.name);
            }
        );

        if (appliedTableSettings?.view_data) {
            const columnsList =
                metricCols?.map((superSeason, idx) => {
                    const isLastCol = metricCols.length === idx + 1;
                    const headerClass = isLastCol
                        ? ""
                        : "extra-border xxxxl-border";
                    const headerBgClass = ` header-${
                        idx % 2 === 0 ? "gray" : "blue"
                    }`;
                    if (superSeason.name === "Total") {
                        return {
                            headerName: "",
                            headerClass:
                                "extra-border xxxxl-border no-bottom-border sub-header-gray",
                            children: [
                                {
                                    ...superSeason,
                                    headerName: `Year ${superSeason.name}`,
                                    headerClass: isLastCol
                                        ? "left-sticky-label sub-header-gray"
                                        : "extra-border xxxxl-border no-top-border left-sticky-label sub-header-gray",
                                    children: mappingForYears(
                                        superSeason,
                                        "Total",
                                        true,
                                        superSeason.name.toLowerCase(),
                                        {
                                            isLastBigCol: isLastCol,
                                            marketHeaderClass: headerClass,
                                        }
                                    ),
                                },
                            ],
                        };
                    }
                    return {
                        ...superSeason,
                        headerName: `Super Season ${superSeason.name}`,
                        headerClass:
                            headerClass + headerBgClass + " left-sticky-label",
                        children: mappingForMarket(
                            superSeason,
                            superSeason.name.toLowerCase(),
                            isLastCol,
                            headerClass
                        ),
                    };
                }) || [];

            return [
                ...(
                    appliedTableSettings?.view_data?.hierarchyOptions?.filter(
                        (item) => item.isSelected
                    ) || []
                ).map((h, index) => ({
                    ...h,
                    field: h.value,
                    headerName: h.label,
                    enableRowGroup: true,
                    rowGroup: true,
                    rowGroupIndex: index,
                })),
                ...columnsList,
            ];
        }
        return [];
    }, [appliedTableSettings, rawTableData, requiredFilters]);

    const onTableSettingsApply = (tabId, view) => {
        console.log(tabId, view);
        setShowTableSettings(false);
        setAppliedTableSettings(cloneDeep(view));
    };

    const onTableSettingsClose = () => {
        setShowTableSettings(false);
    };

    const onSettingsClick = () => {
        setShowTableSettings(true);
    };

    const getProductAttributes = () => {
        const submittedFilters = {};
        if (!isEmpty(selectedMerchFilters)) {
            submittedFilters.SELECT_FILTERS_MERCH = selectedMerchFilters;
        }

        if (!isEmpty(selectedAttributeFilters)) {
            submittedFilters.SELECT_FILTERS_ATTRIBUTE =
                selectedAttributeFilters;
        }

        if (!isEmpty(selectedProductFilters)) {
            submittedFilters.SELECT_FILTERS_PRODUCT = selectedProductFilters;
        }

        if (!isEmpty(selectedPlanFilters)) {
            submittedFilters.SELECT_FILTERS_PLANNING = selectedPlanFilters;
        }

        if (
            requiredFiltersBeforeFetch.selectedMarkets.length &&
            requiredFiltersBeforeFetch.selectedRegions.length &&
            requiredFiltersBeforeFetch.selectedChannels.length
        ) {
            const data = getMaterialFetchData(
                submittedFilters,
                requiredFiltersBeforeFetch.selectedMarkets,
                requiredFiltersBeforeFetch.selectedRegions,
                requiredFiltersBeforeFetch.selectedChannels,
                priceFileId
            );

            return data.products;
        }

        return {};
    };

    const onSubmit = () => {
        if (
            requiredFiltersBeforeFetch.selectedRegions?.length > 0 &&
            requiredFiltersBeforeFetch.selectedChannels?.length > 0 &&
            requiredFiltersBeforeFetch.selectedYear?.length > 0 &&
            requiredFiltersBeforeFetch.selectedSuperSeasons?.length > 0 &&
            requiredFiltersBeforeFetch.selectedMarkets?.length > 0 &&
            requiredFiltersBeforeFetch.selectedMetricVersion?.length > 0 &&
            requiredFiltersBeforeFetch.selectedCostUsed?.length > 0
        ) {
            onRefreshData();
            setFiltersExpanded(false);
        } else {
            dispatch(
                toastError(
                    "Please select all filters before fetching the data."
                )
            );
        }
    };

    const handleAccordion = () => {
        setFiltersExpanded((prevValue) => !prevValue);
    };

    const selectFilterHandler = () => setFiltersExpanded(true);

    const onFilterClearClick = () => {
        setRequiredFiltersBeforeFetch({})
        setSelectedMerchFilters({});
        setSelectedProductFilters({});
        setSelectedPlanFilters({});
        setSelectedAttributeFilters({});
    };

    const filterItemUpdate = (params) => {
        setSelectedItemInList(
            appliedTableSettings.view_data.hierarchyOptions,
            params.hierarchyOptions
        );

        changeMetrics(params.metrics, appliedTableSettings.view_data, false);

        setAppliedTableSettings(cloneDeep(appliedTableSettings));

        views[
            selectedViewIndex[tableSettingTabIds.PLANNING_ROLL_UPS]
        ].view_data = appliedTableSettings.view_data;

        dispatch(
            userUpdateColumnSettings({
                tabId: tableSettingTabIds.PLANNING_ROLL_UPS,
                views,
            })
        );
    };

    const multiplyingFactorChangeHandler = (event) => {
        setRequiredFiltersBeforeFetch({
            ...requiredFiltersBeforeFetch,
            multiplyingFactor: event.target.value,
        });
    };

    const multiplyingFactorBlurHandler = (event) => {
        const value = event.target.value;

        if (value <= 0) {
           setRequiredFiltersBeforeFetch({
               ...requiredFiltersBeforeFetch,
               multiplyingFactor: 1,
           });
        }
    };

    return (
        <div
            className={`${pageStyles["body"]} ${pageStyles["planning-roll-up-container"]} buyers-screen-container`}
        >
            <LoadingOverlay
                showLoader={isFetching}
                customStyle={{ top: 0, left: 0 }}
                isLocalLoader
            />
            <div className="planning-roll-topbar center-space-between">
                <div className="page-title">Planning Roll Up</div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: "80%",
                    }}
                >
                    {rawTableData ? (
                        <div className="button-group-flex">
                            <Tooltip title="Refresh planning rollups">
                                <Button
                                    variant="contained"
                                    onClick={onRefreshData}
                                >
                                    <RefreshOutlinedIcon fontSize="small" />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Download Report">
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        downloadHandler(
                                            "Planning Roll Up",
                                            gridRef
                                        )
                                    }
                                >
                                    <DownloadOutlinedIcon fontSize="small" />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Table settings">
                                <Button
                                    variant="contained"
                                    onClick={onSettingsClick}
                                >
                                    <SettingsIcon fontSize="small" />
                                </Button>
                            </Tooltip>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {isComponentLoaded && (
                <div className="planning-roll-up-filter-accordian">
                    <CustomAccordion
                        label="Select Filters"
                        expanded={filtersExpanded}
                        handleAccordion={handleAccordion}
                    >
                        <Paper
                            className="filter-group-container"
                            elevation={0}
                            sx={{ paddingTop: 0, paddingBottom: "8px" }}
                        >
                            <Box className="px-12" sx={{ flexGrow: 1 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    columns={{ xs: 2, sm: 6, md: 12, lg: 10 }}
                                >
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Select Region{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                initialData={regionList}
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedRegions
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedRegions:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Select Channel{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                initialData={channel}
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedChannels
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedChannels:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Select Year{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                initialData={yearsList}
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedYear
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedYear:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Select Super Season{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                initialData={superSeason}
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedSuperSeasons
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedSuperSeasons:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                is_multiple_selection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Select Market{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                initialData={market}
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedMarkets
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedMarkets:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                is_multiple_selection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Select Metric Version{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                className="input"
                                                initialData={
                                                    metricVersionOptions
                                                }
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedMetricVersion
                                                }
                                                isDisabled={
                                                    requiredFiltersBeforeFetch
                                                        ?.selectedYear?.[0]
                                                        ?.label ===
                                                    yearsList[0]?.label
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedMetricVersion:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    {requiredFiltersBeforeFetch
                                        .selectedMetricVersion?.length > 0 &&
                                        requiredFiltersBeforeFetch
                                            .selectedMetricVersion[0]?.label !==
                                            metricVersionOptions[0]?.label && (
                                            <Grid
                                                item
                                                xs={2}
                                                sm={2}
                                                md={3}
                                                lg={2}
                                            >
                                                <Item>
                                                    <label className="select-label">
                                                        LY Multiplying Factor{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            requiredFiltersBeforeFetch.multiplyingFactor
                                                        }
                                                        onChange={
                                                            multiplyingFactorChangeHandler
                                                        }
                                                        onBlur={
                                                            multiplyingFactorBlurHandler
                                                        }
                                                    />
                                                </Item>
                                            </Grid>
                                        )}
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item>
                                            <label className="select-label">
                                                Cost of Origin{" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <MultiSelect
                                                className="input"
                                                initialData={costUsedList}
                                                selectedOptions={
                                                    requiredFiltersBeforeFetch.selectedCostUsed
                                                }
                                                updateSelected={(data) =>
                                                    setRequiredFiltersBeforeFetch(
                                                        {
                                                            ...requiredFiltersBeforeFetch,
                                                            selectedCostUsed:
                                                                data.selectedItems,
                                                        }
                                                    )
                                                }
                                                hideSearch
                                                hideClearSelection
                                                updateSelectedOnEachSelection
                                                is_multiple_selection
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <div
                            className={`${pageStyles.divider} mt-16 mb-12`}
                        ></div>
                        <CustomAccordion
                            label="Merch Division"
                            defaultExpanded={false}
                        >
                            <FlatFilter
                                screenName="SELECT_FILTERS_MERCH_DIVISION"
                                onFilterSelect={onMerchFilterSelect}
                                selectedFilters={selectedMerchFilters}
                            />
                        </CustomAccordion>
                        <div className={`${pageStyles.divider}`}></div>
                        <CustomAccordion
                            label="Attributes"
                            defaultExpanded={false}
                        >
                            <FlatFilter
                                screenName="SELECT_FILTERS_ATTRIBUTES"
                                onFilterSelect={onAttributeFilterSelect}
                                selectedFilters={selectedAttributeFilters}
                            />
                        </CustomAccordion>
                        <div className={`${pageStyles.divider}`}></div>
                        <CustomAccordion
                            label="Merch Hierarchy"
                            defaultExpanded={false}
                        >
                            <FlatFilter
                                screenName="SELECT_FILTERS_MERCH"
                                onFilterSelect={onMerchFilterSelect}
                                selectedFilters={selectedMerchFilters}
                            />
                        </CustomAccordion>
                        <div className={`${pageStyles.divider}`}></div>
                        <CustomAccordion
                            label="Product Hierarchy"
                            defaultExpanded={false}
                        >
                            <FlatFilter
                                screenName="SELECT_FILTERS_PRODUCT"
                                onFilterSelect={onProductFilterSelect}
                                selectedFilters={selectedProductFilters}
                            />
                        </CustomAccordion>
                        <div className={`${pageStyles.divider}`}></div>
                        <CustomAccordion
                            label="Planning Hierarchy"
                            defaultExpanded={false}
                        >
                            <FlatFilter
                                screenName="SELECT_FILTERS_PLANNING"
                                onFilterSelect={onPlanFilterSelect}
                                selectedFilters={selectedPlanFilters}
                            />
                        </CustomAccordion>
                        <div className={`${pageStyles.divider}`}></div>
                        <CustomAccordion
                            label="Brand Group"
                            defaultExpanded={false}
                        >
                            <FlatFilter
                                screenName="SELECT_FILTERS_BRAND_GROUP"
                                onFilterSelect={onMerchFilterSelect}
                                selectedFilters={selectedMerchFilters}
                            />
                        </CustomAccordion>
                        <div className={`${pageStyles.divider}`}></div>
                        <div className={`${pageStyles["action-container"]}`}>
                            <Tooltip title="Click to fetch data">
                                <Button variant="contained" onClick={onSubmit}>
                                    Fetch
                                </Button>
                            </Tooltip>
                            <Button
                                className={`${pageStyles["tertiary-button"]}`}
                                onClick={onFilterClearClick}
                            >
                                Clear Filter
                            </Button>
                        </div>
                    </CustomAccordion>
                </div>
            )}
            {rawTableData ? (
                <FilterShowcaseBar
                    hierarchyOptions={appliedTableSettings?.view_data?.hierarchyOptions.filter(
                        (item) => item.isSelected
                    )}
                    metrics={appliedTableSettings?.view_data?.columns[0].children[0].children.filter(
                        (item) => item.isSelected
                    )}
                    filterItemUpdate={filterItemUpdate}
                />
            ) : (
                ""
            )}
            <div
                style={{
                    height: "calc(100vh - 320px)",
                    marginTop: "15px",
                }}
                className="ag-theme-alpine buyers-screen-table"
            >
                {rawTableData ? (
                    <AgGridReact
                        {...{
                            ...gridOptions,
                            ref: gridRef,
                            rowData,
                            columnDefs: colDefs,
                            onModelUpdated: onTableDataChange,
                            onColumnResized,
                            autoGroupColumnDef,
                        }}
                    />
                ) : (
                    <div className="empty-page-onload">
                        <div>
                            <img
                                src={emptyStateImage}
                                alt="Empty Data On Load"
                            />
                            <b className="page-title mb-12">No Data To Show</b>
                            <Tooltip title="Click to open the filter accordion">
                                <Button
                                    variant="contained"
                                    onClick={selectFilterHandler}
                                    disabled={filtersExpanded}
                                >
                                    Select Filters
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
            {rawTableData ? (
                <TableRowCount count={rowCount} selected={selectedRowCount} />
            ) : (
                ""
            )}
            {showTableSettings ? (
                <>
                    <div className="drawer-inner-full-height">
                        <TableSettings
                            planningRollUpSettings
                            onApply={onTableSettingsApply}
                            onClose={onTableSettingsClose}
                            selectedChannels={requiredFilters.selectedChannels}
                        />
                    </div>
                    <div className="drawer-inner-full-height-cover" />
                </>
            ) : null}
        </div>
    );
}

export default EUPlanningRollUp;
