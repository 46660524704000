import axios from "axios";
import moment from "moment";

import { interceptResponse } from "./helper";
import { funcForHardRefresh } from "../../store/features/auth/auth";

export const { REACT_APP_API_BASE_URL } = process.env;

const API = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    timeout: 9999999,
    headers: {
        "Content-Type": "application/json",
    },
});

const requestInterceptor = (request) => {
    const token = localStorage.getItem("token");
    request.headers.common = { Authorization: `Bearer ${token}` };
    // Do something before request is sent

    const localNextUpdateTime =
        localStorage.getItem("nextUpdateTime") !== "undefined"
            ? JSON.parse(localStorage.getItem("nextUpdateTime"))
            : undefined;

    if (
        localNextUpdateTime &&
        moment(localNextUpdateTime.start_time).isBefore(moment(Date.now()))
    ) {
        alert("For maintenance purposes, you are being logged out");
        signOutExtraFunc();
    }
    return request;
};

const signOutExtraFunc = () => {
    window.location.href = "/";

    funcForHardRefresh();

    setTimeout(() => {
        window.location.reload();
    }, 1000);
};

API.interceptors.request.use(requestInterceptor, (error) => {
    return Promise.reject(error);
});

API.interceptors.response.use(
    (response) => {
        interceptResponse(response);

        return response;
    },
    (error) => {
        const { response } = error;
        console.log(error, "API response interceptor");
        const pathname = window.location?.pathname;
        if (response && response.status === 401 && pathname !== "/")
            signOutExtraFunc();
        else if (response?.status === 409) {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
);

const ExcelAPI = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    timeout: 9999999,
    headers: {
        "Content-Type": "application/json",
    },
    responseType: "arraybuffer",
});

ExcelAPI.interceptors.request.use(requestInterceptor, (error) => {
    return Promise.reject(error);
});

ExcelAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;
        const pathname = window.location?.pathname;
        if (
            response &&
            (response.status === 403 || response.status === 401) &&
            pathname !== "/"
        )
            signOutExtraFunc();

        return Promise.reject(error);
    }
);

const axiosBaseQueryForRTKQuery =
    ({ extendedUrl } = { extendedUrl: "" }) =>
    async ({ url, method, data, params }) => {
        try {
            const result = await API({
                url: extendedUrl + url,
                method,
                data,
                params,
            });
            return { data: result };
        } catch (axiosError) {
            const err = axiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export { API, ExcelAPI, axiosBaseQueryForRTKQuery };
