import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import moment from "moment";

import { ReactComponent as Unapproved } from "../../../assets/images/unapproved.svg";
import {
    toastError,
    toastSuccessMessage,
} from "../../../store/features/global/global";
import {
    MERCHANT,
    PRICING_TEAM,
    SUPER_USER,
} from "../exceptionConfig/ExceptionConfig";

export const channelIdLabelMap = {
    "FULL-PRICE": 1,
    OUTLET: 2,
};

export const getMaterialFetchData = (
    selectedFilters,
    selectedMarkets,
    selectedRegions,
    selectedChannels,
    priceFileId
) => {
    const products = {};
    const filterKeys = [
        "SELECT_FILTERS_MERCH",
        "SELECT_FILTERS_ATTRIBUTE",
        "SELECT_FILTERS_PLANNING",
        "SELECT_FILTERS_PRODUCT",
    ];

    for (const filterKey of filterKeys) {
        if (selectedFilters[filterKey]) {
            for (const hierarchyId in selectedFilters[filterKey]) {
                products[hierarchyId] = selectedFilters[filterKey][
                    hierarchyId
                ].map((obj) => (typeof obj === "object" ? obj.value : obj));
            }
        }
    }

    const materialFetchData = {
        method_type: "GET",
        market_id: selectedMarkets[0].value,
        region: selectedRegions[0].label,
        channel: selectedChannels[0].label,
        price_file_id: priceFileId,
        products,
    };

    return materialFetchData;
};

export function checkMaterialSelectionValidity(
    role_details,
    allowed_regions,
    allowed_channel_hierarchies,
    data
) {
    if (role_details[SUPER_USER]) {
        return true;
    }

    if (role_details[PRICING_TEAM]) {
        if (allowed_regions) {
            for (const allowed_region of allowed_regions) {
                if (allowed_region.label === data.region) {
                    return true;
                }
            }
        }
    }

    if (role_details[MERCHANT]) {
        if (allowed_channel_hierarchies) {
            const hierarchyMap =
                allowed_channel_hierarchies[channelIdLabelMap[data.channel]];

            if (!hierarchyMap) {
                return false;
            }

            for (
                let hierarchyLevel = 1;
                hierarchyLevel <= 16;
                hierarchyLevel++
            ) {
                if (!hierarchyMap[`product_h${hierarchyLevel}`]) {
                    continue;
                }

                if (
                    !hierarchyMap[`product_h${hierarchyLevel}`][
                        data[`product_h${hierarchyLevel}_id`]
                    ]
                ) {
                    return false;
                }
            }

            return true;
        }
    }

    return false;
}

export const checkIfMaterialProcessedForCountryPrice = (
    materialId,
    countryPriceRequestMaterials
) => {
    return (
        countryPriceRequestMaterials.findIndex((id) => id === materialId) !== -1
    );
};
export const isMaterialBeingSynced = (material, countryLevelPrices) => {
    const foundIndex = countryLevelPrices?.findIndex(
        (countryPrice) => countryPrice.id === material.id
    );

    if (foundIndex === -1) {
        return false;
    }

    const countryData = Object.values(
        countryLevelPrices?.[foundIndex].country_data || {}
    );

    for (const countryobj of countryData) {
        if (countryobj.sync_with_gfe_status === 1) {
            return true;
        }
    }

    return false;
};

export const validatePrice = (price) => {
    return !(isNaN(price) || price <= 0 || price > 9999999999999.99);
};

export const dateComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
    const dateA = moment(valueA);
    const dateB = moment(valueB);
    const isDateAValid = dateA.isValid();
    const isDateBValid = dateB.isValid();

    if (!isDateAValid && !isDateBValid) {
        return 0;
    } else if (!isDateAValid) {
        return -1;
    } else if (!isDateBValid) {
        return 1;
    }

    const isBAfterA = dateB.isAfter(dateA);

    return isBAfterA ? -1 : 1;
};

export const APPROVAL_STATUS = {
    0: "Unapproved",
    1: "Pending",
    2: "Approved",
    3: "Unapproved",
    4: "AMF Unapproved",
    5: "AMF Pending",
    6: "AMF Approved",
    7: "AMF Unapproved",
};

export const ApprovalStatusRenderer = ({ value }) => {
    const text = APPROVAL_STATUS[value || 0];

    const iconType = (value || 0) % 4;
    let icon;
    switch (iconType) {
        case 0:
        case 3:
            icon = (
                <Unapproved
                    style={{
                        color: "#fbbd4c",
                        height: "20px",
                        width: "20px",
                        padding: "0px 2px",
                    }}
                />
            );
            break;
        case 1:
            icon = (
                <AccessTimeOutlinedIcon
                    sx={{ color: "#FF832B", height: "18px" }}
                />
            );
            break;
        case 2:
            icon = (
                <DoneOutlinedIcon sx={{ color: "#24A148", height: "20px" }} />
            );
            break;
        default:
            break;
    }

    return (
        <>
            {icon} {text}
        </>
    );
};

export const checkMaterialApprovalStatus = (data) =>
    data.approval_status % 4 !== 1;

export const alertAndExceptionOptions = (
    alertsAndExceptions,
    alerts,
    exceptions
) => {
    const materialAlertsAndExceptions = Object.values(alertsAndExceptions);

    for (const materialAlertsAndExceptionArr of materialAlertsAndExceptions) {
        for (const alertExceptionObj of materialAlertsAndExceptionArr) {
            const { id, entry_type } = alertExceptionObj;

            if (entry_type === 1 && alerts[id] === undefined) {
                alerts[id] = alertExceptionObj;
            }

            if (entry_type === 2 && exceptions[id] === undefined) {
                exceptions[id] = alertExceptionObj;
            }
        }
    }

    const alertOptions = Object.values(alerts).map(
        ({ id, name, is_mandatory }) => ({
            label: `${name} (${is_mandatory ? "Mandatory" : "Optional"})`,
            value: id,
        })
    );
    const exceptionOptions = Object.values(exceptions).map(
        ({ id, name, is_mandatory }) => ({
            label: `${name} (${is_mandatory ? "Mandatory" : "Optional"})`,
            value: id,
        })
    );

    exceptionOptions.unshift({ label: "None", value: "noExceptions" });
    alertOptions.unshift({ label: "None", value: "noAlerts" });
    return [alertOptions, exceptionOptions];
};

export const auditLogDownloader = (
    downloadAuditLogData,
    selectedMaterials,
    materials,
    mainFilters,
    gridRef,
    handleClose,
    dispatch
) => {
    const payload = [];

    const { selectedMarkets, selectedRegions, selectedChannels } = mainFilters;

    if (
        selectedMaterials.length > 300 ||
        (selectedMaterials.length === 0 && materials.length > 300)
    ) {
        dispatch(toastError("Selected row count can not exceed 300"));
        return;
    }

    if (selectedMaterials.length) {
        for (const { data } of selectedMaterials) {
            payload.push(data.uid);
        }
    } else {
        for (const row of materials) {
            payload.push(row.uid);
        }
    }

    dispatch(
        toastSuccessMessage(
            "Audit log download file generation is in progress. Please visit the download page to download the file when completed."
        )
    );

    downloadAuditLogData({
        uid_list: payload,
        market: selectedMarkets[0].label,
        market_id: selectedMarkets[0].value,
        region: selectedRegions[0].label,
        channel: selectedChannels[0].label,
    }).catch((res) => {
        dispatch(toastError(res));
    });

    handleClose();
    gridRef?.current?.api?.deselectAll();
};

const columnNamesForFullPrice = [
    "DTC ITP FLAG",
    "WHSL ITP FLAG",
    "EURO Standard WHSL",
    "WHSL PROJECTION Units",
    "WHSL ACTUAL Units",
    "WHSL PROJECTION Sales",
    "WHSL ACTUAL Sales",
    "WHSL PROJECTION Margin",
    "WHSL ACTUAL Margin",
    "WHSL MARGIN %",
].map((item) => item.toLowerCase());

const columnNamesForOutlet = [
    "Parent Material Number",
    "PARENT SEASON",
    "PARENT SEASON CODE",
    "PRICE ESTABLISHMENT",
    "TYPE OF PE",
    "DISCOUNT",
    "DISCOUNT TABLE",
    "STORE PRICE",
    "Similar Material(SM)",
    "SM Image",
    "SM Season",
    "SM Market",
    "SM Channel",
    "PFS WAS Material",
    "PFS Was Image",
    "PFS WAS Season",
    "PFS WAS Market",
    "PFS WAS Indicated",
].map((item) => item.toLowerCase());

export const outletNameReplacementMapping = {
    "dtc actual margin": "ACTUAL MARGIN",
    "dtc actual sales": "ACTUAL SALES",
    "dtc actual units": "ACTUAL UNITS",
    "dtc margin %": "MARGIN %",
    "dtc projection margin": "PROJECTION MARGIN",
    "dtc projection sales": "PROJECTION SALES",
    "dtc projection units": "PROJECTION UNITS",
    "euro standard msrp": "PFS WAS (EUR)",
};

export const filterAndMapColumnChannelWise = (list, channel) => {
    const selectedChannel = channel[0]?.label || "";
    let newList = [];
    if (selectedChannel.includes("OUTLET")) {
        newList = list
            .filter(
                (col) =>
                    !columnNamesForFullPrice.includes(col.name.toLowerCase())
            )
            .map((col) => {
                let columnName = col.name;
                if (
                    col.name.toLowerCase().replace("(eur)", "").trim() in
                        outletNameReplacementMapping &&
                    selectedChannel.includes("OUTLET")
                ) {
                    columnName =
                        outletNameReplacementMapping[
                            col.name.toLowerCase().replace("(eur)", "").trim()
                        ];
                }
                return {
                    ...col,
                    label: columnName,
                    name: columnName,
                    value: col.id,
                };
            });
    }
    if (selectedChannel.includes("FULL-PRICE"))
        newList = list.filter(
            (col) => !columnNamesForOutlet.includes(col.name.toLowerCase())
        );

    newList = newList
        .map((col) => ({
            ...col,
            label: col.name,
            value: col.id,
        }));

    return newList;
};
