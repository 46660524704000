import { createTheme } from "@mui/material/styles";

// create a temporary theme to get the default options
const defaultTheme = createTheme();
// get the default `shadows` object
const defaultShadows = [...defaultTheme.shadows];

const theme = createTheme({
    shadows: defaultShadows.map(() => "none"),
    customVariables: {
        closedNavWidth: "4.5rem",
        navWidth: "20.75rem",
        commentDrawerWidth: 50,
    },
    typography: {
        allVariants: {
            fontSize: "0.8rem",
            fontFamily: "Poppins, sans-serif",
        },
    },
    palette: {
        primary: {
            main: "#0055AF",
        },
        secondary: {
            main: "#e0e0e0",
        },
        success: {
            main: "#24A148",
        },
        warning: {
            main: "#FF832B",
        },
    },
    components: {
        MuiChip: {
            variants: [
                {
                    props: { color: "default" },
                    style: { color: "#758490" },
                },
            ],
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    flexShrink: "0",
                    whiteSpace: "nowrap",
                },
                paper: {
                    background: "#091523",
                    transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: "0",
                    paddingRight: "0",
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "unset",
                    minWidth: "40px",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {},
                checked: {},
            },
        },
        MuiButtonBase: {
            defaultProps: { disableRipple: true, disableElevation: true },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginBottom: "20px",
                    minHeight: "0px",
                    height: "33px",
                },
                MuiTab: {
                    root: {
                        minWidth: "160px",
                        fontSize: "0.85rem",
                        fontWeight: "600",
                        overflow: "hidden",
                        position: "relative",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    minHeight: "0px",
                    height: "33px",
                    padding: "0px 8px",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {},
                paper: {
                    maxWidth: "430px",
                    borderRadius: "10px 10px 6px 6px",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "25px",
                },
            },
        },
        MuiDialogActions: {
            background: "#d3d3d345",
            padding: "15px",
        },
        TextField: {
            styleOverrides: {
                root: {
                    maxWidth: "100%",
                    minWidth: "100%",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: "pointer",
                    color: "#5295e1",
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: "5px",
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginLeft: "10px",
                    marginRight: "10px",
                    height: "35px",
                    textTransform: "capitalize",
                    transition: "none",
                    boxShadow: "none",
                    borderRadius: "3px",
                    padding: "8px 20px",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontWeight: "400",
                },
            },
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        "&:hover": {
                            backgroundColor: "#e5edf7",
                        },
                        borderColor: "#0055af",
                    },
                },
                {
                    props: { variant: "contained", color: "primary" },
                    style: {
                        "&:hover": {
                            backgroundColor: "#033162",
                        },
                        padding: "8px 20px",
                    },
                },
                {
                    props: { variant: "text" },
                    style: {
                        padding: "8px 0px",
                    },
                },
            ],
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: "6px 0",
                    borderColor: "rgba(0, 0, 0, 0.12)",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    paddingTop: "15px",
                    paddingBottom: "15px",
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                paper: {
                    boxShadow: "0px 0px 6px #00000029",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                action: {
                    paddingTop: "2px",
                    color: "black",
                    paddingLeft: "4px",
                },
                root: {
                    color: "black",
                },
                filledError: {
                    border: "1px solid #DA1E28",
                    background: "#FCE9EA",
                    color: "#DA1E28",
                },
                filledSuccess: {
                    border: "1px solid #24a148",
                    background: "#e8f5ec",
                    color: "#24a148",
                },
                filledInfo: {
                    border: "1px solid #0055af",
                    background: "#e5edf7",
                    color: "#0055af",
                },
                filledWarning: {
                    border: "1px solid #ff832b",
                    background: "#fff3ea",
                    color: "#ff832b",
                },
                message: {
                    color: "black",
                    fontSize: "12px",
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    height: 35,
                    padding: "10.5px 12px",
                },
                switchBase: {
                    padding: "7.5px 9px",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: "14px",
                },
            },
        },
    },
});

export default theme;
