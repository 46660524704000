import * as React from "react";

import Chip from "@mui/material/Chip";

const PerformanceChip = (props) => {
    return(
        <>
            {props.value ? <Chip className={`performanceChip ${props.value}`} label={props.value}/> : '-'}
        </>
    )
}

export default PerformanceChip;