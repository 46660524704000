import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _, { map } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getValidOffers } from "../../../store/features/promo/promo";
import { VALID_OFFER_TYPES } from "../../../constants/Constants";

const FinalizeCellEdit = (props) => {
	const [selected, setSelected] = useState(""); // 0% OFF
	const [offerValueOptions, setOfferValueOptions] = useState({});
	const [offerTypeOptions, setOfferTypeOptions] = useState({});
	const [selectedOfferType, setSelectedOfferType] = useState("");
	const [eligibleFlag, setEligibleFlag] = useState(false);

	useEffect(() => {
		if (props.column?.colId === "ia_recommended") {
			setEligibleFlag(true);
		}
		if (props.value || props.value === 0) {
			const { value, colDef, data } = props;
			let offerValOptions = {},
				offerTypeOptionsTemp = {};
			const newOfferValue = value.offer_value;
			if (!newOfferValue) return;
			offerValOptions[value.offer_type] = [{ ...value }];
			offerTypeOptionsTemp[value.offer_type] =
				VALID_OFFER_TYPES[value.offer_type];

			setSelected(newOfferValue);
			setSelectedOfferType(value.offer_type);
			setOfferValueOptions(offerValOptions);
			setOfferTypeOptions(offerTypeOptionsTemp);
		}
	}, []);

	const offerValueOptionsFormatter = (options) => {
		let newOfferValues = {},
			newOfferTypes = {};
		map(options, (offerVal, i) => {
			newOfferValues[offerVal.offer_type] = offerVal.offer_values;
			newOfferTypes[offerVal.offer_type] = offerVal.offer_display_name;
		});
		return { newOfferTypes, newOfferValues };
	};

	const handleChange = (e) => {
		let val = e.target.value;
		const newValue =
			offerValueOptions[selectedOfferType] &&
			offerValueOptions[selectedOfferType].find(
				(offer) => offer.offer_value === val
			);

		setSelected(val);
		props.setValue({
			...props.value,
			benefit_value: newValue && newValue.effective_discount,
			effective_promo_discount: newValue && newValue.effective_discount,
			offer_value: newValue && newValue.offer_value,
		});
		if (!props.node.selected) {
			props.node.setSelected(true);
		}
	};

	const onOpenHandler = async ({ offerType }) => {
		const { data, colDef, promoTypes, promoValues, lastSavedFilters } =
			props;
		const updatedOfferType = offerType || selectedOfferType;

		// Call Valid offers API based on discount level

		const { promo_id, product_hierarchy, product_details, discount_rules } =
			props.promoDetails;
		const { discount_level } = discount_rules ? discount_rules[0] : {};

		if (
			discount_level === "overall" &&
			_.isEmpty(props.validOffers?.[promo_id]?.overall)
		) {
			const productHierarchyFilters = {};
			Object.keys(product_hierarchy).forEach(function (key) {
				if (!_.isEmpty(product_hierarchy[key])) {
					productHierarchyFilters[key] = [];
					product_hierarchy[key].forEach((h) =>
						productHierarchyFilters[key].push(h.value)
					);
				}
			});

			const producth5Filter = [];
			product_details?.forEach((p) =>
				producth5Filter.push(p.product_h5_id)
			);
			productHierarchyFilters.product_h5 = producth5Filter;
			const validOffersPayload = {
				promo_id,
				overall: true,
				filters: {},
			};
			const validOfferResult = await props.getValidOffers({
				payload: validOffersPayload,
				promo_id: promo_id,
				hierarchy_id: "overall",
			});
			const { newOfferTypes, newOfferValues } =
				offerValueOptionsFormatter(validOfferResult || []);
			setOfferTypeOptions(newOfferTypes);
			setOfferValueOptions(newOfferValues);
			if (!selected || !selectedOfferType) {
				setSelected(
					newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
						?.offer_value
				);
				setSelectedOfferType(Object.keys(newOfferTypes)?.[0]);

				const newCellData = {
					benefit_value:
						newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
							?.effective_discount,
					effective_promo_discount:
						newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
							?.effective_discount,
					offer_value:
						newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
							?.offer_value,
					offer_type: Object.keys(newOfferTypes)?.[0],
				};
				props.setValue(newCellData);
				if (!props.node.selected) {
					props.node.setSelected(true);
				}
			}
		} else if (
			discount_level !== "overall" &&
			_.isEmpty(props.validOffers[promo_id]?.[props.data.id])
		) {
			const productHierarchyFilters = {};
			Object.keys(product_hierarchy).forEach(function (key) {
				if (!_.isEmpty(product_hierarchy[key])) {
					if (key < discount_level) {
						productHierarchyFilters[key] = [];
						product_hierarchy[key].forEach((h) =>
							productHierarchyFilters[key].push(h.value)
						);
					} else if (key === discount_level) {
						productHierarchyFilters[key] = [props.data.id];
					}
				}
			});

			if (discount_level === "product_h5") {
				productHierarchyFilters.product_h5 = [props.data.id];
			}

			// If discount level is lower than hierarchy selection
			if (!productHierarchyFilters[discount_level]) {
				productHierarchyFilters[discount_level] = [props.data.id];
			}

			const validOffersPayload = {
				promo_id,
				filters: {
					product_hierarchy: productHierarchyFilters,
				},
			};

			const validOfferResult = await props.getValidOffers({
				payload: validOffersPayload,
				promo_id: promo_id,
				hierarchy_id: props.data.id,
			});
			const { newOfferTypes, newOfferValues } =
				offerValueOptionsFormatter(validOfferResult || []);
			setOfferTypeOptions(newOfferTypes);
			setOfferValueOptions(newOfferValues);
			if (!selected || !selectedOfferType) {
				setSelected(
					newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
						?.offer_value
				);
				setSelectedOfferType(Object.keys(newOfferTypes)?.[0]);

				const newCellData = {
					benefit_value:
						newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
							?.effective_discount,
					effective_promo_discount:
						newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
							?.effective_discount,
					offer_value:
						newOfferValues?.[Object.keys(newOfferTypes)?.[0]]?.[0]
							?.offer_value,
					offer_type: Object.keys(newOfferTypes)?.[0],
				};
				props.setValue(newCellData);
				if (!props.node.selected) {
					props.node.setSelected(true);
				}
			}
		} else if (
			discount_level === "overall" &&
			!_.isEmpty(props.validOffers?.[promo_id]?.overall)
		) {
			const offerTypeOptions = {};
			props.validOffers?.[promo_id]?.overall?.forEach((ot) => {
				offerTypeOptions[ot.offer_type] = ot.offer_display_name;
			});
			setOfferTypeOptions(offerTypeOptions);
			const offerValueOptions = {};
			props.validOffers?.[promo_id]?.overall?.map((ot) => {
				offerValueOptions[ot.offer_type] = ot.offer_values;
			});
			setOfferValueOptions(offerValueOptions);
		} else {
			// Check if the offerTypes and offerValues are just the initial values or have been set
			const offerTypeOptions = {};
			props.validOffers?.[promo_id]?.[props.data.id]?.forEach((ot) => {
				offerTypeOptions[ot.offer_type] = ot.offer_display_name;
			});
			setOfferTypeOptions(offerTypeOptions);
			const offerValueOptions = {};
			props.validOffers?.[promo_id]?.[props.data.id]?.map((ot) => {
				offerValueOptions[ot.offer_type] = ot.offer_values;
			});
			setOfferValueOptions(offerValueOptions);
		}
	};

	const offerTypeChangeHandler = async (e) => {
		let val = e.target.value;
		setSelectedOfferType(val);
		let newCellData = { ...props.value, offer_type: val };
		if (val !== selectedOfferType) {
			const newValue =
				(offerValueOptions[val] && offerValueOptions[val][0]) || {};
			newCellData = {
				...newCellData,
				benefit_value: newValue.effective_discount,
				effective_promo_discount: newValue.effective_discount,
				offer_value: newValue.offer_value,
			};
			props.setValue(newCellData);
			setSelected(newValue.offer_value);
			if (!props.node.selected) {
				props.node.setSelected(true);
			}
		}
	};

	return (
		<>
			<div className="finalize-cell-edit-container">
				<div className="finalize-offer-type-container">
					<FormControl className="finalize-offer-type-drop-down">
						<Select
							displayEmpty
							autoWidth
							value={selectedOfferType}
							onOpen={onOpenHandler}
							onChange={offerTypeChangeHandler}
							input={<OutlinedInput />}
							id="finalize-offer-type-select"
							inputProps={{ "aria-label": "Without label" }}
							disabled={eligibleFlag}
						>
							{map(Object.keys(offerTypeOptions), (option) => (
								<MenuItem key={option} value={option}>
									{offerTypeOptions[option]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="finalize-offer-value-container">
					<FormControl className="finalize-offer-value-drop-down">
						<Select
							displayEmpty
							autoWidth
							value={selected}
							onOpen={onOpenHandler}
							onChange={handleChange}
							input={<OutlinedInput />}
							id="finalize-offer-value-select"
							inputProps={{ "aria-label": "Without label" }}
							disabled={eligibleFlag}
						>
							{map(
								offerValueOptions[selectedOfferType],
								(option) => (
									<MenuItem
										key={option.offer_value}
										value={option.offer_value}
									>
										{option.offer_value}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (store) => {
	return {
		promoValues: store.promo.promoValues,
		promoTypes: store.promo.promoTypes,
		promoDetails: store.promo.promoDetails,
		validOffers: store.promo.validOffers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getValidOffers: (data, inlineEditFlag) =>
			dispatch(getValidOffers(data, inlineEditFlag)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeCellEdit);
