import { createSlice } from "@reduxjs/toolkit";

import { API } from "../../../utils/axios/index";
import {
    requestComplete,
    requestFail,
    requestStart,
    toastError,
    toastMessage,
} from "../global/global";

const initialState = {
    requests: [],
};

const approvalLogSlice = createSlice({
    name: "approvalLog",
    initialState,
    reducers: {
        setRequests(state, action) {
            const requests = action.payload;

            requests.sort((obj1, obj2) =>
                obj1.created_at < obj2.created_at ? 1 : -1
            );

            state.requests = requests;
        },
    },
});

export const { setRequests } = approvalLogSlice.actions;

export default approvalLogSlice.reducer;

export const fetchRequesterData = () => (dispatch) => {
    dispatch(requestStart());

    return API.get("/fetch_requester_table_data")
        .then((res) => {
            const { data } = res.data;

            dispatch(setRequests(data));

            dispatch(requestComplete());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Approval log data fetch failed";

            dispatch(requestFail(message));
        });
};

export const cancelApprovalRequest = (payload) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/cancel_request_data", payload)
        .then(() => {
            dispatch(
                requestComplete({
                    successMessage:
                        "Approval request(s) cancelled successfully",
                    success: true,
                })
            );

            dispatch(fetchRequesterData());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Approval request(s) cancellation failed";

            dispatch(requestFail(message));
        });
};

export const fetchApproverData = () => (dispatch) => {
    dispatch(requestStart());

    return API.get("/fetch_approver_table_data")
        .then((res) => {
            const { data } = res.data;

            dispatch(setRequests(data));

            dispatch(requestComplete());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Approval log data fetch failed";

            dispatch(requestFail(message));
        });
};

export const approveRequests = (payload) => (dispatch) => {
    dispatch(requestStart());

    return API.put("/update_approver_status", payload)
        .then(() => {
            dispatch(
                requestComplete({
                    successMessage: "Request(s) approved successfully",
                    success: true,
                })
            );

            dispatch(fetchApproverData());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Request(s) approval failed";

            dispatch(requestFail(message));
        });
};

export const rejectRequests = (payload) => (dispatch) => {
    dispatch(requestStart());

    return API.put("/update_reject_comment", payload)
        .then(() => {
            dispatch(
                requestComplete({
                    successMessage: "Request(s) rejected successfully",
                    success: true,
                })
            );

            dispatch(fetchApproverData());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Request(s) rejection failed";

            dispatch(requestFail(message));
        });
};

export const downloadFile = (data) => (dispatch) => {
    return API.get(`/download_request_file/${data}`, {
        responseType: "blob",
    }).catch((err) => {
        const message =
            err?.response?.data?.message ??
            "Approval request file download failed";

        dispatch(requestFail(message));

        throw message;
    });
};
