import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    TextField,
    Button,
    InputLabel,
    IconButton,
    InputAdornment,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import FirebaseLogin from "./FirebaseUI";
import { firebaseobj } from "./FirebaseInitialize";
import { toastError } from "../../../store/features/global/global";
import { samlLoginCallback } from "../../../store/features/auth/auth";

import styles from "../Login.module.scss";

const LoginForm = (props) => {
    const { email, password, onChange, onResetPassword, onSubmit, error } = {
        ...props,
    };

    const { REACT_APP_SAML_PROVIDER_ID } = process.env;

    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [showSSOSection, setShowSSOSection] = useState(true);

    return (
        <>
            <Box sx={{ color: "#000" }}>
                {showSSOSection ? (
                    <FirebaseLogin
                        FirebaseUIConfig={{
                            signInFlow: "popup",
                            // signInSuccessUrl: "/decision-dashboard",
                            signInOptions: [
                                {
                                    provider: REACT_APP_SAML_PROVIDER_ID,
                                    fullLabel: "Login with SSO",
                                    buttonColor: "#fff",
                                    iconUrl: "/IA.svg",
                                },
                            ],
                            callbacks: {
                                signInSuccessWithAuthResult: function (
                                    authResult,
                                    redirectUrl
                                ) {
                                    authResult.user
                                        .getIdToken()
                                        .then((accessToken) => {
                                            const samlLoginCallbackPayload = {
                                                idToken: accessToken,
                                            };
                                            dispatch(
                                                samlLoginCallback(
                                                    samlLoginCallbackPayload
                                                )
                                            ).catch((error) => {
                                                firebaseobj.auth().signOut();

                                                setShowSSOSection(false);

                                                setTimeout(() => {
                                                    setShowSSOSection(true);
                                                }, 0);
                                            });
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            dispatch(
                                                toastError(
                                                    "Unable to authenticate. Please contact the Administrator"
                                                )
                                            );
                                        });
                                    return false;
                                },
                                signInFailure: function (error) {
                                    dispatch(
                                        toastError(
                                            "Unable to authenticate. Please contact the Administrator"
                                        )
                                    );
                                },
                            },
                        }}
                        firebaseobj={firebaseobj}
                    />
                ) : null}
            </Box>
            <Divider sx={{ margin: "32px 100px" }} className="login-footer">
                Or continue with
            </Divider>
            <form
                className={`form-width ${styles["signin-form"]}`}
                onSubmit={onSubmit}
            >
                <div className={`${styles["input-group"]}`}>
                    <InputLabel margin="dense" htmlFor="loginInputEmail">
                        Email
                    </InputLabel>
                    <TextField
                        variant="outlined"
                        name="email"
                        type="email"
                        size="small"
                        id="loginInputEmail"
                        className={
                            error.email && error.email.length
                                ? `${styles["signin-form-input"]} ${styles["error-border"]}`
                                : styles["signin-form-input"]
                        }
                        aria-describedby="emailHelp"
                        placeholder="E-mail"
                        required=""
                        value={email}
                        onChange={onChange}
                        fullWidth
                    />
                    <p className={`${styles["error-text"]}`}>{error.email}</p>
                </div>
                <div className={`${styles["input-group"]}`}>
                    <InputLabel htmlFor="loginPassword">Password</InputLabel>
                    <TextField
                        variant="outlined"
                        name="password"
                        type={`${showPassword ? "text" : "password"}`}
                        size="small"
                        id="loginPassword"
                        className={
                            error.password && error.password.length
                                ? `${styles["signin-form-input"]} ${styles["error-border"]}`
                                : styles["signin-form-input"]
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        onMouseDown={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <VisibilityOutlinedIcon
                                                color="default"
                                                fontSize="small"
                                            />
                                        ) : (
                                            <VisibilityOffOutlinedIcon
                                                color="default"
                                                fontSize="small"
                                            />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Password"
                        required=""
                        fullWidth
                        value={password}
                        onChange={onChange}
                    />
                    <p className={`${styles["error-text"]}`}>
                        {error.password}
                    </p>
                </div>
                {/* <Button
                    id="btnReset"
                    variant="text"
                    className={`${styles["btn"]}`}
                    onClick={onResetPassword}
                >
                    Forgot Password?
                </Button> */}
                <Button
                    type="submit"
                    fullWidth
                    id="btnLogin"
                    variant="contained"
                    className={`${styles["btn-signin"]}`}
                >
                    Sign in
                </Button>
            </form>
        </>
    );
};

export default LoginForm;
