import React, { useEffect, useState } from "react";
import _ from "lodash";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { color } from "highcharts";
import "../tableRenderer/AgGridTable.scss";

export default (props) => {
	console.log(props);
	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	const [status, setStatus] = useState("");

	const handleClick = () => {
		// props.handleLockUnlock(props.data);
	};

	return (
		<div className="promo-name-container">
			<span className="promo-status">
				{props.data?.status === 6
					? "A"
					: props.data?.status === 0 && props.data?.copied_from
					? "C"
					: props.data?.status === 0
					? "D"
					: props.data?.status === 2
					? "P"
					: props.data?.status === 4
					? "F"
					: "D"}
			</span>
			<span>{cellValue} </span>
		</div>
	);
};
