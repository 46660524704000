import { useState, useEffect } from "react";
import { map } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

const OPTIONS = [
    { key: "Yes", value: 1 },
    { key: "No", value: 0 },
];
const VALUES = [1, 0];

export const DropdownCellRendrer = (props) => {
    const [selected, setSelected] = useState("");
    useEffect(() => {
        if (props.data.under_process === true && props.value !== undefined) {
            setSelected(props.value ? 0 : 1);
        }
        if (props.value !== undefined && !props.data.under_process) {
            const cellVal = VALUES.includes(props.value) ? props.value : "";
            setSelected(cellVal);
        }
    }, []);
    const handleChange = (e) => {
        let val = e.target.value;
        setSelected(val);
        props.setValue(val);

        if (!props.node.selected) {
            props.node.setSelected(true);
        }
        if (props.node.selected && val === props.data.initial_doorbuster) {
            props.node.setSelected(false);
        }
    };

    return (
        <>
            {selected !== "" ? (
                <div className="comment-dropdown">
                    <Select
                        disabled={
                            props.data.is_locked === "Approved" ||
                            props.data.under_process
                                ? true
                                : false
                        }
                        displayEmpty
                        autoWidth
                        value={selected}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        id="comment-simple-select"
                    >
                        {map(OPTIONS, (option) => (
                            <MenuItem key={option.key} value={option.value}>
                                {option.key}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            ) : (
                (() => {
                    return "-";
                })()
            )}
        </>
    );
};
