import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import {
    RegionPricingTabHeader,
    TabPanel,
} from "./EUPricePlanningFilterAttributesMaterials-New";
import EUPricPlanningDetailsSlider from "./EUPricePlanningDetailsSlider";
import EUPricePlanningPlanGroupSelection from "./EUPricePlanningPlanGroupSelection";
import EUPriceListLevelPrices from "./EUPriceListLevelPrices";
import EUCountryLevelPrices from "./EUCountryLevelPrices";
import {
    fetchCountryLevelPrices,
    getPriceFileMaterials,
    resetPageData,
    setCurrentTab,
} from "../../../store/features/workbench/workbench";
import { getMaterialFetchData } from "./euHelper";
import { toastError } from "../../../store/features/global/global";
import {
    resetSavedFilters,
    setSavedFilters,
} from "../../../store/features/filters/filters";
import { HISTORICAL_DATA } from "../../../constants/Constants";
import EUBuyersScreen from "./rollupsComponents/EUBuyersScreen";

import styles from "./EUPricePlanning.module.scss";

export const page = "filterByPlangroup";

export const RegionalPricingTab = (props, selectedMarkets) =>
    !HISTORICAL_DATA.includes(
        selectedMarkets?.length > 0 && selectedMarkets[0]?.label
    ) ? (
        <RegionPricingTabHeader {...props} />
    ) : (
        ""
    );

function EUPricePlanningPlanGroupMaterials(props) {
    const { onReuploadPriceFileClick } = props;

    const dispatch = useDispatch();

    const [showSlidingOverlay, setShowSlidingOverlay] = useState(false);

    const {
        selectedMarkets,
        selectedRegions,
        selectedChannels,
        priceFileId,
        countryPriceRequestPendingMaterials,
    } = useSelector((state) => state.workbench.general);
    const {
        compLoading: {
            basePricing: basePricingLoader,
            regionalPricing: regionalPricingLoader,
        },
    } = useSelector((state) => state.global);
    const { currentTab, selectedPlanGroup } = useSelector(
        (state) => state.workbench[page]
    );

    const regionalPricingTab = useCallback(
        (props) => RegionalPricingTab(props, selectedMarkets),
        [selectedMarkets]
    );

    useEffect(() => {
        return () => {
            dispatch(resetSavedFilters({ screen: "EU_VIEW_BY_PLAN_GROUPS" }));
            dispatch(resetSavedFilters({ screen: "EU_SELECT_PLAN_GROUPS" }));

            dispatch(resetPageData({ page }));
        };
    }, []);

    useEffect(() => {
        if (
            selectedMarkets.length &&
            selectedRegions.length &&
            selectedChannels.length &&
            selectedPlanGroup
        ) {
            const filters = {
                SELECT_FILTERS_MERCH:
                    selectedPlanGroup["merchandise_hierarchy"] ?? {},
                SELECT_FILTERS_PRODUCT:
                    selectedPlanGroup["product_hierarchy"] ?? {},
                SELECT_FILTERS_PLANNING:
                    selectedPlanGroup["planning_hierarchy"] ?? {},
            };

            dispatch(
                setSavedFilters({
                    screen: "EU_VIEW_BY_PLAN_GROUPS",
                    data: filters,
                })
            );

            const data = getMaterialFetchData(
                filters,
                selectedMarkets,
                selectedRegions,
                selectedChannels,
                priceFileId
            );

            dispatch(getPriceFileMaterials({ page, data })).then((res) => {
                if (!res?.table_data.length) {
                    dispatch(
                        toastError(
                            "No material tagged for the selection you have made"
                        )
                    );
                }

                dispatch(
                    fetchCountryLevelPrices({
                        page,
                        data: {
                            ...data,
                            price_file_material_ids: res?.table_data.map(
                                (e) => e.id
                            ),
                        },
                    })
                );
            });
        }
    }, [selectedMarkets, selectedRegions, selectedChannels, selectedPlanGroup]);

    const onTabClick = (_, newTab) => {
        dispatch(setCurrentTab({ page, data: newTab }));
    };

    const navigateToCountryLevelPrices = () => {
        onTabClick(null, "country");
    };

    return (
        <>
            {showSlidingOverlay && (
                <EUPricPlanningDetailsSlider
                    setShowSlidingOverlay={setShowSlidingOverlay}
                />
            )}
            <div className={`${styles["view-by-filtering-attributes"]}`}>
                <EUPricePlanningPlanGroupSelection />
                {selectedPlanGroup && (
                    <div className={`${styles["level-price-container"]}`}>
                        <TabContext value={currentTab}>
                            <TabList onChange={onTabClick}>
                                <Tab label="Base Pricing" value="price-list" />
                                <Tab
                                    label="Regional Pricing"
                                    value="country"
                                    component={regionalPricingTab}
                                    disabled={
                                        countryPriceRequestPendingMaterials.length ||
                                        basePricingLoader !== 0 ||
                                        regionalPricingLoader !== 0
                                    }
                                />
                                <Tab label="Buying Rollups" value="buyer" />
                            </TabList>
                            <TabPanel
                                value="price-list"
                                currentTab={currentTab}
                            >
                                <EUPriceListLevelPrices
                                    page="filterByPlangroup"
                                    filterPage="EU_VIEW_BY_PLAN_GROUPS"
                                    navigateToCountryLevelPrices={
                                        navigateToCountryLevelPrices
                                    }
                                    onReuploadPriceFileClick={
                                        onReuploadPriceFileClick
                                    }
                                    currentTab={currentTab}
                                />
                            </TabPanel>
                            <TabPanel value="country" currentTab={currentTab}>
                                <EUCountryLevelPrices
                                    page="filterByPlangroup"
                                    filterPage="EU_VIEW_BY_PLAN_GROUPS"
                                    currentTab={currentTab}
                                />
                            </TabPanel>
                            <TabPanel value="buyer" currentTab={currentTab}>
                                <EUBuyersScreen
                                    page={page}
                                    filterPage="EU_VIEW_BY_PLAN_GROUPS"
                                    currentTab={currentTab}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                )}
            </div>
        </>
    );
}

export default EUPricePlanningPlanGroupMaterials;
