import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
    requestStart,
    requestComplete,
    requestFail,
    toastError,
} from "../global/global";
import { setFilteredData } from "../filters/filters";
import { data } from "./metricsData";

const initialState = {
    metricsData: [],//[...data],
    metricsTableData: [],
    productsData:[]
};

const dashboardSlice = createSlice({
    name: "event",
    initialState,
    reducers: {
        setMetricsData(state, action) {
            state.metricsData = action.payload;
        },
        setMetricsTableData(state, action) {
            state.metricsTableData = action.payload;
        },
        setProductsData(state, action) {
            state.productsData = action.payload;
        },
    },
});

export const { setMetricsData, setMetricsTableData, setProductsData } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const getMetricsData = (payload) => (dispatch) => {
    dispatch(requestStart());
    return API.post("/metrics", payload)
        .then((response) => {
            if (response.status === 200) {
                const { data } = response.data;
                dispatch(setMetricsData(data));
                dispatch(setMetricsTableData(data));
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

export const getMetricsTableData = (payload) => (dispatch) => {
    dispatch(requestStart());
    return API.post("/metrics", payload)
        .then((response) => {
            if (response.status === 200) {
                const { data } = response.data;
                dispatch(setMetricsTableData(data));
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

export const bulkEditPromoName = (payload) => (dispatch) => {
    dispatch(requestStart());
    return API.post("/model-update", payload)
        .then((response) => {
            if (response.status === 200) {
                // const { data } = response.data;
                console.log(response);
                // dispatch(setMetricsTableData(data));
                dispatch(requestComplete());
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

export const getProducts = (payload) => (dispatch) => {
    dispatch(requestStart());
    return API.post("/model-data", payload)
        .then((response) => {
            if (response.status === 200) {
                const { data } = response.data;
                dispatch(setProductsData(data));
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};
