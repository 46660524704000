import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import Select from "../filters/Select/Select";
import { API } from "../../../utils/axios";

// import styles from "./RegionHierarchyFilter.module.scss";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "0 0.7rem 0 0",
    textAlign: "left",
    boxShadow: "none",
    // color: theme.palette.text.secondary,
}));

function RegionHierarchyFilter(props) {
    const { screenName, selectedFilters, onFilterSelect } = props;

    const [options, setOptions] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});

    const hierarchyConfig = useSelector(
        (store) => store.global.screenConfig[screenName].filter_options
    );
    const elementLabels = useSelector((store) => store.global.elementLabels);

    useEffect(() => {
        setSelectedOptions(selectedFilters ?? {});
    }, [selectedFilters]);

    useEffect(() => {
        API.get("/regions").then((res) => {
            setOptions((prevValue) => {
                const newValue = { ...prevValue };

                newValue[hierarchyConfig[0].type] = res.data.data;

                return newValue;
            });
        });
    }, []);

    // useEffect(() => {
    //     onFilterSelect(selectedOptions);
    // }, [selectedOptions]);

    const onSelectChange = useCallback(
        (hierarchyId, selectedItems) => {
            setSelectedOptions((prevValue) => {
                const newValue = { ...prevValue };

                newValue[hierarchyId] = selectedItems;

                if (hierarchyId === hierarchyConfig[0].type) {
                    delete newValue[hierarchyConfig[1].type];
                }

                onFilterSelect(newValue);

                return newValue;
            });

            if (hierarchyId === hierarchyConfig[0].type) {
                setOptions((prevValue) => {
                    const newValue = { ...prevValue };

                    delete newValue[hierarchyConfig[1].type];

                    return newValue;
                });

                if (selectedItems?.length) {
                    API.get(`/regions/${selectedItems[0].value}/channels`).then(
                        (res) => {
                            setOptions((prevValue) => {
                                const newValue = { ...prevValue };

                                newValue[hierarchyConfig[1].type] =
                                    res.data.data;

                                return newValue;
                            });
                        }
                    );
                }
            }
        },
        [selectedOptions, onFilterSelect]
    );

    const onSelectOpen = useCallback(() => {
        const selectedRegions = selectedOptions[hierarchyConfig[0].type];

        if (
            selectedRegions?.length &&
            !options[hierarchyConfig[1].type]?.length
        ) {
            API.get(`/regions/${selectedRegions[0].value}/channels`).then(
                (res) => {
                    setOptions((prevValue) => {
                        const newValue = { ...prevValue };

                        newValue[hierarchyConfig[1].type] = res.data.data;

                        return newValue;
                    });
                }
            );
        }
    }, [options, selectedOptions]);

    return (
        <Paper className="filter-group-container" elevation={0}>
            <Box sx={{ flexGrow: 1, margin: "0 10px" }}>
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 6, md: 12, lg: 10 }}
                >
                    <Grid item xs={2} sm={2} md={3} lg={2}>
                        <Item className="filterGroup">
                            <label>
                                {elementLabels[hierarchyConfig[0].type]}
                                {hierarchyConfig[0].is_mandatory ? (
                                    <span style={{ color: "red" }}> * </span>
                                ) : null}
                            </label>
                            <Select
                                initialData={
                                    options?.[hierarchyConfig[0].type] || []
                                }
                                selectedOptions={
                                    selectedOptions?.[
                                        hierarchyConfig[0].type
                                    ] || []
                                }
                                updateSelected={(data) =>
                                    onSelectChange(
                                        hierarchyConfig[0].type,
                                        data.selectedItems
                                    )
                                }
                                onDropdownOpen={() => {}}
                                forceApiCall
                                updateSelectedOnEachSelection
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={2} sm={2} md={3} lg={2}>
                        <Item className="filterGroup">
                            <label>
                                {elementLabels[hierarchyConfig[1].type]}
                                {hierarchyConfig[1].is_mandatory ? (
                                    <span style={{ color: "red" }}> * </span>
                                ) : null}
                            </label>
                            <Select
                                initialData={
                                    options?.[hierarchyConfig[1].type] || []
                                }
                                selectedOptions={
                                    selectedOptions?.[
                                        hierarchyConfig[1].type
                                    ] || []
                                }
                                updateSelected={(data) =>
                                    onSelectChange(
                                        hierarchyConfig[1].type,
                                        data.selectedItems
                                    )
                                }
                                onDropdownOpen={() => onSelectOpen()}
                                is_multiple_selection
                                forceApiCall
                            />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

export default RegionHierarchyFilter;
