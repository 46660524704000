import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import ClearAllFilterButton from "../../common/clearAllFilterButton/ClearAllFilterButton";
import { Tooltip } from "@mui/material";

import FilterOverlay from "../../common/filters/FilterOverlay";
import CustomAccordion from "../../common/customAccordian/CustomAccordion";
import FilterSummary from "../../common/filters/FilterSummary";
import AgGridTableRenderer from "../../../utils/reactTable/tableRenderer/AgGridTableRenderer";
import { setSavedFilters } from "../../../store/features/filters/filters";
import { fetchPlangroups } from "../../../store/features/plangroup/plangroup";
import { isEmpty } from "lodash";
import { AgGridReact } from "ag-grid-react";
import { fallbackFormatter } from "../../../utils/helpers/formatter";
import TableRowCount from "../../common/tableRowCount/TableRowCount";
import useTableRowCount from "../../common/tableRowCount/useTableRowCount";
import { setSelectedPlanGroup } from "../../../store/features/workbench/workbench";
import styles from "./EUPricePlanning.module.scss";
import { toastError } from "../../../store/features/global/global";

const filterValueGetter = ({ getValue, column: { colId } }) => {
    return (
        getValue(colId)
            ?.map?.((obj) => obj.label)
            .join(",") ?? ""
    );
};

const cellRenderer = (props) => {
    const value = props.value;
    const text =
        (Array.isArray(value)
            ? value.map((obj) => obj.label).join(",")
            : value) ?? "-";

    if (text !== "-") {
        return (
            <Tooltip
                classes={{
                    tooltip: styles["cell-tooltip-text"],
                    popper: styles["cell-tooltip-container"],
                }}
                placement="top-start"
                title={text}
            >
                <div>{text}</div>
            </Tooltip>
        );
    }

    return text;
};

const comparator = (a, b) => {
    if (!a) {
        if (!b) return 0;
        return -1;
    }
    if (!b) return 1;
    return (a?.map?.((obj) => obj.label).join(",") ?? "") >
        (b?.map?.((obj) => obj.label).join(",") ?? "")
        ? 1
        : -1;
};

function EUPricePlanningPlanGroupSelection(props) {
    const dispatch = useDispatch();
    const gridRef = useRef();

    const [showFilter, setShowFilter] = useState(false);
    const [filterSummary, setFilterSummary] = useState([]);
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);
    const [filteredPlangroups, setFilteredPlangroups] = useState([]);
    const [expandAccordion, setExpandAccordion] = useState(true);
    const [isPlangroupFetched, setIsPlangroupFetched] = useState(false);

    const selectedFilters = useSelector(
        (state) => state.filters.savedFilters["EU_SELECT_PLAN_GROUPS"]
    );
    const elementLabels = useSelector((state) => state.global.elementLabels);
    const plangroups = useSelector((state) => state.plangroup.plangroups);
    const { selectedRegions, selectedChannels } = useSelector(
        (state) => state.workbench.general
    );
    const { selectedPlanGroup } = useSelector(
        (state) => state.workbench.filterByPlangroup
    );

    const colDefs = [
        {
            headerName: "PLAN GROUP",
            field: "name",
            pinned: true,
            lockPinned: true,
            cellClass: ({ data }) =>
                +data.plan_group_id !== +selectedPlanGroup?.plan_group_id
                    ? styles["dark-cell"]
                    : "",
            cellStyle: ({ data }) =>
                +data.plan_group_id !== +selectedPlanGroup?.plan_group_id
                    ? {
                          color: "#0054af",
                          fontWeight: 600,
                          cursor: "pointer",
                      }
                    : {},
            cellRenderer,
        },
        {
            headerName: "BRAND GROUP",
            field: "merchandise_hierarchy.product_h4",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "MERCH DIVISION",
            field: "merchandise_hierarchy.product_h1",
            cellRenderer,
            comparator,
            filterValueGetter,
            // cellStyle: {
            //     width: "500px",
            //     overflow: "hidden",
            // },
            // width: 500,
            suppressAutoSize: true,
        },
        {
            headerName: "MERCH ORG",
            field: "merchandise_hierarchy.product_h2",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "GENDER",
            field: "merchandise_hierarchy.product_h3",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "BRAND",
            field: "merchandise_hierarchy.product_h5",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "MERCH SEGMENT",
            field: "merchandise_hierarchy.product_h6",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "MERCH SIZE GROUP",
            field: "merchandise_hierarchy.product_h7",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "MERCH CATEGORY",
            field: "merchandise_hierarchy.product_h8",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "PRODUCT CATEGORY",
            field: "product_hierarchy.product_h9",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "PRODUCT CLASS",
            field: "product_hierarchy.product_h10",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
        {
            headerName: "PRODUCT SUBCLASS",
            field: "product_hierarchy.product_h11",
            cellRenderer,
            comparator,
            filterValueGetter,
        },
    ];

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    useEffect(() => {
        if (!plangroups?.length) {
            dispatch(fetchPlangroups()).then(() => {
                setIsPlangroupFetched(true);
            });
        } else {
            setIsPlangroupFetched(true);
        }

        if (selectedPlanGroup) {
            setExpandAccordion(false);
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setSelectedPlanGroup(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (!isPlangroupFetched) {
            return;
        }

        const {
            SELECT_FILTERS_MERCH: merchFilters,
            SELECT_FILTERS_PRODUCT: productFilters,
            SELECT_FILTERS_PLANNING: planningFilters,
        } = selectedFilters;

        const newFilteredPlangroups = plangroups.filter((plangroup) => {
            {
                let matched = false;

                for (const regionObj of selectedRegions) {
                    if (plangroup.region.value === parseInt(regionObj.value)) {
                        matched = true;
                    }
                }

                if (!matched) {
                    return false;
                }
            }

            {
                let matched = false;

                for (const channelObj of selectedChannels) {
                    for (const plangroupChannelObj of plangroup.channel) {
                        if (
                            parseInt(channelObj.value) ===
                            plangroupChannelObj.value
                        ) {
                            matched = true;
                        }
                    }
                }

                if (!matched) {
                    return false;
                }
            }

            if (!isEmpty(merchFilters)) {
                for (let index = 1; index <= 8; index++) {
                    const hierarchyKey = "product_h" + index;

                    if (!merchFilters[hierarchyKey]) {
                        continue;
                    }

                    if (!plangroup.merchandise_hierarchy?.[hierarchyKey]) {
                        return false;
                    }

                    let matched = false;

                    for (const merchObj of merchFilters[hierarchyKey]) {
                        for (const plangroupObj of plangroup
                            .merchandise_hierarchy[hierarchyKey]) {
                            if (merchObj.value === plangroupObj.value) {
                                matched = true;
                            }
                        }
                    }

                    if (!matched) {
                        return false;
                    }
                }
            }

            if (!isEmpty(productFilters)) {
                for (let index = 9; index <= 11; index++) {
                    const hierarchyKey = "product_h" + index;

                    if (!productFilters[hierarchyKey]) {
                        continue;
                    }

                    if (!plangroup.product_hierarchy?.[hierarchyKey]) {
                        return false;
                    }

                    let matched = false;

                    for (const merchObj of productFilters[hierarchyKey]) {
                        for (const plangroupObj of plangroup.product_hierarchy[
                            hierarchyKey
                        ]) {
                            if (merchObj.value === plangroupObj.value) {
                                matched = true;
                            }
                        }
                    }

                    if (!matched) {
                        return false;
                    }
                }
            }

            if (!isEmpty(planningFilters)) {
                for (let index = 12; index <= 16; index++) {
                    const hierarchyKey = "product_h" + index;

                    if (!planningFilters[hierarchyKey]) {
                        continue;
                    }

                    if (!plangroup.plan_hierarchy?.[hierarchyKey]) {
                        return false;
                    }

                    let matched = false;

                    for (const merchObj of planningFilters[hierarchyKey]) {
                        for (const plangroupObj of plangroup.plan_hierarchy[
                            hierarchyKey
                        ]) {
                            if (merchObj.value === plangroupObj.value) {
                                matched = true;
                            }
                        }
                    }

                    if (!matched) {
                        return false;
                    }
                }
            }

            return true;
        });

        setFilteredPlangroups(newFilteredPlangroups);

        if (!newFilteredPlangroups.length) {
            dispatch(
                toastError("No plangroup found for the selection you have made")
            );
        }
    }, [
        selectedFilters,
        plangroups,
        selectedRegions,
        selectedChannels,
        isPlangroupFetched,
        dispatch,
    ]);

    useEffect(() => {
        const newFilterSummary = [];

        for (const selectedFilter of Object.values(selectedFilters)) {
            for (const [key, value] of Object.entries(selectedFilter)) {
                if (elementLabels[key]) {
                    newFilterSummary.push({
                        label: elementLabels[key],
                        value: value.map((obj) => obj.label),
                    });
                }
            }
        }

        setFilterSummary(newFilterSummary);
    }, [selectedFilters, elementLabels]);

    const onFilterSubmit = (data) => {
        dispatch(setSavedFilters({ screen: "EU_SELECT_PLAN_GROUPS", data }));
    };

    const onFilterOverlayClose = () => {
        setShowFilter(false);
    };

    const handleAccordion = () => {
        setExpandAccordion((prevValue) => !prevValue);
    };

    const onSelectFilterClick = () => {
        setShowFilter(true);
    };

    const onTableDataChange = (event) => {
        // event.columnApi.autoSizeAllColumns();

        onTableModelUpdate(event);
    };

    const onCellClicked = (event) => {
        if (event.column.colId !== "name") {
            return;
        }

        dispatch(setSelectedPlanGroup(event.data));

        setExpandAccordion(false);
    };

    const getRowClass = useCallback(
        ({ data, rowIndex }) => {
            if (+data.plan_group_id !== +selectedPlanGroup?.plan_group_id)
                return "";

            gridRef.current.api.ensureIndexVisible(rowIndex, "middle");

            return "ag-highlighted-row";
        },
        [selectedPlanGroup]
    );

    useEffect(() => {
        if (selectedPlanGroup?.plan_group_id)
            gridRef.current?.api?.redrawRows();
    }, [selectedPlanGroup]);

    const onGridReady = () => {
        gridRef.current.api.addEventListener("filterChanged", (event) => {
            setIsAnyFilterApplied(gridRef?.current?.api?.isAnyFilterPresent());
        });
    };

    return (
        <div className={`${styles["select-plan-groups-container"]}`}>
            {/* <FilterOverlay
                screen="EU_SELECT_PLAN_GROUPS"
                show={showFilter}
                onFilterSubmit={onFilterSubmit}
                onClose={onFilterOverlayClose}
                hideRegionAndChannel
            /> */}
            <CustomAccordion
                label="Select Plan Groups"
                expanded={expandAccordion}
                handleAccordion={handleAccordion}
            >
                <div
                    // className={`${styles["page-header"]} align-center justify-space-between`}
                    className={`align-center justify-space-between`}
                >
                    <div className={`${styles["page-title"]}`}></div>

                    {/* <Button
                        variant="contained"
                        startIcon={<FilterAltOutlinedIcon fontSize="small" />}
                        onClick={onSelectFilterClick}
                    >
                        Select Filters
                    </Button> */}
                    <div style={{ position: "absolute", top: 13, right: 15 }}>
                        <ClearAllFilterButton
                            isVisible={isAnyFilterApplied}
                            agGridApi={gridRef?.current?.api}
                        />
                    </div>
                </div>
                {/* <FilterSummary summary={filterSummary}></FilterSummary> */}
                {/* <AgGridTableRenderer
                    data={filteredPlangroups}
                    from="workbenchViewPlanGroupTable"
                    rowSelectionHandler={() => {}}
                    onFilterChanged={() => {}}
                    autoSizeAllColumns
                /> */}
                <div
                    style={{ height: "calc(100vh - 400px)" }}
                    className="ag-theme-alpine"
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={filteredPlangroups}
                        onGridReady={onGridReady}
                        // rowHeight="60"
                        columnDefs={colDefs}
                        rowSelection="multiple"
                        defaultColDef={{
                            sortable: true,
                            // flex: 1,
                            filter: "agMultiColumnFilter",
                            resizable: true,
                            floatingFilter: true,
                            menuTabs: ["filterMenuTab", "generalMenuTab"],
                            valueFormatter: fallbackFormatter,
                            cellStyle: {
                                display: "flex",
                                alignItems: "center",
                            },
                            floatingFilterComponentParams: {
                                suppressFilterButton: true,
                            },
                        }}
                        suppressRowClickSelection
                        suppressClickEdit
                        suppressMovableColumns
                        suppressColumnVirtualisation
                        onModelUpdated={onTableDataChange}
                        onCellClicked={onCellClicked}
                        onSelectionChanged={onTableSelectChange}
                        getRowClass={getRowClass}
                    />
                </div>
                <TableRowCount count={rowCount} selected={selectedRowCount} />
            </CustomAccordion>
        </div>
    );
}

export default EUPricePlanningPlanGroupSelection;
