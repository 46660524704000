import React, { useEffect, useRef, useState } from "react";
import { Switch } from "@mui/material";
import AlertExceptionEnabledCell from "./AlertExceptionEnabledCell";
import { cloneDeep } from "lodash";

function SwitchCellRenderer({
    cellProps,
    editMode,
    selectedOptions,
    setData,
    disabled,
    isMandatoryColumn,
    onChange,
    editData,
}) {
    const [state, setState] = useState(
        editData[cellProps.data.id]?.[cellProps.column.colId] ? true : false
    );

    useEffect(() => {
        setState(
            editData[cellProps.data.id]?.[cellProps.column.colId] ? true : false
        );
    }, [editData, cellProps]);

    // const ref = useRef(Math.random());

    // if (cellProps.rowIndex === 0) console.log(ref, cellProps.rowIndex);

    return editMode &&
        selectedOptions.find((e) => e.rowIndex === cellProps.rowIndex) ? (
        <Switch
            checked={state}
            disabled={disabled}
            onChange={(val) => {
                const newValue = val.target.checked;

                setState(newValue);

                onChange(cellProps, isMandatoryColumn, newValue);

                // setData((prev) => {
                //     const newDt = cloneDeep(prev);
                //     const currentRow = newDt.find(
                //         (dt) => dt.id === cellProps.data.id
                //     );

                //     currentRow[cellProps.column.colId] = newValue
                //         ? 1
                //         : 0;

                //     if (isMandatoryColumn && newValue) {
                //         currentRow.is_enabled = 1;
                //     }

                //     return newDt;
                // });
            }}
        />
    ) : (
        <AlertExceptionEnabledCell {...cellProps} />
    );
}

export default SwitchCellRenderer;
