import { createSlice } from "@reduxjs/toolkit";

import { API } from "../../../utils/axios/index";
import {
    toastError,
    toastMessage,
    requestStart,
    requestFail,
    requestComplete,
} from "../global/global";

const initialState = {
    plangroups: [],
};

function sortPlangroups(plangroups) {
    plangroups.sort((plangroup1, plangroup2) => {
        if (plangroup1.region.label !== plangroup2.region.label) {
            return plangroup1.region.label.toLowerCase() <
                plangroup2.region.label.toLowerCase()
                ? -1
                : 1;
        }

        const channelStr1 = plangroup1.channel
            .map((obj) => obj.label)
            .join(",");
        const channelStr2 = plangroup2.channel
            .map((obj) => obj.label)
            .join(",");

        if (channelStr1 !== channelStr2) {
            return channelStr1.toLowerCase() < channelStr2.toLowerCase()
                ? -1
                : 1;
        }

        return plangroup1.name.toLowerCase() < plangroup2.name.toLowerCase()
            ? -1
            : 1;
    });
}

const plangroupSlice = createSlice({
    name: "plangroup",
    initialState,
    reducers: {
        setPlangroups(state, action) {
            sortPlangroups(action.payload);

            state.plangroups = action.payload;
        },
    },
});

export const { setPlangroups } = plangroupSlice.actions;

export default plangroupSlice.reducer;

export const fetchPlangroups = () => (dispatch) => {
    dispatch(requestStart());

    return API.get("/plan-groups")
        .then((response) => {
            const { data: plangroups } = response.data;

            plangroups.forEach((plangroup) => {
                if (plangroup.merchandise_hierarchy) {
                    plangroup.merchandise_hierarchy.forEach((hierarchy) => {
                        for (const hierarchyKey in hierarchy) {
                            hierarchy[hierarchyKey] = hierarchy[
                                hierarchyKey
                            ].map(
                                // (str) => JSON.parse(str)
                                (obj) => obj
                            );
                        }
                    });

                    const hierarchyObj = {};

                    plangroup.merchandise_hierarchy.forEach((hierarchy) => {
                        for (const hierarchyKey in hierarchy) {
                            hierarchyObj[hierarchyKey] =
                                hierarchy[hierarchyKey];
                        }
                    });

                    plangroup.merchandise_hierarchy = hierarchyObj;
                }

                if (plangroup.product_hierarchy) {
                    plangroup.product_hierarchy.forEach((hierarchy) => {
                        for (const hierarchyKey in hierarchy) {
                            hierarchy[hierarchyKey] = hierarchy[
                                hierarchyKey
                            ].map(
                                // (str) => JSON.parse(str)
                                (obj) => obj
                            );
                        }
                    });

                    const hierarchyObj = {};

                    plangroup.product_hierarchy.forEach((hierarchy) => {
                        for (const hierarchyKey in hierarchy) {
                            hierarchyObj[hierarchyKey] =
                                hierarchy[hierarchyKey];
                        }
                    });

                    plangroup.product_hierarchy = hierarchyObj;
                }

                if (plangroup.plan_hierarchy) {
                    plangroup.plan_hierarchy.forEach((hierarchy) => {
                        for (const hierarchyKey in hierarchy) {
                            hierarchy[hierarchyKey] = hierarchy[
                                hierarchyKey
                            ].map(
                                // (str) => JSON.parse(str)
                                (obj) => obj
                            );
                        }
                    });

                    const hierarchyObj = {};

                    plangroup.plan_hierarchy.forEach((hierarchy) => {
                        for (const hierarchyKey in hierarchy) {
                            hierarchyObj[hierarchyKey] =
                                hierarchy[hierarchyKey];
                        }
                    });

                    plangroup.plan_hierarchy = hierarchyObj;
                }
            });

            dispatch(setPlangroups(plangroups));

            dispatch(requestComplete());

            return plangroups;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Plan Groups fetch failed";

            dispatch(requestFail(message));
        });
};

const processPlanGroupPayload = (data) => {
    if (!data.merchandise_hierarchy) {
        data.merchandise_hierarchy = {};
    }
    if (!data.product_hierarchy) {
        data.product_hierarchy = {};
    }
    if (!data.plan_hierarchy) {
        data.plan_hierarchy = {};
    }
};

export const createPlanGroup = (data) => (dispatch) => {
    dispatch(requestStart());

    processPlanGroupPayload(data);

    return API.post("/plan-group", data)
        .then((response) => {
            const { data, message } = response.data;

            if (!data) {
                dispatch(requestFail(message));
            } else {
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Plan Group created successfully.",
                    })
                );
            }

            return dispatch(fetchPlangroups());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Plan Group create failed";

            dispatch(requestFail(message));
        });
};

export const editPlanGroup = (data) => (dispatch) => {
    dispatch(requestStart());

    processPlanGroupPayload(data);

    return API.post("/edit", data)
        .then((response) => {
            const { data, message } = response.data;

            if (!data) {
                dispatch(requestFail(message));
            } else {
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Plan Group edited successfully.",
                    })
                );
            }

            return dispatch(fetchPlangroups());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Plan Group edit failed";

            dispatch(requestFail(message));
        });
};

export const clonePlanGroup = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/clone_plan_group", data)
        .then((response) => {
            const { data, message } = response.data;

            if (!data) {
                dispatch(requestFail(message));
            } else {
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Plan Group cloned successfully.",
                    })
                );
            }

            return dispatch(fetchPlangroups());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Plan Group clone failed";

            dispatch(requestFail(message));
        });
};

export const deletePlanGroups = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/delete-plan-group", data)
        .then(() => {
            dispatch(
                requestComplete({
                    success: true,
                    successMessage: "Plan Group deleted successfully.",
                })
            );

            return dispatch(fetchPlangroups());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Plan Group delete failed";

            dispatch(requestFail(message));
        });
};
