import React, { useState } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";
import { map } from "lodash";
import brewImage from "../../../assets/images/brewing.gif";
import "./LoaderBrew.scss";

export const LoadingOverlayBrew = (props) => {
	const [showOptions, showOptionsHandler] = useState(true);
	const [showOtherPlans, showOtherPlanOptions] = useState(false);
	const [showOtherScreens, showOtherScreensOptions] = useState(false);

	const screens = {
		summary: {
			screenName: "Summary Screen",
			link: "/vis/Markdown/summary",
		},
		reporting: {
			screenName: "Reporting Screen",
			link: "/vis/Markdown/reporting",
		},
		draft: {
			screenName: "Draft Screen",
			link: "/vis/Markdown/draftStatus",
		},
		config: {
			screenName: "Configuration Screen",
			link: "/vis/Markdown/configuration",
		},
	};

	return (
		<Modal
			aria-labelledby="optimization-brewing"
			aria-describedby="optimization-brewing"
			className="optimization-loader"
			open={props.showLoader}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.showLoader}>
				<div className="overlayContainer">
					<img
						className="brewingGIF"
						src={brewImage}
						alt="Generate Cadence Brewing"
					/>
					<p className="imageText">
						Your optimization is brewing ...
					</p>
					{/* {!props.showOptions ? (
						showOptions ? (
							<>
								<p className="overlayText">
									While the plan is getting created, do you
									want to
								</p>
								<div className="btnSection">
									<Dropdown
										isOpen={showOtherPlans}
										onClose={() =>
											showOtherPlanOptions(false)
										}
										target={
											<button
												id="dropdown-button"
												data-testid="dropdown-button"
												onClick={() =>
													showOtherPlanOptions(true)
												}
												className="createAnotherPlanBtn"
											>
												<span className="selected-text">
													Create another plan
												</span>
											</button>
										}
									>
										<div>
											<div
												className="createdropdownOption"
												// onClick={() =>
												// 	props.createNewPlan(true)
												// }
											>
												New Plan
											</div>
											{props.country[0] === "US" && (
												<div
													className="createdropdownOption"
													onClick={() =>
														props.createSamePlanCanada()
													}
												>
													Same plan for Canada
												</div>
											)}
										</div>
									</Dropdown>
									<Dropdown
										isOpen={showOtherScreens}
										onClose={() =>
											showOtherScreensOptions(false)
										}
										target={
											<button
												id="dropdown-button"
												data-testid="dropdown-button"
												onClick={() =>
													showOtherScreensOptions(
														true
													)
												}
												className="goToScrBtn"
											>
												<span className="selected-text">
													{" "}
													Go to other screens
													<i
														class="fa fa-angle-down genOverlayAngleDown1"
														aria-hidden="true"
													></i>
												</span>
											</button>
										}
									>
										<div>
											{map(screens, (screen) => {
												return (
													<span
														onClick={() =>
															props.updateSidebar(
																screen.link
															)
														}
													>
														<Link to={screen.link}>
															<div className="createdropdownOption">
																{
																	screen.screenName
																}
															</div>
														</Link>
													</span>
												);
											})}
										</div>
									</Dropdown>
								</div>
								<div
									className="divBtn"
									onClick={() => showOptionsHandler(false)}
								>
									I'm good until my plan is brewed
								</div>
							</>
						) : (
							<div
								onClick={() => showOptionsHandler(true)}
								className="showOptions"
							>
								<i
									class="fa fa-angle-down genOverlayAngleDown2"
									aria-hidden="true"
								></i>
							</div>
						)
					) : null} */}
				</div>
			</Fade>
		</Modal>
	);
};

const Menu = (props) => {
	return <Styled109 {...props}></Styled109>;
};

const Blanket = (props) => <Styled110 {...props}></Styled110>;
const Dropdown = ({ children, isOpen, target, onClose, className }) => (
	<Styled111 className={className}>
		{target}
		{isOpen ? <Menu>{children}</Menu> : null}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</Styled111>
);
const Styled109 = styled.div`
	background-color: white;
	border-radius: 2px;
	box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
		0 4px 11px hsla(218, 50%, 10%, 0.1);
	margin-top: 8px;
	position: absolute;
	left: 1px;
	z-index: 2;
	width: 98%;
	min-width: 150px;
`;
const Styled110 = styled.div`
	bottom: 0;
	left: 0;
	top: 0;
	right: 0;
	position: fixed;
	z-index: 1;
`;
const Styled111 = styled.div`
	position: relative;
	line-height: 20px;
`;
