import _ from "lodash";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../constants/Constants";

export const eventListFormatter = (eventList) => {
	let data = _.cloneDeep(eventList);
	const formattedData = [];
	!_.isEmpty(data) &&
		data.forEach((event) => {
			const formattedEvent = {
				event_id: event.event_id,
				event_name: event.name,
				label: event.name,
				value: event.event_id,
				start_date: event.start_date,
				end_date: event.end_date,
			};
			formattedData.push(formattedEvent);
		});
	return formattedData;
};

const formatdropdownSelection = (data) => {
	let formattedData = [];
	formattedData.push({
		value: data,
		label: data,
	});
	return formattedData;
};

const getEventStatus = (start_date, end_date) => {
	let status;
	const currDt = moment();
	if (currDt.isBetween(moment(start_date), moment(end_date), null, "[]")) {
		status = "ongoing";
	} else if (currDt.isAfter(moment(end_date))) {
		status = "completed";
	} else if (currDt.isBefore(moment(end_date))) {
		status = "upcoming";
	}
	return status;
};

export const basicEventDetailsFormatter = (eventDetails) => {
	console.log("*************eventDetails", eventDetails);
	const tempData = eventDetails?.[0] || {};

	const formattedData = {
		name: tempData.name,
		marketing_notes: tempData.marketing_notes,
		// customer_segment:[],
		end_date: moment(tempData.end_date),
		event_objective:
			formatdropdownSelection(tempData.event_objective) || [],
		event_objective_description: tempData.event_objective_description || "",
		event_type: formatdropdownSelection(tempData.event_type) || [],
		start_date: moment(tempData.start_date),
		submit_offer_by: moment(tempData.submit_offer_by),
	};

	return formattedData;
};

export const postbasicEventFormatter = (data) => {
	const formattedData = {
		name: data.name,
		start_date: data.start_date.format(DEFAULT_DATE_FORMAT),
		end_date: data.end_date.format(DEFAULT_DATE_FORMAT),
		submit_offer_by: data.submit_offer_by.format(DEFAULT_DATE_FORMAT),
		marketing_notes: data.marketing_notes,
		event_type: data.event_type?.length > 0 ? data.event_type[0].value : "",
		event_objective:
			data.event_objective?.length > 0
				? data.event_objective[0].value
				: "",
		event_objective_description: data.event_objective_description || "",
	};

	return formattedData;
};

export const marketingCalendarEventTableFormatter = (eventsList) => {
	let data = _.cloneDeep(eventsList);
	const formattedData = [];
	data.forEach((event) => {
		const formattedEvent = {
			...event,
			sales_units_baseline:
				event?.ia_recommended?.baseline_sales_units ||
				event?.finalized?.baseline_sales_units,
			units_ia: event?.recommended_sales_units			,
			units_finalized: event?.finalized_sales_units,
			units_incremental_ia:
				event?.recommended_incremental_sales_units,
			units_incremental_finalized:
				event?.finalized_incremental_sales_units				,
			revenue_baseline:
				event?.recommended_baseline_revenue ||
				event?.finalized_baseline_revenue				,
			revenue_ia: event?.recommended_revenue,
			revenue_finalized: event?.finalized_revenue,
			revenue_incremental_ia: event?.recommended_incremental_revenue,
			revenue_incremental_finalized:
				event?.finalized_incremental_revenue,
			gross_margin_baseline:
				event?.recommended_baseline_margin ||
				event?.finalized_baseline_margin,
			gross_margin_ia: event?.recommended_margin,
			gross_margin_finalized: event?.finalized_margin,
			gross_margin_incremental_ia:
				event?.irecommended_incremental_margin,
			gross_margin_incremental_finalized:
				event?.finalized_incremental_margin,
			event_status: event?.status,
			promosCount: event?.event_promo_count || 0,
			productsCount: event?.event_products_count,
			storeCount: event?.event_stores_count,
			event_lifecycle: event?.event_lifecycle_status,
			units_target: event?.finalized_units_target,
			revenue_target: event?.finalized_revenue_target,
			gross_margin_target: event?.finalized_gross_margin_target,
		};
		formattedData.push(formattedEvent);
	});

	return formattedData;
};

const calculateDaysLeft = (endDate) => {
	console.log("**********endDate");
	let today = new Date();
	if (today < Date.parse(endDate)) {
		const daysLeft = Math.round(
			(Date.parse(endDate) - today) / (1000 * 60 * 60 * 24)
		);

		return daysLeft;
	}

	return null;
};

export const marketingCalendarEventCalendarFormatter = (eventsList) => {
	let data = _.cloneDeep(eventsList);
	const formattedData = [];
	data.forEach((event) => {
		const formattedEvent = {
			...event,
			id: event.id,
			type: "event",
			status:
				event.status ||
				getEventStatus(event.start_date, event.end_date),
			budget: event.finalized_promo_spend,
			sales_units_baseline:
				event?.recommended_baseline_sales_units ||
				event?.finalized_baseline_sales_units				,
			units_ia: event?.recommended_sales_units			,
			units_finalized: event?.finalized_sales_units,
			units_incremental_ia:
				event?.recommended_incremental_sales_units,
			units_incremental_finalized:
				event?.finalized_incremental_sales_units				,
			revenue_baseline:
				event?.recommended_baseline_revenue	||
				event?.finalized_baseline_revenue				,
			revenue_ia: event?.recommended_revenue,
			revenue_finalized: event?.finalized_revenue,
			revenue_incremental_ia: event?.recommended_incremental_revenue,
			revenue_incremental_finalized:
				event?.finalized_incremental_revenue,
			gross_margin_baseline:
				event?.recommended_baseline_margin ||
				event?.finalized_baseline_margin,
			gross_margin_ia: event?.recommended_margin,
			gross_margin_finalized: event?.finalized_margin,
			gross_margin_incremental_ia:
				event?.recommended_incremental_margin,
			gross_margin_incremental_finalized:
				event?.finalized_incremental_margin,
			event_status: event?.status,
			promosCount: event?.event_promo_count || 0,
			productsCount: event?.event_products_count,
			storeCount: event?.event_stores_count,
			is_locked: event?.is_locked || 0,
			daysToDeadline: calculateDaysLeft(event.end_date),
			event_lifecycle: event?.event_lifecycle_status,
			units_target: event?.finalized_units_target,
			revenue_target: event?.finalized_revenue_target,
			gross_margin_target: event?.finalized_gross_margin_target,
		};
		formattedData.push(formattedEvent);
	});

	return formattedData;
};
