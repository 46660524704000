import React, { useEffect, useState } from "react";
import _ from "lodash";
import Button from "@mui/material/Button";

import "../tableRenderer/AgGridTable.scss";

const TextButtonCell = (props) => {

    const [cellValue, setCellValue] = useState(props.valueFormatted ? props.valueFormatted : props.value);

    const handleClick = () => {

        const obj = {
            rowData: props.data
        }
        props.handleButtonClick(obj);
    }

    return (
        <div className="text-button-cell">
            <Button
                // id="event-details-clear-filter"
                className="text-button"
                onClick={handleClick}
                variant="text"
            // disabled={!buttonState}
            >
                {cellValue}
            </Button>
        </div>
    );
};

export default TextButtonCell;
