import React, { useEffect, useRef, useState } from "react";

function AgGridHeaderRenderer(props) {
    const [sort, setSort] = useState("");
    const refButton = useRef(null);

    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current);
    };

    const onSortChanged = () => {
        let sortType = props.column.isSortAscending() ? "asc" : "";
        if (!sortType) sortType = props.column.isSortDescending() ? "desc" : "";
        setSort(sortType);
    };

    const onSortRequested = (order, event) => {
        props.setSort(order, event.shiftKey);
    };

    useEffect(() => {
        props.column?.addEventListener("sortChanged", onSortChanged);
        onSortChanged();
    }, []);

    let menu = null;
    if (!props.disableMenu) {
        menu = (
            <div
                ref={refButton}
                className="custom-ag-grid-header-menu-button"
                onClick={() => onMenuClicked()}
                onKeyDown={() => {}}
            >
                <span
                    className="ag-icon ag-icon-menu"
                    unselectable="on"
                    role="presentation"
                ></span>
            </div>
        );
    }

    let sortRender = null;
    if (!props.disableSorting) {
        sortRender = (
            <div style={{ display: "inline-block" }}>
                {sort ? (
                    <span
                        className={
                            sort === "asc"
                                ? "ag-icon ag-icon-asc"
                                : "ag-icon ag-icon-desc"
                        }
                        unselectable="on"
                        role="presentation"
                    ></span>
                ) : null}
            </div>
        );
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}
        >
            <div
                className="align-center hover-pointer"
                onClick={(event) =>
                    onSortRequested(
                        !sort ? "asc" : sort === "asc" ? "desc" : "",
                        event
                    )
                }
                onKeyDown={() => {}}
            >
                {props.customComp ? props.children : props.displayName}
                {sortRender}
            </div>
            {menu}
        </div>
    );
}

export default AgGridHeaderRenderer;
