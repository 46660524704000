/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React from "react";
import ReactSelect, { components } from "react-select";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./NewSelect.module.scss";

const inlineStyles = {
    container: (base) => ({
        ...base,
        lineHeight: "0",
    }),
    control: (base, x) => ({
        ...base,
        height: "38px",
        border: "0.5px solid #d8e0e8",
        borderRadius: "2px",
        cursor: "pointer !important",
        minHeight: "32px",
        // height: '32px',
        boxShadow: x.isFocused ? "0 0 0 1px hsl(0, 0%, 60%)" : "none",
        "&:hover": {
            ...base["&:hover"],
            border: x.isFocused
                ? "0.5px solid hsl(0, 0%, 60%)"
                : "0.5px solid #d8e0e8",
        },
    }),
    // placeholder: (base) => {
    //   console.log(base);
    //   // return base;
    //   return {
    //     ...base,
    //     font: 'normal normal normal 13px/20px Poppins',
    //     letterSpacing: '0px',
    //     color: '#1F2D3D',
    //     whiteSpace: 'nowrap',
    //     textOverflow: 'ellipsis',
    //     overflow: 'hidden',
    //   };
    // },
    singleValue: (base) => ({
        ...base,
        font: "normal normal normal 13px/20px Poppins, sans-serif",
        letterSpacing: "0px",
        color: "#1F2D3D",
    }),
    option: (base, { isDisabled, isFocused }) => ({
        ...base,
        backgroundColor: isDisabled
            ? "hsl(0, 0%, 90%)"
            : isFocused
            ? "hsl(0, 0%, 95%)"
            : "#fff",
        ":active": {
            ...base[":active"],
            backgroundColor: isDisabled ? "#fff" : "hsl(0, 0%, 90%)",
        },
        font: "normal normal normal 13px/20px Poppins, sans-serif",
        letterSpacing: "0px",
        color: "#1F2D3D",
        padding: "8px 0px 8px 14px",
        textAlign: "left",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "#3d5772",
        padding: "0px",
        margin: "8px",
        height: "20px",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        width: "0",
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0px 8px",
        textAlign: "left",
        // flexWrap: 'nowrap',
    }),
    multiValueRemove: (base) => ({
        ...base,
        paddingLeft: "2px",
        paddingRight: "2px",
    }),
    clearIndicator: (base) => ({
        ...base,
        color: "#3d5772",
        padding: "0px",
    }),
    menu: (base) => ({
        ...base,
        border: "1px solid #a7bace",
        zIndex: 100000,
    }),
    input: (base) => ({
        ...base,
        margin: "2px 0px",
    }),
};

export default function MultiSelect(props) {
    const {
        value,
        label,
        required,
        isMulti,
        placeholder,
        hideSelectedOptions,
        selectedItemLimit,
        showSelectedAsChip,
        onChange,
        behaveAsSingleSelect,
        isDisabled,
    } = props;

    const handleDelete = (index) => {
        value.splice(index, 1);
        onChange([...value]);
    };

    return (
        <>
            {label ? (
                <label className="select-label">
                    {label}
                    {required ? <span style={{ color: "red" }}>*</span> : null}
                </label>
            ) : null}
            <ReactSelect
                styles={inlineStyles}
                closeMenuOnSelect={!isMulti}
                controlShouldRenderValue={hideSelectedOptions}
                components={{
                    Placeholder: (propts) =>
                        Placeholder(propts, placeholder, value),
                    Option: (propts) =>
                        Option(propts, behaveAsSingleSelect, isMulti),
                    DropdownIndicator,
                }}
                {...props}
            />
            {showSelectedAsChip && (
                <div style={{}}>
                    {(value || []).map((item, index) => {
                        if (index < selectedItemLimit) {
                            return (
                                <Chip
                                    style={{ margin: "5px 2px 0px 0px" }}
                                    key={item.value}
                                    label={item.label}
                                    variant="outlined"
                                    onDelete={() => handleDelete(index)}
                                    disabled={isDisabled}
                                />
                            );
                        }
                        return "";
                    })}
                    {(value || []).length > selectedItemLimit && (
                        <Tooltip
                            title={(value || [])
                                .slice(selectedItemLimit)
                                .map((item) => item.label)
                                .join(", ")}
                        >
                            <Chip
                                style={{ marginTop: 5 }}
                                label={`+${
                                    (value || []).length - selectedItemLimit
                                }`}
                                variant="outlined"
                                disabled={isDisabled}
                            />
                        </Tooltip>
                    )}
                </div>
            )}
        </>
    );
}

function DropdownIndicator(propts) {
    return (
        <components.DropdownIndicator {...propts}>
            {/* <i className="fas fa-caret-down" /> */}
            <ExpandMoreIcon />
        </components.DropdownIndicator>
    );
}

function Placeholder(propts, placeholder, value) {
    if (placeholder)
        return (
            <components.Placeholder {...propts}>
                <div className={`${styles["select-font"]}`}>{placeholder}</div>
            </components.Placeholder>
        );
    return (
        <components.Placeholder {...propts}>
            {!value?.length ? (
                <div className={`${styles["select-font"]}`}>Type to search</div>
            ) : (
                <span className={`${styles["select-font"]}`}>
                    {value?.length} selected | Search..
                </span>
            )}
        </components.Placeholder>
    );
}

function Option(propts, behaveAsSingleSelect, isMulti) {
    if (behaveAsSingleSelect)
        return (
            <components.Option {...propts}>{propts.label}</components.Option>
        );
    return (
        <>
            <components.Option {...propts}>
                {isMulti ? (
                    <input
                        type="checkbox"
                        style={{ verticalAlign: "middle" }}
                        checked={propts.isSelected}
                        onChange={() => {}}
                    />
                ) : null}
                <span
                    className={`${styles["select-font"]}`}
                    style={{ paddingLeft: "14px" }}
                >
                    {propts.label}
                </span>
            </components.Option>
        </>
    );
}
